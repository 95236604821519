import React from 'react';

import { DOCUMENT_SOURCE } from '@src/constants/documents';
import { IDocument } from '@src/types/documents';

import DetailsRegionProvider from '@src/components/ui_v2/layout/details_region/provider';
import Section from '@src/components/utils_v2/section';

import { IRelatedDocumentsCollection } from './hooks';
import Table from './related_documents_table';

interface IRelatedDocumentsContentProps {
  collection: IRelatedDocumentsCollection;
  document: IDocument;
}

const RelatedDocumentsContent = ({
  collection,
  document,
}: IRelatedDocumentsContentProps) => (
  <Section.Provider section={ collection.section }>
    <DetailsRegionProvider>
      <Table
        isRelatedToExpenseReceipt={ document.source === DOCUMENT_SOURCE.FROM_EXPENSE_REPORTS }
        records={ collection.records }
        sorting={ collection.sorting }
      />
    </DetailsRegionProvider>
  </Section.Provider>
);

export default React.memo(RelatedDocumentsContent);
