import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IFullPageModalBodyProps {
  children: React.ReactNode,
  hasSidemenu?: boolean;
  className?: string;
}

const FullPageModalBody = ({
  children,
  hasSidemenu,
  className,
}: IFullPageModalBodyProps): JSX.Element => {
  const classes = classNames(
    styles['full-page-modal-body'],
    className,
    {
      [styles['with-side-menu']]: hasSidemenu,
    },
  );

  return (
    <div className={ classes }>
      { children }
    </div>
  );
};

export default FullPageModalBody;
