import React from 'react';

import { AppLink } from '@src/components/ui_v2/buttons';

import ChatIconWithBadge from './chat_with_badge';

import styles from './style.module.scss';

interface LinkedChatIconWithBadgeProps {
  businessId: number;
  unreadCount: number;
}

const LinkedChatIconWithBadge: React.FC<LinkedChatIconWithBadgeProps> = ({ businessId, unreadCount }) => {
  return (
    <div className={ styles['icon-badge-container'] }>
      <AppLink href={ `/chats?channelUrl=DYTBID_${businessId}` }>
        <ChatIconWithBadge unreadCount={ unreadCount } />
      </AppLink>
    </div>
  );
};

export default LinkedChatIconWithBadge;
