import React from 'react';

import { useAtom } from 'jotai';

import { SwitchInput } from '@src/components/ui_v2/inputs';

import { toggleTargetsState } from '../atoms';

const ToggleTargetsAction = (): JSX.Element => {
  const [showTargets, setShowTargets] = useAtom(toggleTargetsState);

  return (
    <div className="display-flex p-t-5">
      <span className="m-r-10 m-t-5">Targets</span>
      <span>
        <SwitchInput
          checked={ showTargets }
          onChange={ () => setShowTargets(!showTargets) }
        />
      </span>
    </div>
  );
};

export default ToggleTargetsAction; 