import React, { useMemo } from 'react';

import { useGetLimitedBusinesses } from '@src/hooks/queries/businesses';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import Spinner from '@src/components/ui/spinner';
import BusinessesIcon from '@src/components/utils/icomoon/businesses';

import EditLinkedBusinessesAction from './actions/edit_linked_businesses_action';

import styles from './styles.module.scss';

interface ILinkBusinessesProps {
  businessId: TID,
  vendorId: TID,
  linkedBusinesses: IBusiness[],
  refetchBusinesses: () => void,
}

const LinkBusinesses = ({
  businessId,
  vendorId,
  linkedBusinesses,
  refetchBusinesses,
}: ILinkBusinessesProps): JSX.Element | null => {
  const query = useGetLimitedBusinesses({
    includeEmployedBusiness: true,
    simple:                  true,
    page:                    1,
  });

  const businesses = useMemo(() => {
    if (!query.data?.collection) return [];

    return query.data.collection
      .filter((business) => business.id.toString() !== businessId.toString());
  }, [query.data?.collection, businessId]);

  if (query.isLoading) return <Spinner />;

  return (
    <div className={ styles['link-businesses-container'] }>
      <BusinessesIcon fontSize={ 64 } />
      {businesses.length > 0 ? (
        <>
          <h2 className={ styles['link-businesses-title'] }>
            Link the vendor to other businesses
          </h2>
          <p className={ styles['link-businesses-description'] }>
            All edits and changes to this vendor will be applied across all selected businesses.
          </p>
          <EditLinkedBusinessesAction
            businessId={ businessId }
            linkedBusinesses={ linkedBusinesses }
            vendorId={ vendorId }
            onDone={ refetchBusinesses }
          />
        </>
      ) : (
        <>
          <h2 className={ styles['link-businesses-title'] }>
            No other businesses to link.
          </h2>
          <p className={ styles['link-businesses-description'] }>
            To use this vendor across multiple businesses, the businesses must first be added on Docyt, and you need to have access to them.
          </p>
        </>
      )}
    </div>
  );
};

export default LinkBusinesses;
