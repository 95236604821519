/* eslint-disable no-shadow */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useReviewDocumentRequest, useAddNotesToDocumentRequest } from '@src/hooks/queries/document_requests';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocumentModel } from '@src/types/document_model';
import { IDocumentRequest } from '@src/types/document_requests';
import { IDocument } from '@src/types/documents';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import Form from '@src/components/ui_v2/form';

import DocumentsSection from '../documents_section';
import NotesSection from '../notes_section';

import styles from '../document_list.module.scss';

export enum DocumentRequestState {
  REVIEWED = 'reviewed',
  OPENED = 'opened',
}

interface DocumentConversationRightSideViewProps {
  businessId: TID;
  model: IDocumentModel;
  bankStatement: IBankStatement;
  documents: IDocument[];
  balanceSheetStatements: IBalanceSheetStatement[];
  onClose: () => void;
  isLast: boolean;
  documentRequests: IDocumentRequest[];
}

interface IConversationFormInput {
  documents: IDocument[];
  note?: string;
}

const DocumentConversationRightSideView: React.FC<DocumentConversationRightSideViewProps> = ({
  bankStatement,
  businessId,
  model,
  documents: initialDocuments,
  balanceSheetStatements: initialBalanceSheetStatements,
  onClose,
  isLast,
  documentRequests,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSavingNotes, setIsSavingNotes] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isDocumentsExpanded, setIsDocumentsExpanded] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const balanceSheetStatements = useMemo<IBalanceSheetStatement[]>(
    () => initialBalanceSheetStatements,
    [initialBalanceSheetStatements],
  );
  const [noStatementAvailable, setNoStatementAvailable] =
  useState<boolean>(Boolean(model.get('no_statement_available')));
  const [note, setNote] = useState<string>(String(model.get('notes') || ''));
  const [isReviewed] = useState<boolean>(model.get('state') === DocumentRequestState.REVIEWED);
  const [documents, setDocuments] = useState<IDocument[]>(initialDocuments);

  const {
    handleSubmit,
  } = useForm<IConversationFormInput>();

  const reviewDocumentRequest = useReviewDocumentRequest();
  const addNotesToDocumentRequest = useAddNotesToDocumentRequest();

  const isReviewedState = useMemo(() => model.get('state') === DocumentRequestState.REVIEWED, [model]);

  const updateLastStatus = useCallback(() => {
    if (isReviewedState) {
      window.Docyt.vent.trigger('transactions:request:done');
    }
  }, [isReviewedState]);

  useEffect(() => {
    if (!window.Docyt) {
      return undefined;
    }

    if (!window.Docyt.rightSideViewContent) {
      window.Docyt.rightSideViewContent = {};
    }

    window.Docyt.rightSideViewContent.updateLastStatus = updateLastStatus;

    return () => {
      if (window.Docyt?.rightSideViewContent) {
        window.Docyt.rightSideViewContent.updateLastStatus = undefined;
      }
    };
  }, [updateLastStatus]);

  const saveNotes = useCallback(async (noteText: string) => {
    const trimmedNoteText = noteText.trim();
    try {
      setIsSavingNotes(true);
      await addNotesToDocumentRequest.mutateAsync({
        documentRequestId: model.id,
        notes:             trimmedNoteText,
      });

      window.Docyt.vent.trigger('document:request:notes:updated', {
        requestId: model.id,
        notes:     trimmedNoteText,
      });

      setNote(trimmedNoteText);
    } finally {
      setIsSavingNotes(false);
    }
  }, [model, addNotesToDocumentRequest, setIsSavingNotes]);

  const handleNoteChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;

    setNote(newValue);

    if (newValue === '' && note) {
      saveNotes('');
    }
  }, [note, saveNotes]);

  const handleNoteBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    saveNotes(e.target.value);
  }, [saveNotes]);

  const toggleDocumentsSection = useCallback(() => {
    setIsDocumentsExpanded((prev) => !prev);
  }, []);

  const findNextDocumentRequest = useCallback(() => {
    const currentState = model.get('state');
    const filteredRequests = documentRequests?.filter((req) => {
      if (currentState === DocumentRequestState.REVIEWED) {
        return req.state === DocumentRequestState.REVIEWED;
      }
      return req.state === DocumentRequestState.OPENED;
    });

    const currentIndex = filteredRequests?.findIndex((req) => req.id === model.id);

    if (currentIndex >= 0 && currentIndex < (filteredRequests?.length || 0) - 1) {
      const nextRequest = filteredRequests[currentIndex + 1];

      const nextRequestRow = document.querySelector(
        `.client-central-main-view .document-requests-region tr[data-request-id="${nextRequest.id}"]`,
      );
      if (nextRequestRow) {
        const detailColumn = nextRequestRow.querySelector('td.request-detail-column');
        if (detailColumn) {
          (detailColumn as HTMLElement).click();
          return null;
        }
      }

      return nextRequest;
    }

    return null;
  }, [documentRequests, model]);

  const onSubmit = useCallback(async (_data: IConversationFormInput) => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const nextRequest = findNextDocumentRequest();

      if (!isReviewed) {
        await reviewDocumentRequest.mutateAsync({
          documentRequestId: model.id,
          notes:             note,
          state:             DocumentRequestState.REVIEWED,
        });

        toastr.success('Document marked as reviewed successfully', 'Success');
        window.Docyt.vent.trigger('document:request:notes:updated', {
          requestId: model.id,
          notes:     note,
        });
      }

      window.Docyt.vent.trigger('document:request:reviewed', model.id);

      if (nextRequest) {
        window.Docyt.vent.trigger('request:item:unselected');

        setTimeout(() => {
          const nextRequestElement =
            document.querySelector(`tr[data-request-id="${nextRequest.id}"] td.request-detail-column`);
          if (nextRequestElement) {
            (nextRequestElement as HTMLElement).click();
            window.Docyt.vent.trigger('request:item:clicked', nextRequest);
            window.Docyt.vent.trigger('active:request:item:changed', nextRequest);

            const parentTr = nextRequestElement.closest('tr');
            if (parentTr) {
              parentTr.classList.add('selected');
            }
          } else {
            window.Docyt.vent.trigger('request:item:clicked', nextRequest);
            window.Docyt.vent.trigger('active:request:item:changed', nextRequest);
          }
        }, 100);
      }

      if (!nextRequest) {
        window.Docyt.vent.trigger('client:central:right:side:closed');
      }

      window.Docyt.vent.trigger('transactions:request:done');
    } catch {
      setErrorMessage('An error occurred while processing the request');
      toastr.error('Failed to mark document as reviewed', 'Error');
    } finally {
      setIsLoading(false);
    }
  }, [isReviewed, model.id, findNextDocumentRequest, isLoading, reviewDocumentRequest, note]);

  const getReviewButtonText = useCallback(() => {
    if (isReviewed) {
      return 'Review Next';
    }

    return isLast ? 'Mark as Reviewed' : 'Mark as Reviewed - Review Next';
  }, [isReviewed, isLast]);

  const handleCloseClick = useCallback(() => {
    window.Docyt.vent.trigger('request:item:unselected');
    onClose();
  }, [onClose]);

  const documentModel = useMemo(() => ({
    id:             model.id,
    business_id:    businessId,
    bank_statement: bankStatement ? {
      ...bankStatement,
      docytId: bankStatement.docytId,
    } : null,
    balance_sheet_statements: balanceSheetStatements?.map((stmt) => ({
      ...stmt,
      docytId:      stmt.docytId,
      request_type: model.get('request_type'),
    })),
    isBankStatementRequest:         () => model.get('document_type') === 'bank-statement',
    isBalanceSheetStatementRequest: () => model.get('document_type') === 'balance-sheet-statement',
    isMailroomRequest:              () => model.get('document_type') === 'mailroom',
    get:                            (key: string) => {
      if (key === 'business_id') return businessId;
      if (key === 'document_type') {
        if (bankStatement?.statementFileUrl) return 'bank-statement';
        if (balanceSheetStatements?.[0]?.state !== 'requested') return 'balance-sheet-statement';
        return 'mailroom';
      }
      return model.get(key);
    },
  }), [model, businessId, bankStatement, balanceSheetStatements]);

  const handleDocumentsUpdate = useCallback((updatedDocs: IDocument[]) => {
    setDocuments(updatedDocs);
  }, []);

  const shouldShowButton = useCallback(() => {
    if (isReviewed) {
      return !isLast;
    }
    return true;
  }, [isReviewed, isLast]);

  const isMailroomRequest = model.get('document_type') === 'mailroom';

  const isSubmitDisabled = useMemo(() => {
    if (isReviewed) return false;
    const hasUploadedDocuments = documents.length > 0
      || bankStatement?.state === 'uploaded'
      || balanceSheetStatements?.some((stmt) => stmt.state === 'uploaded');

    const hasNoteOrNoStatement = Boolean(note.trim()) || noStatementAvailable;

    return !(hasUploadedDocuments || hasNoteOrNoStatement);
  }, [isReviewed, documents, bankStatement, balanceSheetStatements, note, noStatementAvailable]);

  return (
    <div>
      {shouldShowButton() ? (
        <SideView.Form
          isOpen
          isRoot
          doneTitle={ getReviewButtonText() }
          isSubmitDisabled={ isSubmitDisabled }
          title="Conversations"
          onCancel={ handleCloseClick }
        >
          {({ formId }) => (
            <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
              {isLoading && <Spinner />}
              {errorMessage && (
              <SideView.Error>
                <div className="error-message">
                  <span>{errorMessage}</span>
                </div>
              </SideView.Error>
              )}
              <div className={ styles.sections }>
                <DocumentsSection
                  balanceSheetStatements={ balanceSheetStatements as IBalanceSheetStatement[] }
                  bankStatement={ bankStatement }
                  documents={ documents }
                  isDocumentsExpanded={ isDocumentsExpanded }
                  isMailroomRequest={ isMailroomRequest }
                  isReviewed={ isReviewed }
                  isUploading={ isUploading }
                  model={ documentModel }
                  noStatementAvailable={ noStatementAvailable }
                  notes={ note }
                  setDocuments={ setDocuments }
                  setIsUploading={ setIsUploading }
                  setNoStatementAvailable={ setNoStatementAvailable }
                  toggleDocumentsSection={ toggleDocumentsSection }
                  onDocumentsUpdate={ handleDocumentsUpdate }
                />

                <hr className={ styles['section-divider'] } />

                <NotesSection
                  isReviewed={ isReviewed }
                  isSaving={ isSavingNotes }
                  note={ note }
                  onNoteBlur={ handleNoteBlur }
                  onNoteChange={ handleNoteChange }
                />
              </div>
            </Form>
          )}
        </SideView.Form>
      ) : (
        <SideView.Content isOpen isRoot>
          <SideView.Header
            title="Conversations"
            onClose={ handleCloseClick }
          />
          <SideView.Body onClosed={ handleCloseClick }>
            {isLoading && <Spinner />}
            {errorMessage && (
              <SideView.Error>
                <div className="error-message">
                  <span>{errorMessage}</span>
                </div>
              </SideView.Error>
            )}
            <div className={ styles.sections }>
              <DocumentsSection
                balanceSheetStatements={ balanceSheetStatements as IBalanceSheetStatement[] }
                bankStatement={ bankStatement }
                documents={ documents }
                isDocumentsExpanded={ isDocumentsExpanded }
                isMailroomRequest={ isMailroomRequest }
                isReviewed={ isReviewed }
                isUploading={ isUploading }
                model={ documentModel }
                noStatementAvailable={ noStatementAvailable }
                notes={ note }
                setDocuments={ setDocuments }
                setIsUploading={ setIsUploading }
                setNoStatementAvailable={ setNoStatementAvailable }
                toggleDocumentsSection={ toggleDocumentsSection }
                onDocumentsUpdate={ handleDocumentsUpdate }
              />

              <hr className={ styles['section-divider'] } />

              <NotesSection
                isReviewed={ isReviewed }
                isSaving={ isSavingNotes }
                note={ note }
                onNoteBlur={ handleNoteBlur }
                onNoteChange={ handleNoteChange }
              />
            </div>
          </SideView.Body>
        </SideView.Content>
      )}
    </div>
  );
};

export default DocumentConversationRightSideView;
