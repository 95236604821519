import React, { useMemo } from 'react';

import { IPopoverParams } from '@src/hooks/popover';
import { ITooltipVariant } from '@src/types/tooltip';

import HoverContainer from './hover_container';
import Provider from './provider';
import Tooltip from './tooltip';

const defaultPopoverParams = {
  showDelay: 1000,
};

const TOOLTIP_HIDE_DELAY = 300;

interface IHoverTooltipProps extends React.ComponentPropsWithoutRef<typeof HoverContainer> {
  children: React.ReactNode,
  content: React.ReactNode | undefined,
  isControl?: boolean
  showTooltip?: boolean,
  tooltipClassName?: string,
  label?: string,
  variant?: ITooltipVariant,
  popoverParams?: IPopoverParams,
  isVisibleArrow?: boolean,
  keepShowOnHover?: boolean,
  width?: string | number,
}

const HoverTooltip = ({
  children,
  content,
  isControl,
  label,
  variant,
  showTooltip,
  tooltipClassName,
  popoverParams = defaultPopoverParams,
  isVisibleArrow,
  keepShowOnHover = false,
  width,
  ...props
}: IHoverTooltipProps): JSX.Element => {
  const hasContent = Boolean(content);

  const popParams = useMemo(() => {
    let params: IPopoverParams = defaultPopoverParams;

    if (keepShowOnHover) {
      params = { ...params, hideDelay: TOOLTIP_HIDE_DELAY };
    }

    return { ...params, ...popoverParams };
  }, [keepShowOnHover, popoverParams]);

  return (
    <Provider popoverParams={ popParams }>
      <HoverContainer
        isControl={ isControl }
        showTooltip={ showTooltip }
        { ...props }
      >
        { children }
      </HoverContainer>
      { hasContent && (
        <Tooltip
          className={ tooltipClassName }
          isVisibleArrow={ isVisibleArrow }
          keepShowOnHover={ keepShowOnHover }
          label={ label }
          variant={ variant }
          width={ width }
        >
          { content }
        </Tooltip>
      )}
    </Provider>
  );
};

export default HoverTooltip;
