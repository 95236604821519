import React, { useCallback } from 'react';

import { businessSettingsPath, clientBusinessSettingsPath } from '@src/routes';
import { TID } from '@src/types/common';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { Button } from '@src/components/ui_v2/buttons';

const CompleteSetupButton = ({ businessId }: { businessId: TID }) => {
  const handleClick = useCallback(() => {
    if (window.Docyt.currentAdvisor.isBookgptBusinessUser()) {
      backboneNavigateToUrl(businessSettingsPath(businessId));
    } else if (window.Docyt.currentAdvisor.defaultAccountingFirmId()) {
      const defaultAccountingFirmId = window.Docyt.currentAdvisor.defaultAccountingFirmId();
      backboneNavigateToUrl(clientBusinessSettingsPath(businessId, defaultAccountingFirmId));
    }
  }, [businessId]);

  return (
    <Button size="compact" variant="outline" onClick={ handleClick }>Complete Setup</Button>
  );
};

export default CompleteSetupButton;
