/* eslint-disable max-len */
import React, { FC, useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IGetAdjustmentEntriesResponse } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry, TIAdjustmentEntrySortColumn } from '@src/types/adjustment_entries';
import { IBankAccountReconciliation } from '@src/types/bank_account_reconciliations';
import { ISorting } from '@src/types/sorting';

import NameColumn from '@src/components/reconciliation_center/journal_entry/name_column';
import { buildItemTypesFromArray } from '@src/components/reconciliation_center/month_end/utils';
import CollectionTable from '@src/components/ui_v2/collection_table';
import Tooltip from '@src/components/ui_v2/tooltip';
import { CopyIcon, EditIcon } from '@src/components/utils/icomoon';
import TrashIcon from '@src/components/utils/icomoon/trash';

import DeleteIcon from './delete_icon';
import Filter from './filter';

interface TableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetAdjustmentEntriesResponse, Error>,
  records: IAdjustmentEntry[],
  sorting: ISorting<TIAdjustmentEntrySortColumn>,
  reconciliation?: IBankAccountReconciliation,
  duplicate?: (id: number) => void,
}

const isNormalJournalEntry = (it: IAdjustmentEntry) => it.source?.type === 'Normal';

const duplicateIcon = (adjustmentEntry: IAdjustmentEntry, duplicate?: (id: number) => void) => {
  const enabled = isNormalJournalEntry(adjustmentEntry);

  if (enabled) {
    return <CopyIcon fontSize={ 19 } onClick={ () => duplicate && duplicate(adjustmentEntry.id) } />;
  }

  return (
    <Tooltip.Hover content="It is an automated journal entry created by Docyt workflows and cannot be copied.">
      <CopyIcon fontSize={ 19 } inColor="grey-500" />
    </Tooltip.Hover>
  );
};

const editIcon = (adjustmentEntry: IAdjustmentEntry) => {
  return <EditIcon fontSize={ 16 } id={ adjustmentEntry.id.toString() } />;
};

const qboLink = (adjustmentEntry: IAdjustmentEntry) => {
  if (adjustmentEntry.qboError) {
    return (
      <span className="display-inline-flex in-grey-700 align-items-center" title={ adjustmentEntry.qboError }>
        <span className="icon-qbo-error font-18" />
      </span>
    );
  }

  return (
    <a aria-label="QBO Link" className="display-inline-flex in-green-600 align-items-center" href={ adjustmentEntry.qboLink?.qboLink } rel="noreferrer" target="_blank">
      <span className="icon-qbo-on font-18" />
    </a>
  );
};

const formatCategories = (categories: string[]) => {
  if (categories.length === 0) {
    return '';
  }

  if (categories.length === 1) {
    return categories[0];
  }

  return `${categories[0]?.toString()?.substring(0, 15)} + ${categories.length - 1} more`;
};

const renderNameColumn = (document: IAdjustmentEntry) => <NameColumn document={ document } />;

const Table: FC<TableProps> =
  ({ records, sorting, infiniteQuery, reconciliation, duplicate }) => {
    const items = useMemo(
      () => buildItemTypesFromArray(reconciliation?.bankAccountReconciliationItems || []),
      [reconciliation?.bankAccountReconciliationItems],
    );

    const difference = items.difference;
    const acknowledged = !difference || difference.status === 'acknowledged';

    const business = useBusinessContext();

    const trashIcon = (adjustmentEntry: IAdjustmentEntry) => {
      const enabled = isNormalJournalEntry(adjustmentEntry);
      if (!enabled) {
        return (
          <Tooltip.Hover content="It is an automated journal entry created by Docyt workflows and cannot be deleted.">
            <TrashIcon inColor="grey-500" />
          </Tooltip.Hover>
        );
      }

      const isBeforeBookClosingDate = business?.reconciliationStartDate && adjustmentEntry.entryDate && (new Date(adjustmentEntry.entryDate) <= new Date(business.reconciliationStartDate));
      if (isBeforeBookClosingDate) {
        return (
          <Tooltip.Hover content="Cannot delete the journal entry since the journal entry belongs to a period before book closing date.">
            <TrashIcon inColor="grey-500" />
          </Tooltip.Hover>
        );
      }

      return <DeleteIcon adjustmentEntry={ adjustmentEntry } />;
    };

    return (
      <CollectionTable<IAdjustmentEntry, TIAdjustmentEntrySortColumn>
        isRegionScroll
        filter={ <Filter /> }
        height="calc(80vh - 120px)"
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.DateColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="entry_date"
          sortColumn="entry_date"
          title="Date"
          value={ (document) => document.entryDate }
        />

        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="docyt_id"
          sortColumn="docyt_id"
          title="ID"
          value={ renderNameColumn }
        />

        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="group"
          sortColumn="group"
          title="Type"
          value={ (document) => document.group }
        />

        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="debit_categories"
          title="Debit Account"
          tooltip={ (it) => it.debitCategories?.join(', ') }
          value={ (document) => formatCategories(document.debitCategories || []) }
        />

        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="credit_categories"
          title="Credit Account"
          tooltip={ (it) => it.creditCategories?.join(', ') }
          value={ (document) => formatCategories(document.creditCategories || []) }
        />

        <CollectionTable.TextColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="created_by"
          title="Created By"
          value={ (document) => document.createdByName ?? '--' }
        />

        <CollectionTable.AmountColumn<
            IAdjustmentEntry,
            TIAdjustmentEntrySortColumn
          >
          name="debits"
          sortColumn="debits"
          title="Amount"
          value={ (document) => document.debits }
        />

        <CollectionTable.IconColumn<IAdjustmentEntry>
          name="link"
          title="QBO Link"
          value={ (adjustmentEntry) => qboLink(adjustmentEntry) }
        />

        <CollectionTable.IconColumn<IAdjustmentEntry>
          name="edit"
          title="Edit"
          value={ (adjustmentEntry) => (acknowledged ? '' : editIcon(adjustmentEntry)) }
        />

        <CollectionTable.IconColumn<IAdjustmentEntry>
          name="duplicate"
          title="Duplicate"
          value={ (adjustmentEntry) => duplicateIcon(adjustmentEntry, duplicate) }
        />

        <CollectionTable.IconColumn<IAdjustmentEntry>
          name="delete"
          title="Delete"
          value={ (adjustmentEntry) => trashIcon(adjustmentEntry) }
        />
      </CollectionTable>
    );
  };

export default Table;
