import React, { useEffect, useMemo, useState } from 'react';

import { useAtomValue } from 'jotai';

import { useGetMultiBusinessReportItemAccountValuesQuery } from '@src/hooks/queries/report_service/multi_business_report';
import { IBusiness } from '@src/types/businesses';
import { IReport } from '@src/types/report_service/report';
import { IReportItemAccountValue } from '@src/types/report_service/report_item_value_account';
import { getReportBTFEndPoint } from '@src/utils/config';

import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import QueryStatus from '@src/components/utils/query_status';

import List from './list';

interface IMultiEntityItemAccountValuesDetailsProps {
  multiBusinessReportId: string,
  from: string,
  to: string,
  itemIdentifier: string,
  businesses: IBusiness[],
  reports: IReport[],
}

const MultiEntityItemAccountValuesDetails = ({
  multiBusinessReportId,
  from,
  to,
  itemIdentifier,
  businesses,
  reports,
}: IMultiEntityItemAccountValuesDetailsProps): JSX.Element => {
  const [aggregatedValues, setAggregatedValues] = useState<IReportItemAccountValue[]>([]);
  const [businessItemValues, setBusinessItemValues] = useState<any[]>([]);
  const hideZeroRows = useAtomValue(hideZeroRowsType);
  const endPoint =
    getReportBTFEndPoint(
      window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.MULTI_BUSINESS_REPORT_ITEM_ACCOUNT_VALUES,
    );
  const valuesQuery = useGetMultiBusinessReportItemAccountValuesQuery(
    endPoint,
    {
      multiBusinessReportId,
      from,
      to,
      itemIdentifier,
    },
  );

  const aggregatedItemAccountValues = useMemo(
    () => valuesQuery.data?.aggregatedItemAccountValues || [],
    [valuesQuery.data?.aggregatedItemAccountValues]
  );

  const businessItemAccountValues = useMemo(
    () => valuesQuery.data?.businessItemAccountValues || [],
    [valuesQuery.data?.businessItemAccountValues]
  );

  useEffect(() => {
    if (hideZeroRows) {
      const tmpAggregatedItemAccountValues = [];
      const tmpBusinessItemAccountValues = [];

      for (let i = 0; i < aggregatedItemAccountValues.length; i += 1) {
        let isNoneZero = false;
        const aggregated = aggregatedItemAccountValues[i];
        const businessItems = businessItemAccountValues.filter((bi => aggregated.name === bi.name));
        if (businessItems.some((bi => Number(bi.value) !== 0))) {
          isNoneZero = true;
          for (let j = 0; j < businessItems.length; j += 1) {
            const businessItem = businessItems[j];
            tmpBusinessItemAccountValues.push(businessItem);
          }
        };

        if (isNoneZero) tmpAggregatedItemAccountValues.push(aggregated);
      }

      setAggregatedValues(tmpAggregatedItemAccountValues);
      setBusinessItemValues(tmpBusinessItemAccountValues);
    } else {
      setAggregatedValues(aggregatedItemAccountValues);
      setBusinessItemValues(businessItemAccountValues);
    }
  }, [
    hideZeroRows,
    aggregatedItemAccountValues,
    businessItemAccountValues,
    setAggregatedValues,
    setBusinessItemValues,
  ]);

  return (
    <>
      <QueryStatus query={ valuesQuery } />
      <List
        aggregatedItemAccountValues={ aggregatedValues }
        businessItemAccountValues={ businessItemValues }
        businesses={ businesses }
        from={ from }
        itemIdentifier={ itemIdentifier }
        multiBusinessReportId={ multiBusinessReportId }
        reports={ reports }
        to={ to }
      />
    </>
  );
};

export {
  IMultiEntityItemAccountValuesDetailsProps,
  MultiEntityItemAccountValuesDetails as default,
};
