import React from 'react';

import { IReconciliationProps } from '@src/types/common_operational_dashboard';

import {
  uncategorizedTransactionsTooltipData,
  unclearedDocumentsTooltipData,
} from '@src/components/operational_dashboard/helper';
import { ICommonProps } from '@src/components/operational_dashboard/types';
import EncryptedIcon from '@src/components/utils/icomoon/encrypted';

import ReconciliationItem from './reconciliation_item';
import { encryptedIconColor } from './utils';

import styles from './style.module.scss';

interface IReconciliationCellProps extends IReconciliationProps, ICommonProps {}
const ReconciliationCell = ({
  uncategorizedTransactions,
  unclearedDocuments,
  preferences,
  setupStatus,
  businessId,
}: IReconciliationCellProps) => {
  return (
    <div className={ `${styles['business-reconciliation-container']} ${styles['expense-header-container']}` }>
      {preferences?.continuousReconciliation?.uncategorizedTransactions
        && (uncategorizedTransactions !== undefined ? (
          <div className={ styles['header-item'] }>
            <ReconciliationItem
              businessId={ businessId }
              setupStatus={ setupStatus }
              tooltipData={ uncategorizedTransactionsTooltipData }
              tooltipValue={ uncategorizedTransactions }
              value={ uncategorizedTransactions }
            />
          </div>
        )
          : (
            <div className={ styles['header-item'] }>
              <EncryptedIcon fontSize={ 20 } inColor={ encryptedIconColor } />
            </div>
          ))}
      {preferences?.continuousReconciliation?.unclearedDocuments
        && (unclearedDocuments !== undefined ? (
          <div className={ styles['header-item'] }>
            <ReconciliationItem
              businessId={ businessId }
              setupStatus={ setupStatus }
              tooltipData={ unclearedDocumentsTooltipData }
              tooltipValue={ unclearedDocuments }
              value={ unclearedDocuments }
            />
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            <EncryptedIcon fontSize={ 20 } inColor={ encryptedIconColor } />
          </div>
        ))}
    </div>
  );
};
export default ReconciliationCell;
