import React, { useState } from 'react';

import classNames from 'classnames';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Content from '@src/components/reconciliation_center/journal_entry/content';
import Root from '@src/components/root';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';

import ActivityHistoryContainer from './activity_history_container';

import styles from './styles.module.scss';

const JournalEntry: React.FC<{ businessId: TID }> = ({ businessId }) => {
  const [isShowAuditHistory, setIsShowAuditHistory] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<TID | null>(null);

  const auditHistoryClasses = classNames(styles['audit-history-container'], {
    [styles.hidden]: !isShowAuditHistory,
  });

  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <DetailsRegion className={ styles['details-region-top'] }>
          <Content setDocumentId={ setDocumentId } setIsShowAuditHistory={ setIsShowAuditHistory } />
          <div className={ auditHistoryClasses }>
            <ActivityHistoryContainer
              documentId={ documentId }
              isShowAuditHistory={ isShowAuditHistory }
              setIsShowAuditHistory={ setIsShowAuditHistory }
            />
          </div>
        </DetailsRegion>
      </BusinessProvider>
    </Root>
  );
};

export default JournalEntry;
