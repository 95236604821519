module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<h2 class="font-24 font-semi-bold">Setup Payment Methods</h2>\n<hr/>\n<!--start authorized payer-->\n<div class="ach-check-setting-item-wrapper display-flex align-items-center in-black ');
    
      __out.push(__sanitize(this.ap_service.get('is_setup_authorized_payer') ? 'pointer authorized-payer-view-js' : ''));
    
      __out.push('">\n    <div class="display-flex align-items-center width-40-percent">\n        <div class="middle-avatar-wrapper">\n            <i class="icon icon-authorized-payers font-39">\n                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span>\n            </i>\n        </div>\n        <span class="font-18 m-l-20">Authorized Signatory</span>\n    </div>\n    <div class="display-flex align-items-center width-60-percent">\n    ');
    
      if (this.ap_service.get('is_setup_authorized_payer')) {
        __out.push('\n        <div class="m-l-15 in-grey-1050 font-14">');
        __out.push(__sanitize(this.ap_service.get('count_bank_account_admins')));
        __out.push(' Bank Account Admin');
        __out.push(__sanitize(this.ap_service.get('count_bank_account_admins') === 1 ? '' : 's'));
        __out.push('</div>\n    ');
      }
    
      __out.push('\n    </div>\n    <div class="display-flex align-items-center">\n        ');
    
      if (this.ap_service.get('is_setup_authorized_payer')) {
        __out.push('\n        <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/payers');
        if (this.for_onboarding) {
          __out.push(__sanitize('?for_onboarding=true'));
        }
        __out.push('" class="pull-right pointer font-16 font-semi-bold authorized-payer-view-js"><i class="icon icon-arrow-right"></i></a>\n        ');
      } else {
        __out.push('\n        <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/confirm_payers');
        if (this.for_client) {
          __out.push(__sanitize('?for_onboarding=true'));
        }
        __out.push('" class="btn btn-blue pull-right font-16 font-semi-bold btn-activate-payers-js">Activate</a>\n        ');
      }
    
      __out.push('\n    </div>\n</div>\n<!--start bank connection-->\n');
    
      if (this.ap_service.get('is_setup_generate_check') && this.ap_service.get('ach_document')) {
        __out.push('\n<div class="ach-check-setting-item-wrapper in-black display-flex align-items-center pointer docyt-check-view-js">\n    <div class="display-flex align-items-center width-40-percent">\n        <div class="middle-avatar-wrapper">\n            <i class="icon icon-bank-connection font-39">\n                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span>\n            </i>\n        </div>\n        <span class="font-18 m-l-20">Bank Account Connection</span>\n    </div>\n    <div class="display-flex align-items-center width-60-percent">\n        <div class="col-xs-6 in-grey-1050 font-14">');
        __out.push(__sanitize(this.ap_service.get('ach_document').name));
        __out.push('</div>\n        ');
        if (this.ap_service.get('ach_document').state !== 'verified') {
          __out.push('\n        <div class="col-xs-6 in-orange-300 font-14 font-semi-bold">\n            <i class="icon icon-unable-to-sync font-18 m-r-8 vertical-align-middle"><span class="path1"></span><span class="path2"></span></i>\n            <span class="vertical-align-middle">Verify Bank Account for Docyt Check</span>\n        </div>\n        ');
        }
        __out.push('\n    </div>\n    <div class="display-flex align-items-center">\n        <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/checks');
        if (this.for_onboarding) {
          __out.push(__sanitize('?for_onboarding=true'));
        }
        __out.push('" class="pull-right pointer font-16 font-semi-bold btn-bank-connection-js"><i class="icon icon-arrow-right"></i></a>\n    </div>\n</div>\n');
      }
    
      __out.push('\n<!--start docyt check-->\n<div class="ach-check-setting-item-wrapper in-black display-flex align-items-center ');
    
      __out.push(__sanitize(this.ap_service.get('is_setup_generate_check') ? 'pointer docyt-check-view-js' : ''));
    
      __out.push('">\n    <div class="display-flex align-items-center width-40-percent">\n        <div class="middle-avatar-wrapper">\n            <i class="icon icon-docyt-check font-39">\n                <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span>\n            </i>\n        </div>\n        <span class="font-18 m-l-20">');
    
      __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
    
      __out.push('</span>\n    </div>\n    <div class="display-flex align-items-center width-60-percent">\n    ');
    
      if (this.ap_service.get('is_setup_generate_check')) {
        __out.push('\n        ');
        if (this.ap_service.get('ach_document') && this.ap_service.get('ach_document').state === 'verified') {
          __out.push('\n        <div class="in-green-1000 font-14 font-semi-bold m-l-15">Active</div>\n        ');
        } else {
          __out.push('\n        <div class="in-red-600 font-14 font-semi-bold m-l-15">Inactive</div>\n        ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n    </div>\n    <div class="display-flex align-items-center">\n        ');
    
      if (this.ap_service.get('is_setup_generate_check')) {
        __out.push('\n        <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/checks');
        if (this.for_onboarding) {
          __out.push(__sanitize('?for_onboarding=true'));
        }
        __out.push('" class="pull-right pointer font-16 font-semi-bold btn-docyt-check-js"><i class="icon icon-arrow-right"></i></a>\n        ');
      } else {
        __out.push('\n        <button class="btn btn-blue pull-right pointer font-16 font-semi-bold btn-activate-check-js" ');
        __out.push(__sanitize(!this.ap_service.get('is_setup_authorized_payer') ? 'disabled' : ''));
        __out.push('>Activate</button>\n        ');
      }
    
      __out.push('\n    </div>\n</div>\n<!--start ach payments-->\n<div class="ach-check-setting-item-wrapper in-black display-flex align-items-center ');
    
      __out.push(__sanitize(this.dwolla_customer ? 'pointer ach-payment-view-js' : ''));
    
      __out.push('">\n    <div class="display-flex align-items-center width-40-percent">\n        <div class="middle-avatar-wrapper">\n            <i class="icon icon-ach-payments font-39">\n                <span class="path1"></span><span class="path2"></span>\n            </i>\n        </div>\n        <span class="font-18 m-l-20">ACH Payments</span>\n    </div>\n    <div class="display-flex align-items-center width-60-percent">\n    ');
    
      if (this.dwolla_customer && this.dwolla_customer.id) {
        __out.push('\n        <div class="col-xs-6 font-14 font-semi-bold">\n            ');
        if (this.dwolla_customer.get('is_verified') && this.dwolla_customer.get('is_verified_funding_source')) {
          __out.push('\n            <div class="in-green-1000">Active</div>\n            ');
        } else {
          __out.push('\n            <div class="in-red-600">Inactive</div>\n            ');
        }
        __out.push('\n        </div>\n        ');
        if (!this.dwolla_customer.get('is_verified_beneficial_owners')) {
          __out.push('\n        <div class="col-xs-6 in-orange-300 font-14 font-semi-bold">\n            <i class="icon icon-unable-to-sync font-18 m-r-8 vertical-align-middle"><span class="path1"></span><span class="path2"></span></i>\n            <span class="vertical-align-middle">Review Beneficial Owner</span>\n        </div>\n        ');
        }
        __out.push('\n    ');
      }
    
      __out.push('\n    </div>\n    <div class="display-flex align-items-center">\n        ');
    
      if (this.dwolla_customer && this.dwolla_customer.id) {
        __out.push('\n        <a href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/ach');
        if (this.for_onboarding) {
          __out.push(__sanitize('?for_onboarding=true'));
        }
        __out.push('" class="pull-right pointer font-16 font-semi-bold btn-ach-payment-js"><i class="icon icon-arrow-right"></i></a>\n        ');
      } else {
        __out.push('\n        <button class="btn btn-blue pull-right font-16 font-semi-bold btn-activate-ach-js" ');
        __out.push(__sanitize(!this.ap_service.get('is_setup_authorized_payer') ? 'disabled' : ''));
        __out.push('>Activate</button>\n        ');
      }
    
      __out.push('\n    </div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}