import React from 'react';

import { QueryObserverResult } from 'react-query';

import { IGetRevenueReportTypeDetailResponse } from '@src/requests/revenue_report_types';
import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';

import { RowData, HandleChange, HandleSelectChange, RowDataValidation } from './schema';
import TableBody from './table_body';
import TableHeader from './table_header';

import styles from './styles.module.scss';

interface IEditableTableProps {
  errors: RowDataValidation[],
  title: string;
  reportTypeId: number;
  rowData: RowData[];
  handleChange: HandleChange;
  handleSelectChange: HandleSelectChange;
  deleteRow: (index: number) => void;
  sorting: ISorting<keyof RowData>;
  refetchRevenueReport: () => Promise<QueryObserverResult<IGetRevenueReportTypeDetailResponse, Error>>;
}

const EditableTable = ({
  errors,
  title,
  rowData,
  handleChange,
  handleSelectChange,
  deleteRow,
  sorting,
  reportTypeId,
  refetchRevenueReport,
}: IEditableTableProps) => {
  return (
    <Table
      className={ `mapping-revenue-category-table ${styles['editable-table']}` }
      wrapperClassName="mapping-revenue-category-table-table-wrapper"
    >
      <Table.Head>
        <TableHeader
          sorting={ sorting }
          title={ title }
        />
      </Table.Head>
      <Table.Body>
        {rowData.map((itemData, index) => (
          <TableBody
          // eslint-disable-next-line react/no-array-index-key
            key={ index }
            deleteRow={ deleteRow }
            errors={ errors }
            handleChange={ handleChange }
            handleSelectChange={ handleSelectChange }
            index={ index }
            itemData={ itemData }
            refetchRevenueReport={ refetchRevenueReport }
            reportTypeId={ reportTypeId }
            title={ title }
          />
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(EditableTable);
