import React, { useState } from 'react';

import { documentRequestsPath, flaggedTransactionsPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';
import { LDFeatureForUserEnabled, LDFeatureValueForUser } from '@src/utils/config';

import ModuleHeaderMenu from '../module_header_menu';

interface IServiceMenuProps {
  businessId: TID,
  info?: IBusinessServicesInfo,
  activeItem?: string,
  focusToMyClient: boolean,
}

const OpenItems = ({
  businessId,
  info,
  activeItem,
  focusToMyClient = false,
}: IServiceMenuProps) => {
  const [sunsetClientCentralEnabled, setSunsetClientCentralEnabled] = useState<
    string | boolean | undefined>(
      LDFeatureValueForUser(
        window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
        String(window.Docyt.currentAdvisor.id),
      ),
    );

  if (sunsetClientCentralEnabled === undefined) {
    LDFeatureForUserEnabled(
      window.Docyt.Common.Constants.SUNSET_CLIENT_CENTRAL_FLAG,
      String(window.Docyt.currentAdvisor.id),
    ).then((res) => {
      setSunsetClientCentralEnabled(res);
    });
  }

  if (!sunsetClientCentralEnabled && focusToMyClient) return null;

  let itemCount;
  if (sunsetClientCentralEnabled) {
    if (focusToMyClient) {
      itemCount = Number(info?.countDocumentRequests || 0);
    } else {
      itemCount = Number(info?.countDocumentRequests || 0) + Number(info?.countOpenedItems || 0);
    }
    itemCount += Number(info?.countUnreadTransactionChatMessages || 0);
  } else {
    itemCount = info?.countOpenedItems;
  }

  return (
    <ModuleHeaderMenu
      needCount
      active={ activeItem === window.Docyt.Common.Constants.OPEN_ITEMS_PATH }
      itemNumber={ itemCount }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.OPEN_ITEMS }
      url={ sunsetClientCentralEnabled
        ? documentRequestsPath(businessId) : flaggedTransactionsPath(businessId) }
    />
  );
};

export default OpenItems;
