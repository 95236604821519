module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, j, month, ref, ref1, year;
    
      __out.push('<div class="summary-wrapper item-divider">\n    <div class="font-bold font-26">Summary</div>\n    <div class="filter-input-group">\n      <input type="text" class="filter-date-input form-control" disabled id="filterEndDate" autocomplete="off" value="');
    
      __out.push(__sanitize(this.duration));
    
      __out.push('"/>\n      <a class="btn dropdown-toggle range-select-menu-btn pointer" type="button" id="filter-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n        <span class="fa fa-caret-down font-14"></span>\n      </a>\n      <ul class="filter-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="filter-dropdown-btn">\n        <li>\n          <a class="dropdown-item font-14 monthly-dropdown-item-toggle pointer" type="button" data-prefix="0" data-value="0"><span class="m-l-10">All</span></a>\n        </li>\n        ');
    
      for (year = i = ref = this.startYear, ref1 = this.endYear; ref <= ref1 ? i <= ref1 : i >= ref1; year = ref <= ref1 ? ++i : --i) {
        __out.push('\n          <li class="font-14 dropdown-submenu">\n            <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n              <span>');
        __out.push(__sanitize(year));
        __out.push('</span>\n            </a>\n            <ul class="dropdown-menu filter-dropdown-menu dropdown-menu-left">\n              ');
        for (month = j = 0; j <= 11; month = ++j) {
          __out.push('\n              <li>\n                <a class="dropdown-item font-14 monthly-dropdown-item-toggle pointer" type="button" data-prefix="');
          __out.push(__sanitize(year));
          __out.push('" data-value="');
          __out.push(__sanitize(month));
          __out.push('">\n                  ');
          __out.push(__sanitize(this.MONTH_FULL_NAMES[month]));
          __out.push('\n                </a>\n              </li>\n              ');
        }
        __out.push('\n            </ul>\n          </li>\n        ');
      }
    
      __out.push('\n      </ul>\n    </div>\n</div>\n<div class="setting-business-content m-t-30">\n    <a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/reconciliation_center/transactions" class="business-item-wrapper summary-link">\n        <div class="summary-title">\n            <span class="font-18">Uncategorized Transactions</span>\n        </div>\n        <div class="summary-info">\n            <span class="business-issue-wrapper font-18">');
    
      __out.push(__sanitize(this.rc_summary.count_uncategorized_exp_txns));
    
      __out.push('</span>\n        </div>\n        <div class="summary-tail">\n            <span class="fa fa-angle-right font-24"></span>\n        </div>\n    </a>\n    <a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/reconciliation_center/documents" class="business-item-wrapper summary-link">\n        <div class="summary-title">\n            <span class="font-18">Uncleared Documents</span>\n        </div>\n        <div class="summary-info">\n            <span class="business-issue-wrapper font-18">');
    
      __out.push(__sanitize(this.rc_summary.count_unreconciled_exp_docs));
    
      __out.push('</span>\n        </div>\n        <div class="summary-tail">\n            <span class="fa fa-angle-right font-24"></span>\n        </div>\n    </a>\n    <a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/reconciliation_center/bank_transfers" class="business-item-wrapper summary-link">\n        <div class="summary-title">\n            <span class="font-18">Unmatched Transfers</span>\n        </div>\n        <div class="summary-info">\n            <span class="business-issue-wrapper font-18">');
    
      __out.push(__sanitize(this.rc_summary.count_unreconciled_transfer_txns));
    
      __out.push('</span>\n        </div>\n        <div class="summary-tail">\n            <span class="fa fa-angle-right font-24"></span>\n        </div>\n    </a>\n    <a href="/businesses/');
    
      __out.push(__sanitize(this.business_id));
    
      __out.push('/reconciliation_center/all_transactions?filter_all_transactions=has_related_document%3Dundocumented%26qbo_sync%3Dsynced" class="business-item-wrapper summary-link">\n        <div class="summary-title">\n            <span class="font-18">Undocumented Transactions</span>\n        </div>\n        <div class="summary-info">\n            <span class="business-issue-wrapper font-18">');
    
      __out.push(__sanitize(this.rc_summary.count_undocumented_unmatched_txns));
    
      __out.push('</span>\n        </div>\n        <div class="summary-tail">\n            <span class="fa fa-angle-right font-24"></span>\n        </div>\n    </a>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}