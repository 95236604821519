import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import {
  useUpsertIntegration,
  useSetIntegrationPaused,
  useTriggerIntegrationAuthorization,
} from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { IUpsertIntegrationResponse } from '@src/requests/integrations/integrations';
import { TID } from '@src/types/common';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';

import JsonForm from '@src/components/integrations/config/form';
import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';
import Form from '@src/components/ui_v2/form';

import PullIntegrationOauthAuthorization from './pull_integration_oauth_authorization';
import { usePullIntegrationSuccessModal } from './pull_integration_success_modal';
import { useConnectRevenueSystemModal } from '../connect_system/connect_system_modal';

interface IPullIntegrationConnectModalProps extends IUseModalProps {
  externalSystemId: TID,
  externalSystemName: string,
  id?: TID,
  externalSystemConfigSchema: IExternalSystemPullProperties,
  skipRevenueMapping?: boolean,
  isEdit?: boolean,
  valueForm?: { [key: string]: string | number | null };
}

const PullIntegrationConnectModal = ({
  isOpen,
  onDone,
  onCancel,
  externalSystemId,
  externalSystemName,
  externalSystemConfigSchema,
  skipRevenueMapping,
  isEdit = false,
  id,
  valueForm,
  ...props
}: IPullIntegrationConnectModalProps) => {
  const [integration, setIntegration] = useState<IUpsertIntegrationResponse | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const pullIntegrationSuccessModel = usePullIntegrationSuccessModal();
  const triggerIntegrationAuthorization = useTriggerIntegrationAuthorization();
  const [integrationId, setIntegrationId] = useState<TID | null>(null);
  const business = useBusinessContext();

  if (!externalSystemConfigSchema.properties.location) {
    externalSystemConfigSchema.properties.location = {
      type:     'string',
      title:    'Location',
      required: true,
    };
  }

  const handleOauthSuccess = useCallback(() => {
    pullIntegrationSuccessModel.open();
    onDone();
  }, [pullIntegrationSuccessModel, onDone]);

  const camelizeString = (str: string): string => {
    // Implement the camelize logic here if camelizeKeys is not suitable for single strings
    return str.replace(/_([a-z])/g, (match) => match[1].toUpperCase());
  };
  const featureSkipRevenueReportTypeValidation = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.SKIP_REVENUE_REPORT_MAPPING,
  );

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const setAsActive = useSetIntegrationPaused();
  const { mutate: setAsActiveMutate } = setAsActive;

  const { mutate: triggerIntegrationAuthorizationMutate } = triggerIntegrationAuthorization;

  const handleError = useCallback((response) => {
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const handleSuccess = useCallback(() => {
    if (isEdit) {
      toastr.success(
        'Configuration details have been updated',
        'Successfully updated',
      );
    } else {
      pullIntegrationSuccessModel.open();
    }
    onDone();
  }, [pullIntegrationSuccessModel, onDone, isEdit]);

  const connectSystemModal = useConnectRevenueSystemModal();

  const connectPullSystem = useCallback((data: any) => {
    const externalSystemConfig: Record<string, string> = {};
    const otherData: Record<string, any> = {};

    const requiredKeys = (externalSystemConfigSchema?.required || []).map(camelizeString);

    requiredKeys.forEach((key) => {
      if (data[key]) {
        externalSystemConfig[key] = data[key];
      }
    });

    Object.keys(data).forEach((key) => {
      if (!requiredKeys.includes(key)) {
        otherData[key] = data[key];
      }
    });

    ['startDate', 'location'].forEach((key) => {
      if (otherData[key] === null || otherData[key] === undefined) {
        otherData[key] = data[key];
      }
    });

    const integrationData = {
      externalSystemId,
      ...otherData,
      externalSystemConfig,
      id: id || integrationId || undefined,
    };

    mutate({
      businessId:  business.id,
      integration: integrationData,
    }, {
      onSuccess: (response) => {
        setIntegration(response);
        if (externalSystemConfigSchema.authorizationRequired
          || externalSystemConfigSchema.authorizationType === 'oauth') {
          setIntegrationId(response.id);
        } else if (externalSystemConfigSchema.authorizationType === 'request') {
          setIntegrationId(response.id);
          triggerIntegrationAuthorizationMutate({
            integrationId:     response.id,
            authorizationType: externalSystemConfigSchema.authorizationType,
          }, {
            onSuccess: () => {
              handleSuccess();
            },
            onError: handleError,
          });
        } else {
          handleSuccess();
        }
        if (response.skipRevenueMapping) {
          setAsActiveMutate({ integrationId: response.id, isPaused: false });
        }
      },
      onError: handleError,
    });
  }, [externalSystemId,
    handleError,
    business.id,
    mutate,
    triggerIntegrationAuthorizationMutate,
    setAsActiveMutate,
    id,
    integrationId,
    handleSuccess,
    externalSystemConfigSchema,
  ]);

  const handleOnCancel = useCallback(() => {
    if (!isEdit) {
      connectSystemModal.open();
    }
    onCancel();
  }, [connectSystemModal, onCancel, isEdit]);

  // Adding this code to configure functionality
  let otherProperties;
  if (featureSkipRevenueReportTypeValidation.data) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { revenueReportTypeId: _, ...rest } = externalSystemConfigSchema.properties;
    otherProperties = rest;
  } else {
    otherProperties = externalSystemConfigSchema.properties;
  }

  return (
    <>
      {
        (externalSystemConfigSchema.authorizationRequired
          || externalSystemConfigSchema.authorizationType === 'oauth')
        && integrationId && (
          <PullIntegrationOauthAuthorization
            integrationId={ integrationId }
            onSuccess={ handleSuccess }
          />
        )
      }
      <connectSystemModal.Component
        { ...connectSystemModal.props }
      />
      <pullIntegrationSuccessModel.Component
        UpsertIntegrationResponse={ integration }
        { ...pullIntegrationSuccessModel.props }
      />
      <Modal
        show={ isOpen }
        title={ `Integrate with ${externalSystemName} ${skipRevenueMapping ? 'System' : 'Revenue System'}` }
        { ...props }
      >
        <>
          <Modal.Body>
            <p>
              Select the starting date for the
              {' '}
              {skipRevenueMapping ? 'system' : 'revenue system'}
              .
            </p>
            <Form
              id="linked_businesses_form"
              onSubmit={ () => { } }
            >
              <Form.TextField
                hideClear
                readOnly
                label="Business"
                value={ business.name }
              />
            </Form>
            <p>
              This
              {' '}
              {skipRevenueMapping ? 'system' : 'revenue system'}
              {' '}
              requires credential details. Enter below the credential details to connect.
            </p>
            <JsonForm
              businessId={ business.id }
              formId="edit_linked_businesses_form"
              jsonSchema={ otherProperties }
              valueForm={ isEdit ? valueForm : {} }
              onSubmit={ connectPullSystem }
              onValidationChange={ setIsFormValid }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ handleOnCancel }
            >
              Back
            </Button>
            <Button
              disabled={ !isFormValid }
              form="edit_linked_businesses_form"
              type="submit"
              variant="primary"
            >
              {(!isEdit || externalSystemConfigSchema.authorizationRequired
                || externalSystemConfigSchema.authorizationType === 'oauth'
                || externalSystemConfigSchema.authorizationType === 'request') ? 'Connect' : 'Save'}
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

const usePullIntegrationConnectModal = makeUseModal(PullIntegrationConnectModal);

export {
  usePullIntegrationConnectModal,
  PullIntegrationConnectModal as default,
};
