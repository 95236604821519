import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface IProgressActivityProps {
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
}

const ProgressActivity = ({
  className,
  size = 'medium',
}: IProgressActivityProps): JSX.Element => {
  const classes = classNames(
    styles['progress-activity'],
    styles[size],
    className,
  );

  return (
    <div className={ classes }>
      <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <mask height="20" id="progress_mask" maskUnits="userSpaceOnUse" style={ { maskType: 'alpha' } } width="20" x="0" y="0">
          <rect fill="#D9D9D9" height="20" width="20" />
        </mask>
        <g mask="url(#progress_mask)">
          <path d="M10.0013 18.3337C8.86241 18.3337 7.78602 18.1149 6.77214 17.6774C5.75825 17.2399 4.87283 16.6427 4.11589 15.8857C3.35894 15.1288 2.76172 14.2434 2.32422 13.2295C1.88672 12.2156 1.66797 11.1392 1.66797 10.0003C1.66797 8.84755 1.88672 7.76769 2.32422 6.76074C2.76172 5.7538 3.35894 4.87185 4.11589 4.11491C4.87283 3.35796 5.75825 2.76074 6.77214 2.32324C7.78602 1.88574 8.86241 1.66699 10.0013 1.66699C10.2374 1.66699 10.4353 1.74685 10.5951 1.90658C10.7548 2.0663 10.8346 2.26421 10.8346 2.50033C10.8346 2.73644 10.7548 2.93435 10.5951 3.09408C10.4353 3.2538 10.2374 3.33366 10.0013 3.33366C8.15408 3.33366 6.58116 3.98296 5.28255 5.28158C3.98394 6.58019 3.33464 8.1531 3.33464 10.0003C3.33464 11.8475 3.98394 13.4205 5.28255 14.7191C6.58116 16.0177 8.15408 16.667 10.0013 16.667C11.8485 16.667 13.4214 16.0177 14.7201 14.7191C16.0187 13.4205 16.668 11.8475 16.668 10.0003C16.668 9.76421 16.7478 9.5663 16.9076 9.40658C17.0673 9.24685 17.2652 9.16699 17.5013 9.16699C17.7374 9.16699 17.9353 9.24685 18.0951 9.40658C18.2548 9.5663 18.3346 9.76421 18.3346 10.0003C18.3346 11.1392 18.1159 12.2156 17.6784 13.2295C17.2409 14.2434 16.6437 15.1288 15.8867 15.8857C15.1298 16.6427 14.2478 17.2399 13.2409 17.6774C12.2339 18.1149 11.1541 18.3337 10.0013 18.3337Z" fill="currentColor" />
        </g>
      </svg>
    </div>
  );
};

export default ProgressActivity;
