import { basicReportsPath, businessSettingsPath, revenueReportsPath } from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

const reportServiceUrl = (info: IBusinessServicesInfo, businessId: TID) => {
  if (info.reportServiceReady) {
    if (info.isReportAdminUser) {
      return basicReportsPath(businessId);
    }

    if (info.standardCategoryId
      === window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.HOSPITALITY_ID
      || info.standardCategoryId
      === window.Docyt.Common.Constants.STANDARD_CATEGORY_ID.UPS_ID) {
      return revenueReportsPath(businessId);
    }
  }

  return businessSettingsPath(businessId);
};

const activatedModuleHeader = (currentModulePath?: string, sunsetVaultFeatureFlagEnabled?: boolean, isGlobalNavigationEnabled?: boolean) => {
  if (currentModulePath === undefined) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DASHBOARD;
  }

  if (isGlobalNavigationEnabled) {
    if (window.Docyt.Common.Constants.BUSINESS_MODULE_PATHS.includes(currentModulePath)) {
      return window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS;
    }

    if (window.Docyt.Common.Constants.EXPENSE_MODULE_PATHS.includes(currentModulePath)) {
      return window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.EXPENSE;
    }

    if (window.Docyt.Common.Constants.REVENUE_MODULE_PATHS.includes(currentModulePath)) {
      return window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REVENUE;
    }

    if (window.Docyt.Common.Constants.RECONCILIATION_MODULE_PATHS.includes(currentModulePath)) {
      return window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.RECONCILIATION;
    }

    if (window.Docyt.Common.Constants.LEDGER_MODULE_PATHS.includes(currentModulePath)) {
      return window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.LEDGER;
    }

    if (window.Docyt.Common.Constants.REPORTS_MODULE_PATHS.includes(currentModulePath)) {
      return window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.REPORTS;
    }
  }

  if (window.Docyt.Common.Constants.EXPENSE_MANAGEMENT_MODULE_PATHS.includes(currentModulePath)) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.EXPENSE_MANAGEMENT;
  }

  if (window.Docyt.Common.Constants.REVENUE_ACCOUNTING_MODULE_PATHS.includes(currentModulePath)) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.REVENUE_ACCOUNTING;
  }

  if (window.Docyt.Common.Constants.BANKING_AND_RECONCILIATION_MODULE_PATHS
    .includes(currentModulePath)) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BANKING_AND_RECONCILIATION;
  }

  if (window.Docyt.Common.Constants.BUSINESS_REPORTS_MODULE_PATHS.includes(currentModulePath)) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.BUSINESS_REPORTS;
  }

  if (sunsetVaultFeatureFlagEnabled && currentModulePath === window.Docyt.Common.Constants.MAILROOM_PATH) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.MAILROOM;
  }

  if (window.Docyt.Common.Constants.DOCUMENT_MODULE_PATHS.includes(currentModulePath)) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DOCUMENTS;
  }

  if (currentModulePath === window.Docyt.Common.Constants.SERVICE_NAMES.BUSIENSS_INFO) {
    return window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.SETTINGS;
  }

  if (currentModulePath === window.Docyt.Common.Constants.OPEN_ITEMS_PATH) {
    return window.Docyt.Common.Constants.OPEN_ITEMS_PATH;
  }

  return isGlobalNavigationEnabled ? window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.BUSINESS : window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.DASHBOARD;
};

export {
  reportServiceUrl,
  activatedModuleHeader,
};
