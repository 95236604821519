import React, { useCallback, useState } from 'react';

import { accountsPayableDocumentViewPath } from '@src/routes';
import { IDocument } from '@src/types/documents';

import Tooltip from '@src/components/ui_v2/tooltip';
import { ContentCopyIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface ICopyDocumentLinkProps {
  document: IDocument;
}

const CopyDocumentLink = ({ document }: ICopyDocumentLinkProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const copyDocumentUrl = useCallback(() => {
    const copyUrl = accountsPayableDocumentViewPath(document.businesses[0].id, document.id);
    navigator.clipboard.writeText(window.location.origin + copyUrl);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, [document]);

  const handleMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div onMouseLeave={ handleMouseLeave }>
      <Tooltip.Hover
        isControl
        content={ isCopied ? 'Copied!' : 'Click to copy link to document' }
        showTooltip={ showTooltip }
        tooltipClassName={ styles['tooltip-hover'] }
      >
        <ContentCopyIcon
          pointer
          className={ styles['copy-icon'] }
          color="secondary"
          fontSize={ 24 }
          onClick={ isCopied ? undefined : copyDocumentUrl }
          onMouseEnter={ handleMouseEnter }
        />
      </Tooltip.Hover>
    </div>
  );
};

export default React.memo(CopyDocumentLink);
