import React, { useCallback } from 'react';

import Root from '@src/components/root';

import BillingView from './billing/billing_view';
import BeforeLedgerSetup from './connect_ledger/before_ledger_setup';
import ConnectLedgerView from './connect_ledger/connect_ledger_view';
import ConnectLedgerProgress from './connect_ledger/progress';
import TeamMemberView from './team_member/team_member_view';

const SetupAccountingFirmPage = ({ page }: { page: string }) => {
  const renderPage = useCallback(() => {
    if (page === 'before_ledger_setup') {
      return <BeforeLedgerSetup />;
    }

    if (page === 'ledger_setup') {
      return <ConnectLedgerView />;
    }

    if (page === 'ledger_setup_in_progress') {
      return <ConnectLedgerProgress />;
    }

    if (page === 'billing') {
      return <BillingView />;
    }

    return <TeamMemberView />;
  }, [page]);

  return (
    <Root>
      { renderPage() }
    </Root>
  );
};

export default React.memo(SetupAccountingFirmPage);
