import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getPortfolioWidgets,
  IGetPortfolioWidgetsParams,
  IGetPortfolioWidgetsResponse,
} from '@src/requests/portfolio_service/widgets';

const useGetPortfolioWidgets = (params: IGetPortfolioWidgetsParams, onSuccess?: () => void) => {
  return useQuery<IGetPortfolioWidgetsResponse, Error>(
    [QueryKey.portfolioDashboardWidgets, params],
    () => getPortfolioWidgets(params),
    {
      enabled: !!params.dashboardId && !!params.from && !!params.to,
      onSuccess,
    },
  );
};

export {
  useGetPortfolioWidgets,
};
