import React, { useMemo } from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';

import GroupHeader from '../components/custom_cells/group_header';
import { collaborationKeyToStringMap, expenseKeyToStringMap,
  IBusinessOwnerConfigFormType, reconciliationKeyToStringMap, revenueKeyToStringMap, monthEndCloseKeyToStringMap,
  DASHBOARD_ICONS } from '../helper';
import { hasActivePreference, mapObjectKeys } from '../helper/functional';
import { TDashboardSortColumn, TViewOperationalDashboard } from '../types';

import styles from './style.module.scss';

interface IDashboardTableHeadProps {
    preferences?: IBusinessOwnerConfigFormType;
    sorting: ISorting<TDashboardSortColumn>;
    view: TViewOperationalDashboard;
}
const TableHead = ({
  preferences,
  sorting,
  view,
}: IDashboardTableHeadProps) => {
  const IssueColumnElement = useMemo(() => {
    return preferences?.issues && mapObjectKeys(preferences.issues, DASHBOARD_ICONS);
  }, [preferences]);

  const IssueColumnWidth = useMemo(() => {
    // 40px is the width of each icon
    return `${(IssueColumnElement?.length ?? 0) * 40}px`;
  }, [IssueColumnElement]);

  const monthEndClosingElements = useMemo(() => {
    if (!preferences?.monthEndClosing) return [];
    return mapObjectKeys(preferences?.monthEndClosing, monthEndCloseKeyToStringMap);
  }, [preferences?.monthEndClosing]);

  const monthEndClosingWidth = useMemo(() => {
    return `${(monthEndClosingElements?.length ?? 0) * 85}px`;
  }, [monthEndClosingElements]);

  return (
    <Table.Head>
      <Table.Row>
        <Table.HCell<TDashboardSortColumn>
          className={ styles['business-header-cell'] }
          columnName="business"
          sorting={ sorting }
          width={ window.Docyt.currentAdvisor.checkUserAccessToBookgpt() ? '350px' : '274px' }
        >
          {view === 'accountant' ? 'Clients' : 'Businesses'}
        </Table.HCell>
        {hasActivePreference('businessChat', preferences) && (
          <Table.HCell className={ styles['chat-icon-header'] } width="70px" />
        )}
        { hasActivePreference('mailroom', preferences) && (
          <Table.HCell className={ styles['business-drive-header'] } width="70px" />
        )}
        {hasActivePreference('issues', preferences) && (
          <Table.HCell width={ IssueColumnWidth }>
            <GroupHeader
              isIssuesHeader
              elements={ IssueColumnElement }
              heading="Issues"
            />
          </Table.HCell>
        )}
        {hasActivePreference('expense', preferences) && (
        <Table.HCell className={ styles['expense-header'] } width="300px">
          <GroupHeader
            elements={
               preferences?.expense
               && mapObjectKeys(preferences.expense, expenseKeyToStringMap)
             }
            heading="Expense"
          />
        </Table.HCell>
        )}
        { hasActivePreference('revenue', preferences)
                && (
                  <Table.HCell width="200px">
                    <GroupHeader
                      elements={
                          preferences?.revenue
                          && mapObjectKeys(preferences?.revenue, revenueKeyToStringMap)
                        }
                      heading="Revenue"
                    />
                  </Table.HCell>
                )}
        { hasActivePreference('continuousReconciliation', preferences) && (
        <Table.HCell width="200px">
          <GroupHeader
            elements={
                          preferences?.continuousReconciliation
                          && mapObjectKeys(
                            preferences?.continuousReconciliation,
                            reconciliationKeyToStringMap,
                          )
                        }
            heading="Continuous Reconciliation"
          />
        </Table.HCell>
        )}
        { hasActivePreference('monthEndClosing', preferences) && (
        <Table.HCell width={ monthEndClosingWidth }>
          <GroupHeader
            elements={ monthEndClosingElements }
            heading="Month End Closing"
          />
        </Table.HCell>
        )}
        {hasActivePreference('collaboration', preferences) && (
        <Table.HCell width="290px">
          {' '}
          <GroupHeader
            elements={
                  preferences?.collaboration
                  && mapObjectKeys(
                    preferences?.collaboration,
                    collaborationKeyToStringMap,
                  )
                }
            heading="Collaboration"
          />
        </Table.HCell>
        )}
      </Table.Row>
    </Table.Head>
  );
};
export default TableHead;
