import { TID } from '@src/types/common';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from './helpers';

interface IInviteUserToUploadDocumentParams {
  email: String,
  firstName: String,
  lastName: String,
  welcomeMessage: String
  managementGroupId: TID;
}

interface IGetDownloadSpreadSheetParams {
  type: 'business_details' | 'entity_info',
}

const inviteUserToUploadDocument = (
  params: IInviteUserToUploadDocumentParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/self_onboarding_invitations/invite_user_to_upload_document',
    underscoreKeys(params),
  );
};

interface IDownloadSpreadsheetResponse {
  data: any,
  fileName: string,
}

const getDownloadSpreadSheet = (
  params: IGetDownloadSpreadSheetParams,
): Promise<IDownloadSpreadsheetResponse> => {
  return apiGet(
    '/api/v1/self_onboarding_invitations/download_spreadsheet',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IDownloadSpreadsheetResponse);
};

interface IUploadSpreadsheetParams {
  id: TID,
  file: File,
}

interface IUploadSpreadsheetResponse {
  parsedBusinessNames: string[],
}

const uploadSpreadsheet = (
  params: IUploadSpreadsheetParams,
): Promise<IUploadSpreadsheetResponse> => {
  const formData = new FormData();
  formData.append('file', params.file);
  return apiPut(
    `/api/v1/self_onboarding_invitations/${params.id}/upload_spreadsheet`,
    formData,
  ).then((data) => camelizeKeys(data) as IUploadSpreadsheetResponse);
};

interface IRemoveSpreadsheetParams {
  id: TID,
}

interface IRemoveSpreadsheetResponse {
  selfOnboardingInvitation: ISelfOnboardingInvitation,
}

const removeSpreadsheet = (
  params: IRemoveSpreadsheetParams,
): Promise<IRemoveSpreadsheetResponse> => {
  const formData = new FormData();
  return apiPut(
    `/api/v1/self_onboarding_invitations/${params.id}/remove_spreadsheet`,
    formData,
  ).then((data) => camelizeKeys(data) as IRemoveSpreadsheetResponse);
};

interface IUploadKYCDocumentsParams {
  id: TID,
  businessName: string,
  documentType: string,
  file: File,
}

const uploadKYCDocuments = (
  params: IUploadKYCDocumentsParams,
): Promise<void> => {
  const formData = new FormData();
  formData.append('business_name', params.businessName);
  formData.append('document_type', params.documentType);
  formData.append('file', params.file);
  return apiPut(
    `/api/v1/self_onboarding_invitations/${params.id}/upload_kyc_documents`,
    formData,
  );
};

export {
  IInviteUserToUploadDocumentParams,
  IDownloadSpreadsheetResponse,
  IUploadSpreadsheetParams,
  IUploadSpreadsheetResponse,
  IRemoveSpreadsheetParams,
  IRemoveSpreadsheetResponse,
  IUploadKYCDocumentsParams,
  inviteUserToUploadDocument,
  getDownloadSpreadSheet,
  uploadSpreadsheet,
  removeSpreadsheet,
  uploadKYCDocuments,
};
