import { object, boolean } from 'yup';

export type TPreferencesStatus = 'coming_soon' | 'disabled' | 'active' | 'deprecated';
interface IIssuesPreferences {
  status?: TPreferencesStatus;
  disconnectedLedger?: boolean;
  disconnectedRevenue?: boolean;
  disconnectedBank?: boolean;
  ledgerIssues?: boolean;
  duplicateVendor?: boolean;
  chartOfAccountSyncErrors?: boolean;
}

interface IExpensePreferences {
  status?: TPreferencesStatus;
  billPay?: {
    uploadedInvoices?: boolean;
    approvedInvoices?: boolean;
    readyToPayInvoices?: boolean;
  };
  corporateCards?: {
    unapprovedReceipts?: boolean;
  };
  expenseReports?: {
    employeeReimbursements?: boolean;
    myReimbursements?: boolean;
  };
}

interface IRevenuePreferences {
  status?: TPreferencesStatus;
  undepositedRevenue?: {
    undepositedRevenueAmount?: boolean;
  };
  pendingReview?: {
    missingRevenueReports?: boolean;
    unbalancedReports?: boolean;
  };
}

interface IReconciliationPreferences {
  status?: TPreferencesStatus;
  uncategorizedTransactions?: boolean;
  unclearedDocuments?: boolean;
}

interface IMonthEndClosingPreferences {
  status?: TPreferencesStatus;
  missingBankStatements?: boolean;
  lastCloseDate?: boolean;
  auditScan?: boolean;
  notes?: boolean;
}

interface ICollaborationPreferences {
  status?: TPreferencesStatus;
  flaggedItems?: {
    flaggedInvoices?: boolean;
    flaggedReceipts?: boolean;
    flaggedTransactions?: boolean;
  };
  documentRequests?: {
    reviewed?: boolean;
    open?: boolean;
  };
  transactionMessages?: {
    unreadTransactionMessages?: boolean;
  };
}

interface IMailroomPreferences {
  status?: TPreferencesStatus;
  unreviewedDocuments?: boolean;
}

interface IBusinessChatPreferences {
  status?: TPreferencesStatus;
  unreadBusinessChatsCounts?: boolean;
}

interface IBusinessOwnerConfigFormType {
  issues?: IIssuesPreferences;
  expense?: IExpensePreferences;
  revenue?: IRevenuePreferences;
  continuousReconciliation?: IReconciliationPreferences;
  monthEndClosing?: IMonthEndClosingPreferences;
  collaboration?: ICollaborationPreferences;
  mailroom?: IMailroomPreferences;
  businessChat?: IBusinessChatPreferences;
}

const businessOwnerFilterValidation = object({
  issues: object({
    disconnected_ledger:          boolean().optional(),
    disconnected_revenue:         boolean().optional(),
    disconnected_bank:            boolean().optional(),
    ledger_issues:                boolean().optional(),
    duplicate_vendor:             boolean().optional(),
    chart_of_account_sync_errors: boolean().optional(),
  }),
  expense: object({
    billPay: object({
      uploadedInvoices:   boolean().optional(),
      approvedInvoices:   boolean().optional(),
      readyToPayInvoices: boolean().optional(),
    }),
    corporateCards: object({
      unapprovedReceipts: boolean().optional(),
    }),
    expenseReports: object({
      employeeReimbursements: boolean().optional(),
      myReimbursements:       boolean().optional(),
    }),
  }),
  revenue: object({
    undepositedRevenue: object({
      undepositedRevenueAmount: boolean().optional(),
    }),
    pendingReview: object({
      missingRevenueReports: boolean().optional(),
      unbalancedReports:     boolean().optional(),
    }),
  }),
  continuousReconciliation: object({
    uncategorizedTransactions: boolean().optional(),
    unclearedDocuments:        boolean().optional(),
  }),
  monthEndClosing: object({
    missingBankStatements: boolean().optional(),
    auditScan:             boolean().optional(),
    lastCloseDate:         boolean().optional(),
    notes:                 boolean().optional(),
  }),
  collaboration: object({
    flaggedItems: object({
      flaggedInvoices:     boolean().optional(),
      flaggedReceipts:     boolean().optional(),
      flaggedTransactions: boolean().optional(),
    }),
    documentRequests: object({
      reviewed: boolean().optional(),
      open:     boolean().optional(),
    }),
    transactionMessages: object({
      unreadTransactionMessages: boolean().optional(),
    }),
  }),
  businessChat: object({
    unreadBusinessChatsCounts: boolean().optional(),
  }),
  mailroom: object({
    unreviewedDocuments: boolean().optional(),
  }),
});

export {
  IExpensePreferences,
  IRevenuePreferences,
  IReconciliationPreferences,
  IBusinessOwnerConfigFormType,
  IMailroomPreferences,
  ICollaborationPreferences,
  businessOwnerFilterValidation,
};
