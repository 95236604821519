import React from 'react';

import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';
import SelfOnboardingMainView from '@src/components/self_onboarding/self_onboarding_main_view';
import Sidemenu from '@src/components/self_onboarding/sidemenu/sidemenu';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';

interface ISelfOnboardingPageProps {
  step: number,
  invitation: any,
}

const SelfOnboardingPage = ({
  step,
  invitation,
}: ISelfOnboardingPageProps): JSX.Element => {
  const selfOnboardingInvitation = camelizeKeys(invitation) as ISelfOnboardingInvitation;
  return (
    <Root>
      <PageContainer
        className="self-onboarding-invitation-container"
        sidemenu={ (
          <Sidemenu
            invitation={ selfOnboardingInvitation }
            step={ step }
          />
        ) }
      >
        <SelfOnboardingMainView
          invitation={ selfOnboardingInvitation }
          step={ step }
        />
      </PageContainer>
    </Root>
  );
};

export default SelfOnboardingPage;
