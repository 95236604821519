import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IAngleRightIconProps extends Omit<IFaIconProps, 'icon'> {}

const AngleRightIcon = (props: IAngleRightIconProps): JSX.Element => {
  return <FaIcon icon="angle-right" { ...props } />;
};

export default AngleRightIcon;
