import React, { useMemo, useState } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useAddToLinkedBusiness, useRemoveFromBusiness } from '@src/hooks/queries/business_vendors';
import { useGetLimitedBusinesses, useGetBusinessQuery } from '@src/hooks/queries/businesses';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { SuccessNotification } from '@src/components/ui/notification';

import { useEditLinkedBusinessesModal } from '../modal/edit_linked_businesses_modal';
import useProgressModal from '../modal/progress_modal';

import styles from '../styles.module.scss';

interface IEditLinkedBusinessesActionProps {
  businessId: TID,
  vendorId: TID,
  linkedBusinesses: IBusiness[],
  onDone: () => void,
}

const EditLinkedBusinessesAction = ({
  businessId,
  vendorId,
  linkedBusinesses,
}: IEditLinkedBusinessesActionProps): JSX.Element => {
  const [doneCount, setDoneCount] = useState(0);
  const [unlinkedCount, setUnlinkedCount] = useState<number>(0);
  const [unLinkedBusinesses, setUnLinkedBusinesses] = useState(0);
  const [successState, setSuccessState] = useState<string | undefined>(undefined);
  const [missingBusinesses, setMissingBusinesses] = useState<IBusiness[]>([]);
  const editLinkedBusinesses = useEditLinkedBusinessesModal();
  const progressModal = useProgressModal();

  const queryClient = useQueryClient();
  const addToBusiness = useAddToLinkedBusiness();
  const { mutateAsync } = addToBusiness;
  const removeFromBusiness = useRemoveFromBusiness();
  const { mutate: removeVendor } = removeFromBusiness;

  const { data: businessData } = useGetBusinessQuery(businessId);
  const limitedBusinessesQuery = useGetLimitedBusinesses({
    managementGroupId:           businessData?.managementGroupId,
    forClient:                   true,
    includeAccessibleBusinesses: true,
    filter:                      {
      role:           'All',
      forServiceRole: {
        serviceType: 'VendorService',
        serviceRole: 'ADMIN',
      },
    },
  });

  const linkedBusinessIds = useMemo(() => linkedBusinesses.map((business) => business.id), [linkedBusinesses]);

  const associatedBusinesses = useMemo(() => {
    return limitedBusinessesQuery.data?.collection?.filter(
      (business) => business.id.toString() !== businessId?.toString() && linkedBusinessIds.includes(business.id),
    ) || [];
  }, [businessId, linkedBusinessIds, limitedBusinessesQuery.data?.collection]);

  const handleLinkToBusinesses = async (businesses: IBusiness[]) => {
    progressModal.open();
    const missingBusinessesList = associatedBusinesses.filter(
      (associatedBusiness) => !businesses.some((business) => business.id === associatedBusiness.id),
    );
    setMissingBusinesses(missingBusinessesList);

    if (missingBusinessesList.length > 0) {
      await missingBusinessesList.reduce(async (promise, business) => {
        await promise; // Wait for the previous promise to resolve
        return new Promise<void>((resolve, reject) => {
          removeVendor(
            {
              businessId: business.id,
              vendorId,
            },
            {
              onSuccess: () => {
                resolve(); // Resolve the promise on success
                setUnlinkedCount((count) => count + 1);
              },
              onError: (error) => {
                const errorMessage = (error as any)?.response?.data?.errors[0] || 'Unknown error';
                toastr.error(errorMessage, 'Error removing vendor');
                reject(new Error(errorMessage)); // Reject the promise on error
                progressModal.props.onDone();
              },
            },
          );
        });
      }, Promise.resolve());
    }

    try {
      const params = businesses
        .filter((business) => !linkedBusinessIds.includes(business.id))
        .map((business) => mutateAsync({ businessId: business.id, vendorIds: [vendorId] })
          .then(() => {
            setDoneCount((count) => count + 1);
          }));
      setUnLinkedBusinesses(params.length);
      await Promise.all(params);
      await queryClient.invalidateQueries(QueryKey.linkedBusinesses);
      setSuccessState('Success');
    } catch (error) {
      await queryClient.invalidateQueries(QueryKey.linkedBusinesses);
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`${errorMessage}`, 'Something went wrong');
    } finally {
      setTimeout(() => {
        progressModal.props.onDone();
        setUnlinkedCount(0);
        setDoneCount(0);
      }, 2000);
    }
  };

  const linkedMessage = useMemo(() => {
    if (doneCount === 0) return '';
    return doneCount === 1
      ? `${doneCount} business was linked successfully`
      : `${doneCount} businesses were linked successfully`;
  }, [doneCount]);

  const unlinkedMessage = useMemo(() => {
    if (unlinkedCount === 0) return ''; // Return empty string if unlinkedCount is 0

    const unlinkedText = `${unlinkedCount} business${unlinkedCount > 1 ? 'es' : ''} `
                         + `${unlinkedCount > 1 ? 'were' : 'was'} unlinked successfully`;
    return doneCount > 0 ? `and ${unlinkedText}` : unlinkedText; // Add 'and' if doneCount is not zero
  }, [doneCount, unlinkedCount]);

  const message = `${linkedMessage} ${unlinkedMessage}`;
  return (
    <>
      {successState && (
        <SuccessNotification
          message={ message }
          onHidden={ () => setSuccessState(undefined) }
        />
      )}
      <div className="pull-right pointer">
        <progressModal.Component
          doneCount={ doneCount }
          totalCount={ unLinkedBusinesses }
          totalUnlinkedCount={ missingBusinesses.length }
          unlinkedCount={ unlinkedCount }
          { ...progressModal.props }
        />
        <editLinkedBusinesses.Component
          { ...editLinkedBusinesses.props }
          businessId={ businessId }
          linkedBusinessIds={ linkedBusinessIds }
          onDone={ handleLinkToBusinesses }
        />
        <a className={ styles.edit } role="button" tabIndex={ 0 } onClick={ editLinkedBusinesses.open }>
          Edit
        </a>
      </div>
    </>
  );
};

export default EditLinkedBusinessesAction;
