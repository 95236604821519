import React, { useCallback, useState, useEffect } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IBusinessUser } from '@src/types/business_users';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';

import EditUsersSection from './edit_users_section';

interface IEditUsersModalProps extends Omit<IUseModalProps, 'onDone'> {
  title: string,
  managementGroupId?: TID,
  businessId?: TID,
  serviceProviderBusinessId?: TID | null,
  reportName?: string,
  onDone: (users: IBusinessUser[], removedUsers?: IBusinessUser[]) => void
  editUsers: IBusinessUser[],
}

const EditUsersModal = ({
  title,
  managementGroupId,
  businessId,
  serviceProviderBusinessId,
  reportName,
  isOpen,
  onCancel,
  onDone,
  editUsers,
}: IEditUsersModalProps): JSX.Element => {
  const [users, setUsers] = useState<IBusinessUser[]>([]);
  const [removedUsers, setRemovedUsers] = useState<IBusinessUser[]>([]);

  useEffect(() => {
    setUsers(editUsers);
    setRemovedUsers([]);
  }, [editUsers, isOpen]);

  const handleSubmit = useCallback(() => {
    onDone(users, removedUsers);
    onCancel();
  }, [onCancel, onDone, users, removedUsers]);

  const handleSelect = useCallback((item) => {
    setUsers((accessibleUsers) => {
      if (item && !users.some((user) => user.id === item.id)) {
        return [...accessibleUsers, item];
      }
      return accessibleUsers;
    });
  }, [users]);

  const handleRemove = useCallback((user) => {
    setUsers((accessibleUsers) => {
      return accessibleUsers.filter((u) => u.id !== user.id);
    });
    setRemovedUsers((prevRemovedUsers) => [...prevRemovedUsers, user]);
  }, []);

  return (
    <Modal.Standard
      dialogClassName="edit-users-modal"
      proceedTitle="Done"
      show={ isOpen }
      title={ title }
      onCancel={ onCancel }
      onProceed={ handleSubmit }
    >
      {() => (
        <EditUsersSection
          businessId={ businessId }
          managementGroupId={ managementGroupId }
          reportName={ reportName }
          serviceProviderBusinessId={ serviceProviderBusinessId }
          users={ users }
          onRemove={ handleRemove }
          onSelect={ handleSelect }
        />
      )}
    </Modal.Standard>
  );
};

const useEditUsersModal = makeUseModal(EditUsersModal);

export {
  IEditUsersModalProps,
  useEditUsersModal,
  EditUsersModal as default,
};
