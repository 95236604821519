/* global PDFJS_WORKER_NAME */

import React from 'react';

import { Worker } from '@react-pdf-viewer/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Location } from 'react-router-dom';
import { Provider } from 'jotai';

import BackboneRouter from '@src/components/utils_v2/backbone_router';

interface IRootProps {
  children: React.ReactNode,
  location?: Location,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry:                false,
    },
  },
});

/**
 * Use this component as root for all entry points.
 * It setup environment for react components
 */
const Root = ({ children, location }: IRootProps): JSX.Element => {
  return (
    <QueryClientProvider client={ queryClient }>
      <Provider>
        <BackboneRouter location={ location }>
          <React.StrictMode>
            <Worker workerUrl={ `/${PDFJS_WORKER_NAME}` }>
              { children }
            </Worker>
          </React.StrictMode>
        </BackboneRouter>
      </Provider>
    </QueryClientProvider>
  );
};

export default Root;
