import React, { useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { TDateRange } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import Table from '@src/components/ui_v2/table/table';
import TableSection from '@src/components/ui_v2/table_section';

import Body from './body';
import Header from './header';
import { getDefaultDateRange, getRealDateRange } from '../charts/chart_helpers';

import styles from './styles.module.scss';

interface IWidgetReportDataTableProps {
  collection: IWidget[];
  hideEmptyRows?: boolean;
  targets: Record<string, number>;
  standardCategoryId: number;
}

const WidgetDataTable = ({
  collection,
  hideEmptyRows = false,
  targets,
  standardCategoryId,
}: IWidgetReportDataTableProps): JSX.Element => {
  const business = useBusinessContext();
  const widget = collection[0];
  const realRange: TDateRange = useMemo(() => {
    const defaultRange = getDefaultDateRange(widget?.scale);
    const dateRange: TDateRange = {
      startDate: formatDate(defaultRange.from, API_DATE_FORMAT),
      endDate:   formatDate(defaultRange.to, API_DATE_FORMAT),
    };
    return getRealDateRange(
      dateRange,
      business.bookkeepingStartDate,
      widget?.scale,
      widget?.startDate,
    );
  }, [business.bookkeepingStartDate, widget?.scale, widget?.startDate]);

  return (
    <TableSection className={ styles['widget-report-data-table-section'] }>
      <TableSection.Panel>
        <Table isRegionScroll sticky wrapperClassName="table-region-container">
          <Header dateRange={ realRange } />
          <Body
            dateRange={ realRange }
            hideEmptyRows={ hideEmptyRows }
            standardCategoryId={ standardCategoryId }
            targets={ targets }
            widgets={ collection }
          />
        </Table>
      </TableSection.Panel>
    </TableSection>
  );
};

export default WidgetDataTable;
