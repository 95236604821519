import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import TransactionMessages from './transaction_messages';

interface ITransactionMessagesPageProps {
  businessId: TID;
}

const TransactionMessagesPage: React.FC<ITransactionMessagesPageProps> = ({ businessId }) => {
  return (
    <Root>
      <TransactionMessages businessId={ businessId } />
    </Root>
  );
};

export default TransactionMessagesPage;
