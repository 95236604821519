import React, { useMemo, useState } from 'react';

import { flatten } from 'lodash';

import { useGetTransactionMessages } from '@src/hooks/queries/transaction_messages';
import { TID } from '@src/types/common';
import { IChat, TTransactionMessageState } from '@src/types/transaction_message';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Header from './components/header';
import MessageList from './components/message_list';

interface ITransactionMessagesProps {
  businessId: TID;
}

const TransactionMessages: React.FC<ITransactionMessagesProps> = ({ businessId }) => {
  const [state, setState] = useState<TTransactionMessageState>('unread');
  const query = useGetTransactionMessages({ businessId, state });
  const { data: transactionMessagesData } = query;

  const transactionMessagesPages = useMemo(() => {
    return transactionMessagesData?.pages || [];
  }, [transactionMessagesData?.pages]);

  const transactionMessagesList = useMemo(() => {
    return flatten(transactionMessagesPages.map((item) => {
      return item.collection;
    }));
  }, [transactionMessagesPages]);

  const onMessageClick = (message: IChat) => {
    backboneNavigateToUrl(`/document/${message.chatableEntityId}/chat`);
  };

  const hasNoMessages = useMemo(() => {
    return (
      !query.isLoading
      && !query.isFetching
      && !query.isError
      && transactionMessagesList.length === 0
      && !query.hasNextPage);
  }, [
    query.isLoading,
    query.isFetching,
    query.isError,
    transactionMessagesList.length,
    query.hasNextPage,
  ]);

  return (
    <div>
      <Header
        businessId={ businessId }
        hasNoMessages={ hasNoMessages }
      />
      <MessageList
        hasNoMessages={ hasNoMessages }
        messages={ transactionMessagesList }
        query={ query }
        setState={ setState }
        state={ state }
        onMessageClick={ onMessageClick }
      />
    </div>
  );
};

export default TransactionMessages;
