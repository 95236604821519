import React, { useCallback, useMemo } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetTransactionMessagesResponseInfinite } from '@src/requests/transaction_messages';
import { IChat, TTransactionMessageState } from '@src/types/transaction_message';

import { SelectInput, TOption } from '@src/components/ui_v2/inputs';

import TableList from './table/table_list';

import styles from './styles.module.scss';

interface IMessageListProps {
  hasNoMessages: boolean;
  state: TTransactionMessageState | undefined;
  setState: (state: TTransactionMessageState) => void;
  query: UseInfiniteQueryResult<IGetTransactionMessagesResponseInfinite, Error>;
  messages: IChat[];
  onMessageClick: (message: IChat) => void;
}

const stateOptions: TOption[] = [
  { label: 'All', value: 'all' },
  { label: 'Unread', value: 'unread' },
  { label: 'Read', value: 'read' },
];

const MessageList: React.FC<IMessageListProps> = ({
  hasNoMessages,
  messages,
  onMessageClick,
  state,
  setState,
  query,
}) => {
  const handleStateChange = useCallback((option: TOption | null) => {
    if (option) {
      setState(option.value as TTransactionMessageState);
    } else {
      setState('all');
    }
  }, [setState]);

  const selectedStateOption = useMemo(
    () => stateOptions.find((option) => option.value === state) || null,
    [state],
  );

  return (
    <div className={ styles['message-list-wrapper'] }>
      <div className={ styles['table-header-filter-wrapper'] }>
        <div className={ styles['table-header-filter-state'] }>
          <SelectInput
            menuPlacement="auto"
            menuPortalTarget={ document.body }
            options={ stateOptions }
            placeholder="Select State"
            value={ selectedStateOption }
            onChange={ handleStateChange }
          />
        </div>
      </div>
      <TableList
        hasNoMessages={ hasNoMessages }
        messages={ messages }
        query={ query }
        state={ state ?? 'all' }
        onMessageClick={ onMessageClick }
      />
    </div>
  );
};

export default MessageList;
