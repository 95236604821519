import { IBusinessSimple } from '@src/types/businesses';
import { TDate, TDateRange } from '@src/types/common';
import { IDataSet } from '@src/types/dashboards/data_sets';
import { IValue } from '@src/types/dashboards/values';
import { API_DATE_FORMAT, formatDate, formatMonth, getUTCTimezone } from '@src/utils/date_helpers';

import { TChartScale } from './types';

export type TChartDataInfo = {
  date: Date,
  value: number,
}

export type TChartDateRange = {
  from: Date,
  to: Date,
}

export const getDefaultDateRange = (scale?: TChartScale): TChartDateRange => {
  const startDate = new Date();
  switch (scale) {
    case window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY:
      startDate.setMonth(startDate.getMonth() - 2);
      break;
    case window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.DAILY:
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setDate(startDate.getDate() - 1);
      break;
    default:
      break;
  }
  return { from: startDate, to: new Date() };
};

export const getRealDateRange = (
  dateRange: TDateRange,
  bookkeepingStartDate: TDate,
  scale?: TChartScale,
  startDate?: string,
): TDateRange => {
  let from = new Date(bookkeepingStartDate);
  if (dateRange.startDate) from = getUTCTimezone(new Date(dateRange.startDate!));
  if (startDate && from < new Date(formatDate(startDate))) from = new Date(formatDate(startDate));

  let to = new Date();
  if (dateRange.endDate) to = getUTCTimezone(new Date(dateRange.endDate));
  switch (scale) {
    case window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY:
      return {
        startDate: formatDate(new Date(from.getFullYear(), from.getMonth(), 1), API_DATE_FORMAT),
        endDate:   formatDate(new Date(to.getFullYear(), to.getMonth() + 1, 0), API_DATE_FORMAT),
      };
    case window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.DAILY:
      return dateRange;
    default:
      break;
  }
  return dateRange;
};

const isEqualDates = (date1: TDate | Date, date2: TDate | Date, scale?: TChartScale): boolean => {
  switch (scale) {
    case window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY:
      return formatMonth(date1) === formatMonth(date2);
    case window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.DAILY:
      return formatDate(date1) === formatDate(date2);
    default:
      break;
  }
  return false;
};

export const fillData = (
  dataInfo: TChartDataInfo[],
  dataSets?: IDataSet[],
  identifier?: string,
  dataSet?: IDataSet,
  scale?: TChartScale,
) => {
  dataInfo.forEach((data) => { data.value = 0; });

  let resultDataSet = dataSet;
  if (dataSets && identifier) {
    resultDataSet = dataSets.find((data) => data.identifier === identifier);
  }
  if (!resultDataSet) return {};

  resultDataSet.values?.forEach((value: IValue) => {
    const data = dataInfo.filter((item) => {
      return isEqualDates(item.date, value.date, scale);
    })[0];
    if (data) data.value = value.value;
  });

  const values: number[] = [];
  dataInfo.forEach((data) => values.push(data.value));
  return {
    resultDataSet,
    values,
  };
};

export const getDataSetLabel = (
  dataSet: IDataSet,
  businesses: IBusinessSimple[],
) => {
  let label = dataSet.label;
  const business = businesses.find((item) => item.id === dataSet.businessId);
  if (business && business.name) label = business.name;
  return label;
};

export const getDataSetColor = (
  index: number,
) => {
  const colors: string[] = [
    '#F03E97', '#00ACB3', '#FFAC07', '#A500B3', '#479EF2', '#B84273',
    '#9D57FF', '#87B624', '#CA8E72', '#72AECA',
  ];

  return colors[index % 10];
};
