import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';

type TStringKeyData = { [key: string]: any };

const camelizeKeys = (data: object | object[], skipChildKeys: string[] = []): object => {
  return transform(data, (acc: { [key: string]: any }, value: any, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = (isObject(value) && !skipChildKeys.includes(camelKey)) ? camelizeKeys(value, skipChildKeys) : value;
  });
};

const manualUnderscore: { [key: string]: string } = {
  s3ObjectKey: 's3_object_key',
};

const underscoreKeys = (data: object | object[]): object => {
  return transform(data, (acc: { [key: string]: any }, value: any, key: string, target) => {
    let underscoreKey = key;
    if (!isArray(target) && key !== 'last4') {
      underscoreKey = manualUnderscore[key] ? manualUnderscore[key] : snakeCase(key);
    }

    acc[underscoreKey] = isObject(value) ? underscoreKeys(value) : value;
  });
};

const false2Undefined = <T extends TStringKeyData>(
  data: TStringKeyData,
  keys: (keyof T)[],
): TStringKeyData => {
  return transform(data, (acc: TStringKeyData, value: any, key: string) => {
    if (!keys.includes(key)) {
      acc[key] = value;
      return;
    }

    acc[key] = value ? true : undefined;
  });
};

const capitalize = (
  str: string,
) => {
  return (
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  );
};

const trimNumKeys = (data: object | object[], exceptions: string[] = []): object => {
  return transform(data, (acc: { [key: string]: any }, value: any, key: string) => {
    const isException = exceptions.includes(key);
    let convertedKey = key;

    if (!isException) {
      convertedKey = snakeCase(key);
      convertedKey = convertedKey.replace(/_([0-9])/g, '$1');
    }

    acc[convertedKey] = isObject(value) ? trimNumKeys(value, exceptions) : value;
  });
};

const toTitleCase = (str: string | null): string => (str ? str
  .toLowerCase()
  .replace(/\b\w/g, (char) => char.toUpperCase()) : '');

export {
  camelizeKeys,
  false2Undefined,
  underscoreKeys,
  capitalize,
  trimNumKeys,
  toTitleCase,
};
