const AT_UID_HEADER = '$[uid:';
const AT_NAME_HEADER = ',name:';
const AT_ROLE_HEADER = '$[role:';
const AT_FOOTER = ']$';
const AT_MARK = '@';

export const parseMessage = (text: string | undefined): string | undefined => {
  if (!text) return undefined;

  let message = text;

  while (message.includes(AT_UID_HEADER) || message.includes(AT_ROLE_HEADER)) {
    let startPos: number;

    if (!message.includes(AT_UID_HEADER)) {
      startPos = message.indexOf(AT_ROLE_HEADER);
    } else if (!message.includes(AT_ROLE_HEADER)) {
      startPos = message.indexOf(AT_UID_HEADER);
    } else {
      startPos = Math.min(
        message.indexOf(AT_UID_HEADER),
        message.indexOf(AT_ROLE_HEADER),
      );
    }

    const endPos = message.indexOf(AT_FOOTER);

    if (startPos > -1) {
      const atName = message.substring(startPos, endPos);
      const name = atName.includes(AT_NAME_HEADER)
        ? atName.substring(atName.indexOf(AT_NAME_HEADER) + AT_NAME_HEADER.length)
        : atName.substring(AT_ROLE_HEADER.length);

      message = message.replace(
        message.substring(startPos, endPos + AT_FOOTER.length),
        `<span class='in-blue-900'>${AT_MARK}${name}</span>`,
      );
    }
  }

  return message;
};
