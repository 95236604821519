import React, { useCallback, useState, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';

import toastr from '@lib/toastr';
import { useUploadBankStatementAttachment } from '@src/hooks/bank_statements';
import { useDeleteBalanceSheetStatement, useUploadBalanceSheetAttachment }
  from '@src/hooks/queries/balance_sheet_statements';
import { useDeleteBankStatement } from '@src/hooks/queries/bank_statements';
import { useDeleteDocument, useUploadDocument } from '@src/hooks/queries/documents';
import { IBankStatementWithDocytId, IBalanceSheetStatementWithDocytId, useDocumentTableItems }
  from '@src/hooks/use_document_table_items';
import { getBalanceSheetUrl } from '@src/requests/balance_sheet_statements';
import { getBankStatementUrl } from '@src/requests/bank_statements';
import { downloadDocument } from '@src/requests/documents';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocumentModel } from '@src/types/document_model';
import { IDocument } from '@src/types/documents';
import { createAndDownloadBlobFile, downloadFile } from '@src/utils/download_file';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import Spinner from '@src/components/ui/spinner';

import DocumentDropZone from './document_drop_zone';
import DocumentTable from './document_table';
import NoStatementCheckbox from './no_statement_checkbox';

import styles from './document_list.module.scss';

interface DocumentUploaderProps {
  model: IDocumentModel;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  documents: IDocument[];
  setDocuments?: React.Dispatch<React.SetStateAction<IDocument[]>>;
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  onDocumentsUpdate?: (docs: IDocument[]) => void;
  bankStatement: IBankStatementWithDocytId;
  balanceSheetStatements: IBalanceSheetStatementWithDocytId[];
  isReviewed?: boolean;
  notes?: string;
}

export type DocumentType = 'bank_statement' | 'balance_sheet' | 'document';

const DocumentUploader: React.FC<DocumentUploaderProps> = ({
  model,
  isUploading,
  setIsUploading,
  documents,
  bankStatement,
  balanceSheetStatements,
  noStatementAvailable,
  setNoStatementAvailable,
  onDocumentsUpdate,
  setDocuments,
  isReviewed = false,
}) => {
  const [selectedDocId, setSelectedDocId] = useState<TID | null>(null);
  const [localBankStatement, setLocalBankStatement] = useState<IBankStatementWithDocytId | null>(bankStatement);
  const [localBalanceSheetStatements, setLocalBalanceSheetStatements] =
    useState<IBalanceSheetStatementWithDocytId[]>(balanceSheetStatements);
  const uploadAttachment = useUploadBankStatementAttachment();
  const uploadBalanceSheet = useUploadBalanceSheetAttachment();

  useEffect(() => {
    setLocalBalanceSheetStatements(balanceSheetStatements);
  }, [balanceSheetStatements]);

  useEffect(() => {
    setLocalBankStatement(bankStatement);
  }, [bankStatement]);

  const isMailroomRequest = model.isMailroomRequest();
  const isBankStatementRequest = model.isBankStatementRequest();
  const isBalanceSheetRequest = model.isBalanceSheetStatementRequest();

  const deleteDocument = useDeleteDocument();
  const deleteBankStatement = useDeleteBankStatement();
  const deleteBalanceSheetStatement = useDeleteBalanceSheetStatement();

  const deleteModal = useConfirmDeleteModal({
    onDone: async (confirmed: boolean) => {
      if (confirmed && selectedDocId) {
        try {
          if (isBankStatementRequest) {
            await deleteBankStatement.mutateAsync({
              bankStatementId: selectedDocId,
              businessId:      model.get('business_id') as TID,
            });
            setLocalBankStatement(null);
            setDocuments?.([]);
            onDocumentsUpdate?.([]);
            window.Docyt.vent.trigger('bank_statement:deleted');
          } else if (isBalanceSheetRequest) {
            await deleteBalanceSheetStatement.mutateAsync({
              balanceSheetStatementId: selectedDocId,
              businessId:              model.get('business_id') as TID,
            });
            setLocalBalanceSheetStatements([]);
            setDocuments?.([]);
            onDocumentsUpdate?.([]);
            window.Docyt.vent.trigger('balance_sheet:deleted');
          } else {
            await deleteDocument.mutateAsync({ documentId: selectedDocId });
            const updatedDocs = documents.filter((doc) => doc.id !== selectedDocId);
            setDocuments?.(updatedDocs);
            onDocumentsUpdate?.(updatedDocs);
          }

          setNoStatementAvailable(false);
          toastr.success('Document deleted successfully', 'Success');
        } catch {
          toastr.error('Failed to delete document', 'Error');
        }
      }
    },
  });

  const validateBankStatementFileFormat = useCallback((file: File): boolean => {
    const allowedSize = window.configData.allowed_file_upload_size;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (file.size > allowedSize) {
      toastr.error(`File size should be less than ${allowedSize}`, 'Something went wrong');
      return false;
    }

    if (!fileExtension || !window.configData.allowed_file_upload_format.includes(fileExtension)) {
      toastr.error(
        'File you uploaded is not supported. You can only upload in one of these formats: pdf',
        'Something went wrong',
      );
      return false;
    }

    return true;
  }, []);

  const [isJustUploaded, setIsJustUploaded] = useState(false);

  const documentTableItems = useDocumentTableItems({
    documents,
    bankStatement:          localBankStatement,
    balanceSheetStatements: localBalanceSheetStatements,
    isMailroomRequest,
    isBankStatementRequest,
    isBalanceSheetRequest,
    isJustUploaded,
    _isReviewed:            isReviewed,
    noStatementAvailable,
  });

  const handleDocumentClick = useCallback(async (e: React.MouseEvent, docId: number, type: string) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      toastr.success('Downloading document...', 'Download in progress');

      let bankStatementResponse;
      let balanceSheetResponse;
      let response;

      switch (type) {
        case 'bank_statement':
          bankStatementResponse = await getBankStatementUrl({
            bankStatementId: docId,
          });
          downloadFile(bankStatementResponse.statementFileUrl);
          break;
        case 'balance_sheet':
          balanceSheetResponse = await getBalanceSheetUrl({
            balanceSheetStatementId: docId,
          });
          downloadFile(balanceSheetResponse.statementFileUrl);
          break;
        default:
          response = await downloadDocument({
            documentId: docId,
            type:       'original',
          });
          createAndDownloadBlobFile(response.fileData, response.fileName, { type: 'application/pdf' });
      }

      toastr.success('Document downloaded successfully', 'Success');
    } catch {
      toastr.error('Document PDF not ready', 'Warning');
    }
  }, []);

  const handleUploadSuccess = useCallback((newDocument: IDocument) => {
    setDocuments?.((prevDocs) => [...prevDocs, newDocument]);
    onDocumentsUpdate?.([...documents, newDocument]);
    setIsJustUploaded(true);
    window.Docyt.vent.trigger('document:uploaded', model.id);
  }, [documents, model.id, onDocumentsUpdate, setDocuments]);

  const uploadBankStatement = useCallback(async (files: File[]): Promise<IBankStatement[]> => {
    setIsUploading(true);
    try {
      const uploadPromises = files.map(async (file) => {
        if (!validateBankStatementFileFormat(file)) return null;

        try {
          const response = await uploadAttachment.mutateAsync({
            businessId:      model.get('business_id') as number,
            bankStatementId: bankStatement.id,
            file,
          });

          if (response.bankStatement) {
            const newDocument: IDocument = {
              id:                        response.bankStatement.id,
              name:                      response.bankStatement.name || '',
              docytId:                   response.bankStatement.docytId || '',
              chatId:                    0,
              consumerId:                0,
              current:                   true,
              businessDocuments:         [],
              businessNames:             [],
              businesses:                [],
              createdAt:                 new Date().toISOString(),
              documentOwners:            [],
              source:                    '',
              state:                     'uploaded',
              storageSize:               0,
              unencryptedDocumentFields: [],
              updatedAt:                 new Date().toISOString(),
              uploaderEmail:             '',
              uploaderName:              '',
              dueOff:                    false,
              expiryOff:                 false,
              haveAccess:                true,
              isFrozen:                  false,
              pages:                     [],
              sharers:                   [],
              lastModifiedAt:            new Date().toISOString(),
              computed_final_filename:   null,
              final_file_key:            null,
              original_file_key:         null,
              original_file_name:        null,
            };

            handleUploadSuccess(newDocument);
            setLocalBankStatement(response.bankStatement);
            return response.bankStatement;
          }
          return null;
        } catch {
          toastr.error('Failed to upload file', 'Error');
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((r): r is IBankStatement => r !== null);

      if (successfulUploads.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return successfulUploads;
    } catch {
      toastr.error('Upload failed', 'Error');
      return [];
    } finally {
      setIsUploading(false);
    }
  }, [
    model,
    bankStatement?.id,
    handleUploadSuccess,
    setIsUploading,
    uploadAttachment,
    validateBankStatementFileFormat,
  ]);

  const uploadBalanceSheetStatement = useCallback(async (files: File[]): Promise<IBalanceSheetStatement[]> => {
    setIsUploading(true);
    try {
      const uploadPromises = files.map(async (file) => {
        if (!validateBankStatementFileFormat(file)) {
          return null;
        }
        try {
          const response = await uploadBalanceSheet.mutateAsync({
            businessId:              model.get('business_id') as number,
            balanceSheetStatementId: balanceSheetStatements[0]?.id,
            file,
          });

          if (response.balanceSheetStatement) {
            const newDocument: IDocument = {
              id:                        response.balanceSheetStatement.id,
              name:                      response.balanceSheetStatement.name || '',
              docytId:                   response.balanceSheetStatement.docytId || '',
              chatId:                    0,
              consumerId:                0,
              current:                   true,
              businessDocuments:         [],
              businessNames:             [],
              businesses:                [],
              createdAt:                 new Date().toISOString(),
              documentOwners:            [],
              source:                    '',
              state:                     'uploaded',
              storageSize:               0,
              unencryptedDocumentFields: [],
              updatedAt:                 new Date().toISOString(),
              uploaderEmail:             '',
              uploaderName:              '',
              dueOff:                    false,
              expiryOff:                 false,
              haveAccess:                true,
              isFrozen:                  false,
              pages:                     [],
              sharers:                   [],
              lastModifiedAt:            new Date().toISOString(),
              computed_final_filename:   null,
              final_file_key:            null,
              original_file_key:         null,
              original_file_name:        null,
            };

            setLocalBalanceSheetStatements([response.balanceSheetStatement]);
            setDocuments?.([newDocument]);
            onDocumentsUpdate?.([newDocument]);
            setIsJustUploaded(true);
            window.Docyt.vent.trigger('document:uploaded', model.id);
            return response.balanceSheetStatement;
          }
          return null;
        } catch {
          toastr.error('Failed to upload file', 'Error');
          return null;
        }
      });

      const results = await Promise.all(uploadPromises);
      const successfulUploads = results.filter((r): r is IBalanceSheetStatement => r !== null);

      if (successfulUploads.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return successfulUploads;
    } catch {
      toastr.error('Upload failed', 'Error');
      return [];
    } finally {
      setIsUploading(false);
    }
  }, [
    model,
    balanceSheetStatements,
    uploadBalanceSheet,
    setIsUploading,
    validateBankStatementFileFormat,
    onDocumentsUpdate,
    setDocuments,
  ]);

  const uploadDocument = useUploadDocument();

  const uploadMailroomDocuments = useCallback(async (files: File[]): Promise<IDocument[]> => {
    try {
      const uploadPromises = files.map((file) => {
        return uploadDocument.mutateAsync({
          documentParams: {
            document: {
              originalFileName: file.name,
              fileContentType:  file.type,
              storageSize:      file.size,
            },
            businessId:    model.get('business_id') as TID,
            inboxDocument: true,
          },
          file,
        }).then((doc) => doc as IDocument);
      });

      const uploadedDocuments = await Promise.all(uploadPromises);
      const validDocuments = uploadedDocuments.filter((doc): doc is IDocument => doc != null);

      const newDocuments = [...documents, ...validDocuments];
      setDocuments?.(newDocuments);
      onDocumentsUpdate?.(newDocuments);

      window.Docyt.vent.trigger('document:uploaded', model.id);

      if (validDocuments.length > 0) {
        toastr.success('Files uploaded successfully', 'Success');
      }

      return validDocuments;
    } catch {
      toastr.error('Failed to upload documents', 'Error');
      return [];
    }
  }, [
    model,
    uploadDocument,
    documents,
    onDocumentsUpdate,
    setDocuments,
  ]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({

    onDrop: async (acceptedFiles) => {
      if (isUploading) return;

      try {
        setIsUploading(true);

        if (isMailroomRequest) {
          await uploadMailroomDocuments(acceptedFiles);
        } else if (isBankStatementRequest) {
          if (acceptedFiles.length > 1) {
            toastr.error('Only one file can be uploaded at a time', 'Error');
            return;
          }

          if (documents.length > 0) {
            return;
          }

          await uploadBankStatement([acceptedFiles[0]]);
        } else if (isBalanceSheetRequest) {
          if (acceptedFiles.length > 1) {
            toastr.error('Only one file can be uploaded at a time', 'Error');
            return;
          }

          if (documents.length > 0) {
            return;
          }

          await uploadBalanceSheetStatement([acceptedFiles[0]]);
        }
      } catch {
        toastr.error('Upload failed', 'Error');
      } finally {
        setIsUploading(false);
      }
    },
    multiple: isMailroomRequest,
    accept:   '.pdf,application/pdf',
  });

  const handleDelete = useCallback(async (docId: TID) => {
    setSelectedDocId(docId);
    deleteModal.open();
  }, [deleteModal]);

  return (
    <div>
      {isUploading && <Spinner />}
      {!documentTableItems.length ? (
        (isReviewed || noStatementAvailable) && (
          <div className={ styles['documents-table'] }>
            <div className={ styles['no-documents-message'] }>
              {noStatementAvailable ? 'No statement needed' : 'No document available'}
            </div>
          </div>
        )
      ) : (
        <DocumentTable
          balanceSheetStatements={ localBalanceSheetStatements }
          bankStatement={ localBankStatement }
          businessId={ model.get('business_id') as TID }
          documents={ documents }
          isBalanceSheetRequest={ isBalanceSheetRequest }
          isBankStatementRequest={ isBankStatementRequest }
          isJustUploaded={ isJustUploaded }
          isMailroomRequest={ isMailroomRequest }
          isReviewed={ isReviewed }
          onDeleteClick={ handleDelete }
          onDocumentClick={ handleDocumentClick }
        />
      )}
      {(!isReviewed && !noStatementAvailable) && (
        (isMailroomRequest || !documentTableItems.length) && (
          <DocumentDropZone
            getInputProps={ getInputProps }
            getRootProps={ getRootProps }
            isDragActive={ isDragActive }
            isMailroomRequest={ isMailroomRequest }
            isReviewed={ isReviewed }
            isUploading={ isUploading }
          />
        )
      )}
      {!isReviewed && (
        <NoStatementCheckbox
          documentRequestId={ model.id }
          documentsLength={ documentTableItems.length }
          isMailroomRequest={ isMailroomRequest }
          noStatementAvailable={ noStatementAvailable }
          setNoStatementAvailable={ setNoStatementAvailable }
        />
      )}

      <deleteModal.Component
        confirmStyle="primary"
        confirmTitle="Delete"
        text="Are you sure you want to delete this document?"
        title="Delete Document"
        { ...deleteModal.props }
      />
    </div>
  );
};

export default DocumentUploader;
