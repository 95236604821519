import React from 'react';

import { IDocument } from '@src/types/documents';

import { useUploadDocumentModal } from '@src/components/common_v2/document/upload_document';
import { TextAreaInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';
import PlusIcon from '@src/components/utils/fa_icons/plus_icon';

import styles from './styles.module.scss';

interface IChatInputProps {
  document: IDocument;
}

const ChatInput: React.FC<IChatInputProps> = ({ document }) => {
  const uploadModal = useUploadDocumentModal();
  return (
    <div className={ styles['chat-input-container'] }>
      <TextAreaInput
        placeholder="Send a message..."
        prefixIcon={ (
          <Tooltip.Hover
            className={ styles['chat-input-attachment-button-container'] }
            content="Add Document"
            width={ 102 }
          >
            <PlusIcon
              className={ styles['chat-input-attachment-button'] }
              onClick={ uploadModal.open }
            />
          </Tooltip.Hover>
        ) }
      />
      {uploadModal.Component && <uploadModal.Component { ...uploadModal.props } document={ document } />}
    </div>
  );
};

export default ChatInput;
