import isArray from 'lodash/isArray';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { tableHiddenColumnsStorageKey } from '@src/constants/storage_keys';
import { TSection } from '@src/types/common';

import { TColumn, TModel } from './types';

type TColumnsState = TColumn<TModel, string>[];

const restoreHiddenColumnsFromStorage = (section: TSection): string[] | undefined => {
  const serializedColumns = localStorage.getItem(tableHiddenColumnsStorageKey(section));
  if (!serializedColumns) return undefined;

  const columns = JSON.parse(serializedColumns);
  if (!columns) return undefined;

  if (!isArray(columns)) return undefined;

  return columns as string[];
};

const storeHiddenColumnsToStorage = (
  section: TSection,
  columns: string[] | undefined,
) => {
  if (!columns || columns.length === 0) {
    localStorage.removeItem(tableHiddenColumnsStorageKey(section));
    return;
  }

  localStorage.setItem(tableHiddenColumnsStorageKey(section), JSON.stringify(columns));
};

const makeSyncHiddenColumnsWithStorageEffect = (
  section: TSection,
) => {
  const baseAtom = atom(restoreHiddenColumnsFromStorage(section));
  return atom(
    (get) => get(baseAtom),
    (_get, set, newValue: string[]) => {
      storeHiddenColumnsToStorage(section, newValue);
      set(baseAtom, newValue);
    }
  );
};

const hiddenColumns = atomFamily((section: TSection) => makeSyncHiddenColumnsWithStorageEffect(section));

const tableColumns = atomFamily((section: TSection) => 
  atom<TColumnsState>([])
);

const tableColumnsWithHidden = atomFamily((section: TSection) => {
  return atom(
    (get) => {
      const hiddenColumnsData = get(hiddenColumns(section));
      const columnsData = get(tableColumns(section));
      return columnsData.map((column) => {
        if (!hiddenColumnsData?.includes(column.name)) return column;
  
        return {
          ...column,
          hidden: true,
        };
      });
    },
    (get, set, newValue: TColumnsState | ((prev: TColumnsState) => TColumnsState)) => {
      const resolvedValue = typeof newValue === 'function' 
        ? newValue(get(tableColumnsWithHidden(section)))
        : newValue;
      if (resolvedValue.length === 0) return;
      
      const hiddenColumnsData = resolvedValue.filter((c) => c.hidden).map((c) => c.name);
      set(tableColumns(section), resolvedValue);
      set(hiddenColumns(section), hiddenColumnsData);
    }
  );
});

export {
  hiddenColumns,
  tableColumns,
  tableColumnsWithHidden,
};
