import { uploadBalanceSheetStatementAttachment, IUploadBalanceSheetStatementAttachmentParams,
  IUploadBalanceSheetStatementAttachmentResponse } from '@src/requests/attachments';
import {
  IBalanceSheetStatement,
} from '@src/types/balance_sheet_statements';
import { TDate, TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';

interface ICreateBalanceSheetStatementParams {
  businessId: TID,
  file: File,
  chartOfAccountId: TID,
  date?: TDate,
  description?: string,
}

interface ICreateBalanceSheetStatementResponse {
  balanceSheetStatement: IBalanceSheetStatement,
}

const createBalanceSheetStatement = (
  params: ICreateBalanceSheetStatementParams,
): Promise<ICreateBalanceSheetStatementResponse> => {
  const formData = new FormData();
  formData.append('business_id', String(params.businessId));
  formData.append('chart_of_account_id', String(params.chartOfAccountId));
  formData.append('date', String(params.date));
  formData.append('description', String(params.description));
  if (params.file) {
    formData.append('file', params.file);
  }
  return apiPost(
    '/api/v1/balance_sheet_statements',
    formData,
  ).then((data) => camelizeKeys(data) as ICreateBalanceSheetStatementResponse);
};

interface IUpdateBalanceSheetStatementParams {
  id: TID,
  businessId: TID,
  chartOfAccountId?: TID,
  date?: TDate,
  description?: string,
}

const updateBalanceSheetStatement = (
  params: IUpdateBalanceSheetStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/balance_sheet_statements/${params.id}`,
    underscoreKeys(params),
  );
};

interface IGetBalanceSheetStatementResponse {
  balanceSheetStatement: IBalanceSheetStatement,
}

const getBalanceSheetStatementByDocytId = (
  businessId?: TID,
  docytId?: string,
): Promise<IGetBalanceSheetStatementResponse> => {
  return apiGet(
    '/api/v1/balance_sheet_statements/by_docyt_id',
    underscoreKeys({ businessId, docytId }),
  ).then((data) => {
    return {
      balanceSheetStatement: camelizeKeys(data.balance_sheet_statement),
    } as IGetBalanceSheetStatementResponse;
  });
};

interface IRequestBalanceSheetStatementParams {
  businessId: TID,
  chartOfAccountId: TID,
  date: TDate,
  description?: string,
}

const postRequestBalanceSheetStatement = (
  params: IRequestBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_statements/request_statement',
    underscoreKeys(params),
  );
};

interface IVerifyalanceSheetStatementParams {
  id: TID,
  businessId: TID,
}

const verifyRequestBalanceSheetStatement = (
  params: IVerifyalanceSheetStatementParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/balance_sheet_statements/${params.id}/verify`,
    underscoreKeys(params),
  );
};

interface IDeleteBalanceSheetStatementParams {
  id: TID;
  state?: string;
  confirm?: boolean;
  businessId: TID;
}

const deleteBalanceSheetStatement = (
  params: IDeleteBalanceSheetStatementParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/balance_sheet_statements/${params.id}`,
    underscoreKeys(params),
  );
};

interface IUnverifyBalanceSheetStatementParams {
  id?: TID,
  businessId?: TID,
}

const postUnverifyBalanceSheetStatement = (
  params: IUnverifyBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_statements/unverify',
    underscoreKeys(params),
  );
};

interface IDownloadBalanceSheetStatementParams {
  balanceSheetStatementId: TID;
}

interface IDownloadBalanceSheetStatementResponse {
  id: TID,
  statementFileUrl: string,
  balanceSheetFileName: string,
}

const getBalanceSheetStatement = (businessId: TID, id: TID): Promise<IGetBalanceSheetStatementResponse> => {
  return apiGet(`/api/v1/balance_sheet_statements/${id}`)
    .then((data) => ({
      balanceSheetStatement: camelizeKeys(data.balance_sheet_statement) as IBalanceSheetStatement,
    }));
};

export const uploadBalanceSheetAttachment = async (
  params: IUploadBalanceSheetStatementAttachmentParams,
): Promise<IUploadBalanceSheetStatementAttachmentResponse> => {
  const response = await uploadBalanceSheetStatementAttachment(params);

  window.Docyt.vent.trigger('balance_sheet:uploaded');

  return response;
};

interface IDeleteBalanceSheetStatementAttachmentParams {
  id: TID;
  businessId: TID;
}

const deleteBalanceSheetStatementAttachment = (
  params: IDeleteBalanceSheetStatementAttachmentParams,
): Promise<void> => {
  return apiDelete(
    `/api/v1/balance_sheet_statements/${params.id}/delete_attachment`,
    underscoreKeys(params),
  );
};

const getBalanceSheetUrl = (params: IDownloadBalanceSheetStatementParams):
Promise<IDownloadBalanceSheetStatementResponse> => {
  return apiGet(
    `/api/v1/balance_sheet_statements/${params.balanceSheetStatementId}/statement_file_url`,
  ).then((data) => {
    return {
      id:                   data.balance_sheet_statement.id,
      statementFileUrl:     data.balance_sheet_statement.statement_file_url,
      balanceSheetFileName: data.balance_sheet_statement.balance_sheet_file_name,
    } as IDownloadBalanceSheetStatementResponse;
  });
};

export {
  ICreateBalanceSheetStatementParams,
  ICreateBalanceSheetStatementResponse,
  IUpdateBalanceSheetStatementParams,
  IRequestBalanceSheetStatementParams,
  IVerifyalanceSheetStatementParams,
  IGetBalanceSheetStatementResponse,
  IDeleteBalanceSheetStatementParams,
  IUnverifyBalanceSheetStatementParams,
  IDeleteBalanceSheetStatementAttachmentParams,
  createBalanceSheetStatement,
  updateBalanceSheetStatement,
  getBalanceSheetStatementByDocytId,
  postRequestBalanceSheetStatement,
  verifyRequestBalanceSheetStatement,
  deleteBalanceSheetStatement,
  postUnverifyBalanceSheetStatement,
  getBalanceSheetStatement,
  deleteBalanceSheetStatementAttachment,
  getBalanceSheetUrl,
};
