import React, { useCallback } from 'react';

import { useResetAtom } from 'jotai/utils';

import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { formatMonth, getUTCTimezone } from '@src/utils/date_helpers';

import { addTransactionFieldsAtom } from '@src/components/banking_accounts/transactions/add_transaction/atoms';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import Table from '@src/components/ui/table';

import DeleteAction from '../../actions/delete_action';
import MarkAsNoAvailableAction from '../../actions/mark_as_no_available/mark_as_no_available_action';
import UnapproveAction from '../../actions/unapprove_action';
import UnmarkNoStatementAvailableAction from '../../actions/unmark_no_statement_available_action';
import UnverifyAction from '../../actions/unverify_action';
import { useBankStatementDetailsModal } from '../../bank_statement_details/details_modal';
import { useVerifyBankFeedModal } from '../../bank_statement_details/verify_bank_feed/verify_bank_feed_modal';

import styles from '../styles.module.scss';

const needDeleteActionStates: string[] = [
  window.Docyt.Common.Constants.BANK_STATEMENT_STATES.UPLOADED,
  window.Docyt.Common.Constants.BANK_STATEMENT_STATES.REQUESTED,
  window.Docyt.Common.Constants.BANK_STATEMENT_STATES.APPROVED,
  window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED,
];

interface IReviewedStatementDetailsTableItemProps {
  bankStatement: IBankStatement,
  bankStatementsDocRequestEnabled: boolean,
  rcPaymentAccountId: TID,
  businessId: TID,
  onCloseDetailsModal?: () => void,
}

const ReviewedStatementDetailsTableItem = ({
  bankStatement,
  bankStatementsDocRequestEnabled,
  rcPaymentAccountId,
  businessId,
  onCloseDetailsModal,
}: IReviewedStatementDetailsTableItemProps) => {
  const resetAddTransactionFieldsAtom = useResetAtom(addTransactionFieldsAtom);

  const verifyBankFeedModal = useVerifyBankFeedModal({
    onDone:   resetAddTransactionFieldsAtom,
    onCancel: resetAddTransactionFieldsAtom,
  });

  const detailModal = useBankStatementDetailsModal();
  const { openWithValue } = detailModal;

  const handleOpenModal = useCallback(() => {
    if (bankStatement.state === 'approved' || bankStatement.state === 'verified') {
      verifyBankFeedModal.open();
    } else if (bankStatement.state === 'uploaded' && bankStatement.docytId) {
      openWithValue({
        docytId: bankStatement.docytId,
      });
    }
  }, [bankStatement.docytId, bankStatement.state, openWithValue, verifyBankFeedModal]);

  let stateContent;
  if (bankStatement.state === 'approved') {
    stateContent = (<span className={ styles['approved-state'] }>{ bankStatementsDocRequestEnabled ? 'Approved' : 'Statement Approved' }</span>);
  } else if (bankStatement.state === 'verified') {
    stateContent = (<span className={ styles['verified-state'] }>{ bankStatementsDocRequestEnabled ? 'Verified' : 'Bank Feed Verified' }</span>);
  } else if (bankStatement.state === 'not_available') {
    stateContent = (<span className={ styles['not-available-state'] }>No Statement Available</span>);
  } else if (bankStatementsDocRequestEnabled
    && bankStatement.state === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.UPLOADED) {
    stateContent = (<span className={ styles['uploaded-state'] }>Uploaded</span>);
  } else if (bankStatementsDocRequestEnabled
    && bankStatement.state === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.REQUESTED) {
    stateContent = (<span className={ styles['requested-state'] }>Document Requested</span>);
  } else {
    stateContent = (<span> - </span>);
  }

  return (
    <>
      <verifyBankFeedModal.Component
        { ...verifyBankFeedModal.props }
        businessId={ businessId }
        docytId={ bankStatement.docytId }
      />
      <detailModal.Component
        { ...detailModal.props }
        businessId={ businessId }
        onCloseModal={ onCloseDetailsModal }
      />
      <Table.Row className={ styles['table-row'] }>
        <Table.Cell
          pointer
          style={ { color: '#0080FF' } }
          onClick={ handleOpenModal }
        >
          { bankStatement.month ? formatMonth(bankStatement.month) : '--' }
        </Table.Cell>
        {
        bankStatement.date ? (
          <Table.DateCell
            pointer
            date={ String(getUTCTimezone(new Date(bankStatement.date))) }
            onClick={ handleOpenModal }
          />
        ) : (
          <Table.Cell pointer onClick={ handleOpenModal }>
            -
          </Table.Cell>
        )
      }
        <Table.Cell pointer onClick={ handleOpenModal }>
          { stateContent }
        </Table.Cell>
        { bankStatementsDocRequestEnabled && (
          <Table.Cell
            pointer
            onClick={ handleOpenModal }
          >
            { bankStatement.uploadedByName ? bankStatement.uploadedByName : '--' }
          </Table.Cell>
        )}
        <Table.Cell
          pointer
          onClick={ handleOpenModal }
        >
          { bankStatement.approvedByName ? bankStatement.approvedByName : '--' }
        </Table.Cell>
        <Table.Cell
          pointer
          onClick={ handleOpenModal }
        >
          { bankStatement.verifiedByName ? bankStatement.verifiedByName : '--' }
        </Table.Cell>
        {
        bankStatement.closingBalance ? (
          <Table.AmountCell
            pointer
            amount={ bankStatement.closingBalance }
            fontVariant="bold"
            style={ { paddingRight: '30px' } }
            onClick={ handleOpenModal }
          />
        ) : (
          <Table.Cell
            pointer
            fontVariant="bold"
            style={ { paddingRight: '30px', textAlign: 'right' } }
            onClick={ handleOpenModal }
          >
            --
          </Table.Cell>
        )
      }
        <Table.Cell className="bank-statement-actions-column">
          {
            bankStatement.state === 'not_available' ? (
              <ActionsDropdown>
                <UnmarkNoStatementAvailableAction
                  bankStatement={ bankStatement }
                  businessId={ businessId }
                />
              </ActionsDropdown>
            ) : (
              <ActionsDropdown>
                { bankStatement.state === 'verified' && <UnverifyAction bankStatement={ bankStatement } businessId={ businessId } /> }
                { bankStatement.state === 'approved' && <UnapproveAction bankStatement={ bankStatement } /> }
                {
                  bankStatement.state === '' && (
                    <MarkAsNoAvailableAction
                      bankStatement={ bankStatement }
                      businessId={ businessId }
                      rcPaymentAccountId={ rcPaymentAccountId }
                    />
                  )
                }
                {
                  needDeleteActionStates.includes(bankStatement.state) && (
                    <DeleteAction bankStatement={ bankStatement } />
                  )
                }
              </ActionsDropdown>
            )
          }
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default React.memo(ReviewedStatementDetailsTableItem);
