import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import {
  DocumentTypeFilterField,
  FlaggedStatusFilterField,
  MultiBankAccountsFilterField,
  QBOSyncFilterField,
  TransactionTypeFilterField,
  VerifiedByFilterField,
  VendorFilterField,
  ChartOfAccountFilterField,
} from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

const AllTransactionsFilter = (
  { bulkActions, type }: { bulkActions: JSX.Element, type?: string },
) => {
  const business = useBusinessContext();
  const visibleColumnNames = useVisibleColumnNames();
  const isTransactionsForReview =
    type === window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTION_FOR_REVIEW;
  const isCategorizedTransactionsAll =
    type === window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTION_CATEGORIZED;

  return (
    <Filter.TableContainer>
      { bulkActions }
      { (visibleColumnNames.includes('transaction_date') && (
        <Filter.DateField name="transaction_date" />
      )) }
      { (visibleColumnNames.includes('description') && (
        <Filter.TextField
          label="Description"
          name="description"
          placeholder="Description"
        />
      )) }
      { (visibleColumnNames.includes('payment_account') && (
        <MultiBankAccountsFilterField
          businessId={ business.id }
          placeholder="Bank Account"
        />
      )) }
      { (visibleColumnNames.includes('transaction_type')
        && !isTransactionsForReview
        && !isCategorizedTransactionsAll && (
        <TransactionTypeFilterField placeholder="Type" />
      )) }
      { (visibleColumnNames.includes('amount') && (
        <Filter.AmountField
          name="amount"
          valueProps={ { fixedDecimalScale: false } }
        />
      )) }
      { (visibleColumnNames.includes('flagged_state') && (
        <FlaggedStatusFilterField placeholder="Flagged State" />
      )) }
      { (visibleColumnNames.includes('categorized_by') && (
        <VerifiedByFilterField placeholder="Categorized By" />
      )) }
      { (visibleColumnNames.includes('qbo_status')
        && !isTransactionsForReview
        && !isCategorizedTransactionsAll && (
        <QBOSyncFilterField placeholder="QBO Status" />
      )) }
      { (visibleColumnNames.includes('document_type') && (
        <DocumentTypeFilterField placeholder="Document Type" />
      )) }
      { (visibleColumnNames.includes('vendor_id') && (
        <VendorFilterField
          businessId={ business.id }
          name="vendor_id"
          placeholder="Vendor"
        />
      )) }
      { (visibleColumnNames.includes('chart_of_account') && (
        <ChartOfAccountFilterField
          businessId={ business.id }
          name="chart_of_account"
          placeholder="Category"
        />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(AllTransactionsFilter);
