import Details from './details';
import DocumentViewLayout from './document_view';
import Footer from './footer';
import Header from './header';
import DocumentView from './view';

export default Object.assign(DocumentViewLayout, {
  Header,
  DocumentView,
  Details,
  Footer,
});
