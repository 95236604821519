module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class="client-column">\n  <div class="client-info">\n    <a class="');
    
      __out.push(__sanitize(this.archived_at ? 'disabled-link' : ''));
    
      __out.push('" href="');
    
      __out.push(__sanitize(this.archived_at ? 'javascript:;' : '/setup-client-business/' + this.id + '?accounting_firm_business_id=' + this.accounting_firm_business_id + '&step=3'));
    
      __out.push('">\n      <div class="avatar-wrapper">\n        ');
    
      if (this.image_url) {
        __out.push('\n          <img class="business-img" src="');
        __out.push(__sanitize(this.image_url));
        __out.push('"/>\n        ');
      } else {
        __out.push('\n          <span class=\'icon-business-default-avatar font-40\'>\n            <span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span>\n            <span class=\'path4\'></span><span class=\'path5\'></span><span class=\'path6\'></span>\n            <span class=\'path7\'></span><span class=\'path8\'></span><span class=\'path9\'></span>\n            <span class=\'path10\'></span><span class=\'path11\'></span><span class=\'path12\'></span>\n            <span class=\'path13\'></span><span class=\'path14\'></span>\n          </span>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class="client-details">\n        <div class="client-name">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</div>\n        <div class="client-description">');
    
      __out.push(__sanitize(this.display_name));
    
      __out.push('</div>\n      </div>\n    </a>\n    ');
    
      if (this.archived_at) {
        __out.push('\n      <div class="action-wrapper">\n        <div class="relative">\n          <button class="pointer dropdown-toggle action-menu-toggle-btn" type="button" id="client-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n            <i class=\'icon icon-3-dots font-20\'></i>\n          </button>\n          <div class="client-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="client-');
        __out.push(__sanitize(this.id));
        __out.push('-action-dropdown-btn">\n            <a class="dropdown-item font-14 pointer unarchive-client-js">\n              <span class="icon icon-account-unarchive"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>\n              <span>Mark as Active</span>\n            </a>\n            <a class="dropdown-item font-14 pointer delete-client-js danger">\n              <span class="icon icon-trashcan m-l-5"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>\n              <span class="m-l-5">Delete</span>\n            </a>\n          </div>\n        </div>\n      </div>\n    ');
      } else {
        __out.push('\n      <div class="action-wrapper">\n        <span class="archive-icon narrow-tooltip" data-toggle="tooltip" data-placement="left" title="Mark as Inactive">\n          <i class="icon icon-archive"></i>\n        </span>\n      </div>\n    ');
      }
    
      __out.push('\n  </div>\n</td> ');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}