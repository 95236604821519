import { Dispatch, ReactElement, SetStateAction, useEffect, useMemo } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { TSection } from '@src/types/common';

import { useDetailsRegion } from '@src/components/ui_v2/layout/details_region/hooks';
import { useExpandRegion } from '@src/components/ui_v2/layout/expand_region/hooks';
import { useSection } from '@src/components/utils_v2/section';

import { tableColumns, tableColumnsWithHidden } from './atoms';
import { TColumn, TModel } from './types';
import { mapChildrenToColumns } from './utils';

const MAX_COLUMN_COUNT = 99;

type IUseCollectionColumnsState<Model extends TModel, TSortColumn extends string = never> = [
  TColumn<Model, TSortColumn>[],
  Dispatch<SetStateAction<TColumn<Model, TSortColumn>[]>>
];

const useCollectionColumnsState = <Model extends TModel, TSortColumn extends string = never>(
  children: ReactElement<TColumn<Model, TSortColumn>> | ReactElement<TColumn<Model, TSortColumn>>[],
  sectionParam?: TSection,
  defaultColumnCount?: number
): IUseCollectionColumnsState<Model, TSortColumn> => {
  const section = useSection(sectionParam);

  const setColumns = useSetAtom(tableColumns(section));
  const [columns, setColumnsWithHidden] = useAtom(tableColumnsWithHidden(section));

  useEffect(() => {
    let columnCount = defaultColumnCount || MAX_COLUMN_COUNT;
    setColumns(mapChildrenToColumns(children) as TColumn<TModel, string>[]);
    setColumnsWithHidden(oldColumns => {
      return oldColumns.map(c => {
        if (columnCount > 0) {
          if (!c.hidden) columnCount -= 1;
          return c;
        }

        return {
          ...c,
          hidden: true,
        };
      });
    });
  }, [children, setColumns, setColumnsWithHidden, defaultColumnCount]);

  return [
    columns as TColumn<Model, TSortColumn>[],
    setColumnsWithHidden as Dispatch<SetStateAction<TColumn<Model, TSortColumn>[]>>,
  ];
};

const useVisibleColumnNames = () => {
  const section = useSection();
  const columns = useAtomValue(tableColumnsWithHidden(section));
  return useMemo(() => {
    return columns.filter(c => !c.hidden).map(c => c.name);
  }, [columns]);
};

const useTableHeight = (height?: string): string => {
  const offsetValue = '25px';
  const { headerRegionHeight, footerHeight, secondHeaderHeight, bannerHeight } = useDetailsRegion();
  const { toggleStatus: expandStatus } = useExpandRegion();
  if (height) return height;

  const heightSecondHeader = expandStatus ? 0 : secondHeaderHeight;

  return `calc(100vh - ${heightSecondHeader}px - ${headerRegionHeight}px - ${footerHeight}px - ${bannerHeight}px - ${offsetValue})`;
}

export {
  useCollectionColumnsState,
  useVisibleColumnNames,
  useTableHeight,
};