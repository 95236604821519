import React, { useState, useMemo, ReactNode } from 'react';

import { MAX_DOCUMENT_WIDTH_PERCENTAGE } from './constant';
import { DocumentViewWidthContext, IDocumentViewWidthContextProps } from './hooks';

interface IDocumentViewWidthProviderProps {
  children: ReactNode;
  parentElementSize: { width: number; height: number };
}

const Provider = ({ children, parentElementSize }: IDocumentViewWidthProviderProps) => {
  const [documentWidthPercentage, setDocumentWidthPercentage] = useState<number>(MAX_DOCUMENT_WIDTH_PERCENTAGE);

  const contextValue: IDocumentViewWidthContextProps = useMemo(() => ({
    documentWidthPercentage,
    setDocumentWidthPercentage,
    parentElementSize,
  }), [documentWidthPercentage, setDocumentWidthPercentage, parentElementSize]);

  return (
    <DocumentViewWidthContext.Provider value={ contextValue }>
      {children}
    </DocumentViewWidthContext.Provider>
  );
};

export default Provider;
