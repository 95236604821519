import React, { useMemo } from 'react';

import { format } from 'date-fns';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { UserAvatar } from '@src/components/ui/avatars';
import { Button } from '@src/components/ui_v2/buttons';
import { DownloadIcon } from '@src/components/utils/icomoon';

import styles from './style.module.scss';

interface IBookClosedItemProps {
  auditScan: AuditScanDetails;
}

const BookClosedItem = ({ auditScan }: IBookClosedItemProps) => {
  const renderMonthlyStatements = useMemo(() => {
    const { closedItems } = auditScan;
    return closedItems.map((item) => (
      <div key={ `${item.id}-${auditScan.id}` }>
        <div className={ styles.monthHeader }>
          <span>{ format(new Date(item.year, item.month - 1), 'MMMM yyyy')}</span>
        </div>
        <div className={ styles.statementLinks }>
          <Button
            disabled={ !item.plStatementDownloadUrl }
            variant="link"
            onClick={ () => {
              if (item.plStatementDownloadUrl) {
                window.open(item.plStatementDownloadUrl, '_blank');
              }
            } }
          >
            P&L Statement
            {' '}
            <DownloadIcon fontSize={ 18 } />
          </Button>
          <Button
            disabled={ !item.balanceSheetDownloadUrl }
            variant="link"
            onClick={ () => {
              if (item.balanceSheetDownloadUrl) {
                window.open(item.balanceSheetDownloadUrl, '_blank');
              }
            } }
          >
            Balance Sheet
            {' '}
            <DownloadIcon fontSize={ 18 } />
          </Button>
        </div>
      </div>
    ));
  }, [auditScan]);

  return (
    <div key={ `audit-scan-${auditScan.id}` } className={ styles.monthclosedItems }>
      {auditScan.note && (
        <>
          <div className={ styles.closedBy }>
            <div>
              <UserAvatar
                avatar={ auditScan.closedBy?.avatarUrl ? {
                  avatarUrl: auditScan.closedBy?.avatarUrl,
                } : undefined }
                size={ 28 }
              />
            </div>
            <div>
              <strong>{auditScan.closedByName}</strong>
              {' '}
              marked the books as closed on
              {' '}
              {format(new Date(auditScan.closedAt), 'MMM dd, yyyy')}
              {' '}
              with following notes:
            </div>
          </div>
          <div className={ styles.noteText }>
            {auditScan.note}
          </div>
        </>
      )}
      {renderMonthlyStatements}
    </div>
  );
};

export default BookClosedItem;
