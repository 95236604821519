module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="container">\n  <div class="service-detail-view team-profile-area m-l-auto m-r-auto">\n    <div class="m-b-25">\n      <label class="font-16 font-bold in-black">Profile Info</label>\n      <a class="font-14 font-bold pointer pull-right edit-profile-js ');
    
      __out.push(__sanitize(this.is_external ? '' : 'hidden'));
    
      __out.push('">Edit Profile</a>\n    </div>\n    <div>\n      <div class="row m-b-20">\n        <label class="col-md-4 font-14 in-black">Email</label>\n        <span class="col-md-8 font-14 color-quota-pages">');
    
      __out.push(__sanitize(this.email));
    
      __out.push('</span>\n      </div>\n      <div class="row m-b-20">\n        <label class="col-md-4 font-14 in-black">Phone Number</label>\n        <span class="col-md-8 font-14 color-quota-pages">');
    
      __out.push(__sanitize(this.phone));
    
      __out.push('</span>\n      </div>\n      <div class="row m-b-20">\n        <label class="col-md-4 font-14 in-black">Address</label>\n        <span class="col-md-8 font-14 color-quota-pages"> </span>\n      </div>\n    </div>\n  </div>\n  ');
    
      if (this.isAllowToChangeRole) {
        __out.push('\n    <div class="service-detail-view accounting-firm-area m-l-auto m-r-auto">\n      <div class="m-b-25">\n        <label class="font-16 font-bold in-black">Accounting Firm</label>\n      </div>\n      <div>\n        <div class="row">\n          <label class="col-md-3 font-16 m-t-12">Role</label>\n          <div class="col-md-9 height-100-percent">\n            <div class="permission-dropdown-wrapper">\n              <div class="dropdown-toggle permission-dropdown-control" id="business-role-dropdown-btn" data-toggle="dropdown">\n                <span class="sel-business-role-js">');
        __out.push(__sanitize(this.isBusinessAdmin ? 'Business Admin' : 'Employee'));
        __out.push('</span>\n                <span class="fa fa-caret-down"></span>\n              </div>\n              <ul class="dropdown-menu" aria-labelledby="business-role-dropdown-btn">\n                <li class="business-role-item-js ');
        if (this.isBusinessAdmin) {
          __out.push(__sanitize('selected'));
        }
        __out.push('" data-value="BusinessPartner">Business Admin</li>\n                <li class="business-role-item-js ');
        if (!this.isBusinessAdmin) {
          __out.push(__sanitize('selected'));
        }
        __out.push('" data-value="Employee">Employee</li>\n              </ul>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}