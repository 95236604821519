import { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { getServiceDocumentSplits } from '@src/requests/accounts_payable/service_document_splits';
import { IAccountingClass } from '@src/types/accounting_class';
import { IServiceDocumentSplit } from '@src/types/accounts_payable/service_document_split';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';

interface IServiceSplit {
  receivableBusinessAccountId?: TID;
  receivableBusinessId?: TID;
  receivableBusinessName?: string;
  chartOfAccountId?: TID;
  chartOfAccountName?: string;
}
interface UseSplitCategoryParams {
  rowData: ITransactionServiceDocument;
  businessId: TID;
  setCategorySplits: (splits: ICategorySplit[]) => void;
  setSuccessMsg: (msg: string | undefined) => void;
}

export const useSplitCategory = ({
  rowData,
  businessId,
  setCategorySplits,
  setSuccessMsg,
}: UseSplitCategoryParams) => {
  const [categoryLength, setCategoryLength] = useState<number>(0);
  const createServiceDocumentSplits = useCreateServiceDocumentSplits();
  const { mutate } = createServiceDocumentSplits;

  const processCategories = useCallback(async (categories: string[]): Promise<ICategorySplit[]> => {
    if (categories.length > 1) {
      try {
        const res = await getServiceDocumentSplits({
          documentID: rowData.documentId,
        });

        return res?.serviceDocumentSplits.map((item: IServiceDocumentSplit) => ({
          amount:              item.amount,
          chartOfAccountId:    item.receivableBusinessAccountId || item.chartOfAccountId,
          chartOfAccountName:  item.chartOfAccountName,
          businessId:          item.receivableBusinessId || businessId,
          accountingClassId:   item.accountingClass?.id,
          accountingClassName: item.accountingClass?.name,
          businessName:        item.receivableBusinessName || null,
          memo:                item.memo,
          percentage:          Number(((parseFloat(item.amount || '0') / parseFloat(rowData.amount)) * 100).toFixed(2)),
        })) || [];
      } catch {
        return [];
      }
    }

    const serviceDocumentSplit: IServiceSplit = rowData?.serviceDocumentSplits[0] || {};
    return categories.map(() => ({
      amount:             rowData.amount,
      chartOfAccountId:   serviceDocumentSplit?.receivableBusinessAccountId || rowData.chartOfAccountId,
      chartOfAccountName: rowData.chartOfAccount?.displayName || serviceDocumentSplit?.chartOfAccountName,
      businessId:         serviceDocumentSplit?.receivableBusinessId || businessId,
      accountingClassId:  typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.id || null : null,
      accountingClassName: typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.name || null : null,
      businessName: serviceDocumentSplit?.receivableBusinessName || null,
      memo:         rowData.description,
      percentage:   100,
    }));
  }, [
    rowData.documentId,
    rowData.amount,
    rowData.chartOfAccountId,
    rowData.chartOfAccount,
    rowData.description,
    rowData.accountingClass,
    businessId,
    rowData.serviceDocumentSplits,
  ]);

  const getCategorySplits = useCallback(async (): Promise<ICategorySplit[]> => {
    if (!rowData.category) {
      return [
        {
          amount:              rowData.amount,
          chartOfAccountId:    rowData.chartOfAccountId,
          chartOfAccountName:  rowData.chartOfAccount?.displayName,
          businessId,
          accountingClassId:   null,
          accountingClassName: null,
          businessName:        null,
          memo:                rowData.description,
          percentage:          100,
        },
      ];
    }

    const categories = rowData.category.split(';');
    setCategoryLength(categories.length);

    return processCategories(categories);
  }, [
    rowData.category,
    rowData.amount,
    rowData.chartOfAccountId,
    rowData.chartOfAccount,
    rowData.description,
    businessId,
    setCategoryLength,
    processCategories,
  ]);

  const handleCategorySplitsChange = (splitChanges: ICategorySplit[] | undefined) => {
    if (splitChanges) {
      mutate(
        {
          documentId:            rowData.documentId,
          serviceDocumentSplits: splitChanges,
        },
        {
          onSuccess: () => {
            setCategoryLength(splitChanges.length);
            setSuccessMsg(
              splitChanges.length > 1
                ? window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SPLIT
                : window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SET,
            );
          },
          onError: (error) => {
            toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
          },
        },
      );
    } else {
      setCategorySplits([]); // When splitChanges is undefined, set it to an empty array.
    }
  };

  return {
    categoryLength,
    getCategorySplits,
    handleCategorySplitsChange,
    processCategories,
  };
};
