import React, { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';

import Dropzone, { FileRejection } from 'react-dropzone';

import { makeUseModal } from '@src/hooks/modal';
import { useUploadDocument } from '@src/hooks/queries/documents';
import { getChatParticipants, IChatParticipant } from '@src/requests/bank_account_reconciliations';
import { IDocument } from '@src/types/documents';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';
import ExportBudgetIcon from '@src/components/utils/icomoon/data_export_budget';

import styles from './styles.module.scss';

const acceptedFileTypes = [
  'application/pdf',
];

interface IUploadDocumentProps {
  isOpen?: boolean;
  onCancel: () => void;
  onDone: () => void;
  document: IDocument;
}

const UploadDocument = ({
  isOpen,
  onCancel,
  onDone,
  document,
}: IUploadDocumentProps) => {
  const [chatMembers, setChatMembers] = useState<IChatParticipant[]>([]);
  const [error, setError] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadDoneCount, setUploadDoneCount] = useState<number>(0);
  const [uploadTotalCount, setUploadTotalCount] = useState<number>(0);

  const uploadDocument = useUploadDocument();
  const business = document.businesses[0];

  const onDropFiles = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setError(undefined);
    if (rejectedFiles.length > 0) {
      setError('Format not supported. Please upload .pdf file only');
      return;
    }

    setIsUploading(true);
    setUploadTotalCount(acceptedFiles.length);

    const promises = acceptedFiles.map((file) => uploadDocument.mutateAsync({
      documentParams: {
        document: {
          originalFileName: file.name,
          fileContentType:  file.type,
          storageSize:      file.size,
          chatMembers:      chatMembers.map((member) => ({ id: member.id })),
          temporary:        true,
        },
        businessId:    business.id,
        inboxDocument: false,
      },
      file,
    }).then((newDocument) => {
      setUploadDoneCount((prev) => prev + 1);
      // @ts-ignore
      window.Docyt.fayeClient.publish(`/chats/${document.chatId}`, {
        document_id:      newDocument.id,
        auth_token:       localStorage.getItem('oauth_access_token'),
        client_timestamp: new Date().getTime(),
        type:             'web',
      });
    }));

    await Promise.all(promises);

    toastr.success('Document added successfully', 'Success');
    setTimeout(() => {
      window.Docyt.vent.trigger('upload:document:done', document);
      onDone();
      setIsUploading(false);
      setUploadDoneCount(0);
      setUploadTotalCount(0);
    }, 2000);
  }, [chatMembers, business.id, onDone, uploadDocument, document.chatId]);

  useEffect(() => {
    if (document) {
      getChatParticipants(document.chatId).then((response) => {
        setChatMembers(response.chatParticipants);
      });
    }
  }, [document]);

  const percent = Math.round(((uploadDoneCount) / uploadTotalCount) * 100);

  return (
    <Modal
      className={ styles['upload-document-modal'] }
      show={ isOpen }
      title="Add Document"
      onCancel={ onCancel }
    >
      <Modal.Body>
        <div className={ styles['upload-document-form'] }>
          <Dropzone
            multiple
            accept={ acceptedFileTypes }
            onDrop={ onDropFiles }
          >
            {({ getRootProps, getInputProps }) => (
              <section className={ styles['drop-zone-section'] } { ...getRootProps() }>
                {isUploading ? (
                  <div className={ styles['upload-progress'] }>
                    <p className={ styles['upload-progress-percent'] }>
                      { percent }
                      {' '}
                      % completed
                    </p>
                    <div className={ styles['upload-progress-bar'] }>
                      <div
                        className={ styles['upload-progress-bar-fill'] }
                        style={ { width: `${percent}%` } }
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <ExportBudgetIcon fontSize={ 56 } />
                    <p className={ styles['drop-zone-text'] }>Drag and drop files here.</p>
                    <p className={ styles['drop-zone-sub-text'] }>OR</p>
                    <Button
                      variant="outline"
                    >
                      Select from your computer
                    </Button>
                    <input { ...getInputProps() } />
                  </>
                )}
              </section>
            )}
          </Dropzone>
          {error && (
            <p className={ styles['error-message'] }>{ error }</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={ onCancel }>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

const MemoisedUploadDocument = React.memo(UploadDocument);
const useUploadDocumentModal = makeUseModal(MemoisedUploadDocument);

export {
  IUploadDocumentProps,
  useUploadDocumentModal,
  UploadDocument as default,
};
