import React, { useEffect, useState } from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import DocumentViewLayout from '@src/components/ui_v2/layout/document_view';

import DocumentDetails from './document_details';
import DocumentFooter from './document_footer';
import DocumentHeader from './document_header';
import DocumentView from './document_view';

interface IDocumentViewPageContentProps {
  document: IDocument;
  serviceDocument: IAccountsPayableServiceDocument;
  onClose?: () => void;
  showNavButtons?: boolean;
  onPrevDocument?: () => void;
  onNextDocument?: () => void;
}

const DocumentViewPageContent = ({
  document,
  serviceDocument,
  onClose,
  showNavButtons,
  onPrevDocument,
  onNextDocument,
}: IDocumentViewPageContentProps) => {
  const [activeDocumentView, setActiveDocumentView] = useState<IDocument>(document);

  useEffect(() => {
    setActiveDocumentView(document);
  }, [document]);

  return (
    <DocumentViewLayout>
      <DocumentViewLayout.Header>
        <DocumentHeader
          document={ document }
          serviceDocument={ serviceDocument }
          onClose={ onClose }
        />
      </DocumentViewLayout.Header>

      <DocumentViewLayout.DocumentView>
        <DocumentView document={ activeDocumentView } />
      </DocumentViewLayout.DocumentView>

      <DocumentViewLayout.Details>
        <DocumentDetails document={ document } />
      </DocumentViewLayout.Details>

      <DocumentViewLayout.Footer>
        <DocumentFooter
          activeDocumentView={ activeDocumentView }
          document={ document }
          showNavButtons={ showNavButtons }
          onDocumentChange={ setActiveDocumentView }
          onNextDocument={ onNextDocument }
          onPrevDocument={ onPrevDocument }
        />
      </DocumentViewLayout.Footer>
    </DocumentViewLayout>
  );
};

export default React.memo(DocumentViewPageContent);
