import React from 'react';

import { useMarkNoStatementAvailable } from '@src/hooks/queries/document_requests';
import { TID } from '@src/types/common';

import Spinner from '@src/components/ui/spinner';

import styles from './document_list.module.scss';

interface NoStatementCheckboxProps {
  documentsLength: number;
  isMailroomRequest: boolean;
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  documentRequestId: TID;
}

const NoStatementCheckbox: React.FC<NoStatementCheckboxProps> = ({
  documentsLength,
  isMailroomRequest,
  noStatementAvailable,
  setNoStatementAvailable,
  documentRequestId,
}) => {
  const markNoStatementAvailable = useMarkNoStatementAvailable();

  const isLoading = markNoStatementAvailable.isLoading;

  const handleNoStatementChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    try {
      await markNoStatementAvailable.mutateAsync({
        documentRequestId,
        noStatementAvailable: isChecked,
      });

      setNoStatementAvailable(isChecked);

      window.Docyt.vent.trigger('document:request:no_statement:updated', {
        requestId:            documentRequestId,
        noStatementAvailable: isChecked,
      });
    } catch {
      setNoStatementAvailable(!isChecked);
    }
  };

  if (isMailroomRequest || documentsLength > 0) {
    return null;
  }

  return (
    <div className={ styles['no-statement-checkbox'] }>
      {isLoading && <Spinner />}
      <label className={ styles['no-statement-checkbox-label'] }>
        <input
          checked={ noStatementAvailable }
          disabled={ isLoading }
          type="checkbox"
          onChange={ handleNoStatementChange }
        />
        <span>No Statement Available</span>
      </label>
    </div>
  );
};

export default NoStatementCheckbox;
