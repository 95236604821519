import { TDate, TID } from '@src/types/common';
import { IIntegration, IExternalSystem, IExternalSystemWithIntegrations } from '@src/types/integrations/integrations';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiDelete, apiPut, apiPost } from '../helpers';

interface IGetIntegrationParams {
  businessId: TID,
  integrationId: TID | null | undefined,
}

interface IDisconnectIntegrationParams {
  integrationId: TID,
}

interface ISetIntegrationPausedParams {
  integrationId: TID,
  isPaused: boolean,
}

interface IExternalSystemConfig {
  location?: number| string | null,
}

interface IUpsertIntegrationResponse {
  id: TID,
  emailAddress?: string,
  externalSystemName: string,
  skipRevenueMapping?: boolean,
}

interface IUpsertIntegrationParams {
  businessId: TID,
  integration: {
    id?: TID,
    externalSystemId: TID,
    startDate?: TDate,
    revenueReportTypeId?: TID,
    location?: string,
    externalSystemConfig: IExternalSystemConfig,
  }
}

interface IGetIntegrationExternalSystemParams {
  businessId: TID;
}

interface IGetIntegrationExternalSystemResponse {
  collection: IExternalSystem[],
}

interface IGetIntegrationExternalSystemConfigParams {
  businessId: TID;
  externalSystemId: TID;
}

interface IGetIntegrationExternalSystemConfigResponse {
    id: number;
    name: string;
    isEnabled: boolean;
    emailAddress: string | null;
    externalSystemId: number;
    isAuthorized: boolean;
    externalSystemName: string;
    integrationType: string;
    externalSystemConfig: {
        [key: string]: string | number | null
    };
}

interface IGetIntegrationExternalSystemByIdParams {
  id: TID;
  businessId: TID,
}

interface IGetIntegrationExternalSystemByIdResponse {
  collection: IExternalSystem,
}

interface IGetExternalSystemWithIntegrationsParams {
  businessId: TID;
}

interface IGetExternalSystemWithIntegrationsResponse {
  collection: IExternalSystemWithIntegrations[],
}

const getIntegration = (params: IGetIntegrationParams): Promise<IIntegration> => {
  return apiGet(`/integrations/api/v1/integrations/${params.integrationId}`, underscoreKeys(params))
    .then((data) => camelizeKeys(data.integration) as IIntegration);
};

const disconnectIntegration = (params: IDisconnectIntegrationParams): Promise<void> => {
  return apiDelete(
    `/integrations/api/v1/integrations/${params.integrationId}`,
  );
};

const setIntegrationPaused = (params: ISetIntegrationPausedParams): Promise<void> => {
  return apiPut(
    `/integrations/api/v1/integrations/${params.integrationId}/set_is_paused`,
    underscoreKeys(params),
  );
};

const UpsertIntegration = (
  params: IUpsertIntegrationParams,
): Promise<IUpsertIntegrationResponse> => {
  return apiPost(
    '/integrations/api/v1/integrations/upsert',
    underscoreKeys(params),
  ).then((data) => <any>camelizeKeys(data));
};

const getIntegrationExternalSystem = (
  params: IGetIntegrationExternalSystemParams,
): Promise<IGetIntegrationExternalSystemResponse> => {
  const searchParams = new URLSearchParams({
    business_id: params.businessId.toString(),
  });
  return apiGet(`/integrations/api/v1/external_systems?${searchParams.toString()}`)
    .then((data) => {
      const cdata = <any>camelizeKeys(data.external_systems);
      return {
        collection: cdata as IExternalSystem[],
      };
    });
};

const getIntegrationExternalSystemConfig = (
  params: IGetIntegrationExternalSystemConfigParams,
): Promise<IGetIntegrationExternalSystemConfigResponse> => {
  return apiGet(
    '/integrations/api/v1/integrations/configured',
    underscoreKeys(params),
  ).then((data) => <any>camelizeKeys(data.integration));
};

const getIntegrationExternalSystemById = (
  params: IGetIntegrationExternalSystemByIdParams,
): Promise<IGetIntegrationExternalSystemByIdResponse> => {
  return apiGet(`/integrations/api/v1/external_systems/${params.id}`, underscoreKeys(params))
    .then((data) => {
      const cdata = <any>camelizeKeys(data.external_system);
      return {
        collection: cdata as IExternalSystem,
      };
    });
};

const getExternalSystemsWithIntegrations = (
  params: IGetExternalSystemWithIntegrationsParams,
): Promise<IGetExternalSystemWithIntegrationsResponse> => {
  const searchParams = new URLSearchParams({
    business_id: params.businessId.toString(),
  });
  return apiGet(`/integrations/api/v1/external_systems/external_system_integrations?${searchParams.toString()}`)
    .then((data) => {
      const cdata = <any>camelizeKeys(data.external_systems);
      return {
        collection: cdata as IExternalSystemWithIntegrations[],
      };
    });
};

export {
  IGetIntegrationParams,
  getIntegration,
  disconnectIntegration,
  IDisconnectIntegrationParams,
  setIntegrationPaused,
  ISetIntegrationPausedParams,
  UpsertIntegration,
  IUpsertIntegrationParams,
  IUpsertIntegrationResponse,
  IGetIntegrationExternalSystemParams,
  getIntegrationExternalSystem,
  IGetIntegrationExternalSystemResponse,
  IGetIntegrationExternalSystemConfigParams,
  getIntegrationExternalSystemConfig,
  IGetIntegrationExternalSystemConfigResponse,
  IGetIntegrationExternalSystemByIdParams,
  getIntegrationExternalSystemById,
  IGetIntegrationExternalSystemByIdResponse,
  IGetExternalSystemWithIntegrationsParams,
  getExternalSystemsWithIntegrations,
  IGetExternalSystemWithIntegrationsResponse,
};
