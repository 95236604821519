import { useMemo } from 'react';

import { Section } from '@src/constants/sections';
import { useReconciliationCenterExceptionMessages } from '@src/hooks/queries/reconciliation_centers';
import { useSorting } from '@src/hooks/url_params';
import { IGetReconciliationCenterExceptionMessagesResponse } from '@src/requests/reconciliation_centers';
import { TID, TSection } from '@src/types/common';
import {
  IReconciliationCenterExceptionMessages,
  TReconciliationCenterExceptionMessagesSortColumn,
} from '@src/types/reconciliation_centers';
import { ISorting, ISortingParams } from '@src/types/sorting';

interface IReconciliationCenterExceptionMessagesCollection {
  records: IReconciliationCenterExceptionMessages[];
  section: TSection;
  sorting: ISorting<TReconciliationCenterExceptionMessagesSortColumn>;
}

interface IReconciliationCenterExceptionMessagesCollectionParams {
  businessId: TID;
}

const defaultSorting: ISortingParams<TReconciliationCenterExceptionMessagesSortColumn> = {
  orderColumn: 'name',
  orderDirection: 'asc',
};

const useReconciliationCenterExceptionMessagesCollection = ({
  businessId,
}: IReconciliationCenterExceptionMessagesCollectionParams): IReconciliationCenterExceptionMessagesCollection => {
  const section = useMemo(
    () => ({
      businessId,
      section: Section.ReconciliationCenter,
    }),
    [businessId]
  );

  const sorting = useSorting<TReconciliationCenterExceptionMessagesSortColumn>({
    defaultSorting,
  });

  const exceptionMessagesQuery = useReconciliationCenterExceptionMessages();
  const messages = useMemo(() => exceptionMessagesQuery.data || [], [exceptionMessagesQuery.data]);
  
  const records: IReconciliationCenterExceptionMessages[] = useMemo(() => {
    let exceptionMessages: string[] = [];
    const filterMessages = messages.filter(
      (f: IGetReconciliationCenterExceptionMessagesResponse) => f.notificationType === 16
    );

    if (filterMessages && filterMessages.length !== 0) {
      exceptionMessages = filterMessages[0].message.split('Details: ')[1].split('; ');
    }

    return exceptionMessages.map((v: string, key: number) => {
      const val = v.split(' has been skipped due to ');

      return {
        id: key + 1,
        name: val[0].replace('COA', '').replace(/'/gi, '').trim(),
        message: val[1].replace(' error', '').replace(/'/gi, '').trim(),
      }
    });
  }, [messages]);

  return {
    records,
    section,
    sorting,
  };
};

export {
  useReconciliationCenterExceptionMessagesCollection,
  IReconciliationCenterExceptionMessagesCollection
}
