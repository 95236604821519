import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAdjustmentEntry } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import Table from '@src/components/reconciliation_center/journal_entries/header/table';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import styles from '@src/components/reconciliation_center/journal_entry/styles.module.scss';

interface DuplicateFormProps {
  duplicateFrom: number;
  close: () => void;
}

const extractRows = (adjustmentEntry: IAdjustmentEntry) => {
  const lines = adjustmentEntry.lines || [];
  return lines.map((line) => {
    return {
      accountObj:      line.chartOfAccount,
      entityObj:       line.entityObj ?? {},
      account:         line.chartOfAccount?.id,
      type:            line.entityType === 'QuickbooksCustomer' ? 'QuickbooksCustomer' : 'Vendor',
      name:            line.entityType === 'QuickbooksCustomer' ? line.entityId : line.entityVendorId,
      department:      line.accountingClass?.id,
      description:     line.memo,
      hasAccountValue: true,
    };
  });
};

const DuplicateForm: FC<DuplicateFormProps> = ({ duplicateFrom, close }) => {
  const business = useBusinessContext();
  const { data, isFetching } = useGetAdjustmentEntry(duplicateFrom, business.id);
  const adjustmentEntry = data?.adjustmentEntry as IAdjustmentEntry;

  if (isFetching) {
    return (
      <div className={ styles['loading-container'] }>
        <SpinnerIcon spin fontSize={ 30 } />
      </div>
    );
  }

  const rows = extractRows(adjustmentEntry);
  const additionalParams = { duplicateFrom: adjustmentEntry };

  return (
    <Table
      additionalParams={ additionalParams }
      close={ close }
      date={ new Date() }
      defaultRows={ rows }
      memo={ adjustmentEntry.memo }
    />
  );
};

export default DuplicateForm;
