import React, { useEffect, useMemo, useState } from 'react';

import { flatten } from 'lodash';

import { useGetBalanceSheetInformations } from '@src/hooks/queries/balance_sheet_informations';
import { useSorting, useURLParams } from '@src/hooks/url_params';
import { IGetBalanceSheetInformationsResponse } from '@src/requests/balance_sheet_informations';
import { IBalanceSheetAccounts } from '@src/types/balance_sheet_information';
import { TStatementSortColumn } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { ISortingParams } from '@src/types/sorting';
import { formatApiYear } from '@src/utils/date_helpers';

import { IStatementViewFilter } from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/filter/types';
import List from '@src/components/reconciliation_center/month_end_documents/balance_sheet_statements/list';

import { useBalanceSheetStatementUploadModal } from './balance_sheet_statement_modal/upload_modal/upload_modal';

interface IStatementProps {
  businessId: TID,
}

const DefaultSorting: ISortingParams<TStatementSortColumn> = {
  orderColumn:    'account_name',
  orderDirection: 'asc',
};

const BalanceSheetInformation = ({
  businessId,
}: IStatementProps): JSX.Element => {
  const [isShowAllAccounts, setIsShowAllAccounts] = useState<boolean>(false);
  const [isHandleCheckbox, setIsHandleCheckbox] = useState<boolean>(false);

  const { filter } = useURLParams<
    IStatementViewFilter,
    TStatementSortColumn
  >({
    businessId,
    section:        window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_TYPE,
    defaultSorting: DefaultSorting,
    filterInitData: { year: formatApiYear(new Date()) },
  });
  const sorting = useSorting<TStatementSortColumn>({ defaultSorting: DefaultSorting });

  const months = useMemo(() => {
    const filteredYear = filter.data?.year;
    if (!filteredYear) return 12;

    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    if (String(currentYear) !== filteredYear) return 12;

    return currentMonth;
  }, [filter.data?.year]);

  const query = useGetBalanceSheetInformations({
    businessId,
    months,
    monthEndDocument: true,
    showAllAccounts:  isShowAllAccounts,
    filter:           filter.data,
    ...sorting.data,
  });

  const statements = useMemo(() => {
    const statementsPages = query.data?.pages || [];

    const allItems = flatten(
      statementsPages.map(
        (p: IGetBalanceSheetInformationsResponse) => p.collection,
      ),
    );

    const groupedByType = allItems.reduce(
      (
        acc: { [key: string]: IBalanceSheetAccounts },
        item: IBalanceSheetAccounts,
      ) => {
        if (!acc[item.accTypeName]) {
          acc[item.accTypeName] = { ...item };
        } else {
          acc[item.accTypeName] = {
            ...acc[item.accTypeName],
            accounts: [...acc[item.accTypeName].accounts, ...item.accounts],
          };
        }
        return acc;
      },
      {},
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(groupedByType).map(([accTypeName, item]) => item);
  }, [query.data?.pages]);

  const uploadModal = useBalanceSheetStatementUploadModal();

  useEffect(() => {
    if (Number(query.data?.pages?.[0]?.meta.totalCount) > 0 && !isHandleCheckbox) {
      setIsShowAllAccounts(query.data?.pages?.[0]?.meta.preferredCount === 0);
    }
  }, [query.data, setIsShowAllAccounts, isHandleCheckbox]);

  return (
    <>
      <uploadModal.Component
        { ...uploadModal.props }
        businessId={ businessId }
      />
      <List
        businessId={ businessId }
        filter={ filter }
        isShowAllAccounts={ isShowAllAccounts }
        months={ months }
        query={ query }
        setIsHandleCheckbox={ setIsHandleCheckbox }
        setIsShowAllAccounts={ setIsShowAllAccounts }
        sorting={ sorting }
        statements={ statements }
        onUploadFile={ uploadModal.open }
      />
    </>
  );
};

export default BalanceSheetInformation;
