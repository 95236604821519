import React, { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import {
  useGetAccountsPayableServiceDocument,
} from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetDocument } from '@src/hooks/queries/documents';
import { accountsPayablePath } from '@src/routes';

import AccountsPayableServiceProvider from '@src/components/providers/accounts_payable_service_provider';
import BusinessProvider from '@src/components/providers/business_provider';
import QueryStatus from '@src/components/utils/query_status';

import DocumentViewPageContent from './document_view_page_content';

const DocumentViewPage = () => {
  const { businessId, documentId } = useParams();
  const getDocumentQuery = useGetDocument(Number(documentId));
  const getServiceDocumentQuery = useGetAccountsPayableServiceDocument(
    Number(getDocumentQuery.data?.serviceDocumentId),
    Boolean(getDocumentQuery.data?.serviceDocumentId),
  );

  const navigateToAccountsPayable = useCallback(() => {
    Backbone.history.navigate(accountsPayablePath(Number(businessId)), { trigger: true, replace: true });
  }, [businessId]);

  useEffect(() => {
    // If the documentId is not a numeric value, navigate to the accounts payable page
    if (Number.isNaN(Number(documentId))) {
      navigateToAccountsPayable();
    }
  }, [documentId, navigateToAccountsPayable]);

  useEffect(() => {
    // If the document is not found, navigate to the accounts payable page
    if (!getDocumentQuery.isLoading && !getDocumentQuery.data) {
      navigateToAccountsPayable();
    }
  }, [getDocumentQuery.isLoading, getDocumentQuery.data, navigateToAccountsPayable]);

  useEffect(() => {
    // If the service document is not found, navigate to the accounts payable page
    if (getDocumentQuery.data && !getServiceDocumentQuery.isLoading && !getServiceDocumentQuery.data) {
      navigateToAccountsPayable();
    }
  }, [
    getDocumentQuery.data,
    getServiceDocumentQuery.isLoading,
    getServiceDocumentQuery.data,
    navigateToAccountsPayable,
  ]);

  return (
    <BusinessProvider businessId={ Number(businessId) }>
      <AccountsPayableServiceProvider businessId={ Number(businessId) }>
        <QueryStatus query={ getDocumentQuery } />
        <QueryStatus query={ getServiceDocumentQuery } />
        { getDocumentQuery.data && getServiceDocumentQuery.data && (
          <DocumentViewPageContent
            document={ getDocumentQuery.data }
            serviceDocument={ getServiceDocumentQuery.data }
          />
        )}
      </AccountsPayableServiceProvider>
    </BusinessProvider>
  );
};

export default React.memo(DocumentViewPage);
