import { AxiosError } from 'axios';
import {
  InfiniteData,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { IGetBankFeedTransactionServiceDocumentsResponse } from '@src/requests/bank_feed_transaction_service_documents';
import {
  IGetDocumentRelatedDocumentsParams,
  IGetDocumentRelatedDocumentsResponse,
  getDocumentRelatedDocuments,
  getTransactionServiceDocumentsForDocument,
} from '@src/requests/documents';
import {
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse,
  getTransactionServiceDocuments,
  IDeleteServiceTransactionDocumentParams,
  IMoveToTrashTransactionServiceDocumentParams,
  IAddTransactionServiceDocumentParams,
  IResolveDuplicateParams,
  deleteServiceTransactionDocument,
  moveToTrashTransactionServiceDocument,
  addTransactionServiceDocument,
  resolveDuplicateTransactionServiceDocument,
  IVerifyTransactionServiceDocumentParams,
  verifyTransactionServiceDocument,
  IVerifyTransactionServiceDocumentResponse,
  ISetBankStatementParams,
  setTransactionServiceDocumentBankStatement,
  ISetTransactionServiceDocumentBankStatementResponse,
  IGetTransfersParams,
  getTransfers,
  updateTsdFlaggedState,
  IUpdateTsdFlaggedStateResponse,
  IUpdateTransactionServiceDocumentResponse,
  IUpdateTransactionServiceDocumentParams,
  updateTransactionServiceDocument,
  IGetSimilarTransactionServiceDocumentsParams,
  getSimilarTransactionServiceDocuments,
  IGetSimilarTransactionServiceDocumentsResponse,
  removeNoAutoVerifyRules,
  IRemoveNoAutoVerifyRulesParams,
  IRemoveNoAutoVerifyResponse,
  IUpdateTransactionServiceDocumentDoNotLearnParams,
  updateTransactionServiceDocumentDoNotLearn,
  groupSimilarTsds,
  IGroupSimilarTsdsResponse,
  IGroupSimilarTsdsParams,
  restoreFromTrashTransactionServiceDocument,
  IRestoreFromTrashTransactionServiceDocumentParams,
  updateTransactionServiceDocumentData,
  IUpdateTransactionServiceDocumentDataResponse,
  IUpdateTransactionServiceDocumentDataParams,
} from '@src/requests/transaction_service_documents';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { IBulkMutationParams, makeBulkRequest, IBulkMutationReturn } from './bulk_mutations';
import {
  createUseGetInfiniteCollection,
  updateItemsInInfiniteCollection,
} from './infinite_collection_queries';

const useGetTransactionServiceDocuments = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse
>({
  queryKey: QueryKey.transactionServiceDocumentsQuery,
  request: getTransactionServiceDocuments,
});

const useGetSubTransactionServiceDocuments = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetTransactionServiceDocumentsParams,
  IGetTransactionServiceDocumentsResponse
>({
  queryKey: QueryKey.subTransactionServiceDocumentsQuery,
  request: getTransactionServiceDocuments,
});

const useGetTransfers = createUseGetInfiniteCollection<
  ITransactionServiceDocument,
  IGetTransfersParams,
  IGetTransactionServiceDocumentsResponse
>({
  queryKey: QueryKey.transfersQuery,
  request: getTransfers,
});

const useGetMatchedDocuments = (params: IGetDocumentRelatedDocumentsParams, enabled: boolean) => {
  return useQuery<IGetDocumentRelatedDocumentsResponse, Error>(
    [QueryKey.matchedDocumentsQuery, params],
    () => getDocumentRelatedDocuments(params),
    { enabled }
  );
};

const useGetTransactionServiceDocumentsForDocument = (params: { documentId: TID }, enabled: boolean) => {
  return useQuery<void, Error>(
    [QueryKey.transactionServiceDocumentsForDocumentQuery, params],
    () => getTransactionServiceDocumentsForDocument(params),
    {
      enabled,
    }
  );
};

const useGetPastTransactions = (params: IGetTransactionServiceDocumentsParams) => {
  return useQuery<IGetTransactionServiceDocumentsResponse, Error>(
    [QueryKey.pastTransactionsQuery, params],
    () => getTransactionServiceDocuments(params)
  );
};

const useDestroyTransactionServiceDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IDeleteServiceTransactionDocumentParams>(
    deleteServiceTransactionDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    }
  );
};

const useMoveToTrashTransactionServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IMoveToTrashTransactionServiceDocumentParams>(
    moveToTrashTransactionServiceDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
        queryClient.invalidateQueries(QueryKey.bankFeedInformation);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    }
  );
};

const useRestoreFromTrashTransactionServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error & AxiosError, IRestoreFromTrashTransactionServiceDocumentParams>(
    restoreFromTrashTransactionServiceDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
        queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
        queryClient.invalidateQueries(QueryKey.bankFeedInformation);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      },
    }
  );
};

const useBulkAddTransactionServiceDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn,
    Error,
    IBulkMutationParams<IAddTransactionServiceDocumentParams>
  >(makeBulkRequest(addTransactionServiceDocument), {
    onSettled: () => {
      return queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery).then(() => {
        queryClient.invalidateQueries(QueryKey.bankFeedTransactionServiceDocument);
        window.Docyt.vent.trigger('banking_accounts:information:updated');
      });
    },
  });
};

const useBulkResolveDuplicates = () => {
  const queryClient = useQueryClient();

  return useMutation<IBulkMutationReturn, Error, IBulkMutationParams<IResolveDuplicateParams>>(
    makeBulkRequest(resolveDuplicateTransactionServiceDocument),
    {
      onSettled: () => {
        return queryClient
          .invalidateQueries(QueryKey.subTransactionServiceDocumentsQuery)
          .then(() => window.Docyt.vent.trigger('banking_accounts:information:updated'));
      },
    }
  );
};

const useBulkVerifyTransactionServiceDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn<IVerifyTransactionServiceDocumentResponse>,
    Error,
    IBulkMutationParams<
      IVerifyTransactionServiceDocumentParams,
      IVerifyTransactionServiceDocumentResponse
    >
  >(makeBulkRequest(verifyTransactionServiceDocument), {
    onSettled: () => {
      return queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
    },
  });
};

const useSetTransactionServiceDocumentBankStatement = () => {
  const queryClient = useQueryClient();

  return useMutation<
    ISetTransactionServiceDocumentBankStatementResponse,
    Error,
    ISetBankStatementParams
  >(setTransactionServiceDocumentBankStatement, {
    onSuccess: (response, params) => {
      queryClient.invalidateQueries(QueryKey.bankFeedInformation);
      queryClient.setQueriesData(
        QueryKey.bankFeedTransactionServiceDocument,
        (
          data?: InfiniteData<IGetBankFeedTransactionServiceDocumentsResponse>
        ): InfiniteData<IGetBankFeedTransactionServiceDocumentsResponse> => {
          return updateItemsInInfiniteCollection<
            ITransactionServiceDocument,
            IGetBankFeedTransactionServiceDocumentsResponse
          >(data, [params.id], {
            amount: response.bankFeedTransactionServiceDocument.amount,
            bankStatement: response.bankFeedTransactionServiceDocument.bankStatement,
          });
        }
      );
    },
  });
};

const useUpdateTsdFlaggedState = () => {
  const queryClient = useQueryClient();
  return useMutation<IUpdateTsdFlaggedStateResponse, Error, ITransactionServiceDocument>(
    updateTsdFlaggedState,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
      },
    }
  );
};

const useBulkUpdateTransactionServiceDocuments = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBulkMutationReturn<IUpdateTransactionServiceDocumentResponse>,
    Error,
    IBulkMutationParams<
      IUpdateTransactionServiceDocumentParams,
      IUpdateTransactionServiceDocumentResponse
    >
  >(makeBulkRequest(updateTransactionServiceDocument), {
    onSettled: () => {
      return queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
    },
  });
};

const useUpdateTransactionServiceDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IUpdateTransactionServiceDocumentResponse,
    Error,
    IUpdateTransactionServiceDocumentParams
  >(updateTransactionServiceDocument, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
    },
  });
};

const useUpdateTransactionServiceDocumentData = () => {
  return useMutation<
    IUpdateTransactionServiceDocumentDataResponse,
    Error,
    IUpdateTransactionServiceDocumentDataParams
  >(updateTransactionServiceDocumentData);
};

const useUpdateTransactionServiceDocumentDoNotLearn = () => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line max-len
  return useMutation<
    IUpdateTransactionServiceDocumentResponse,
    Error,
    IUpdateTransactionServiceDocumentDoNotLearnParams
  >(
    updateTransactionServiceDocumentDoNotLearn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
      },
    },
  );
};

const useGetSimilarTransactionServiceDocuments = (
  params: IGetSimilarTransactionServiceDocumentsParams,
  options?: UseQueryOptions<IGetSimilarTransactionServiceDocumentsResponse, Error>
) => {
  return useQuery<IGetSimilarTransactionServiceDocumentsResponse, Error>(
    [QueryKey.similarTransactionServiceDocumentsQuery, params],
    () => getSimilarTransactionServiceDocuments(params),
    options
  );
};

const useRemoveNoAutoVerifyRules = (
  options?: UseMutationOptions<IRemoveNoAutoVerifyResponse, Error, IRemoveNoAutoVerifyRulesParams>
) => {
  return useMutation<IRemoveNoAutoVerifyResponse, Error, IRemoveNoAutoVerifyRulesParams>(
    removeNoAutoVerifyRules,
    options
  );
};

const useGroupSimilarTsds = () => {
  return useMutation<IGroupSimilarTsdsResponse, Error, IGroupSimilarTsdsParams>(groupSimilarTsds);
};

export {
  useGetPastTransactions,
  useGetTransactionServiceDocuments,
  useGetSubTransactionServiceDocuments,
  useDestroyTransactionServiceDocument,
  useMoveToTrashTransactionServiceDocument,
  useRestoreFromTrashTransactionServiceDocument,
  useBulkAddTransactionServiceDocument,
  useBulkResolveDuplicates,
  useBulkVerifyTransactionServiceDocuments,
  useSetTransactionServiceDocumentBankStatement,
  useGetTransfers,
  useUpdateTsdFlaggedState,
  useUpdateTransactionServiceDocument,
  useBulkUpdateTransactionServiceDocuments,
  useGetSimilarTransactionServiceDocuments,
  useRemoveNoAutoVerifyRules,
  useUpdateTransactionServiceDocumentDoNotLearn,
  useGroupSimilarTsds,
  useGetMatchedDocuments,
  useUpdateTransactionServiceDocumentData,
  useGetTransactionServiceDocumentsForDocument,
};
