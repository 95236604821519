import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IGlobalVendorWithBusinessInfo } from '@src/types/business_vendors';

import Table from '@src/components/ui/table';
import { ClaimedIcon } from '@src/components/utils/icomoon';
import useVendorProfileNavigation from '@src/components/vendors/hooks/vendor_profile_navigation';

import DocytNetworkActions from './docyt_network_actions';

interface IDocytNetworkItemProps {
  vendor: IGlobalVendorWithBusinessInfo,
}

const DocytNetworkItem = ({
  vendor,
}: IDocytNetworkItemProps): JSX.Element => {
  const business = useBusinessContext();
  const { vendorProfileUrl } = useVendorProfileNavigation(business.id, vendor.id);

  const handleDocumentClick = useCallback(() => {
    Backbone.history.navigate(
      vendorProfileUrl,
      { trigger: true },
    );
  }, [vendorProfileUrl]);

  return (
    <Table.Row>
      <Table.SelectItemCell
        inputProps={ { disabled: vendor.addedToBusiness } }
        item={ vendor }
      />
      <Table.Cell
        pointer
        className="flex-wrapper"
      >
        <img
          alt="Vendor avatar"
          className="small-avatar"
          src={ vendor.imageUrl }
          onClick={ handleDocumentClick }
        />
        <p
          className="font-16 inline-block m-l-15 m-t-10 vertical-align-top"
          onClick={ handleDocumentClick }
        >
          { vendor.name }
        </p>
        { vendor.isClaimed && <ClaimedIcon ml={ 6 } /> }
      </Table.Cell>
      <Table.Cell
        pointer
        onClick={ handleDocumentClick }
      >
        <p className="font-16 inline-block m-l-5 m-t-10 vertical-align-top">
          { vendor.docytNetworkId }
        </p>
      </Table.Cell>
      <Table.Cell
        pointer
        onClick={ handleDocumentClick }
      >
        {
          (vendor.achStatus === 'activated') && (<span className="invoice-status-panel font-bold approval"> ACH Active </span>)
        }
      </Table.Cell>
      <Table.Cell>
        <DocytNetworkActions vendor={ vendor } />
      </Table.Cell>
    </Table.Row>
  );
};

export default DocytNetworkItem;
