import React, { memo, useEffect, useState } from 'react';

import { isSetupStatusComplete } from '../../helper/functional';
import { IProgressBarItem, TSetupStatus } from '../../types';

import styles from './style.module.scss';

interface IProgressBarProps {
  segments: IProgressBarItem[];
  isCurrency?: boolean;
  setupStatus?: TSetupStatus
}
const LineGraph = ({
  segments,
  isCurrency = false,
  setupStatus,
}: IProgressBarProps) => {
  const [total, setTotal] = useState(0);
  useEffect(() => {
    const totalValue = segments.reduce(
      (sum: number, item: IProgressBarItem) => sum + (item.value ?? 0),
      0,
    );
    setTotal(totalValue);
  }, [segments]);
  return (
    <div className={ styles['line-graph'] }>
      {isSetupStatusComplete(setupStatus)
        ? 'NA'
        : segments.map((item: IProgressBarItem, index: number) => {
          if (!item.color) {
            return '';
          }

          let valueDisplay: string;
          if (item.value === undefined) {
            valueDisplay = '-';
          } else if (isCurrency) {
            valueDisplay = `$${item.value.toString()}`;
          } else {
            valueDisplay = item.value.toString();
          }

          const separator = index > 0 ? ' , ' : '';
          return `${separator}${valueDisplay}`;
        })}
      <div className={ styles['custom-progress-container'] }>
        {segments.map((segment: IProgressBarItem) => {
          const percentage = ((segment?.value ?? 0) / total) * 100;
          return (
            <div
              key={ segment.color }
              className={ `${styles['custom-progress-bar']}` }
              style={ {
                width:           `${isSetupStatusComplete(setupStatus) ? 0 : percentage}%`,
                backgroundColor: segment.color ?? 'gray',
              } }
            />
          );
        })}
      </div>
    </div>
  );
};
export default memo(LineGraph);
