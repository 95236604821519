import React, { useCallback } from 'react';

import { useLocalStorage } from '@src/hooks/storage';
import { IBalanceSheetAccounts, IBalanceSheetInformation } from '@src/types/balance_sheet_information';
import { TID, TYear } from '@src/types/common';
import { MONTHS } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';
import { Button } from '@src/components/ui_v2/buttons';

import StatementTableSubItem from './table_sub_item';

import styles from './styles.module.scss';

interface IStatementTableItemProps {
  businessId: TID,
  statement: IBalanceSheetAccounts,
  months: number,
  year?: TYear,
}

const StatementTableItem = ({
  businessId,
  statement,
  year,
  months,
}: IStatementTableItemProps) => {
  const breakup = useLocalStorage(`${statement.accTypeName}_breakup_toggle`, false);
  const breakupValue = breakup.storedValue;

  const generateTableCells = useCallback(() => {
    const cells = [];

    for (let i = 0; i < months; i += 1) {
      const monthName = MONTHS[i];
      cells.push(<Table.Cell key={ monthName }>&nbsp;</Table.Cell>);
    }

    return cells;
  }, [months]);

  const handleBreakup = useCallback(() => {
    breakup.setValue(!breakupValue);
  }, [breakup, breakupValue]);

  const renderSubRows = useCallback(() => {
    if (breakupValue) return null;

    return (
      <>
        <div />
        { statement.accounts.map((account: IBalanceSheetInformation) => (
          <StatementTableSubItem
            key={ account.id }
            account={ account }
            businessId={ businessId }
            months={ months }
            year={ year }
          />
        )) }
      </>
    );
  }, [breakupValue, statement.accounts, businessId, months, year]);

  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Button
            className={ breakupValue ? styles['row-arrow-right'] : styles['row-arrow-down'] }
            variant="link"
            onClick={ handleBreakup }
          >
            <p className="m-b-0 m-l-27">{ statement.accTypeName }</p>
          </Button>
        </Table.Cell>
        { generateTableCells() }
      </Table.Row>

      { renderSubRows() }
    </>
  );
};

export default React.memo(StatementTableItem);
