import React, { useState, useCallback, useEffect } from 'react';

import { GroupBase, SingleValue } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { searchBusinessVendors } from '@src/requests/business_vendors';
import { TID } from '@src/types/common';

import { VendorAvatar } from '@src/components/ui/avatars';
import {
  AsyncPaginateIconSelectInput,
  IIconSelectInputProps,
  TIconOption,
} from '@src/components/ui_v2/inputs';

interface IVendorInputProps extends Omit<IIconSelectInputProps, 'value' | 'onChange'> {
  businessId: TID;
  value?: TID | null,
  onChange?: (value?: TID | null) => void,
}

const PER_PAGE = 50;

const VendorInput: React.FC<IVendorInputProps> = ({ businessId, value, onChange, ...props }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<TIconOption | undefined>(undefined);

  const handleChange = useCallback((item: SingleValue<TIconOption>) => {
    setSelectedItem(item || undefined);
    if (onChange) {
      onChange(item?.value ? Number(item.value) : null);
    }
  }, [onChange]);

  const handleSource: LoadOptions<TIconOption, GroupBase<TIconOption>, any> = (query, options, { page }) => {
    setIsLoading(true);
    return searchBusinessVendors({ businessId, search: query, page, perPage: PER_PAGE })
      .then((data) => {
        const hasMore = options.length + data.collection.length < data.meta.totalCount;

        const newOptions = data.collection.map((vendor) => ({
          label:  vendor.name,
          value:  String(vendor.vendorId),
          icon:   <VendorAvatar size="100%" vendor={ vendor } />,
          helper: vendor.individualUserId ? 'Employee' : 'Vendor',
        }));

        setIsLoading(false);

        return {
          hasMore,
          options:    newOptions,
          additional: {
            page: page + 1,
          },
        };
      });
  };

  useEffect(() => {
    if (value) {
      searchBusinessVendors({ businessId, search: '', page: 1, perPage: PER_PAGE })
        .then((data) => {
          const vendor = data.collection.find((v) => String(v.vendorId) === String(value));
          if (vendor) {
            setSelectedItem({
              icon:   <VendorAvatar size="100%" vendor={ vendor } />,
              label:  vendor.name,
              helper: vendor.individualUserId ? 'Employee' : 'Vendor',
              value:  String(vendor.vendorId),
            });
          }
        });
    }
  }, [value, businessId]);

  return (
    <AsyncPaginateIconSelectInput
      defaultOptions
      additional={ {
        page: 1,
      } }
      debounceTimeout={ 300 }
      isLoading={ isLoading }
      loadOptions={ handleSource }
      placeholder={ props.placeholder || 'Select a vendor' }
      value={ selectedItem }
      onChange={ handleChange }
    />
  );
};

export default VendorInput;
