import React from 'react';

import { CloseIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface DocumentUploaderProps {
  children: React.ReactNode;
  onClose: () => void;
  isUploading: boolean;
}

const DocumentUploader = ({ children, onClose, isUploading }: DocumentUploaderProps) => {
  return (
    <div className={ isUploading ? styles['document-uploader-highlight'] : styles['document-uploader'] }>
      {!isUploading && (
        <div
          className={ styles['close-icon-container'] }
          role="button"
          tabIndex={ 0 }
          onClick={ onClose }
        >
          <CloseIcon fontSize={ 30 } />
        </div>
      )}
      {children}
    </div>
  );
};

export default DocumentUploader;
