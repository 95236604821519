import React, { useCallback, useEffect, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useUploadBankStatementAttachment } from '@src/hooks/bank_statements';
import { makeUseModal } from '@src/hooks/modal';
import { useGetBankFeedInformation } from '@src/hooks/queries/bank_feed_transaction_service_documents';
import {
  useGetBankStatementByDocytId,
  useVerifyBankStatement,
} from '@src/hooks/queries/bank_statements';
import { TID } from '@src/types/common';
import { formatDate, MOMENT_SIMPLE_YEAR_DATE_FORMAT } from '@src/utils/date_helpers';

import Modal from '@src/components/ui/modal/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import { HandRight } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';
import QueryStatus from '@src/components/utils/query_status';

import Title from '../details_modal_title';
import { useVerifyBankFeedConfirmModal } from './confirm_modal/confirm_modal';
import Form from './verify_bank_feed_form';

import styles from '../../styles.module.scss';

interface IVerifyBankFeedModalProps {
  businessId: TID,
  isOpen: boolean,
  docytId: string,
  isAdmin?: boolean,
  onDone: () => void;
  onCancel: () => void;
}

const VerifyBankFeedModal = ({
  businessId,
  isOpen,
  docytId,
  isAdmin = true,
  onDone,
  onCancel,
}: IVerifyBankFeedModalProps): JSX.Element => {
  const [canVerify, setCanVerify] = useState<boolean>(false);
  const [unselectedCount, setUnSelectedCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const getBankStatementByDocytIdQuery = useGetBankStatementByDocytId(businessId, docytId, isOpen);
  const bankStatement = getBankStatementByDocytIdQuery.data?.bankStatement;

  const getBankFeedInformationQuery =
    useGetBankFeedInformation(businessId, bankStatement?.id, isOpen);

  const bankFeedInformation = getBankFeedInformationQuery.data;

  const fourDaysBefore = new Date(bankStatement?.lastTransactionDate || '');

  const verify = useVerifyBankStatement();
  const { mutate: verifyBankStatement } = verify;

  const uploadAttachment = useUploadBankStatementAttachment();
  const { mutate: uploadBankStatementFile } = uploadAttachment;
  const queryClient = useQueryClient();

  const handleVerify = useCallback(() => {
    if (bankStatement?.id === undefined) return;

    verifyBankStatement({
      id: bankStatement.id,
    }, {
      onSuccess: onDone,
    });
  }, [bankStatement?.id, onDone, verifyBankStatement]);

  const confirmModal = useVerifyBankFeedConfirmModal({
    onDone: handleVerify,
  });

  const handleSubmit = useCallback(() => {
    if (bankStatement?.id === undefined) return;

    if (!canVerify) {
      setErrorMessage('Cannot verify bank statement. Difference should be equal to 0');
      return;
    }

    if (bankStatement.state === 'verified') {
      setErrorMessage('This statement was already verified.');
      return;
    }

    if (bankStatement.state === 'uploaded') {
      setErrorMessage('Cannot verify statement that was not approved.');
      return;
    }

    if (unselectedCount === 0) {
      handleVerify();
    } else {
      confirmModal.open();
    }
  }, [
    bankStatement?.id,
    bankStatement?.state,
    canVerify,
    confirmModal,
    handleVerify,
    unselectedCount,
  ]);

  const handleChangedBankFeedBalance =
    useCallback((bankFeedBalance: number, count: number) => {
      if (bankStatement === undefined) return;

      setCanVerify(parseFloat(bankStatement.closingBalance) - bankFeedBalance === 0);
      setUnSelectedCount(count);
    }, [bankStatement]);

  useEffect(() => {
    if (isOpen
      && getBankStatementByDocytIdQuery.isFetched && !bankStatement?.reconciliationPaymentAccount) {
      setErrorMessage(window.Docyt.Common.Constants.Messages.BANK_STATEMENT_INFO_MISSING_MSG);
      onCancel();
    }
  }, [
    isOpen,
    bankStatement?.reconciliationPaymentAccount,
    getBankStatementByDocytIdQuery.isFetched,
    onCancel,
  ]);

  const handleFileUpload = useCallback((file: File) => {
    setIsFileUploading(true);

    if (bankStatement?.id && file) {
      uploadBankStatementFile(
        {
          bankStatementId: bankStatement.id,
          businessId,
          file,
        },
        {
          onSuccess: () => {
            setIsFileUploading(false);
            queryClient.invalidateQueries(QueryKey.bankStatement);
            queryClient.invalidateQueries(QueryKey.reviewedBankStatement);
          },
          onError: () => {
            setIsFileUploading(false);
            setErrorMessage('Failed to upload bank statement file');
          },
        },
      );
    }
  }, [bankStatement?.id, businessId, uploadBankStatementFile, queryClient]);

  return (
    <>
      <MutationStatus mutation={ verify } />
      <QueryStatus query={ getBankStatementByDocytIdQuery } />
      <confirmModal.Component
        lastTransactionDate={
          // eslint-disable-next-line max-len
          formatDate(fourDaysBefore, MOMENT_SIMPLE_YEAR_DATE_FORMAT) || bankStatement?.lastBankFeedTransactionDate
        }
        { ...confirmModal.props }
      />
      { errorMessage && <ErrorNotification message={ errorMessage } title="Warning!" onHidden={ () => setErrorMessage(undefined) } /> }
      <Modal.Standard
        showClose
        className={ styles['verify-bank-feed-modal'] }
        footerContent={ (
          <div className={ styles['modal-footer-content'] }>
            <HandRight fontSize={ 16 } mr={ 5 } />
            <span>
              All checked transactions will be kept
              { ' ' }
              and all unchecked transactions will be deleted upon verification
            </span>
          </div>
        ) }
        isProceedDisabled={ !isAdmin }
        proceedTitle="Verify Bank Feed"
        show={ isOpen }
        title={ (
          <Title
            bankStatement={ bankStatement }
            businessId={ businessId }
            enableDeleteAction={ isAdmin }
            fromDetails={ false }
            title={
              bankStatement?.state === window.Docyt.Common.Constants.BANK_STATEMENT_STATES.VERIFIED
                ? 'Reviewed'
                : 'Unreviewed'
            }
            onDeleted={ onCancel }
            onUnapproved={ onDone }
          />
        ) }
        onCancel={ onCancel }
        onProceed={ handleSubmit }
      >
        {() => (
          <Form
            bankFeedInformation={ bankFeedInformation }
            bankStatement={ bankStatement }
            businessId={ businessId }
            handleFileUpload={ handleFileUpload }
            isAdmin={ isAdmin }
            isFileUploading={ isFileUploading }
            onChangeBankFeedTransactions={ handleChangedBankFeedBalance }
          />
        )}
      </Modal.Standard>
    </>
  );
};

const useVerifyBankFeedModal = makeUseModal(VerifyBankFeedModal);

export {
  IVerifyBankFeedModalProps,
  useVerifyBankFeedModal,
  VerifyBankFeedModal as default,
};
