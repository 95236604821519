import React from 'react';

import { useController } from 'react-hook-form';

import { CheckboxInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from './style.module.scss';

const TOOLTIP_OPT = [
  {
    name:    'offset',
    options: {
      offset: [-225, 10],
    },
  },
  {
    name:    'arrow',
    options: {
      padding: 140,
    },
  },
];

interface ICheckboxInputProps {
  description: string;
  label: string;
  name: string;
  control: any;
  disabled?: boolean;
  tooltipText?: string | null
}
const CheckboxWithDescription = ({
  description,
  label,
  name,
  control,
  disabled = false,
  tooltipText,
}: ICheckboxInputProps) => {
  const { field } = useController({ name, control });
  const { onChange, value } = field;
  const handleCheckboxChange = (e: boolean) => {
    onChange(e);
  };
  return (
    <>
      <div>
        <Tooltip.Hover
          content={ tooltipText }
          popoverParams={ {
            showDelay: 0,
            opts:      {
              modifiers: TOOLTIP_OPT,
            },
          } }
        >
          <CheckboxInput
            checked={ value ?? false }
            title={ label }
            { ...field }
            disabled={ disabled }
            onChange={ handleCheckboxChange }
          />
        </Tooltip.Hover>
      </div>
      <div className={ styles.description }>
        <p>{description}</p>
      </div>
    </>
  );
};
export default CheckboxWithDescription;
