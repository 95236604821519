import { useState, useEffect } from 'react';

import { TID } from '@src/types/common';
import { LDFeatureForUserEnabled } from '@src/utils/config';

const useVendorProfileNavigation = (businessId: TID, vendorId: TID) => {
  const [vendorProfileUrl, setVendorProfileUrl] = useState<string>(
    `/businesses/${businessId}/vendor_service/payees/${vendorId}`,
  );

  useEffect(() => {
    const feature = window.Docyt.Common.Constants.NEW_VENDOR_PROFILE_FLAG;
    const userId = window.Docyt.currentAdvisor.id;

    LDFeatureForUserEnabled(feature, userId).then((newVendorProfileEnabled) => {
      const url = newVendorProfileEnabled
        ? `/businesses/${businessId}/vendor_service/payees/${vendorId}/profile`
        : `/businesses/${businessId}/vendor_service/payees/${vendorId}`;
      setVendorProfileUrl(url);
    });
  }, [businessId, vendorId]);

  return { vendorProfileUrl };
};

export default useVendorProfileNavigation;
