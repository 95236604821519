import React, { useCallback } from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import { DOCUMENT_WIDTH_PERCENTAGE_HALF } from '@src/components/ui_v2/layout/document_view/constant';
import { useDocumentViewWidth } from '@src/components/ui_v2/layout/document_view/hooks';

import DocumentField from './document_field';

import styles from './styles.module.scss';

const CategoryField = () => {
  const { setDocumentWidthPercentage } = useDocumentViewWidth();

  const handleClickSelect = useCallback(() => {
    setDocumentWidthPercentage(
      (prev) => (prev > DOCUMENT_WIDTH_PERCENTAGE_HALF ? DOCUMENT_WIDTH_PERCENTAGE_HALF : prev),
    );
  }, [setDocumentWidthPercentage]);

  return (
    <div className={ styles['category-field'] }>
      <DocumentField
        isRequired
        input={ (
          <Button variant="link" onClick={ handleClickSelect }>
            Select
          </Button>
        ) }
        label="Category"
      />
    </div>
  );
};

export default React.memo(CategoryField);
