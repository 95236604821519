module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var bankAccount, i, len, ref;
    
      __out.push('<div class="accounts-payable-settings-detail m-l-auto m-r-auto">\n  <div class="generate-check-container">\n    ');
    
      if (this.is_ready) {
        __out.push('\n    <div class="generate-check-wrapper">\n        <div class="display-flex justify-content-between align-items-center">\n          <div class="font-24 font-semi-bold"><a class="font-16 in-blue-950" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/ach_or_check">Setup Payment Methods<span class="p-l-15 p-r-15">/</span></a>');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
        __out.push('</div>\n          ');
        if (this.ach_document && (this.ach_document.state === 'verified')) {
          __out.push('\n          <a class="font-16 font-semi-bold pointer pull-right reset-check-info">Reset ');
          __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
          __out.push(' Setup</a>\n          ');
        }
        __out.push('\n        </div>\n        <hr/>\n        <!--Business Info-->\n        <div class="m-t-40">\n            <span class="font-20 font-semi-bold">Business Info</span>\n        </div>\n        <div class="m-t-40">\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Legal Entity Name </span>\n                <span class="item-text">');
        __out.push(__sanitize(this.name));
        __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Phone Number </span>\n                <span class="item-text">');
        __out.push(__sanitize(this.phone));
        __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Email </span>\n                <span class="item-text">');
        __out.push(__sanitize(this.email));
        __out.push('</span>\n            </p>\n            <p class="m-b-20 display-flex">\n                <span class="display-flex item-label">Legal Entity Address </span>\n                <span class="display-flex width-60-percent item-text">');
        __out.push(__sanitize([this.address_street, this.address_street_2, this.address_city, this.address_state, this.address_zip].filter(Boolean).join(', ').trim()));
        __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Legal Entity Type </span>\n                <span class="item-text">');
        __out.push(__sanitize(this.entity_type));
        __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Business Type </span>\n                <span class="item-text">');
        __out.push(__sanitize(this.standard_category_name));
        __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">EIN Number </span>\n                <span class="item-text">');
        __out.push(__sanitize(this.ein_number));
        __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Status </span>\n                <span class="in-green-600 font-semi-bold">Activated</span>\n            </p>\n        </div>\n\n        <!--Bank Connection-->\n        ');
        if (this.ach_document && (this.ach_document.state === 'verified')) {
          __out.push('\n          <div class="m-t-50">\n            <span class="font-20 font-semi-bold">Bank Connection</span>\n          </div>\n          <div class="m-t-20">\n            <p class="font-16">Your bank account is connected. This will be used to send payments through ');
          __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
          __out.push('.</p>\n          </div>\n          <div class="m-t-20">\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Bank: </span>\n                <span class="item-text">');
          __out.push(__sanitize(this.ach_document.name));
          __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Account #: </span>\n                <span class="item-text">');
          __out.push(__sanitize('**** **** **** ' + this.ach_document.last4));
          __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Routing #: </span>\n                <span class="item-text">');
          __out.push(__sanitize(this.ach_document.routing_number));
          __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Status: </span>\n                <span class="in-green-600 font-semi-bold">Verified</span>\n            </p>\n            <p class="m-b-90">\n                <span class="item-label display-inline-flex">Connected since: </span>\n                <span class="item-text">');
          __out.push(__sanitize(moment(this.ach_document.created_at).format("MMMM DD, YYYY")));
          __out.push('</span>\n            </p>\n          </div>\n        ');
        } else if (this.ach_document) {
          __out.push('\n          <div class="m-t-50">\n            <span class="font-20 font-semi-bold">Bank Connection</span>\n          </div>\n          <div class="m-t-20">\n            <p class="font-16">Your bank account has been added and is pending verification.</p>\n          </div>\n          <div class="m-t-20">\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Bank: </span>\n                <span class="item-text">');
          __out.push(__sanitize(this.ach_document.name));
          __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Account #: </span>\n                <span class="item-text">');
          __out.push(__sanitize('**** **** **** ' + this.ach_document.last4));
          __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Routing #: </span>\n                <span class="item-text">');
          __out.push(__sanitize(this.ach_document.routing_number));
          __out.push('</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Status: </span>\n                <span class="in-orange-400 font-semi-bold">Unverified</span>\n            </p>\n            <p class="m-b-20">\n                <span class="item-label display-inline-flex">Connected since: </span>\n                <span class="item-text">');
          __out.push(__sanitize(moment(this.ach_document.created_at).format("MMMM DD, YYYY")));
          __out.push('</span>\n            </p>\n          </div>\n          <div class="display-flex-column background-white padding-20 border-gray">\n            <div>\n                We have sent two small fractional dollar transactions to your bank account. It takes about a day for these transactions to appear in your account. Enter the values for these two transactions below.\n            </div>\n            <div class="display-flex m-t-20">\n                <input type="number" class="form-control amount-first text-center" placeholder="0.00">\n                <input type="number" class="form-control amount-second text-center m-l-20" placeholder="0.00">\n            </div>\n            <div class="font-12 display-flex align-items-center m-t-5 in-red-600 warning-wrapper">\n                <span class="icon-warning-triangle"></span>\n                <span class="inline-block nav-item-text">The entered amounts could not be verified. Please make sure you entered the correct amounts.</span>\n            </div>\n            <div class="display-flex align-items-center width-100-percent m-t-20">\n                <button class="btn btn-blue font-semi-bold verify-trans-js m-t-auto"> Verify Transactions </button>\n                <div class="m-l-auto">\n                  <a class="font-bold pointer reset-check-info">Reset ');
          __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
          __out.push(' Setup</a>\n                </div>\n            </div>\n          </div>\n        ');
        } else {
          __out.push('\n          <div class="display-flex align-items-center m-t-50">\n              <span class="font-26 font-bold">Verify Bank Account</span>\n          </div>\n          <div class="border-bottom-gray p-b-20">\n              <p class="font-16">\n                Verify your business bank account. Check and ACH payments will be drawn from this account.\n              </p>\n          </div>\n          <div class="bank-connection-container font-16">\n              <div class="display-flex">\n                <div class="m-t-10 flex-container"> Bank Account </div>\n                <div class="bank-account-picker flex-container m-l-auto">\n                  <div class="permission-dropdown-wrapper">\n                    <div class="dropdown-toggle permission-dropdown-control" id="client-role-dropdown-btn" data-toggle="dropdown">\n                      <span class="bank-account-label-js">Select Bank Account</span>\n                      <span class="fa fa-caret-down"></span>\n                    </div>\n                    <ul class="dropdown-menu" aria-labelledby="client-role-dropdown-btn">\n                      <div class="bank-account-list">\n                      ');
          ref = this.bizBankAccounts;
          for (i = 0, len = ref.length; i < len; i++) {
            bankAccount = ref[i];
            __out.push('\n                      <li class="bank-account-item-js" data-value=');
            __out.push(__sanitize(bankAccount.get('id')));
            __out.push('>\n                        <div class=\'display-flex align-items-center\'>\n                          <span class=\'icon icon-bank-account\'><span class=\'path1\'></span><span class=\'path2\'></span><span class=\'path3\'></span><span class=\'path4\'></span><span class=\'path5\'></span></span>\n                          <span class=\'text m-t-3 m-l-10 bank-name-label-js\'>');
            __out.push(__sanitize(bankAccount.get('display_name')));
            __out.push('</span>\n                        </div>\n                      </li>\n                      ');
          }
          __out.push('\n                      </div>\n                      <li class="add-new-bank-account">\n                        <a href="');
          __out.push(__sanitize(this.bankAccountURL));
          __out.push('">\n                          <div class="in-blue-950" tabindex="-1">\n                            <span class=\'text m-t-3 m-l-10\'>Add New Bank Account</span>\n                          </div>\n                        </a>\n                      </li>\n                    </ul>\n                  </div>\n                  <div class="font-10 m-t-8">\n                    <a class="in-green-700" href="">\n                      <span class="icon-lockedalt"></span>\n                      <span class="inline-block nav-item-text">All connections are encrypted</span>\n                    </a>\n                  </div>\n                </div>\n              </div>\n              <div class="bank-verification-container font-16 font-semi-bold">\n                <div id="bank-verification-info-region"></div>\n                <div class="bank-verification-actions"> \n                  <a class="pointer secure-btn display-flex align-items-center verify-auto-btn">\n                    <span class="icon-bank-circle-o icon-title vertical-align-middle font-35 m-l-auto m-r-10">\n                      <span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span>\n                      </span>\n                    <span class="m-r-auto">Verify instantly</span>\n                  </a>\n                  <a class="pointer verify-manual-btn">\n                    Verify manually\n                  </a>\n                </div>\n              </div>\n              <div class="margin-200 line-height-200">\n              </div>\n          </div>\n        ');
        }
        __out.push('\n    </div>\n    <div class="font-14 generate-sample-check m-b-128">\n      <p>Generate a sample check to ensure the signature and information appears correctly.</p>\n      <p>If there are any issues with the sample checks, select “Reset ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
        __out.push(' Setup” in top right corner of this screen. This will allow to reset the information and signature for your business’s checks.</p>\n      <p>Once all information is correct, share the sample checks with your banking representative.</p>\n      <p>If you have any questions, please contact <a href="mailto:support@docyt.com">support@docyt.com.</a></p>\n      <div class="checkbox checkbox-primary">\n         <input type="checkbox" id="check-agreement-checkbox" class="check-agreement-js pointer" ');
        __out.push(__sanitize(this.is_checked ? 'checked' : 'ddd'));
        __out.push('>\n         <label for="check-agreement-checkbox">I have verified the information on the sample checks looks to be accurate and my bank has verified the check meets their standards.</label>\n      </div>\n      <div class="text-right">\n        <button class="btn btn-info font-semi-bold ladda-button sample-self-print-check-js m-r-10" tabindex="10" data-color="blue">Generate Sample ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_SELF_PRINT_CHECK']));
        __out.push('</button>\n        <button class="btn btn-info font-semi-bold ladda-button sample-docyt-check-js" tabindex="10" data-color="blue">Generate Sample ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
        __out.push('</button>\n        <iframe id="download-iframe" style="display:none;"></iframe>\n      </div>\n    </div>\n    ');
      } else {
        __out.push('\n    <div class="add-authorized-payer text-center">\n        <h2 class="font-24 font-semi-bold text-left m-t-0"><a class="font-16 in-blue-950" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/ach_or_check">Setup Payment Methods<span class="p-l-15 p-r-15">/</span></a>Docyt Check</h2>\n        <hr class="width-100-percent" />\n        <img class="img-checks-wrapper" src="');
        __out.push(__sanitize(configData.generate_check_images.GENERATE_CHECK));
        __out.push('" />\n        <h3 class="font-28 font-semi-bold">Generate physical checks with just a tap!</h3>\n        <p class="font-16 m-t-20 m-b-20">\n            Pay your vendors with just a tap of button. Send physical checks from anywhere in the world.\n        </p>\n        <a class="btn btn-blue width-180px setup-payers-btn btn-activate" href="');
        __out.push(__sanitize(this.homeURL));
        __out.push('/setup_checks">Get Started</a>\n    </div>\n    ');
      }
    
      __out.push('\n  </div>\n</div>\n<div id="bank-right-side-region" class="bank-right-side-region"></div>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}