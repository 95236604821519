import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import {
  IAddNewPaymentProcessorValues,
  selectAddNewPaymentProcessorValidation,
} from './schema';

interface IImportMappingProps {
  formId: string;
  onSubmit: (data: IAddNewPaymentProcessorValues) => void;
  onValidationChange: (isValid: boolean) => void;
}

const ImportMappingForm = ({
  formId,
  onSubmit,
  onValidationChange,
}: IImportMappingProps) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<IAddNewPaymentProcessorValues>({
    mode:          'onChange',
    resolver:      yupResolver(selectAddNewPaymentProcessorValidation),
    defaultValues: {
      namePaymentProcessor: undefined,
    },
  });

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.namePaymentProcessor?.message }
        label="Name"
        maxLength={ 50 }
        { ...register('namePaymentProcessor') }
      />
    </Form>
  );
};

export default React.memo(ImportMappingForm);
