import React from 'react';

import { IDocument } from '@src/types/documents';

import Actions from './actions';
import Documents from './documents';

import styles from '../styles.module.scss';

interface IDocumentFooterProps {
  activeDocumentView: IDocument;
  document: IDocument;
  onDocumentChange: (document: IDocument) => void;
  showNavButtons?: boolean;
  onPrevDocument?: () => void;
  onNextDocument?: () => void;
}

const DocumentFooter = ({
  activeDocumentView,
  document,
  onDocumentChange,
  showNavButtons,
  onPrevDocument,
  onNextDocument,
}: IDocumentFooterProps) => {
  return (
    <div className={ styles['document-footer'] }>
      <Documents
        activeDocumentView={ activeDocumentView }
        document={ document }
        onDocumentChange={ onDocumentChange }
      />
      <Actions
        showNavButtons={ showNavButtons }
        onNextDocument={ onNextDocument }
        onPrevDocument={ onPrevDocument }
      />
    </div>
  );
};

export default React.memo(DocumentFooter);
