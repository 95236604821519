module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<header class=\'header header--in\'>\n  <div class="header-menu header-login height-80px-important" style="margin-top: 0;">\n    <img src="');
    
      __out.push(__sanitize(configData.logoURL));
    
      __out.push('" class="width-50px">\n  </div>\n  <div class=\'header__item-wrapper pull-right fixed-header-menu display-flex\' id=\'user-dropdown\' style="padding-left: 0; gap: 5px;">\n    <div>\n      <button type="button" class=\'settings-js btn bg-white non-outline\'>\n        <icon class="icon icon-settings-cog font-25"></icon>\n      </button>\n    </div>\n    <div>\n      <div class=\'header__userpic\' id=\'user-icon\'>\n        ');
    
      if (this.avatarUrl) {
        __out.push('\n          <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'header-avatar-wrapper\'>\n        ');
      } else {
        __out.push('\n          <span class="icon-default-avatar clients__client-status-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n        ');
      }
    
      __out.push('\n      </div>\n      <div class=\'header__dropdown-menu-wrapper toggle-action-dropdown\'>\n        <ul class=\'header__dropdown-menu m-b-0 p-t-10\'>\n          <span class="in-grey-800 font-14 font-bold p-l-15">Profile</span>\n          <li>\n            <button class="display-table-row">\n              <div class="display-table-cell header-dropdown-user-img-wrapper line-height-0">\n                ');
    
      if (this.avatarUrl) {
        __out.push('\n                  <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'user-avatar-wrapper\'>\n                ');
      } else {
        __out.push('\n                  <span class="icon-default-avatar font-50"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>\n                ');
      }
    
      __out.push('\n              </div>\n              <div class="display-table-cell header__dropdown-workspace p-l-12">\n                <p class="line-height-15 m-b-5 font-14">');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('full_name')));
    
      __out.push('</p>\n                <p class="line-height-20 font-12 in-grey-1100 m-b-0">');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('email')));
    
      __out.push('</p>\n              </div>\n            </button>\n          </li>\n          <li>\n            <button type="button" class=\'sign-out-js\'>\n              <icon class="icon icon-signout"></icon>\n              Sign Out\n            </button>\n          </li>\n        </ul>\n      </div>\n    </div>\n  </div>\n</header>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}