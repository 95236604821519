import React from 'react';

import { IDocument } from '@src/types/documents';

import ChatInput from './chat_input';

import styles from './styles.module.scss';

interface IChatsProps {
  document: IDocument;
}

const Chats: React.FC<IChatsProps> = ({ document }) => {
  return (
    <div className={ styles['chat-container'] }>
      <div className={ styles['chat-messages'] }>
        {/* Chat messages will be rendered here */}
      </div>
      <ChatInput document={ document } />
    </div>
  );
};

export default Chats;
