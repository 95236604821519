import React, { useCallback, useMemo } from 'react';

import { useGetDocumentActivities } from '@src/hooks/queries/joural_entries';
import { IGetDocumentActivitiesResponse } from '@src/requests/joural_entries';
import { TID } from '@src/types/common';
import { formatFullMonthDayYear } from '@src/utils/date_helpers';

import SideView from '@src/components/ui_v2/side_view';
import DefaultAvatarIcon from '@src/components/utils/icomoon/default_avatar';

import styles from './styles.module.scss';

const ActivityHistoryContainer = ({
  isShowAuditHistory,
  setIsShowAuditHistory,
  documentId,
}: {
  isShowAuditHistory: boolean;
  setIsShowAuditHistory: React.Dispatch<React.SetStateAction<boolean>>;
  documentId: TID | null;
}) => {
  const { data } = useGetDocumentActivities(Number(documentId));
  const documentActivities = useMemo(() => data || [], [data]);

  const resultData = useMemo(() => {
    return documentActivities.reduce(
      (acc: { [key: string]: IGetDocumentActivitiesResponse[] }, activity) => {
        const date = formatFullMonthDayYear(activity.performedAt);
        acc[date] = [...(acc[date] || []), activity];
        return acc;
      },
      {},
    );
  }, [documentActivities]);

  const renderContent = useCallback(() => {
    if (Object.entries(resultData).length !== 0) {
      return (
        <div className={ styles['history-content'] }>
          { Object.entries(resultData).map(([date, viewResult]) => (
            <div key={ date }>
              <div className={ styles['section-line'] }>
                <p className={ styles['line-txt'] }>{date}</p>
              </div>
              <div className={ styles['history-item'] }>
                {viewResult.map((activity: IGetDocumentActivitiesResponse) => (
                  <div key={ activity.id } className={ styles.item }>
                    { activity.performedBy.avatar ? (
                      <img alt="Avatar" className={ styles.avatar } src={ activity.performedBy.avatar.avatarUrl } />
                    ) : (
                      <DefaultAvatarIcon fontSize={ 24 } />
                    ) }
                    <p className={ styles['item-txt'] }>{ activity.parsedActivity }</p>
                  </div>
                ))}
              </div>
            </div>
          )) }
        </div>
      );
    }
    return (
      <div className={ styles['history-content'] }>
        <span className="m-l-20 text-danger">No Recent Activity</span>
      </div>
    );
  }, [resultData]);

  return (
    <SideView.Provider>
      <SideView.Standard
        isRoot
        isOpen={ isShowAuditHistory }
        title="Activity"
        onCancel={ () => setIsShowAuditHistory(false) }
      >
        {() => (
          <div className="relative height-100-percent">
            { renderContent() }
          </div>
        )}
      </SideView.Standard>
      <SideView.Render />
    </SideView.Provider>
  );
};

export default React.memo(ActivityHistoryContainer);
