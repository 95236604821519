import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';

import { PushTriggerOption } from '@sendbird/chat';
import { GroupChannel, GroupChannelCreateParams, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { UserMessageCreateParams } from '@sendbird/chat/message';
import { useSendbirdStateContext } from '@sendbird/uikit-react/useSendbirdStateContext';
import classNames from 'classnames';
import { flatten, unionBy } from 'lodash';
import { InfiniteData, useQueryClient } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useScroll } from '@src/hooks/contexts/ai_chatbot_scroll_context';
import { useCreateChatThread, useGetMessageHistory } from '@src/hooks/queries/ai_chat/ai_chatbot';
import { useInfiniteScroll } from '@src/hooks/scroll';
import { camelizeKeys } from '@src/utils/transform_keys';

import {
  isNavBarVisibleAtom,
  activeThreadIdAtom,
  firstMessageAtom,
  disableChatInputAtom,
  channelsAtom,
  activeChannelIdAtom,
  threadsAtom,
  threadLoadingStatesAtom,
  chatMessageLoaderAtom,
  historyItemsAtom,
} from '@src/components/ai_chatbot/atoms';
import ChatInput from '@src/components/ai_chatbot/components/chat_input';
import ChatMessageItem from '@src/components/ai_chatbot/components/chat_message_item';
import StartNewThread from '@src/components/ai_chatbot/components/start_new_thread';
import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/fa_icons';
import { MinimizeIcon, DocytBotAi } from '@src/components/utils/icomoon';

import { RecentChats } from './components/recent_chats';
import { chatChannelCreationMessage, FAQ, welcomeMessage } from './constants';
import { ChatMessageStatus, IGlobalMessageHistoryResponse, IMessageMetaData } from './types';
import { ASK_DOCYT_AI_CHANNEL_HANDLER_KEY, findWithIndex, getDefaultDataProperty } from './utils';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const MainView = () => {
  const queryClient = useQueryClient();
  const { config, stores: { sdkStore: { sdk, initialized: sdkInitialized } } } = useSendbirdStateContext();
  const {
    chatAutoScrollRef,
    chatOuterContainerRef,
    scrollToBottom,
    chatMessagesInfiniteScrollRef,
  } = useScroll();
  const { mutateAsync: createChatThread } = useCreateChatThread();
  const [isNavBarVisible, setIsNavBarVisible] = useAtom(isNavBarVisibleAtom);
  const [firstMessage, setFirstMessage] = useAtom(firstMessageAtom);

  const [channels, setChannels] = useAtom(channelsAtom);
  const activeChannelId = useAtomValue(activeChannelIdAtom);
  const setActiveChannelId = useSetAtom(activeChannelIdAtom);
  const [activeThreadId, setActiveThreadId] = useAtom(activeThreadIdAtom);
  const [threads, setThreads] = useAtom(threadsAtom);
  const [disableChatInput, setDisableChatInput] = useAtom(disableChatInputAtom);
  const [threadLoadingStates, setThreadLoadingStates] = useAtom(threadLoadingStatesAtom);
  const setChatMessageLoader = useSetAtom(chatMessageLoaderAtom);
  const setHistoryItems = useSetAtom(historyItemsAtom);

  const [fetchingChannel, setFetchingChannel] = useState(false);

  const buttonClass = classNames(styles['chat-btn'], 'chat-thread-btn');
  const defaultQuestion = window.localStorage.getItem('default_question') || '';

  const addChannel = useAtomCallback(useCallback((get, set, channel: GroupChannel) => {
    set(channelsAtom, (prev) => ({
      ...prev,
      [channel.url]: channel.serialize() as GroupChannel,
    }));
    set(activeChannelIdAtom, channel.url);
  }, []));

  const onMessageReceived: GroupChannelHandler['onMessageReceived'] =
    useCallback(
      (_channel, message) => {
        const parsedMetaData = message.data
          ? (camelizeKeys(
            JSON.parse(message.data.replace(/'/g, '"')),
          ) as IMessageMetaData)
          : undefined;

        const threadId = parsedMetaData?.chatThreadId as string;

        if (!parsedMetaData?.channelQuestionMessageId) {
          setThreads((prevThreads) => {
            const prevMessages = prevThreads.data[threadId] || [];
            const updatedMessages = [...prevMessages];

            // Update pending message with confirmed message
            const index = updatedMessages.findIndex(
              (item) => item.messageSignature === parsedMetaData?.messageSignature,
            );

            if (index !== -1) {
              if (parsedMetaData?.isError) {
                updatedMessages[index] = {
                  ...updatedMessages[index],
                  answer:            String(message.message),
                  chatMessageStatus: ChatMessageStatus.Error,
                };
              } else {
                updatedMessages[index] = {
                  ...updatedMessages[index],
                  channelQuestionMessageId: message.messageId,
                  question:                 String(message.message),
                  timestamp:                String(message.createdAt),
                  chatMessageStatus:
                    ChatMessageStatus.QuestionRegisteredInSendbird,
                };
              }
            }

            return {
              ...prevThreads,
              data: {
                ...prevThreads.data,
                [threadId]: updatedMessages,
              },
            };
          });
        } else if (parsedMetaData?.channelQuestionMessageId) {
          setThreads((prevThreads) => {
            const prevMessages = prevThreads.data[threadId] || [];
            const updatedMessages = [...prevMessages];

            // Update answer for existing message
            const { element: currentMessage, index } = findWithIndex(
              updatedMessages,
              (item) => item.messageSignature === parsedMetaData.messageSignature
                || item.channelQuestionMessageId === Number(parsedMetaData.channelQuestionMessageId),
            );

            if (index !== -1 && !!currentMessage) {
              updatedMessages[index] = {
                ...currentMessage,
                id:                     String(parsedMetaData?.messageId),
                answer:                 String(message.message),
                channelAnswerMessageId: message.messageId,
                timestamp:              String(message.createdAt),
                chatMessageStatus:      ChatMessageStatus.AnswerReceivedFromSendbird,
              };
            }
            return {
              ...prevThreads,
              data: {
                ...prevThreads.data,
                [threadId]: updatedMessages,
              },
            };
          });

          setChatMessageLoader((prev) => prev.filter(
            (signature) => signature !== parsedMetaData.messageSignature,
          ));
        }

        scrollToBottom();
      },
      [
        setThreads,
        setChatMessageLoader,
        scrollToBottom,
      ],
    );

  const sendMessage = useCallback(
    async (
      threadId: string,
      channel: GroupChannel,
      message: string,
      messageSignature: string = crypto.randomUUID(),
    ) => {
      try {
        setChatMessageLoader((prev) => [...prev, messageSignature]);

        setThreads((prevThreads) => {
          const currentData = prevThreads?.data || {};
          const prevMessages = Array.isArray(currentData[threadId])
            ? currentData[threadId]
            : [];

          return {
            ...prevThreads,
            data: {
              ...currentData,
              [threadId]: [
                {
                  id:                       '',
                  channelQuestionMessageId: 0,
                  question:                 message,
                  answer:                   '',
                  channelAnswerMessageId:   0,
                  messageSignature,
                  chatMessageStatus:        ChatMessageStatus.QuestionSentToSendbird,
                },
                ...prevMessages,
              ],
            },
          };
        });

        scrollToBottom();

        const data = getDefaultDataProperty(
          threadId,
          messageSignature,
        );

        const userMessageParams: UserMessageCreateParams = {
          message,
          data,
        };

        channel.sendUserMessage(userMessageParams).onSucceeded((sendableMessage) => {
          onMessageReceived(channel, sendableMessage);
        }).onFailed((_error) => {
          toastr.error(
            chatChannelCreationMessage.errorSendingMessage.message,
            chatChannelCreationMessage.errorSendingMessage.title,
          );
        });
      } catch {
        toastr.error(
          chatChannelCreationMessage.errorSendingMessage.message,
          chatChannelCreationMessage.errorSendingMessage.title,
        );
      }
    },
    [
      setThreads,
      setChatMessageLoader,
      scrollToBottom,
      onMessageReceived,
    ],
  );

  const startNewThread = useCallback(
    async (message: string, messageSignature: string = crypto.randomUUID()) => {
      if (!message) {
        return;
      }

      try {
        setChatMessageLoader((prev) => [...prev, messageSignature]);
        setFirstMessage({ message, messageSignature });

        // Create a new channel with user + bot
        const groupChannelParams: GroupChannelCreateParams = {
          invitedUserIds: [config.userId, window.configData.ai_chatbot.sendbird_bot_id],
          isDistinct:     false,
          name:           window.configData.ai_chatbot.sendbird_bot_name,
          coverUrl:       '',
        };
        const channel = await sdk.groupChannel.createChannel(groupChannelParams);

        await channel.setMyPushTriggerOption(PushTriggerOption.OFF);

        /**
         * Even though we try to add the channel to the channels atom
         * it doesn't become available within the sendMessage method.
         * The problem is related to the state update timing and React's state batching.
         * When you call addChannel in startNewThread, the state update for channels
         * hasn't been processed yet when sendMessage is called immediately after.
         *
         * So, although we add it, we instead of accessing the channel through channels
         * object within sendMessage, we just send the newly created channel as parameter.
         */
        addChannel(channel);

        // create chat thread
        const chatThread = await createChatThread({
          userId:     config.userId,
          channelId:  channel.url || '',
          message,
          summary:    message,
          providerId: 'Sendbird',
        });

        await channel.updateMetaData({
          threadId: chatThread.id || '',
        }, true);

        setHistoryItems((prev) => ({
          ...prev,
          data: [chatThread, ...prev.data],
        }));

        const threadId = chatThread.id as string;
        setActiveThreadId(threadId);

        await sendMessage(threadId, channel, message, messageSignature);
      } catch {
        toastr.error(
          chatChannelCreationMessage.errorCreating.message,
          chatChannelCreationMessage.errorCreating.title,
        );
        setChatMessageLoader((prev) => prev.filter((item) => item !== messageSignature));
      }
    },
    [
      setChatMessageLoader,
      setFirstMessage,
      config.userId,
      sdk.groupChannel,
      setHistoryItems,
      createChatThread,
      setActiveThreadId,
      sendMessage,
      addChannel,
    ],
  );

  const handleChatMessagesSuccess = useCallback(
    (data: InfiniteData<IGlobalMessageHistoryResponse>) => {
      // Save current scroll position before updating messages
      const container = chatOuterContainerRef.current;
      const scrollHeightBeforeFetch = container?.scrollHeight;
      const scrollTopBeforeFetch = container?.scrollTop;
      const shouldScrollToBottom = threads.data[activeThreadId]?.length === 0;

      const messageResponse = data?.pages || [];
      const fetchedMessages = flatten(
        messageResponse.map((p) => p.collection),
      ).map((msg) => ({
        ...msg,
        // to account for the case where the question is registered in sendbird
        // but the answer is not yet generated by the AI
        chatMessageStatus: msg.channelQuestionMessageId && !msg.channelAnswerMessageId
          ? ChatMessageStatus.QuestionRegisteredInSendbird
          : ChatMessageStatus.AnswerReceivedFromSendbird,
      }));

      setThreads((prevThreads) => {
        const currentThreadMessages = prevThreads.data[activeThreadId] || [];

        // Find any pending messages (messages with messageSignature)
        const pendingMessages = currentThreadMessages.filter(
          (msg) => !msg.id
              && (msg.chatMessageStatus
                === ChatMessageStatus.QuestionSentToSendbird
                || msg.chatMessageStatus
                  === ChatMessageStatus.QuestionRegisteredInSendbird),
        );

        // Find any existing messages (messages with id)
        const existingMessages = currentThreadMessages.filter(
          (msg) => !!msg.id
              && msg.chatMessageStatus
                === ChatMessageStatus.AnswerReceivedFromSendbird,
        );

        // Combine existing messages with fetched messages and sort by timestamp
        // because this query is called also from useInfiniteScroll hook
        // and so it becomes necessary to combine the messages and sort them
        const combinedMessages = unionBy(
          [...fetchedMessages, ...existingMessages, ...pendingMessages],
          'channelQuestionMessageId',
        ).sort(
          (a, b) => new Date(b.timestamp!).getTime()
              - new Date(a.timestamp!).getTime(),
        );

        return {
          ...prevThreads,
          data: {
            ...prevThreads.data,
            [activeThreadId]: combinedMessages,
          },
        };
      });

      // Restore scroll position after state update
      setTimeout(() => {
        if (container) {
          const scrollTopToSet =
            container.scrollHeight - (scrollHeightBeforeFetch ?? 0) + (scrollTopBeforeFetch ?? 0);
          container.scrollTop = scrollTopToSet;
        }
      }, 0);

      if (shouldScrollToBottom) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }

      setThreadLoadingStates((prev) => ({
        ...prev,
        [activeThreadId]: 'success',
      }));
    },
    [
      chatOuterContainerRef,
      threads.data,
      activeThreadId,
      setThreads,
      setThreadLoadingStates,
      scrollToBottom,
    ],
  );

  const getChatMessagesQuery = useGetMessageHistory(
    { chatThreadId: activeThreadId },
    {
      enabled:   !!activeThreadId,
      onSuccess: handleChatMessagesSuccess,
    },
  );

  const getChannel = useCallback(async (channelId: string) => {
    try {
      setFetchingChannel(true);
      const channel = await sdk.groupChannel.getChannel(channelId);
      setChannels((prev) => ({
        ...prev,
        [channelId]: channel.serialize() as GroupChannel,
      }));
      setFetchingChannel(false);
    } catch {
      setFetchingChannel(false);
      toastr.error(
        'Failed to fetch channel',
        'Error',
      );
    }
  }, [setChannels, sdk.groupChannel]);

  // fetch the channel if it doesn't exist
  useEffect(() => {
    if (activeChannelId && !channels[activeChannelId]) {
      getChannel(activeChannelId);
    }
  }, [channels, activeChannelId, getChannel]);

  useEffect(() => {
    if (sdkInitialized && sdk.groupChannel) {
      const groupChannelHandler = new GroupChannelHandler();
      groupChannelHandler.onMessageReceived = onMessageReceived;
      sdk.groupChannel.addGroupChannelHandler(ASK_DOCYT_AI_CHANNEL_HANDLER_KEY, groupChannelHandler);
    }
    return () => {
      if (sdkInitialized && sdk.groupChannel) {
        sdk.groupChannel.removeGroupChannelHandler(ASK_DOCYT_AI_CHANNEL_HANDLER_KEY);
      }
    };
  }, [sdkInitialized, sdk.groupChannel, onMessageReceived]);

  /**
     * We cancel the query when the activeThreadId changes,
     * this happens when the user switches between threads
     * and the query is being made for the previous thread.
     */
  useEffect(() => {
    return () => {
      if (activeThreadId) {
        queryClient.cancelQueries([
          QueryKey.AIChatThreadMessages,
          activeThreadId,
        ]);
      }
    };
  }, [activeThreadId, queryClient]);

  // scroll to bottom if there are pending messages
  useEffect(() => {
    if (activeThreadId && threads.data[activeThreadId]) {
      const hasPendingMessages = threads.data[activeThreadId].some(
        (msg) => msg.chatMessageStatus === ChatMessageStatus.QuestionSentToSendbird,
      );
      if (hasPendingMessages) {
        scrollToBottom();
      }
    }
  }, [activeThreadId, scrollToBottom, threads.data]);

  // update loading state for the active thread
  useEffect(() => {
    if (activeThreadId) {
      setThreadLoadingStates((prev) => ({
        ...prev,
        [activeThreadId]: getChatMessagesQuery.status,
      }));

      if (getChatMessagesQuery.status === 'success') {
        setTimeout(() => {
          setThreadLoadingStates((prev) => ({
            ...prev,
            [activeThreadId]: 'idle',
          }));
        }, 0);
      }
    }

    return () => {
      if (activeThreadId) {
        setThreadLoadingStates((prev) => ({
          ...prev,
          [activeThreadId]: 'idle',
        }));
      }
    };
  }, [activeThreadId, getChatMessagesQuery.status, setThreadLoadingStates]);

  useInfiniteScroll({
    elementRef:   chatMessagesInfiniteScrollRef,
    query:        getChatMessagesQuery,
    isTopReached: true,
  });

  const hideNavBar = useCallback(() => {
    setIsNavBarVisible(false);
  }, [setIsNavBarVisible]);

  const showNavBar = useCallback(() => {
    setIsNavBarVisible(true);
  }, [setIsNavBarVisible]);

  const handleMessageSend = useCallback(
    async (message: string) => {
      if (activeThreadId) {
        setDisableChatInput(true);
        const channel = sdk.groupChannel.buildGroupChannelFromSerializedData(channels[activeChannelId]);
        await sendMessage(activeThreadId, channel, message);
        setDisableChatInput(false);
      } else {
        await startNewThread(message);
      }
    },
    [
      activeThreadId,
      channels,
      sdk.groupChannel,
      activeChannelId,
      sendMessage,
      setDisableChatInput,
      startNewThread,
    ],
  );

  useEffect(() => {
    if (sdkInitialized && defaultQuestion !== '') {
      handleMessageSend(defaultQuestion);
      window.localStorage.setItem('default_question', '');
    }
  }, [defaultQuestion, sdkInitialized, handleMessageSend]);

  const handleFAQClick = useCallback(
    (message: string) => () => {
      handleMessageSend(message);
    },
    [handleMessageSend],
  );

  const reversedMessages = useMemo(() => {
    if (!threads.data[activeThreadId]) return [];
    const messages = threads.data[activeThreadId].slice().reverse();
    return messages;
  }, [activeThreadId, threads.data]);

  const renderChatLoadingSpinner = useCallback(() => {
    if (getChatMessagesQuery.isLoading) {
      return (
        <div className={ styles['chat-no-message'] }>
          <div className={ styles['no-message-content'] }>
            <div className={ styles.spinner } />
          </div>
        </div>
      );
    }

    return null;
  }, [getChatMessagesQuery.isLoading]);

  const renderFAQButtons = useCallback(
    (welcomeView: boolean = false) => (
      FAQ.map((item) => (
        <Button
          key={ item.summary }
          className={ welcomeView ? buttonClass : `${styles['chat-btn']} ${styles['is-ellipsis']}` }
          variant="link"
          onClick={ handleFAQClick(item.message) }
        >
          {item.summary}
        </Button>
      ))
    ),
    [handleFAQClick, buttonClass],
  );

  const renderWelcomeView = useCallback(() => {
    return !firstMessage.message ? (
      <div className={ styles['chat-no-message'] }>
        <div className={ styles['no-message-content'] }>
          <div className={ styles['text-container'] }>
            <DocytBotAi fontSize={ 25 } />
            <div className={ styles['text-content'] }>
              <p className={ styles['title-text'] }>
                <span className={ styles['title-bold'] }>{welcomeMessage.title}</span>
                <span className={ styles['title-light'] }>{welcomeMessage.second_title}</span>
              </p>
              <p className={ styles['message-text'] }>{welcomeMessage.message}</p>
            </div>
          </div>
        </div>
        <div className={ styles['quick-chat-buttons'] }>
          {renderFAQButtons(true)}
        </div>
      </div>
    ) : (
      <div className={ styles['chat-message-scroll-outer'] }>
        <ChatMessageItem
          key={ firstMessage.messageSignature }
          answer=""
          channelAnswerMessageId={ 0 }
          channelQuestionMessageId={ 0 }
          chatMessageStatus={ ChatMessageStatus.QuestionSentToSendbird }
          id=""
          messageSignature={ firstMessage.messageSignature }
          question={ firstMessage.message }
        />
      </div>
    );
  }, [firstMessage, renderFAQButtons]);

  const renderChatView = useCallback(() => {
    return (
      <div ref={ chatOuterContainerRef } className={ styles['chat-message-scroll-outer'] }>
        {!threads.data[activeThreadId] ? (
          renderChatLoadingSpinner()
        ) : (
          <div
            ref={ chatMessagesInfiniteScrollRef }
            className={ styles['chat-message-scroll-inner'] }
          >
            {getChatMessagesQuery.isFetchingNextPage && (
            <div className={ styles['chat-message-next-page-loader'] }>
              <div className={ styles.spinner } />
            </div>
            )}
            {reversedMessages?.map((item) => (
              <ChatMessageItem
                key={ item.id + item.messageSignature }
                answer={ item.answer }
                channelAnswerMessageId={ item.channelAnswerMessageId }
                channelQuestionMessageId={ item.channelQuestionMessageId }
                id={ item.id }
                messageSignature={ item.messageSignature }
                question={ item.question }
              />
            ))}
          </div>
        )}
        <div ref={ chatAutoScrollRef } />
      </div>
    );
  }, [
    getChatMessagesQuery.isFetchingNextPage,
    reversedMessages,
    renderChatLoadingSpinner,
    chatAutoScrollRef,
    chatMessagesInfiniteScrollRef,
    chatOuterContainerRef,
    activeThreadId,
    threads.data,
  ]);

  return (
    <div className={ styles['chat-container'] }>
      {isNavBarVisible && (
        <div className={ styles['chat-container-header'] }>
          <div className={ styles['chat-list-section'] }>
            <div className={ `${styles['chat-btn-thread-content']}` }>
              <MinimizeIcon
                fontSize={ 24 }
                onClick={ hideNavBar }
              />
              <StartNewThread
                buttonText="New Chat"
                prefixIcon={ <PlusIcon fontSize={ 12 } /> }
              />
            </div>

            <div className={ styles['chat-list-scroll'] }>
              <div className={ styles['chat-btn-section'] }>
                <div className={ styles['chat-list-header'] }>
                  <h4 className={ styles['chat-list-heading'] }>PINNED</h4>
                </div>
                {renderFAQButtons(false)}
              </div>
              <RecentChats />
            </div>
          </div>
        </div>
      )}
      <div className={ styles['chat-content-section'] }>
        <div className={ styles['chat-message-container'] }>
          {!isNavBarVisible && (
            <div className={ styles['chat-message-navbar'] }>
              <MinimizeIcon
                fontSize={ 24 }
                onClick={ showNavBar }
              />
              <StartNewThread
                buttonText="New Chat"
                prefixIcon={ <PlusIcon fontSize={ 12 } /> }
              />
            </div>
          )}
        </div>
        <div className={ styles['view-container'] }>
          <div className={ styles['chat-wrapper'] }>
            <div className={ styles['chat-top-section'] }>
              {activeThreadId ? (
                renderChatView()
              ) : (
                renderWelcomeView()
              )}
            </div>
            <div className={ styles['chat-bottom-section'] }>
              <ChatInput
                disabled={
                  disableChatInput
                    || threadLoadingStates[activeThreadId] === 'loading'
                    || fetchingChannel
                  }
                handleMessageSend={ handleMessageSend }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MainView);
