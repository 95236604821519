import React from 'react';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { Button } from '@src/components/ui_v2/buttons';

import HoverTooltipWrapper from '../hover_tooltip_wrapper';

interface AuditScanButtonProps {
  status?: AuditScanDetails['status'];
  onRunAudit: () => void;
}

const AuditScanButton = ({ status, onRunAudit }: AuditScanButtonProps) => {
  return (
    <HoverTooltipWrapper
      content={ null }
    >
      <Button
        size="compact"
        variant="outline"
        onClick={ onRunAudit }
      >
        { status === 'completed' ? 'Re-run Audit Scan' : 'Run Audit Scan' }
      </Button>
    </HoverTooltipWrapper>
  );
};

export default AuditScanButton;
