import { useMemo } from 'react';

import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';
import { getDisplayName } from '@src/utils/document_helpers';

import { DocumentType } from '@src/components/documents/document_uploader';

export interface IBankStatementWithDocytId extends IBankStatement {
    docyt_id?: string;
  }

export interface IBalanceSheetStatementWithDocytId extends IBalanceSheetStatement {
    docyt_id?: string;
  }

interface UseDocumentTableItemsProps {
  documents: IDocument[];
  bankStatement: IBankStatementWithDocytId | null;
  balanceSheetStatements: IBalanceSheetStatementWithDocytId[];
  isMailroomRequest: boolean;
  isBankStatementRequest: boolean;
  isBalanceSheetRequest: boolean;
  isJustUploaded?: boolean;
  _isReviewed?: boolean;
  noStatementAvailable?: boolean;
}

export interface DocumentTableItem {
  id: TID;
  docytId: string;
  name: string;
  type: DocumentType;
  state: string;
  fileUrl?: string;
}

export const useDocumentTableItems = ({
  documents,
  bankStatement,
  balanceSheetStatements,
  isMailroomRequest,
  isBankStatementRequest,
  isBalanceSheetRequest,
  isJustUploaded = false,
  _isReviewed = false,
  noStatementAvailable = false,
}: UseDocumentTableItemsProps): DocumentTableItem[] => {
  return useMemo(() => {
    if (isBankStatementRequest && bankStatement) {
      if (_isReviewed || isJustUploaded || (bankStatement && bankStatement.state !== 'requested')) {
        if (noStatementAvailable) {
          return [];
        }
        return [{
          id:      bankStatement.id,
          docytId: bankStatement.docyt_id || bankStatement.docytId || '',
          name:    bankStatement.name || 'Bank Statement',
          type:    'bank_statement',
          state:   bankStatement.state,
          fileUrl: bankStatement.statementFileUrl,
        }];
      }
      return [];
    }

    if (isBalanceSheetRequest) {
      if (_isReviewed) {
        return balanceSheetStatements.map((stmt) => ({
          id:      stmt.id,
          docytId: stmt.docyt_id || stmt.docytId || '',
          name:    stmt.name || 'Balance Sheet',
          type:    'balance_sheet',
          state:   stmt.state,
        }));
      }

      const nonRequestedStatements = balanceSheetStatements?.filter(
        (stmt) => stmt.state !== window.Docyt.Common.Constants.BALANCE_SHEET_STATEMENT_STATES.REQUESTED,
      );

      if (nonRequestedStatements?.length > 0) {
        return nonRequestedStatements.map((stmt) => ({
          id:      stmt.id,
          docytId: stmt.docyt_id || stmt.docytId || '',
          name:    stmt.name || 'Balance Sheet',
          type:    'balance_sheet',
          state:   stmt.state,
        }));
      }
      return [];
    }

    if (isMailroomRequest) {
      return documents.map((doc) => ({
        id:      doc.id,
        docytId: doc.docytId || `DOC-${doc.id}`,
        name:    getDisplayName(doc, 'document'),
        type:    'document' as DocumentType,
        state:   doc.state || 'uploaded',
      }));
    }

    return [];
  }, [
    documents,
    bankStatement,
    balanceSheetStatements,
    isMailroomRequest,
    isBankStatementRequest,
    isBalanceSheetRequest,
    isJustUploaded,
    _isReviewed,
    noStatementAvailable,
  ]);
};
