import { TBooksGptOnboardingState, TBusinessIntent, TFirmSuiteOnboardingState } from '@src/types/businesses';
import { IUndepositedRevenueProps } from '@src/types/common_operational_dashboard';
import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { IBusinessOwnerConfigFormType } from '@src/components/operational_dashboard/helper/validation.schema';
import {
  IDashboardData,
  IIconConfig,
  IListItem,
  NumberBoxValue,
  TSetupStatus,
} from '@src/components/operational_dashboard/types';

const hasAnyTrueValue = (obj: any): boolean => {
  if (typeof obj !== 'object' || obj === null) return false;
  return Object.values(obj).some((value) => {
    if (typeof value === 'object') {
      return hasAnyTrueValue(value);
    }
    return value === true;
  });
};

type MappingObject = { [key: string]: string } | IIconConfig[];

const mapObjectKeys = <T extends object>(
  schema: T,
  mapping: MappingObject,
): string[] | IIconConfig[] => {
  if (Array.isArray(mapping)) {
    // console.log('DEBUG CODE::', mapping, Object.keys(schema));
    // Handle IIconConfig[] case
    return Object.keys(schema)
      .filter((key) => {
        const value = schema[key as keyof T];
        if (typeof value === 'object' && value !== null) {
          return hasAnyTrueValue(value);
        }
        return value === true;
      })
      .map((key) => mapping.find((icon) => icon.key === key))
      .filter((icon): icon is IIconConfig => icon !== undefined);
  }

  // Handle object mapping case
  const mapKeys = (obj: any, map: { [key: string]: string }): string[] => {
    return Object.keys(obj)
      .filter((key) => {
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          return hasAnyTrueValue(value);
        }
        return value === true;
      })
      .map((key) => map[key] ?? '');
  };
  return mapKeys(schema, mapping);
};

const filterTooltipData = (data: IListItem[], schema: any) => {
  return (
    data?.filter((item: IListItem) => {
      return schema?.[item?.key as keyof IBusinessOwnerConfigFormType];
    }) ?? []
  );
};

const hasActivePreference = (
  preferenceKey: keyof IBusinessOwnerConfigFormType | string,
  preferences?: IBusinessOwnerConfigFormType,
): boolean => {
  if (preferences) {
    const preference =
      preferences[preferenceKey as keyof IBusinessOwnerConfigFormType];
    return hasAnyTrueValue(preference);
  }
  return false;
};

const isSetupStatusComplete = (setupStatus?: TSetupStatus) => setupStatus === 'COMPLETE_SETUP';
const isInSetupStatus = (setupStatus?: TSetupStatus) => setupStatus === 'IN_SETUP';

const progressUndepositedRevenueData = (
  undepositedRevenue: IUndepositedRevenueProps,
) => {
  return [
    {
      value: undepositedRevenue?.undepositedRevenueAmount?.[0] ?? 0,
      color: '#8a2be2',
    },
    {
      value: undepositedRevenue?.undepositedRevenueAmount?.[1] ?? 0,
      color: '',
    },
  ];
};

const getNumberBoxValue = (val?: number): NumberBoxValue => {
  if (typeof val === 'number') {
    return {
      type:  'number',
      value: val,
    };
  }
  return {
    type: 'empty',
  };
};

/**
 * Checks if the audit scan polling is enabled for the given dashboard data.
 * Polling is enabled if the business ID is present, the firm suite onboarding state is 'import_ledger_completed',
 * and the setup is completed.
 *
 * @param {IDashboardData} columnData - The dashboard data to check.
 * @returns {boolean} - Returns true if polling is enabled, otherwise false.
 */
const isAuditScanPollingEnabled = (columnData: IDashboardData): boolean => {
  if (
    columnData.businessId
    && columnData.firmSuiteOnboardingState === 'import_ledger_completed'
    && columnData.isSetupCompleted
  ) {
    return true;
  }
  return false;
};

type TButtonState = 'activate'
| 'complete_setup'
| 'in_ledger_setup'
| 'ledger_setup_failed'
| 'run_audit_scan'
| 'run_audit_scan_in_progress'
| 'run_audit_scan_pending'
| 'run_audit_scan_failed'
| 'run_audit_scan_broken'
| 'none';

const businessCellActionButton = (states : {
  firmSuiteOnboardingState: TFirmSuiteOnboardingState,
  booksGptOnboardingState: TBooksGptOnboardingState,
  isSetupCompleted: boolean,
  businessIntent: TBusinessIntent,
  auditScan: AuditScanDetails | null | undefined,
  disconnectedGeneralLedger?: boolean;
}): TButtonState => {
  const {
    firmSuiteOnboardingState,
    booksGptOnboardingState,
    isSetupCompleted,
    businessIntent,
    auditScan,
    disconnectedGeneralLedger,
  } = states;

  if (businessIntent === 'FullBookkeeping') {
    if (!isSetupCompleted) {
      return 'complete_setup';
    }
  } else if (businessIntent === 'BooksGPT') {
    // Handle activation pending or disconnected ledger
    if (firmSuiteOnboardingState === 'activation_pending' || booksGptOnboardingState === 'activation_pending') {
      return 'activate';
    }
    if (!isSetupCompleted) {
      return 'complete_setup';
    }

    if (booksGptOnboardingState === 'ledger_setup_failed' || firmSuiteOnboardingState === 'ledger_setup_failed') {
      return 'ledger_setup_failed';
    }
    // Handle ledger setup in progress
    if (
      (
        firmSuiteOnboardingState === 'in_ledger_setup'
        || booksGptOnboardingState === 'in_ledger_setup'
      )
      && isSetupCompleted
    ) {
      return 'in_ledger_setup';
    }
    // Handle setup completion
    const isLedgerCompleted =
      firmSuiteOnboardingState === 'import_ledger_completed'
      || booksGptOnboardingState === 'ledger_setup_completed';

    // Handle audit scan cases
    if (isSetupCompleted === true && isLedgerCompleted === true) {
      // Do not show audit scan button if disconnected general ledger
      if (disconnectedGeneralLedger) {
        return 'none';
      }

      if (auditScan) {
        if (auditScan.status === 'in_progress') {
          return 'run_audit_scan_in_progress';
        }
        if (auditScan.status === 'pending') {
          return 'run_audit_scan_pending';
        }
        if (auditScan.status === 'completed') {
          return 'run_audit_scan';
        }
        if (auditScan.status === 'failed') {
          return 'run_audit_scan_failed';
        }
        if (auditScan.status === 'broken') {
          return 'run_audit_scan_broken';
        }
      }
      // If audit scan is not in progress or pending or undefine from api response, show the run audit scan button
      return 'run_audit_scan';
    }
  }
  return 'none';
};

export {
  TButtonState,
  hasActivePreference,
  progressUndepositedRevenueData,
  isInSetupStatus,
  getNumberBoxValue,
  hasAnyTrueValue,
  mapObjectKeys,
  filterTooltipData,
  isSetupStatusComplete,
  isAuditScanPollingEnabled,
  businessCellActionButton,
};
