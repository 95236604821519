import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { TSection } from '@src/types/common';
import { TItemsSelectorState } from './types';

const itemsSelector = atomFamily((section: TSection) => 
  atom<TItemsSelectorState>({
    all:      [],
    selected: [],
  })
);

export {
  itemsSelector,
};
