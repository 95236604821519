import React from 'react';

import { IDocument } from '@src/types/documents';

import VendorInput from '@src/components/common_v2/inputs/vendor_input';
import DatePickerInput from '@src/components/ui/form/inputs/datepicker_input';
import { TextInput } from '@src/components/ui_v2/inputs';
import AmountInput from '@src/components/ui_v2/inputs/amount_input';

import CategoryField from './category_field';
import DocumentField from './document_field';
import PaymentAddressField from './payment_adress_field';

import styles from './styles.module.scss';

interface IDocumentFieldsProps {
  document: IDocument;
}

const DocumentFields = ({ document }: IDocumentFieldsProps) => {
  return (
    <div className={ styles['document-fields'] }>
      <DocumentField
        isRequired
        input={ (
          <VendorInput
            businessId={ document.businesses[0].id }
            name="vendorId"
          />
        ) }
        label="Vendor"
      />
      <DocumentField
        isRequired
        input={ (
          <AmountInput
            onChange={ () => {} }
          />
        ) }
        label="Amount"
      />
      <DocumentField
        isRequired
        input={ (
          <DatePickerInput
            onChange={ () => {} }
          />
      ) }
        label="Invoice Date"
      />
      <DocumentField
        isRequired
        input={ (
          <DatePickerInput
            onChange={ () => {} }
          />
        ) }
        label="Due Date"
      />
      <DocumentField
        input={ (
          <TextInput
            onChange={ () => {} }
          />
        ) }
        label="Invoice No"
      />
      <DocumentField
        input={ (
          <TextInput
            onChange={ () => {} }
          />
        ) }
        label="Account No"
      />
      <CategoryField />
      <div className={ styles['period-field'] }>
        <DocumentField
          input={ <span className={ styles['document-field-button'] }>Muliple Months</span> }
          label="Period"
        />
      </div>
      <PaymentAddressField />
    </div>
  );
};

export default React.memo(DocumentFields);
