import { useQuery, UseQueryResult } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { BusinessChartOfAccounts, getSystemCategories } from '@src/requests/system_categories';
import { TID } from '@src/types/common';

const useGetSystemCategories = (businessId: TID): { query: UseQueryResult<BusinessChartOfAccounts, Error> } => {
  const query = useQuery<BusinessChartOfAccounts, Error>(
    [QueryKey.systemCategoriesQuery, businessId],
    () => getSystemCategories(businessId),
  );

  return {
    query,
  };
};
export {
  useGetSystemCategories,
};
