import React, { memo } from 'react';

import { useAtomValue } from 'jotai';

import {
  activeThreadIdAtom,
  threadLoadingStatesAtom,
} from '@src/components/ai_chatbot/atoms';
import StartChatThread from '@src/components/ai_chatbot/components/start_chat_thread';
import { IChatThreadSchema } from '@src/components/ai_chatbot/types';

import styles from '@src/components/ai_chatbot/styles.module.scss';

interface IChatListItemProps {
  thread: IChatThreadSchema;
  prefixIcon?: React.ReactElement;
}

export const ChatListItem = memo(
  ({ thread, prefixIcon }: IChatListItemProps) => {
    const activeThreadId = useAtomValue(activeThreadIdAtom);
    const loadingStates = useAtomValue(threadLoadingStatesAtom);

    const isLoading = loadingStates[thread.id as string] === 'loading' || false;

    return (
      <div className={ styles['chat-list-item'] }>
        <div className={ styles['chat-list-item-content'] }>
          <StartChatThread
            buttonText={ thread.summary }
            channelId={ thread?.channelId }
            isActive={ thread.id === activeThreadId && thread.id !== '' }
            prefixIcon={ prefixIcon }
            threadId={ thread.id }
          />
          {isLoading && <div className={ styles.spinner } />}
        </div>
      </div>
    );
  },
);

ChatListItem.displayName = 'ChatListItem';
