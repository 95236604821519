import React, { useCallback, useMemo } from 'react';

import sortedUniq from 'lodash/sortedUniq';

import { rcMonthEndDocmentsOtherDocumentsStatementsPath } from '@src/routes';
import { IBalanceSheetInformation } from '@src/types/balance_sheet_information';
import { IStatementState, TStatementState } from '@src/types/balance_sheet_statements';
import { TID, TYear } from '@src/types/common';
import { formatMonth, formatApiDate, getUTCTimezone } from '@src/utils/date_helpers';

import Table from '@src/components/ui/table';

import StatementStateList from './balance_sheet_statement_state/balance_sheet_statement_state_list';

interface IStatementTableItemProps {
  businessId: TID,
  account: IBalanceSheetInformation,
  months: number,
  year?: TYear,
}

const formatState = (
  states: string[],
): string => {
  let statementState = 'missing';
  if (states.every((state) => state === 'requested')) {
    statementState = 'requested';
  } else if (states.every((state) => state === 'verified')) {
    statementState = 'verified';
  } else if (states.every((state) => ['uploaded', 'verified'].includes(state))) {
    statementState = 'uploaded';
  } else if (states.every((state) => ['uploaded', 'verified', 'requested'].includes(state))) {
    statementState = 'requested';
  } else if (states.some((state) => state === 'not_available')) {
    statementState = 'not_available';
  }
  return statementState;
};

const statementStates = (
  statementData: IBalanceSheetInformation,
  year?: TYear,
  months?: number,
) => {
  let result: Array<IStatementState> = [];
  if (!year || !months) return result;

  const endDate = new Date(parseInt(year, 10), months, 0);
  const nextDate = new Date(parseInt(year, 10), 0, 1);
  const days = [];

  for (let i = nextDate; i < endDate; nextDate.setMonth(nextDate.getMonth() + 1)) {
    days.push(formatApiDate(nextDate));
  }

  days.forEach((d) => {
    const bState = statementData.statements.find(
      (statement) => formatMonth(getUTCTimezone(new Date(statement.date)))
      === formatMonth(getUTCTimezone(new Date(d))),
    );

    const statements = statementData.statements.filter(
      (s) => formatMonth(getUTCTimezone(new Date(s.date)))
        === formatMonth(getUTCTimezone(new Date(d))),
    );

    const itemState = formatState(sortedUniq(statements.map((st) => st.state)));

    if (bState != null) {
      result = [
        ...result,
        {
          month:                     formatMonth(getUTCTimezone(new Date(d))),
          chartOfAccountName:        statementData.name,
          id:                        bState.id,
          state:                     itemState as TStatementState,
          year,
          statementDate:             bState.date,
          verifiedAt:                bState.verifiedAt,
          createdAt:                 bState.createdAt,
          uploadedAt:                bState.uploadedAt,
          requestedAt:               bState.requestedAt,
          docytId:                   bState.docytId,
          startingDate:              bState.startingDate,
          date:                      bState.date,
          hasNextVerifiedStatements: bState.hasNextVerifiedStatements,
        }];
    } else {
      result = [
        ...result,
        {
          month:              formatMonth(getUTCTimezone(new Date(d))),
          state:              'missing',
          year,
          chartOfAccountName: statementData.name,
        },
      ];
    }
  });

  return result;
};

const StatementTableSubItem = ({
  businessId,
  account,
  months,
  year,
}: IStatementTableItemProps) => {
  const states = useMemo(() => {
    return statementStates(account, year, months);
  }, [months, account, year]);

  const handleClickItem = useCallback(() => {
    const redirectPath = rcMonthEndDocmentsOtherDocumentsStatementsPath(
      businessId,
      account.id,
      year,
    );
    Backbone.history.navigate(redirectPath, { trigger: true });
  }, [businessId, account.id, year]);

  return (
    <Table.Row>
      <Table.Cell
        pointer
        style={ { color: '#4D4EFF' } }
        widthPercent={ 20 }
        onClick={ handleClickItem }
      >
        <p className="m-b-0 m-l-27">{ account.name }</p>
      </Table.Cell>
      <StatementStateList
        businessId={ businessId }
        chartOfAccountId={ account.id }
        states={ states }
      />
    </Table.Row>
  );
};

export default React.memo(StatementTableSubItem);
