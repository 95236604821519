import React, { useMemo } from 'react';

import { useGetActivities } from '@src/hooks/queries/documents';
import { IActivity } from '@src/types/activity';
import { IDocument } from '@src/types/documents';
import { formatFullMonthDayYear } from '@src/utils/date_helpers';

import { DefaultAvatarIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IActivitiesProps {
  document: IDocument;
}

const Activities = ({ document }: IActivitiesProps) => {
  const { data: activities } = useGetActivities(document.id);

  const dateWiseActivities = useMemo(() => {
    return activities?.reduce(
      (acc: { [key: string]: IActivity[] }, activity) => {
        const date = formatFullMonthDayYear(activity.performedAt);
        acc[date] = [...(acc[date] || []), activity];
        return acc;
      },
      {},
    );
  }, [activities]);

  return (
    <div className={ styles['activities-container'] }>
      { !!dateWiseActivities && Object.entries(dateWiseActivities).map(([date, dateActivities]) => (
        <div key={ date } className={ styles['activities-section'] }>
          <p className={ styles['activities-section-title'] }>{ date }</p>
          <div>
            { dateActivities.map((activity) => (
              <div key={ activity.id } className={ styles['activity-item'] }>
                <div className={ styles['activity-item-avatar'] }>
                  { activity.performedBy?.avatar?.avatarUrl ? (
                    <img alt="Avatar" className={ styles.avatar } src={ activity.performedBy.avatar.avatarUrl } />
                  ) : (
                    <DefaultAvatarIcon fontSize={ 24 } />
                  ) }
                </div>
                <p className={ styles['activity-item-title'] }>{ activity.parsedActivity }</p>
              </div>
            )) }
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Activities);
