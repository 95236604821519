import React, { useRef, useCallback, useMemo } from 'react';

import { ScrollContext } from '@src/hooks/contexts/ai_chatbot_scroll_context';

export const ScrollProvider = ({ children }: { children: React.ReactNode }) => {
  const chatThreadsInfiniteScrollRef = useRef<HTMLDivElement>(null);
  const chatMessagesInfiniteScrollRef = useRef<HTMLDivElement>(null);
  const chatAutoScrollRef = useRef<HTMLDivElement>(null);
  const chatOuterContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    chatAutoScrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const contextValue = useMemo(() => ({
    chatThreadsInfiniteScrollRef,
    chatMessagesInfiniteScrollRef,
    chatAutoScrollRef,
    chatOuterContainerRef,
    scrollToBottom,
  }), [scrollToBottom]);

  return (
    <ScrollContext.Provider value={ contextValue }>
      {children}
    </ScrollContext.Provider>
  );
};
