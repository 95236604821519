import React, { useMemo } from 'react';

import classNames from 'classnames';

import { formatCount } from '@src/utils/count_formatter';

import { IBusinessCommonProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import { BusinessDriveIcon } from '@src/components/utils/icomoon';

import styles from './style.module.scss';

const BusinessDriveCell: React.FC<IBusinessCommonProps> = ({ unreadInboxMessages, businessId }) => {
  const hasUnreadMessages = useMemo(() => {
    return Boolean((unreadInboxMessages && unreadInboxMessages > 0));
  }, [unreadInboxMessages]);

  return (
    <div className={ styles['icon-badge-container'] }>
      <AppLink
        href={ `/businesses/${businessId}/business_inbox` }
      >
        <div className={ styles['cell-icon-container'] }>
          <BusinessDriveIcon
            className={ styles[unreadInboxMessages ? 'dark-icon' : 'light-icon'] }
            fontSize={ 28 }
          />
          {hasUnreadMessages && (
            <span className={ classNames(styles['badge-count'], 'badge') }>
              {formatCount(unreadInboxMessages || 0)}
            </span>
          )}
        </div>
      </AppLink>
    </div>
  );
};

export default BusinessDriveCell;
