import React, { useCallback, useState } from 'react';

import {
  useUpdateBalanceSheetGroupedAccounts,
} from '@src/hooks/queries/balance_sheet_informations';
import { IGetBalanceSheetGroupAccountsResponse } from '@src/requests/balance_sheet_informations';
import { IBalanceSheetGroupAccount } from '@src/types/balance_sheet_information';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';

import Filter from '@src/components/ui/filter';
import { Button } from '@src/components/ui_v2/buttons';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { SetupSettingsIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useAccountsFilterModal } from './accounts_filter_modal';
import { IStatementViewFilter } from './types';

import styles from '../styles.module.scss';

interface IFilterProps {
  businessId: TID;
  filter: IFilter<IStatementViewFilter>;
  isShowAllAccounts: boolean;
  setIsShowAllAccounts: React.Dispatch<React.SetStateAction<boolean>>;
  setIsHandleCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
}

const StatementFilter = ({
  businessId,
  filter,
  isShowAllAccounts,
  setIsShowAllAccounts,
  setIsHandleCheckbox,
}: IFilterProps) => {
  const [groupedAccounts, setGroupedAccounts] = useState<IGetBalanceSheetGroupAccountsResponse[] | undefined>([]);

  const updateBalanceSheetGroupedAccounts = useUpdateBalanceSheetGroupedAccounts();
  const { mutate } = updateBalanceSheetGroupedAccounts;

  const submitAccountsFilter = useCallback(() => {
    const ids = groupedAccounts
      ?.map((group: IGetBalanceSheetGroupAccountsResponse) => {
        return group.accounts
          .filter((account: IBalanceSheetGroupAccount) => account.visible)
          .map((account: IBalanceSheetGroupAccount) => account.id);
      }).flat();

    mutate({
      businessId,
      ids: ids || [],
    }, {
      onSuccess: () => {
        setIsShowAllAccounts(false);
      },
    });
  }, [groupedAccounts, businessId, mutate, setIsShowAllAccounts]);

  const accountsFilterModal = useAccountsFilterModal({
    onDone: () => submitAccountsFilter(),
  });

  const handleAllAccountsCheckbox = useCallback(() => {
    setIsShowAllAccounts(!isShowAllAccounts);
    setIsHandleCheckbox(true);
  }, [setIsShowAllAccounts, setIsHandleCheckbox, isShowAllAccounts]);

  return (
    <>
      <MutationStatus mutation={ updateBalanceSheetGroupedAccounts } />
      <div className={ styles['filter-container'] }>
        <Filter<IStatementViewFilter>
          className={ styles['search-filter'] }
          filter={ filter }
        >
          <Filter.TextField
            className={ styles['text-field'] }
            name="accountName"
            placeholder="Balance Sheet Account"
          />
          <Filter.YearField
            className={ styles['year-field'] }
            name="year"
            options={ { endDate: new Date(), date: new Date() } }
            placeholder="Year"
          />
        </Filter>
        <div className={ styles['control-filter'] }>
          <CheckboxInput
            checked={ isShowAllAccounts }
            title="Show All Accounts"
            onChange={ handleAllAccountsCheckbox }
          />
          <Button variant="link" onClick={ accountsFilterModal.open }>
            <SetupSettingsIcon fontSize={ 20 } />
          </Button>
        </div>
      </div>
      <accountsFilterModal.Component
        { ...accountsFilterModal.props }
        businessId={ businessId }
        groupedAccounts={ groupedAccounts }
        setGroupedAccounts={ setGroupedAccounts }
      />
    </>
  );
};

export default React.memo(StatementFilter);
