import React, { ComponentPropsWithoutRef, useCallback, useRef } from 'react';

import DatePicker, { CalendarContainer, CalendarContainerProps } from 'react-datepicker';

import { TDate } from '@src/types/common';
import {
  DATEFNS_DATE_FORMAT,
  formatApiDate,
  parseApiDate,
} from '@src/utils/date_helpers';
import { uiStyleProps } from '@src/utils/ui_style_helpers';

import DateInnerInput from './date_inner_input';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.module.scss';

interface IDateInputProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value?: TDate,
  placeholder?: string,
  maxDate?: Date,
  minDate?: Date,
  onChange?: (value: TDate) => void,
  filterDate?: (date: Date) => boolean,
}

const DateInput = ({
  placeholder = 'MM/DD/YYYY',
  value,
  onChange,
  maxDate,
  minDate,
  filterDate,
  ...props
}: IDateInputProps) => {
  const [classes] = uiStyleProps(styles['date-range-input'], props);
  const pickerRef = useRef<DatePicker>(null);

  const handleChange = useCallback((date: Date | null) => {
    if (!onChange) return;

    const apiDate = formatApiDate(date);

    if (value === apiDate) return;

    onChange(apiDate);
  }, [value, onChange]);

  // I need to place this component here because there is no another way to pass
  // additional properties to the container because we pass only container function
  // and it's rendered inside of date picker.
  const DataRangeContainer = useCallback(({
    children,
    className,
  }: CalendarContainerProps) => {
    return (
      <div className={ styles['date-input-container'] }>
        <div className={ styles['date-input-calendar'] }>
          <CalendarContainer className={ className }>
            { children }
          </CalendarContainer>
        </div>
      </div>
    );
  }, []);

  const handleClear = useCallback(() => {
    handleChange(null);
  }, [handleChange]);

  const filterDateCallback = useCallback((date: Date) => {
    return filterDate ? filterDate(date) : true;
  }, [filterDate]);

  return (
    <DatePicker
      ref={ pickerRef }
      autoComplete="off"
      calendarContainer={ DataRangeContainer }
      className={ classes }
      customInput={ <DateInnerInput onClear={ handleClear } /> }
      dateFormat={ DATEFNS_DATE_FORMAT }
      filterDate={ filterDateCallback }
      maxDate={ maxDate }
      minDate={ minDate }
      placeholderText={ placeholder }
      popperClassName={ styles['date-input-popper'] }
      selected={ parseApiDate(value) }
      onChange={ handleChange }
    />
  );
};

export default React.memo(DateInput);
