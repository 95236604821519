import { number, object, string } from 'yup';

import { TDate, TID } from '@src/types/common';

interface IPushIntegrationConnectValues {
  startDate: TDate;
  revenueReportTypeId: TID | undefined;
  location: string;
}

// Function to create the validation schema based on a condition
const createPushIntegrationConnectValidation = (validateRevenueReportTypeId: boolean) => {
  return object({
    startDate:           string().trim().label('Start Date').required(),
    revenueReportTypeId: validateRevenueReportTypeId
      ? number().nullable().label('Revenue Mapping').required()
      : number().nullable().label('Revenue Mapping').optional(),
    location: string().trim().label('Location').required(),
  });
};

export {
  IPushIntegrationConnectValues,
  createPushIntegrationConnectValidation,
};
