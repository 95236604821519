import React, { useCallback } from 'react';

import classNames from 'classnames';

import Button from '@src/components/ui_v2/buttons/button';
import ReconciledIcon from '@src/components/utils/icomoon/reconciled';

import OnboardingPage from '../layout/onboarding_page';

import styles from '../styles.module.scss';

const beforeLedgerSetupVideoURL = 'https://docyt-assets.s3.us-east-1.amazonaws.com/all/assets/firm_selection_guide.mov';

const BeforeLedgerSetup = () => {
  const classes = classNames(
    styles['onboarding-form'],
    styles['width-600'],
  );

  const handleNextPage = useCallback(() => {
    window.Docyt.currentAdvisor.get('default_accounting_firm').before_ledger_setup = true;
    Backbone.history.navigate('setup_accounting_firm?step=ledger_setup', { trigger: true });
  }, []);

  return (
    <OnboardingPage isHideBorder title="Before you connect QuickBooks">
      <div className={ classes }>
        <div className={ styles['onboarding-body'] }>
          <div className="m-b-12">
            <ReconciledIcon fontSize={ 14 } />
            <span className="m-b-0 m-l-8">
              Please make sure you have
              {' '}
              <strong>Admin Access</strong>
              {' '}
              for your
              {' '}
              <strong>QuickBooks Accountant</strong>
              {' '}
              account before proceeding.
            </span>
          </div>
          <div className="m-b-24">
            <ReconciledIcon fontSize={ 14 } />
            <span className="m-b-0 m-l-8">
              Start by selecting a Company or Firm from the list, as shown below.
            </span>
          </div>
          <video autoPlay controls className={ styles['onboarding-form-video'] }>
            <track
              default
              kind="captions"
              label="English captions"
              srcLang="en"
            />
            <source src={ beforeLedgerSetupVideoURL } type="video/mp4" />
          </video>
        </div>
        <div className={ styles['onboarding-footer'] }>
          <div />
          <Button
            variant="primary"
            onClick={ handleNextPage }
          >
            Got it! Continue to Quickbooks
          </Button>
        </div>
      </div>
    </OnboardingPage>
  );
};

export default React.memo(BeforeLedgerSetup);
