import React, { useCallback } from 'react';

import classNames from 'classnames';

import { TQBOSyncStatus } from '@src/types/common';
import { IQBOLink } from '@src/types/qbo_links';
import { IUIStyleProps } from '@src/utils/ui_style_helpers';

import Tooltip from '@src/components/ui_v2/tooltip';
import { QBOBlockIcon, QBOErrorIcon, QBOOffIcon, QBOOnIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IQBOStatusModel {
  qboSyncStatus?: TQBOSyncStatus|string,
  documentQboSyncStatus?: TQBOSyncStatus,
  qboLink?: IQBOLink,
  qboError?: string|null,
}

interface IQBOStatusIconProps extends IUIStyleProps {
  linkToQBO?: boolean,
  model: IQBOStatusModel,
  showIndividualSyncStatus?: boolean,
  showLabel?: boolean,
  skipQboLinkForIsSynced?: boolean,
}

const isDisabled = (document: IQBOStatusModel, status?: string) => {
  const STATUS_DISABLED = window.Docyt.Common.Constants.QBO_SYNC_STATUS.DISABLED;
  return status === STATUS_DISABLED;
};

const isStarted = (document: IQBOStatusModel, status?: string) => {
  const STATUS_STARTED = window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED;
  return status === STATUS_STARTED;
};

const isSynced = (document: IQBOStatusModel, status?: string, skipQboLinkForIsSynced?: boolean) => {
  const STATUS_SYNCED = window.Docyt.Common.Constants.QBO_SYNC_STATUS.SYNCED;
  return status === STATUS_SYNCED || (!!document.qboLink?.qboLink && !skipQboLinkForIsSynced);
};

const isErrored = (document: IQBOStatusModel) => {
  return !!document.qboError || !!document.qboLink?.qboError;
};

const QBOStatusIcon = ({
  linkToQBO,
  model,
  showIndividualSyncStatus,
  showLabel,
  skipQboLinkForIsSynced,
  ...styleProps
}: IQBOStatusIconProps) => {
  let qboSyncStatus = model.qboSyncStatus;
  if (showIndividualSyncStatus) qboSyncStatus = model.documentQboSyncStatus || model.qboSyncStatus;

  const renderLabel = useCallback(() => showLabel && <span>View in QuickBooks</span>, [showLabel]);

  if (isSynced(model, qboSyncStatus, skipQboLinkForIsSynced)) {
    if (linkToQBO) {
      return (
        <a
          className={ classNames(styles['qbo-status-icon'], styles['qbo-status-icon-synced']) }
          href={ model.qboLink?.qboLink || '#' }
          rel="noreferrer"
          target="_blank"
        >
          <QBOOnIcon { ...styleProps } />
          { renderLabel() }
        </a>
      );
    }

    return (
      <div className={ classNames(styles['qbo-status-icon'], styles['qbo-status-icon-synced']) }>
        <QBOOnIcon { ...styleProps } />
        { renderLabel() }
      </div>
    );
  }

  if (isDisabled(model, qboSyncStatus)) {
    return (
      <Tooltip.Hover content={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_BLOCK }>
        <div className={ classNames(styles['qbo-status-icon'], styles['qbo-status-icon-disabled']) }>
          <QBOBlockIcon { ...styleProps } />
          { renderLabel() }
        </div>
      </Tooltip.Hover>
    );
  }

  if (isErrored(model)) {
    return (
      <Tooltip.Hover content={ model.qboLink?.qboError || model.qboError || '' }>
        <div className={ classNames(styles['qbo-status-icon'], styles['qbo-status-icon-error']) }>
          <QBOErrorIcon { ...styleProps } />
          { renderLabel() }
        </div>
      </Tooltip.Hover>
    );
  }

  if (isStarted(model, qboSyncStatus)) {
    return (
      <Tooltip.Hover content={ window.Docyt.Common.Constants.Tooltips.QUICKBOOKS_STARTED }>
        <div className={ classNames(styles['qbo-status-icon'], styles['qbo-status-icon-started']) }>
          <QBOOffIcon { ...styleProps } />
          { renderLabel() }
        </div>
      </Tooltip.Hover>
    );
  }

  return (
    <Tooltip.Hover content={ model.qboLink?.qboError || '' }>
      <div className={ classNames(styles['qbo-status-icon'], styles['qbo-status-icon-error']) }>
        <QBOErrorIcon { ...styleProps } />
        { renderLabel() }
      </div>
    </Tooltip.Hover>
  );
};

export default QBOStatusIcon;
