import {
  IBalanceSheetAccounts,
  IBalanceSheetGroupAccount,
} from '@src/types/balance_sheet_information';
import {
  IBalanceSheetStatement,
  IStatementsFilter,
  TStatementSortColumn,
} from '@src/types/balance_sheet_statements';
import { TID, TDate, TOrderDirection } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetBalanceSheetInformationsParams {
  businessId: TID;
  months?: number;
  monthEndDocument?: boolean;
  showAllAccounts?: boolean;
  filter?: IStatementsFilter;
  page?: number;
  orderColumn?: TStatementSortColumn;
  orderDirection?: TOrderDirection;
}

interface IGetBalanceSheetInformationsResponse {
  meta: {
    totalCount: TID;
    preferredCount: TID;
  };
  collection: IBalanceSheetAccounts[];
}

interface IBalanceSheetStatementParams {
  id: TID;
  businessId: TID;
}

interface IMarkAsNoAvailableBalanceSheetStatementParams {
  businessId: TID;
  chartOfAccountId: TID;
  date?: string;
}

interface IGetBalanceSheetStatementsResponse {
  meta: {
    totalCount: number;
    startImportDate: TDate;
    chartOfAccountName: string;
  };
  collection: IBalanceSheetStatement[];
}

interface IGetBalanceSheetStatementsParams {
  businessId: TID;
  chartOfAccountId?: TID;
  filter?: IStatementsFilter;
  page?: number;
  orderColumn?: TStatementSortColumn;
  orderDirection?: TOrderDirection;
}

interface IGetBalanceSheetGroupAccountsParams {
  businessId: TID;
  page?: number;
  perPage?: number;
}

interface IGetBalanceSheetGroupAccountsResponse {
  accTypeName: string;
  accounts: IBalanceSheetGroupAccount[];
}

interface IUpdateBalanceSheetGroupedAccountsParams {
  businessId: TID;
  ids: TID[];
}

const getBalanceSheetInformations = (
  params: IGetBalanceSheetInformationsParams,
): Promise<IGetBalanceSheetInformationsResponse> => {
  return apiGet(
    '/api/v1/balance_sheet_informations',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.groupedAccounts,
    } as IGetBalanceSheetInformationsResponse;
  });
};

const getBalanceSheetGroupedAccounts = (
  params: IGetBalanceSheetGroupAccountsParams,
): Promise<IGetBalanceSheetGroupAccountsResponse[]> => {
  return apiGet(
    '/api/v1/balance_sheet_informations/grouped_accounts',
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(
      data.grouped_accounts,
    ) as IGetBalanceSheetGroupAccountsResponse[];
  });
};

const updateBalanceSheetGroupedAccounts = (
  params: IUpdateBalanceSheetGroupedAccountsParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_informations/update_visibility',
    underscoreKeys(params),
  );
};
const postMarkAsNoAvailableBalanceSheetStatement = (
  params: IMarkAsNoAvailableBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/balance_sheet_statements/mark_as_no_available',
    underscoreKeys(params),
  );
};

const postUnmarkNoStatementAvailable = (
  params: IBalanceSheetStatementParams,
): Promise<void> => {
  return apiPost(
    `/api/v1/balance_sheet_statements/${params.id}/unmark_no_statement_available`,
    underscoreKeys(params),
  );
};

const getBalanceSheetStatements = (
  params: IGetBalanceSheetStatementsParams,
): Promise<IGetBalanceSheetStatementsResponse> => {
  return apiGet(
    '/api/v1/balance_sheet_statements',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.balanceSheetStatements,
    } as IGetBalanceSheetStatementsResponse;
  });
};

export {
  IGetBalanceSheetInformationsParams,
  IGetBalanceSheetInformationsResponse,
  getBalanceSheetInformations,
  IMarkAsNoAvailableBalanceSheetStatementParams,
  postMarkAsNoAvailableBalanceSheetStatement,
  IBalanceSheetStatementParams,
  postUnmarkNoStatementAvailable,
  getBalanceSheetStatements,
  IGetBalanceSheetStatementsParams,
  IGetBalanceSheetStatementsResponse,
  getBalanceSheetGroupedAccounts,
  updateBalanceSheetGroupedAccounts,
  IGetBalanceSheetGroupAccountsParams,
  IGetBalanceSheetGroupAccountsResponse,
  IUpdateBalanceSheetGroupedAccountsParams,
};
