import {
  businessPath,
  clientBusinessPath,
  businessServicePath,
  clientInfoPath,
  clientBusinessHomePath,
  businessHomePath,
} from '@src/routes';
import { IBusiness, IUsersBusiness } from '@src/types/businesses';

const CLIENTS = 'clients';
const REPORTS = 'reports';
const METRICS_SERVICE = 'metrics_service';
const DASHBOARDS = 'dashboards';
const DETAILS = 'details';
const basicReportsServiceUrl = 'reports?category=basic';
const categoryUrl = 'reports?category';

const reportsServiceUrl = (
  currentUrl: string,
  currentBusiness?: IBusiness | IUsersBusiness | null,
  newBusiness?: IBusiness | IUsersBusiness | null,
) => {
  if (!currentBusiness || !newBusiness) return null;

  if (currentBusiness.standardCategoryId === newBusiness.standardCategoryId) {
    const urlAfterBizId = currentUrl.split('/')[5];
    if (urlAfterBizId.includes(categoryUrl)) {
      return urlAfterBizId;
    }

    return currentUrl.split('/').slice(5, 7).join('/');
  }

  return basicReportsServiceUrl;
};

const backboneNavigateToUrl = (url: string) => {
  Backbone.history.navigate(url, { trigger: true });
};

const redirectToBusinessDashboard = (business: IBusiness) => {
  if (business.serviceProviderBusiness?.id) {
    backboneNavigateToUrl(
      clientBusinessPath(String(business.serviceProviderBusiness?.id), String(business.id)),
    );
  } else {
    backboneNavigateToUrl(businessPath(String(business.id)));
  }
};

const redirectToBusinessService = (
  businessId: string,
  clientBusinessId: string | null,
  withServiceItem: boolean,
  currentBusiness?: IBusiness | IUsersBusiness | null,
  newBusiness?: IBusiness | IUsersBusiness | null,
) => {
  const currentUrl = window.location.href;
  let urlAfterBizId = null;
  const currentService = currentUrl.replace('?', '/').split('/')[5];
  const isClientDetails = clientBusinessId
    && currentService === CLIENTS
    && currentUrl.replace('?', '/').split('/')[7] === DETAILS;

  if (isClientDetails) {
    backboneNavigateToUrl(clientInfoPath(
      Number(clientBusinessId),
      Number(businessId),
    ));
    return;
  }

  if (currentService !== CLIENTS) {
    switch (currentService) {
      case REPORTS:
        urlAfterBizId = reportsServiceUrl(currentUrl, currentBusiness, newBusiness);
        break;
      case METRICS_SERVICE:
        urlAfterBizId = METRICS_SERVICE;
        break;
      case DASHBOARDS:
        break;
      default:
        if (withServiceItem) {
          // with service item, for example: banking_accounts/transactions
          urlAfterBizId = currentUrl.split('/').slice(5, 7).join('/');
        } else {
          // go to service, for example: banking_accounts
          urlAfterBizId = currentService;
        }
    }
  }

  if (urlAfterBizId) {
    const [path, queryString] = urlAfterBizId.split('?');
    if (queryString !== '') {
      // Cleanup filter params
      const params = new URLSearchParams(queryString);
      params.forEach((value, key) => {
        if (key.startsWith('filter_')) {
          params.delete(key);
        }
      });
      urlAfterBizId = path;
      if (params.toString() !== '') urlAfterBizId += `?${params}`;
    }

    backboneNavigateToUrl(businessServicePath(
      String(clientBusinessId || businessId),
      urlAfterBizId,
    ));
  } else if (clientBusinessId) {
    backboneNavigateToUrl(clientBusinessPath(
      String(businessId),
      clientBusinessId,
    ));
  } else {
    backboneNavigateToUrl(businessPath(String(businessId)));
  }
};

const navigateHome = (
  isClientView: boolean,
) => {
  if (isClientView) backboneNavigateToUrl(clientBusinessHomePath());
  else backboneNavigateToUrl(businessHomePath());
};

export {
  backboneNavigateToUrl,
  redirectToBusinessDashboard,
  redirectToBusinessService,
  navigateHome,
};
