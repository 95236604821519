import { useCallback, useMemo } from 'react';

import { PushTriggerOption } from '@sendbird/chat';
import { useAtom, useSetAtom } from 'jotai';

import toastr from '@lib/toastr';
import { useCreateChatThread } from '@src/hooks/queries/ai_chat/ai_chatbot';

import {
  activeChannelAtom,
  activeChannelIdAtom,
  activeThreadIdAtom,
  chatMessageLoaderAtom,
  conversationLoaderAtom,
  firstMessageAtom,
  historyItemsAtom,
  threadsAtom,
} from '@src/components/ai_chatbot/atoms';
import { chatChannelCreationMessage } from '@src/components/ai_chatbot/constants';
import useSendbirdHelper from '@src/components/ai_chatbot/hooks/use_sendbird_helper';
import { getDefaultDataProperty } from '@src/components/ai_chatbot/utils';

import { ChatMessageStatus } from '../types';

const useChatProvider = () => {
  const { context, sdk } = useSendbirdHelper();
  const { mutateAsync: createChatThread } = useCreateChatThread();

  const setHistoryItems = useSetAtom(historyItemsAtom);
  const setThreads = useSetAtom(threadsAtom);
  const setActiveThreadId = useSetAtom(activeThreadIdAtom);
  const setActiveChannelId = useSetAtom(activeChannelIdAtom);
  const setActiveChannel = useSetAtom(activeChannelAtom);
  const setFirstMessage = useSetAtom(firstMessageAtom);
  const setChatMessageLoader = useSetAtom(chatMessageLoaderAtom);
  const [conversationLoader, setConversationLoader] = useAtom(conversationLoaderAtom);

  const startNewThread = useCallback(
    async (message: string, messageSignature: string = crypto.randomUUID()) => {
      if (!message) {
        return;
      }

      try {
        setChatMessageLoader((prev) => [...prev, messageSignature]);
        setFirstMessage({ message, messageSignature });

        // Create a new channel with user + bot
        const channel = await sdk.groupChannel.createChannel({
          invitedUserIds: [
            context.config.userId,
            window.configData.ai_chatbot.sendbird_bot_id,
          ],
          name:       window.configData.ai_chatbot.sendbird_bot_name,
          isDistinct: false,
          coverUrl:   '',
        });

        channel.setMyPushTriggerOption(PushTriggerOption.OFF);

        setActiveChannel(channel);
        setActiveChannelId(channel.url);

        // create chat thread
        const chatThread = await createChatThread({
          userId:     context.config.userId,
          channelId:  channel.url,
          message,
          summary:    message,
          providerId: 'Sendbird',
        });

        setHistoryItems((prev) => ({
          ...prev,
          data: [chatThread, ...prev.data],
        }));

        const threadId = chatThread.id as string;
        setActiveThreadId(threadId);

        setThreads((prevThreads) => {
          const currentData = prevThreads?.data || {};
          const prevMessages = Array.isArray(currentData[threadId])
            ? currentData[threadId]
            : [];

          return {
            ...prevThreads,
            data: {
              ...currentData,
              [threadId]: [
                {
                  id:                       '',
                  channelQuestionMessageId: 0,
                  question:                 message,
                  answer:                   '',
                  channelAnswerMessageId:   0,
                  messageSignature,
                  chatMessageStatus:        ChatMessageStatus.QuestionSentToSendbird,
                },
                ...prevMessages,
              ],
            },
          };
        });

        const data = getDefaultDataProperty(threadId, messageSignature);

        await channel?.sendUserMessage({ message, data });
      } catch {
        toastr.error(
          chatChannelCreationMessage.errorCreating.message,
          chatChannelCreationMessage.errorCreating.title,
        );
        setChatMessageLoader((prev) => prev.filter((item) => item !== messageSignature));
      }
    },
    [
      setChatMessageLoader,
      setFirstMessage,
      sdk.groupChannel,
      context.config.userId,
      setActiveChannel,
      setActiveChannelId,
      createChatThread,
      setHistoryItems,
      setActiveThreadId,
      setThreads,
    ],
  );

  return useMemo(
    () => ({
      startNewThread,
      conversationLoader,
      setConversationLoader,
    }),
    [startNewThread, conversationLoader, setConversationLoader],
  );
};

export default useChatProvider;
