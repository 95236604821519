import React, { forwardRef } from 'react';

import range from 'lodash/range';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

interface IIcomoonProps extends React.ComponentPropsWithoutRef<'span'>, IUIStyleProps {
  color?: 'black' | 'blue' | 'highlight' | 'white' | 'gray' | 'secondary',
  iconName: string,
  pathsNumber?: number,
}

interface IIcomoonIconProps extends Omit<IIcomoonProps, 'iconName' | 'pathsNumber'> {}

type TIcomoonRef = HTMLSpanElement;

const Icomoon = forwardRef<TIcomoonRef, IIcomoonProps>(({
  color,
  iconName,
  pathsNumber = 0,
  ...props
}: IIcomoonProps, ref): JSX.Element => {
  const [classes, spanProps] = uiStyleProps(
    `icon icon-${iconName}`,
    props,
    color && styles[`color-${color}`],
  );

  return (
    <span ref={ ref } className={ classes } { ...spanProps }>
      {
        range(1, pathsNumber + 1).map((i) => (
          <span key={ i } className={ `path${i}` } />
        ))
      }
    </span>
  );
});

Icomoon.displayName = 'Icomoon';

export {
  IIcomoonProps,
  IIcomoonIconProps,
  TIcomoonRef,
  Icomoon as default,
};
