import React, { memo } from 'react';

import { ICommonTooltipProps,
  IListItem,
  TBusinessIntent } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';

import { TooltipItem } from './tooltip_item';
import TooltipWrapper from './wrapper';

import styles from './style.module.scss';

interface ICorporateCardsTooltipProps extends ICommonTooltipProps {
  businessIntent?: TBusinessIntent;
  item: IListItem;
}
const CorporateCardsTooltip: React.FC<ICorporateCardsTooltipProps> = ({
  title,
  item,
  cellData,
  isArray = false,
  value,
  businessId,
  businessIntent,
}) => {
  const getValue = () => {
    if (item.key && cellData) {
      return isArray
        ? cellData?.[item.key]?.[0] ?? 0
        : cellData?.[item.key] ?? 0;
    }
    return null;
  };
  return (
    <TooltipWrapper title={ title }>
      <TooltipItem key={ item.id }>
        <div className={ styles['tooltip-card-item-main'] }>
          <div>
            {item.titleIcon && (
            <span className={ styles['tooltip-card-iconbox'] }>
              {item.titleIcon}
            </span>
            )}
            { (value || getValue()) && (
            <span className={ styles['tooltip-card-item-value'] }>
              {value || getValue()}
            </span>
            )}
            <span className={ styles['tooltip-card-heading'] }>
              {item.heading}
            </span>
          </div>
          {item.actionLink && item.actionLabel && (
          <AppLink
            className={ styles['tooltip-card-action-link'] }
            href={ item.actionLink(businessId, businessIntent) }
          >
            {item.actionLabel}
          </AppLink>
          )}
        </div>
      </TooltipItem>
    </TooltipWrapper>
  );
};
export default memo(CorporateCardsTooltip);
