import { TID } from '@src/types/common';
import { IRevenueCategories } from '@src/types/revenue_categories';
import { IRevenueReportDetailType, IRevenueReportType } from '@src/types/revenue_report_types';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetRevenueReportTypesParams {
  businessId: TID,
  onlyActive?: boolean,
}

interface IGetRevenueCategoriesParams {
  businessId: TID,
  section: string,
}

interface IGetRevenueReportTypesResponse {
  collection: IRevenueReportType[],
}

interface IGetRevenueCategoriesResponse {
  collection: IRevenueCategories[],
}

const getRevenueReportTypes = (
  params: IGetRevenueReportTypesParams,
): Promise<IGetRevenueReportTypesResponse> => {
  return apiGet(
    '/api/v1/revenue_report_types',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.revenueReportTypes as IRevenueReportType[],
    };
  });
};

const getRevenueCategories = (
  params: IGetRevenueCategoriesParams,
): Promise<IGetRevenueCategoriesResponse> => {
  return apiGet(
    '/api/v1/chart_of_accounts/revenue_categories',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.chartOfAccounts as IRevenueCategories[],
    };
  });
};

interface IDataCategory {
  id: number;
  isDebit: boolean;
}
interface ICreateRevenueReportTypeParams {
  revenueId: TID
  revenueReportType: ICreateRevenueReportTypeData,
}
interface ICreateRevenueReportTypeData {
  name:string
  frequency?: string
  splitRevenueReceivables?: boolean,
  categoryIds?: IDataCategory[];
  taxIds?: IDataCategory[];
  ledgerIds?: IDataCategory[];
}

interface ICreateRevenueReportTypeResponse {
  revenueReportType: IRevenueReportType
}

interface IGetRevenueReportTypeDetailResponse {
  revenueReportType: IRevenueReportDetailType
}

interface ICreatePaymentProcessorParams {
  revenueReportTypeId: TID,
  paymentProcessors:{
    id: TID,
    name: string,
  }[]
}
interface ICreatePaymentProcessorResponse {
  revenueReportType: IRevenueReportType
}

const createRevenueReportType = (
  params: ICreateRevenueReportTypeParams,
) : Promise<ICreateRevenueReportTypeResponse> => {
  return apiPost(
    `/api/v1/revenue_services/${params.revenueId}/revenue_report_types`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as ICreateRevenueReportTypeResponse;
  });
};

const getRevenueReportTypeDetail = (
  id: number,
): Promise<IGetRevenueReportTypeDetailResponse> => {
  return apiGet(
    `/api/v1/revenue_report_types/${id}`,
  ).then((data) => {
    return camelizeKeys(data) as IGetRevenueReportTypeDetailResponse;
  });
};

const createPaymentProcessors = (
  params: ICreatePaymentProcessorParams,
) : Promise<ICreatePaymentProcessorResponse> => {
  return apiPost(
    `/api/v1/revenue_report_types/${params.revenueReportTypeId}/add_payment_processors`,
    underscoreKeys({ paymentProcessors: params.paymentProcessors }),
  ).then((data) => {
    return camelizeKeys(data) as ICreatePaymentProcessorResponse;
  });
};
interface IManageRevenueReportTypeMappingParams {
  revenueId: TID,
  type: string,
  payload: IManageRevenueReportTypeMappingData,
}

interface IManageRevenueReportTypeMappingResponse {
  revenueReportType: IRevenueReportType
}

interface IManageRevenueReportTypeMappingData {
  id?: number,
  revenue_service_category_id?: number,
  line_item_name?: string,
  is_debit?: boolean,
  revenue_service_category_ids?: number,
  section?: string,
  category_ids?: IDataCategoryPayload[],
  payment_processors?: IDataPaymentPayload[]
}

interface IDataCategoryPayload {
  id?: number;
  vendor_id?: number | null;
  payment_processor_id?: number | null;
  category_id: number | null;
  accountClass: number | null;
  line_item_name: string;
  is_debit: boolean;
}

interface IDataPaymentPayload {
  id?: number;
  name?: string | null;
  business_id?: number | null;
  selected_in_current_business: number | null;
  is_debit: boolean;
  line_item_name: string;
}

const manageRevenueReportTypeMapping = (
  params: IManageRevenueReportTypeMappingParams,
) : Promise<ICreateRevenueReportTypeResponse> => {
  return apiPost(
    `/api/v1/revenue_report_types/${params.revenueId}/${params.type}`,
    underscoreKeys(params.payload),
  ).then((data) => {
    return camelizeKeys(data) as ICreateRevenueReportTypeResponse;
  });
};

export {
  IGetRevenueReportTypesParams,
  IGetRevenueReportTypesResponse,
  getRevenueReportTypes,
  ICreateRevenueReportTypeParams,
  ICreateRevenueReportTypeResponse,
  createRevenueReportType,
  IGetRevenueCategoriesParams,
  IGetRevenueCategoriesResponse,
  getRevenueCategories,
  getRevenueReportTypeDetail,
  IGetRevenueReportTypeDetailResponse,
  ICreatePaymentProcessorParams,
  createPaymentProcessors,
  ICreatePaymentProcessorResponse,
  IManageRevenueReportTypeMappingParams,
  IManageRevenueReportTypeMappingResponse,
  manageRevenueReportTypeMapping,
  IDataCategoryPayload,
  IDataPaymentPayload,
};
