import React, { useCallback } from 'react';

import { SelfOnboardintSteps } from '@src/constants/self_onboarding_steps';
import { ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import { Button } from '@src/components/ui/buttons';
import Table from '@src/components/ui/table/table';

import ConnectQboListItem from './connect_qbo_item';

import styles from './styles.module.scss';

interface IConnectQboListProps {
  invitation: ISelfOnboardingInvitation,
}

const ConnectQboList = ({
  invitation,
}: IConnectQboListProps) => {
  const handleSubmit = useCallback(() => {
    Backbone.history.navigate(`/self_onboarding?step=${SelfOnboardintSteps.STEP_CONNECT_FIN}`, { trigger: true });
  }, []);

  const onBack = useCallback(() => {
    Backbone.history.navigate(
      `/self_onboarding?step=${SelfOnboardintSteps.STEP_BILLING_INFO}`,
      { trigger: true },
    );
  }, []);

  const connectableBusinesses = invitation.businesses.filter(
    (business) => !business.onboardingLedgerConnectionDisabled,
  );

  return (
    <div className={ styles['self-onboarding-qbo-container'] }>
      <div className="tasks-container">
        <h2>Connect Accounting Software</h2>
        <p className="font-11 in-grey-1050">
          Docyt can automatically update a connected QuickBooks Online account. Seamlessly connect your QBO with Docyt for
          streamlined and enhanced accounting.
        </p>
        <div className="tasks-view">
          <Table>
            <Table.Body>
              {
                connectableBusinesses.map((business) => (
                  <ConnectQboListItem key={ business.id } business={ business } />
                ))
              }
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="setup-client-footer">
        <a
          className="cancel-link pointer"
          role="button"
          tabIndex={ -1 }
          onClick={ onBack }
        >
          Previous
        </a>
        <Button
          className="pull-right bg-purple-1000 in-white width-180px"
          data-color="$purple-1000"
          onClick={ handleSubmit }
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ConnectQboList;
