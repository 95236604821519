import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { useMarkAllChatsAsRead } from '@src/hooks/queries/transaction_messages';
import { TID } from '@src/types/common';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';

import styles from './styles.module.scss';

interface HeaderProps {
  hasNoMessages: boolean;
  businessId: TID;
}

const Header = ({ businessId, hasNoMessages }: HeaderProps): JSX.Element => {
  const { mutate: markAllAsRead } = useMarkAllChatsAsRead();

  const handleMarkAllAsRead = useCallback(() => {
    markAllAsRead({ businessId }, {
      onSuccess: () => {
        toastr.success('All messages marked as read', 'Success');
      },
      onError: () => {
        toastr.error('Failed to mark all messages as read', 'Error');
      },
    });
  }, [markAllAsRead, businessId]);

  return (
    <div className={ styles['page-main-header'] }>
      <div className={ styles['breadcrumb-container'] }>
        <div>
          <Text fontSize={ 24 } fontVariant="bold">Transaction Messages</Text>
        </div>
        <div>
          <Button
            disabled={ hasNoMessages }
            variant="outline"
            onClick={ handleMarkAllAsRead }
          >
            <Text fontSize={ 14 } fontVariant="bold">Mark All as Read</Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
