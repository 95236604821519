/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
import React, { useCallback, useState } from 'react';

import { QueryObserverResult, useQuery } from 'react-query';
import { SingleValue } from 'react-select';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetAccountingClasses } from '@src/hooks/queries/accounting_classes';
import { useGetSearchBusinessVendors } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetPaymentProcessors } from '@src/hooks/queries/bank_account_reconciliations';
import {
  getBusinessChartOfAccounts,
  IGetBusinessChartOfAccountsParams,
} from '@src/requests/business_chart_of_accounts';
import { IGetRevenueReportTypeDetailResponse } from '@src/requests/revenue_report_types';
import { toTitleCase } from '@src/utils/transform_keys';

import { Button } from '@src/components/ui_v2/buttons';
import ReactSelectInput from '@src/components/ui_v2/inputs/react_select/react_select_input';
import Table from '@src/components/ui_v2/table';
import { PlusIcon } from '@src/components/utils/icomoon';

import { getNormalizedValue, SelectOnTableStyles } from './helpers';
import {
  HandleChange,
  HandleSelectChange,
  RowData,
  RowDataValidation,
  SelectOption,
} from './schema';
import { useAddPaymentProcessorsModal } from '../add_payments_processors/add_payments_processors_modal';

import styles from './styles.module.scss';

interface EditableTableItemProps {
  errors: RowDataValidation[],
  itemData: RowData;
  index: number;
  handleChange: HandleChange;
  handleSelectChange: HandleSelectChange;
  deleteRow: (index: number) => void;
  title: string;
  reportTypeId:number;
  refetchRevenueReport: () => Promise<QueryObserverResult<IGetRevenueReportTypeDetailResponse, Error>>;
}

const journalEntryPositionOptions: SelectOption[] = [
  { value: true, label: 'Debit' },
  { value: false, label: 'Credit' },
];

const getOptionLabel = (type: SelectOption) => type.label;
const getOptionValue = (type: SelectOption) => type.value;

const EditableTableItem: React.FC<EditableTableItemProps> = ({
  errors,
  itemData,
  handleChange,
  handleSelectChange,
  index,
  deleteRow,
  title,
  reportTypeId,
  refetchRevenueReport,
}) => {
  const [paymentProcessorOpen, setPaymentProcessorOpen] = useState(false);
  const business = useBusinessContext();
  const addPaymentProcessorsModal = useAddPaymentProcessorsModal();

  const handleCreatePayment = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      addPaymentProcessorsModal.open();
      setPaymentProcessorOpen(false);
    },
    [addPaymentProcessorsModal, setPaymentProcessorOpen],
  );

  const queryParams: IGetBusinessChartOfAccountsParams | null = business.id
    ? { businessId: business.id, simple: true }
    : null;

  // Fetch Department Data
  const {
    data: departmentData,
    isLoading: isDepartmentLoading,
    error: departmentError,
  } = useGetAccountingClasses(queryParams ?? {});
  const departmentOptions = departmentData?.collection?.map((account) => ({
    value: account.id,
    label: toTitleCase(account.name),
  })) || [];

  // Fetch Tax Agency Data
  const {
    data: taxAgencyData,
    isLoading: taxAgencyLoading,
    error:taxAgencyError,
  } = useGetSearchBusinessVendors({ businessId: business.id, search: '' });
  const taxAgencyOptions = taxAgencyData?.collection?.map((account) => ({
    value: account.id,
    label: toTitleCase(account.name),
  })) || [];

  // Fetch Chart of Accounts Data
  const { data: chartOfAccountsData, isLoading: isChartLoading, error: chartError } = useQuery(
    ['chartOfAccounts', business.id],
    () => (queryParams ? getBusinessChartOfAccounts(queryParams) : Promise.resolve({ collection: [] })),
    { staleTime: 1000 * 60 * 5, retry: 2, enabled: !!queryParams },
  );
  const chartOfAccountsOptions = chartOfAccountsData?.collection?.map((account) => ({
    value: account.id,
    label: toTitleCase(account.name),
  })) || [];

  // Fetch Payment Processors Data
  const processorsQuery = useGetPaymentProcessors({ businessId: business.id });
  const paymentProcessorsOptions = processorsQuery?.data?.paymentProcessors?.map((val) => ({
    value: val.id,
    label: toTitleCase(val.name),
  })) || [];

  if (!itemData) return null;

  const shouldRenderCell = (allowedTitles: string[]) => allowedTitles.includes(title);

  return (
    <>
      <addPaymentProcessorsModal.Component
        refetchRevenueReport={ refetchRevenueReport }
        reportTypeId={ reportTypeId }
        { ...addPaymentProcessorsModal.props }
      />
      <Table.Row>
        {/* displayName as Items/Services */}
        <Table.Cell pointer>
          <div className={ styles['table-cell-container'] }>
            <div
              className={ styles['input-area'] }
              style={ { border: errors.length > index && errors[index]?.lineItemName ? '1px solid red' : 'none' } }
            >
              <input
                maxLength={ 100 }
                placeholder="Enter Items/Services"
                style={ { fontSize: 12 } }
                type="text"
                value={ itemData?.lineItemName ?? '' }
                onChange={ (e) => handleChange(index, 'lineItemName', e.target.value) }
              />
            </div>
            {
            errors.length > index && errors[index]?.lineItemName
            && <span className={ styles['required-label'] }>Required fields</span>
          }
          </div>
        </Table.Cell>
        {/* Select Journal Entry Position */}
        <Table.Cell pointer>
          <div className={ styles['table-cell-container'] }>
            <div className={ styles['input-area'] }>
              <ReactSelectInput
                hideClear
                menuShouldBlockScroll
                components={ {
                  IndicatorsContainer: () => null,
                } }
                getOptionLabel={ getOptionLabel }
                getOptionValue={ getOptionValue }
                menuPlacement="auto"
                menuPortalTarget={ document.body }
                noOptionsMessage={ () => <span style={ { cursor: 'default' } }>No options</span> }
                options={ journalEntryPositionOptions }
                placeholder="Select Journal Entry Position"
                styles={ SelectOnTableStyles(errors.length > index && errors[index]?.journalEntryPosition) }
                value={ getNormalizedValue(itemData.journalEntryPosition) }
                onChange={
              (option: SingleValue<SelectOption>) => handleSelectChange(index, 'journalEntryPosition', option)
            }
              />
            </div>
            {
            errors.length > index && errors[index]?.journalEntryPosition
            && <span className={ styles['required-label'] }>Required fields</span>
          }
          </div>
        </Table.Cell>
        {/* Select Chart of Accounts */}
        {shouldRenderCell([
          'Revenue Categories',
          'Tax Categories',
          'Other Ledger Categories',
          'Expense Adjustments',
        ]) && (
          <Table.Cell pointer>
            <div className={ styles['table-cell-container'] }>
              <div className={ styles['input-area'] }>
                {isChartLoading && <p className={ styles['loading-select'] }>Loading...</p>}
                {chartError && !isChartLoading && (
                <p style={ { color: 'red' } }>
                  Error:
                  {' '}
                  {chartError}
                </p>
                )}
                {!isChartLoading && !chartError && (
                <ReactSelectInput
                  hideClear
                  menuShouldBlockScroll
                  components={ {
                    IndicatorsContainer: () => null,
                  } }
                  getOptionLabel={ getOptionLabel }
                  getOptionValue={ getOptionValue }
                  menuPlacement="auto"
                  menuPortalTarget={ document.body }
                  noOptionsMessage={ () => <span style={ { cursor: 'default' } }>No options</span> }
                  options={ chartOfAccountsOptions }
                  placeholder="Select Chart of Accounts"
                  styles={ SelectOnTableStyles(errors.length > index && errors[index]?.chartOfAccounts) }
                  value={ getNormalizedValue(itemData.chartOfAccounts) }
                  onChange={
                      (option: SingleValue<SelectOption>) => handleSelectChange(index, 'chartOfAccounts', option)
                    }
                />
                )}
              </div>
              {
              errors.length > index && errors[index]?.chartOfAccounts
              && <span className={ styles['required-label'] }>Required fields</span>
            }
            </div>
          </Table.Cell>
        )}
        {/* Select Payment Processor */}
        {shouldRenderCell(['Payment Processors', 'Expense Adjustments']) && (
        <Table.Cell pointer>
          <div className={ styles['table-cell-container'] }>
            <div className={ styles['input-area'] }>
              {processorsQuery.isLoading && <p className={ styles['loading-select'] }>Loading...</p>}
              {processorsQuery.error && !processorsQuery.isLoading && (
              <p style={ { color: 'red' } }>
                Error:
                {' '}
                {processorsQuery.error}
              </p>
              )}
              {!processorsQuery.isLoading && !processorsQuery.error && (
              <ReactSelectInput
                hideClear
                menuShouldBlockScroll
                components={ {
                  IndicatorsContainer: () => null,
                } }
                getOptionLabel={ getOptionLabel }
                getOptionValue={ getOptionValue }
                menuFooter={ (
                  <Button
                    className={ styles['button-add'] }
                    variant="link"
                    onClick={ handleCreatePayment }
                  >
                    <PlusIcon fontSize={ 16 } variant="o" />
                    Add Payment Processor
                  </Button>
              ) }
                menuIsOpen={ paymentProcessorOpen }
                menuPlacement="auto"
                menuPortalTarget={ document.body }
                noOptionsMessage={ () => <span style={ { cursor: 'default' } }>No options</span> }
                options={ paymentProcessorsOptions }
                placeholder="Select Payment Processor"
                styles={ SelectOnTableStyles(false) }
                value={ getNormalizedValue(itemData.paymentProcessor) }
                onChange={
                  (option: SingleValue<SelectOption>) => handleSelectChange(index, 'paymentProcessor', option)
                }
                onMenuClose={ () => setPaymentProcessorOpen(false) }
                onMenuOpen={ () => setPaymentProcessorOpen(true) }
              />
              )}
            </div>
          </div>
        </Table.Cell>
        )}
        {/* Select Department */}
        {shouldRenderCell(['Revenue Categories', 'Expense Adjustments']) && (
        <Table.Cell pointer>
          <div className={ styles['table-cell-container'] }>
            <div className={ styles['input-area'] }>
              {isDepartmentLoading && <p className={ styles['loading-select'] }>Loading...</p>}
              {departmentError && !isDepartmentLoading && (
              <p style={ { color: 'red' } }>
                Error:
                {' '}
                {departmentError.message}
              </p>
              )}
              {!isDepartmentLoading && !departmentError && (
              <ReactSelectInput
                hideClear
                menuShouldBlockScroll
                components={ {
                  IndicatorsContainer: () => null,
                } }
                getOptionLabel={ getOptionLabel }
                getOptionValue={ getOptionValue }
                menuPlacement="auto"
                menuPortalTarget={ document.body }
                noOptionsMessage={ () => <span style={ { cursor: 'default' } }>No options</span> }
                options={ departmentOptions }
                placeholder="Select Department"
                styles={ SelectOnTableStyles(false) }
                value={ getNormalizedValue(itemData.department) }
                onChange={ (option: SingleValue<SelectOption>) => handleSelectChange(index, 'department', option) }
              />
              )}
            </div>
          </div>
        </Table.Cell>
        )}
        {/* Select Tax Agency */}
        {shouldRenderCell(['Tax Categories']) && (
        <Table.Cell pointer>
          <div className={ styles['table-cell-container'] }>
            <div className={ styles['input-area'] }>
              {taxAgencyLoading && <p className={ styles['loading-select'] }>Loading...</p>}
              {taxAgencyError && !taxAgencyLoading && (
              <p style={ { color: 'red' } }>
                Error:
                {' '}
                {taxAgencyError.message}
              </p>
              )}
              {!taxAgencyLoading && !taxAgencyError && (
              <ReactSelectInput
                hideClear
                menuShouldBlockScroll
                components={ {
                  IndicatorsContainer: () => null,
                } }
                getOptionLabel={ getOptionLabel }
                getOptionValue={ getOptionValue }
                menuPlacement="auto"
                menuPortalTarget={ document.body }
                noOptionsMessage={ () => <span style={ { cursor: 'default' } }>No options</span> }
                options={ taxAgencyOptions }
                placeholder="Select Tax Agency"
                styles={ SelectOnTableStyles(false) }
                value={ getNormalizedValue(itemData.vendor) }
                onChange={ (option: SingleValue<SelectOption>) => handleSelectChange(index, 'vendor', option) }
              />
              )}
            </div>
          </div>
        </Table.Cell>
        )}
        {/* Delete Button */}
        <Table.Cell pointer>
          <Button
            className={ styles['delete-button'] }
            variant="link"
            onClick={ () => deleteRow(index) }
          >
            <i className="icon icon-trashcan" />
          </Button>
        </Table.Cell>
      </Table.Row>
    </>
  );
};

export default React.memo(EditableTableItem);
