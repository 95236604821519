module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade revenue-action-modal">\n  <div class="modal-dialog modal-lg modal-xlg revenue-action-modal-dialog relative">\n    <div class="modal-content">\n      <div class="modal-body docyt-id-modal-body">\n        <div class="docyt-id-header">\n          <div class="header-title-and-menu">\n            <div class="pull-left">\n              <div class="header-icon">\n                <i class="svg-icon-revenue font-30"></i>\n              </div>\n              <div class="header-text">\n                <span class="header-breadcrumb font-16">\n                  ');
    
      __out.push(__sanitize(this.business.get('display_name')));
    
      __out.push(' / Financials / Revenue Report /\n                </span>\n                <span class="badge badge-secondary">');
    
      __out.push(__sanitize(this.document.get('docyt_id')));
    
      __out.push('</span>\n                <div class="copy-tooltip-container">\n                  <a class="copy-url-button pointer inline-block vertical-align-middle" data-clipboard-text="');
    
      __out.push(__sanitize(this.documentURL));
    
      __out.push('">\n                    <span class="icon icon-copy-icon font-24"></span>\n                  </a>\n                </div>\n              </div>\n            </div>\n            <div id="header-menu-container" class="header-action">\n              <a class="pointer pull-right m-t-5 m-l-10 close-link close-icon-btn"><span class="icon icon-not-a-chargeback-icon font-25"></span></a>\n              <div class="dropdown pull-right">\n                <button class="btn btn-secondary dropdown-toggle header-icon-button m-l-10" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n                  <span class="icon icon-more-icon font-22"></span>\n                </button>\n                <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">\n                  ');
    
      if (this.revenue_service_document.isVerified()) {
        __out.push('\n                  <a class="dropdown-item font-16 pointer edit-revenue-js">\n                    <i class="icon icon-edit-account m-r-10 m-t-3"></i>\n                    <span>Edit Report</span>\n                  </a>\n                  ');
      }
    
      __out.push('\n                  <a class="dropdown-item font-16 pointer export-document-pdf-js">\n                    <i class="icon icon-download m-r-10 m-t-3"></i>\n                    <span>Export as PDF</span>\n                  </a>\n                  ');
    
      if (this.hasQboError) {
        __out.push('\n                    <a class="dropdown-item font-16 pointer re-sync-js">\n                      <i class="icon-qbo-on font-25"></i>\n                      <span>Re-sync</span>\n                    </a>\n                  ');
      }
    
      __out.push('\n                  <a class="dropdown-item font-16 pointer delete-document-js">\n                    <i class="icon icon-trashcan m-r-10 m-t-3"></i>\n                    <span>Delete</span>\n                  </a>\n                </div>\n              </div>\n              <div id="qbo-document-region-new" class="pull-right"></div>\n              <div class="header-separator pull-right"></div>\n              <div class="document-status-container pull-right">\n                <p class="inline-block vertical-align-middle">\n                  <span class="invoice-status-panel badge font-14 ');
    
      __out.push(__sanitize(this.revenue_service_document.get('state')));
    
      __out.push('">');
    
      __out.push(__sanitize(this.revenue_service_document.get('state')));
    
      __out.push('</span>\n                </p>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class=\'clients-drop-zone\'>\n          <div class="client-main-pane pdf-client-document-wrapper">\n            <div id=\'revenue-details-region\' ></div>\n          </div>\n          <div id="revenue-document-right-side-region" class="client-document-right-side-region"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}