module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="setup-business-header">\n  <h1 class="header-title">Setup ');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</h1>\n  <div id="delete-business-region"></div>\n  <a class="exit-link">\n    <i class="icon-reject-invite"></i>\n  </a>\n</div>\n<div class="setup-business-container ');
    
      if (this.for_onboarding) {
        __out.push(__sanitize('setup-onboarding-container'));
      }
    
      __out.push('">\n  <div class="');
    
      if (!this.for_onboarding) {
        __out.push(__sanitize('container'));
      }
    
      __out.push('">\n    <div class="setup-business-card">\n      <div class="setup-business-side-menu">\n      </div>\n      <div class="setup-business-panel-wrapper ');
    
      if (this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BUSINESS_TEAM_STEP || this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.ACCOUNTING_TEAM_STEP || this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.DOCUMENT_COLLECTION_STEP) {
        __out.push(__sanitize('setup-team-setting-panel-wrapper'));
      }
    
      __out.push('">\n        ');
    
      if (this.for_onboarding && this.isSetupOptionVisible) {
        __out.push('\n        <div class="setup-business-panel-header-wrapper">\n          <div class="setup-business-panel-header ');
        if (this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.PAYMENT_METHODS_STEP) {
          __out.push(__sanitize('setup-payment-panel-header'));
        }
        __out.push('">\n            <span class="font-24 font-semi-bold">');
        __out.push(__sanitize(this.header_title));
        __out.push('</span>\n            <div class="relative">\n              <button class="setup-client-setting-dropdown-btn btn dropdown-toggle font-16 font-semi-bold ');
        if (this.is_setup_done) {
          __out.push(__sanitize('active'));
        }
        __out.push(' ');
        if (this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BANKING_ACCOUNTS_STEP) {
          __out.push(__sanitize('bank-setting-dropdown'));
        }
        __out.push(' " type="button" id="setupClientSettingDropdownBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n                <span class="setup-client-setting-toggle-label">');
        __out.push(__sanitize(!this.is_setup_done ? 'To do' : 'Done'));
        __out.push('</span>\n                <span class="fa fa-caret-down font-12"></span>\n              </button>\n              <div class="dropdown-menu" aria-labelledby="setupClientSettingDropdownBtn">\n                <div class="dropdown-item">\n                  <button class="btn font-16 font-semi-bold setup-client-preparing-btn" type="button">To do</button>\n                </div>\n                <div class="dropdown-item">\n                  <button class="btn font-16 font-semi-bold setup-client-done-btn" type="button">Done</button>\n                </div>\n              </div>\n            </div>\n            ');
        if (this.step === Docyt.Common.Constants.ONBOARDING_BUSINESS_SETUP.BANKING_ACCOUNTS_STEP) {
          __out.push('\n              <button class="btn btn-add-document font-20 add-payment-account-btn"><i class="icon icon-plus"></i></button>\n            ');
        }
        __out.push('\n          </div>\n        </div>\n        ');
      }
    
      __out.push('\n        <div class="setup-business-panel"></div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="setup-business-footer ');
    
      if (this.for_onboarding) {
        __out.push(__sanitize('setup-client-footer'));
      }
    
      __out.push('">\n  <div class="');
    
      if (!this.for_onboarding) {
        __out.push(__sanitize('container'));
      }
    
      __out.push('">\n    <a class="cancel-link back-js">');
    
      __out.push(__sanitize(this.for_onboarding ? 'Previous' : 'Back'));
    
      __out.push('</a>\n    ');
    
      if (!this.for_onboarding) {
        __out.push('\n    <a class="finish-later-link finish-setup-later-js">Finish setup later</a>\n    ');
      }
    
      __out.push('\n    <button type=\'button\' class="btn btn-blue ladda-button next-js pull-right" data-color="blue" data-style="expand-right"><span class="ladda-label">Next</span></button>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}