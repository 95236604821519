module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<button class="btn dropdown-toggle font-15 font-bold btn-blue-on-hover disabled" type="button" id="bulk-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  Bulk Actions\n  <span class="fa fa-angle-down m-l-5"></span>\n</button>\n<div class="bulk-action-drop-down dropdown-menu dropdown-menu-left" aria-labelledby="bulk-action-dropdown-btn">\n  ');
    
      if (this.canEditCategory) {
        __out.push('\n    <button class="dropdown-item edit-category-btn font-14" type="button">\n      <i class="icon icon-edit"></i> <span>Edit Category</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canMergeDocuments) {
        __out.push('\n  <button class="dropdown-item merge-btn font-14" type="button">\n    <i class="icon icon-merge"></i> <span>');
        __out.push(__sanitize(this.merge_title));
        __out.push('</span>\n  </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canForwardDocuments) {
        __out.push('\n    <button class="dropdown-item forward-btn font-14" type="button">\n      <i class="icon icon-forward-o"></i> <span>Forward</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canDonPayInvoice) {
        __out.push('\n    <button class="dropdown-item do-not-pay-btn font-14" type="button">\n      <i class="icon icon-stop"></i> <span>Do Not Pay</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canVerifyInvoice) {
        __out.push('  \n    <button class="dropdown-item font-14 verify-invoices-btn" type="button">\n      <i class="icon icon-thumbs-up"></i> <span>Verify Invoices</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canApproveInvoice) {
        __out.push('\n    <button class="dropdown-item font-14 approve-invoices-btn" type="button">\n      <i class="icon icon-thumbs-up"></i> <span>Approve Invoices</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canUnApproveInvoice) {
        __out.push('\n    <button class="dropdown-item font-14 unapprove-invoices-btn" type="button">\n      <i class="icon icon-thumbs-down"></i> <span>Retract Approval</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canEditInvoice) {
        __out.push('  \n    <button class="dropdown-item edit-invoices-btn font-14" type="button">\n      <i class="icon icon-edit-account"></i> <span>Edit Invoices</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if ((this.newBulkPaymentEnabled && this.canReviewPaymentForPayByDocytAch) || (!this.newBulkPaymentEnabled && this.canPayViaAchInvoice)) {
        __out.push('\n    <button class="dropdown-item font-14 pay-via-ach-btn" type="button">\n      <i class="icon icon-ach"></i> <span>Pay by Docyt ACH</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if ((this.newBulkPaymentEnabled && this.canReviewPaymentForPayByDocytCheck) || (!this.newBulkPaymentEnabled && this.canPayViaDocytInvoice)) {
        __out.push('\n    <button class="dropdown-item font-14 pay-via-docyt-btn" type="button">\n      <i class="icon icon-check-card"></i> <span>Pay by ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_CHECK']));
        __out.push('</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if ((this.newBulkPaymentEnabled && this.canReviewPaymentForMarkAsPaid) || (!this.newBulkPaymentEnabled && this.canMarkPaidInvoice)) {
        __out.push('\n    <div class="font-14 dropdown-submenu">\n      <a class="dropdown-item font-14 pointer dropdown-submenu-owner-item">\n        <i class="icon icon-paid"></i> <span>Mark as paid</span>\n      </a>\n      <div class="dropdown-menu">\n        <button class="dropdown-item font-14 mark-as-paid-by-cash-btn" type="button">\n          <i class="icon icon-cash"></i> <span>Cash</span>\n        </button>\n        <button class="dropdown-item font-14 mark-as-paid-by-card-btn" type="button">\n          <i class="icon icon-credit-card"></i> <span>Credit Card</span>\n        </button>\n        <button class="dropdown-item font-14 mark-as-paid-by-bank-btn" type="button">\n          <i class="icon icon-business"></i> <span>Bank Account(ACH / Debit Card)</span>\n        </button>\n        <button class="dropdown-item font-14 mark-as-paid-by-settle-btn" type="button">\n          <i class="icon icon-paid"></i> <span>Advanced Settlement...</span>\n        </button>\n      </div>\n    </div>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.newBulkPaymentEnabled && (this.canReviewPaymentForPayByDocytCheck || this.canPayViaDocytInvoice)) {
        __out.push('\n    <button class="dropdown-item font-14 pay-via-self-print-check-btn" type="button">\n      <i class="icon icon-bulk-self-print-check"></i> <span>Pay with ');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_SELF_PRINT_CHECK']));
        __out.push('</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canDeleteInvoice) {
        __out.push('\n    <button class="dropdown-item font-14 destroy-invoices-btn delete-btn" type="button">\n      <i class="icon icon-trashcan"></i> <span>Delete Invoices</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canDeleteReceipt) {
        __out.push('\n    <button class="dropdown-item font-14 destroy-receipt-btn delete-btn" type="button">\n      <i class="icon icon-trashcan"></i> <span>Delete Receipts</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canDeleteRevenue) {
        __out.push('\n    <button class="dropdown-item font-14 destroy-revenues-btn delete-btn" type="button">\n      <i class="icon icon-trashcan"></i> <span>Delete Reports</span>\n    </button>\n  ');
      }
    
      __out.push('\n  ');
    
      if (this.canDownloadCheck) {
        __out.push('\n    <button class="dropdown-item font-14 download-check-btn" type="button">\n      <i class="icon icon-print"></i> <span>Download Printable Checks</span>\n    </button>\n  ');
      }
    
      __out.push('\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}