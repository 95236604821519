/* eslint-disable key-spacing */
import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

import styles from './styles.module.scss';

interface IExternalSystemsIconProps extends IIcomoonIconProps {
  type: string;
}
const ExternalSystemsIcon = ({
  type,
  ...props
}: IExternalSystemsIconProps) => {
  const getIcon: Record<string, { icon: string; length: number }> = {
    'anand-systems':                     { icon: 'anand-systems', length: 137 },
    'choice-advantage':                  { icon: 'choice-advantage-reporting-system', length: 3 },
    'clover':                            { icon: 'clover', length: 0 },
    'hotel-key':                         { icon: 'hotel-key', length: 14 },
    "flexe-pos-for-jersey-mike's":       { icon: 'flexepos-for-jersey-mikes', length: 7 },
    'lightspeed':                        { icon: 'lightspeed', length: 0 },
    'mews':                              { icon: 'mews', length: 5 },
    'oracle-opera':                      { icon: 'oracle-opera', length: 11 },
    'oracle-opera-5-(desktop)':          { icon: 'oracle-opera', length: 11 },
    'paychex-timeclocking':              { icon: 'paychex', length: 11 },
    'paychex-timeclocking-multi-entity': { icon: 'paychex', length: 11 },
    'roomkey':                           { icon: 'roomkey', length: 3 },
    'shopify':                           { icon: 'shopify', length: 3 },
    'square':                            { icon: 'square', length: 0 },
    'str':                               { icon: 'str', length: 0 },
    'stripe':                            { icon: 'stripe', length: 2 },
    'synxis':                            { icon: 'synxis', length: 23 },
    'ups-store':                         { icon: 'ups-store', length: 6 },
    'visual-matrix':                     { icon: 'visual-matrix-reporting-system', length: 18 },
    'toast':                             { icon: 'toast', length: 3 },
    'fosse':                             { icon: 'fosse', length: 15 },
    'gingr':                             { icon: 'gingr', length: 11 },
    'fspms':                             { icon: 'fspms', length: 7 },
    'motel-6':                           { icon: 'motel-6', length: 12 },
    'hilton-pep':                        { icon: 'hilton-pep', length: 410 },
    'on-q':                              { icon: 'on-q', length: 4 },
    'galaxy-lightspeed':                 { icon: 'galaxy-lightspeed', length: 48 },
    'auto-clerk':                        { icon: 'auto-clerk', length: 301 },
    'infor-hms':                         { icon: 'infor-hms', length: 2 },
    'hotel-key-(new)':                   { icon: 'hotel-key', length: 14 },
    'jonas-chorum':                      { icon: 'jonas-chorum', length: 63 },
  };
  const iconData = getIcon[type];
  if (!iconData) {
    return <div className={ styles['revenue-system-indicator'] } />;
  }
  return (
    <Icomoon
      className={ styles['external-systems-icon'] }
      { ...props }
      iconName={ iconData.icon }
      { ...(iconData.length > 0 && { pathsNumber: iconData.length }) }
    />
  );
};
export default ExternalSystemsIcon;
