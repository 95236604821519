import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { IBusiness } from '@src/types/businesses';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import Form from '@src/components/ui_v2/form';
import Tooltip from '@src/components/ui_v2/tooltip';
import { HelpIcon } from '@src/components/utils/icomoon';

import {
  IPushIntegrationConnectValues,
  createPushIntegrationConnectValidation,
} from './schema';

import styles from './styles.module.scss';

interface IPushIntegrationConnectProps {
  business: IBusiness,
  formId: string,
  valueForm?: IPushIntegrationConnectValues,
  onSubmit: (data: IPushIntegrationConnectValues) => void,
  onValidationChange: (isValid: boolean) => void,
  externalSystemConfigSchema: IExternalSystemPullProperties,
}

const PushIntegrationConnectForm = ({
  business,
  formId,
  valueForm,
  onSubmit,
  onValidationChange,
  externalSystemConfigSchema,
}: IPushIntegrationConnectProps) => {
  const featureSkipRevenueReportTypeValidation = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.SKIP_REVENUE_REPORT_MAPPING,
  );

  // Determine whether to validate revenueReportTypeId
  const validationSchema = createPushIntegrationConnectValidation(
    !featureSkipRevenueReportTypeValidation.data,
  );
  const [interactedFields, setInteractedFields] = useState<{ [key: string]: boolean }>({});
  const [focusFiled, setFocusFiled] = useState<string>('');

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<IPushIntegrationConnectValues>({
    mode:          'onChange',
    resolver:      yupResolver(validationSchema),
    defaultValues: {
      startDate:           valueForm ? valueForm.startDate : '',
      revenueReportTypeId: valueForm ? valueForm.revenueReportTypeId : undefined,
      location:            valueForm ? valueForm.location : '',
    },
  });

  const dateControl = useController({ control, name: 'startDate' });
  const rrtControl = useController({ control, name: 'revenueReportTypeId' });

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  const field = externalSystemConfigSchema.properties;

  const handleFieldInteraction = (key: string) => {
    setFocusFiled('');
    if (!interactedFields[key]) {
      setInteractedFields((prev) => ({ ...prev, [key]: true }));
    }
  };

  const renderLabel = (title: string, key: string, showHelp: boolean, focus: string, helpText?: string) => {
    if (!helpText) return title;

    const isFocused = !showHelp && key === focus;
    return (
      <span className={ styles['label-with-help'] }>
        {title}
        {' '}
        <Tooltip.Hover
          key={ `${key}-${isFocused ? 'first' : 'default'}-control` }
          className={ styles['tooltip-wrapper'] }
          content={ helpText }
          isControl={ isFocused }
          showTooltip={ isFocused }
        >
          <HelpIcon fontSize={ 18 } />
        </Tooltip.Hover>
      </span>
    );
  };

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Form.TextField
        hideClear
        readOnly
        label="Business"
        value={ business.name }
      />
      <Form.DateField
        error={ errors.startDate?.message }
        label={ renderLabel(
          field.startDate.title,
          'startDate',
          interactedFields.startDate,
          focusFiled,
          field.startDate.helpText,
        ) }
        { ...omit(dateControl.field, 'ref') }
        onBlur={ () => handleFieldInteraction('startDate') }
        onFocus={ () => setFocusFiled('startDate') }
      />
      {!featureSkipRevenueReportTypeValidation.data && (
        <RevenueReportTypeField
          businessId={ business.id }
          error={ errors.revenueReportTypeId?.message }
          label="Revenue Mapping"
          placeholder="Select Revenue Mapping"
          { ...omit(rrtControl.field, 'ref') }
        />
      )}
      <Form.TextField
        error={ errors.location?.message }
        label={ renderLabel(
          field.location.title,
          'location',
          interactedFields.location,
          focusFiled,
          field.location.helpText,
        ) }
        maxLength={ 50 }
        placeholder="Enter Location"
        { ...register('location') }
        onBlur={ () => handleFieldInteraction('location') }
        onFocus={ () => setFocusFiled('location') }
      />
    </Form>
  );
};

export default React.memo(PushIntegrationConnectForm);
