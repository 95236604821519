import React, { useEffect, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetPaymentProcessors } from '@src/hooks/queries/bank_account_reconciliations';
import { ITSDComponentProps } from '@src/types/transaction_service_documents';

import BusinessChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';
import Spinner from '@src/components/ui/spinner';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import SidebarSelectInput from './sidebar_select_input';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

const RevenueReversal = ({ rowData, setRowData, verify }: ITSDComponentProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } =
    useTxnDoc({ rowData, setRowData });

  const {
    isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo, documentRelated,
  } = commonProps;

  const business = useBusinessContext();
  const { revenueCenterEnabled } = business;
  const processorsQuery = useGetPaymentProcessors({
    businessId: business.id,
  });

  const processorOptions = useMemo(() => {
    const processors = processorsQuery.data?.paymentProcessors;
    if (!processors) return [];
    return processors.filter((i) => i.selectedInCurrentBusiness).map((i) => ({ value: String(i.id), label: i.name }));
  }, [processorsQuery.data?.paymentProcessors]);

  const processorValue = processorOptions.find((i) => i.value === String(rowData.userPaymentProcessorId));

  const requiredValue = revenueCenterEnabled ? processorValue : rowData.chartOfAccountId;
  useEffect(() => {
    let disabled = true;

    if (requiredValue && documentRelated) {
      disabled = false;
    }

    verify(disabled);
  }, [requiredValue, documentRelated, verify]);

  return (
    <>
      {isSubmitting && <Spinner />}
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />

        {
          revenueCenterEnabled ? (
            <SidebarSelectInput
              isDisabled={ isDisabled }
              label="Payment Processor*"
              options={ processorOptions }
              placeholder="Select Payment Processor"
              value={ processorValue }
              onChange={ (val) => updateFields({ paymentProcessorId: val, userPaymentProcessorId: val }) }
            />
          )
            : (
              <BusinessChartOfAccountField
                hideClear
                businessId={ business.id }
                isDisabled={ isDisabled }
                label="Category*"
                labelClass={ styles['select-label'] }
                placeholder="Enter Category Name or Code"
                queryParams={ { searchAccType: 'Income' } }
                value={ rowData.chartOfAccountId }
                onChange={ (id) => updateFields({ userChartOfAccountId: id }) }
              />
            )
        }

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </>
  );
};

export default RevenueReversal;
