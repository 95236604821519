import React from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { categorizedTransactionsTabsPath } from '@src/routes';

import Button from '@src/components/ui_v2/buttons/button';
import Section from '@src/components/utils_v2/section';

import styles from './styles.module.scss';

const TABS = [
  {
    id:    0,
    title: 'All',
    type:  'all',
  },
  {
    id:    1,
    title: 'Expenses',
    type:  'expenses',
  },
  {
    id:    2,
    title: 'Income',
    type:  'revenue',
  },
  {
    id:    3,
    title: 'Bank Transfers',
    type:  'bank_transfers',
  },
  {
    id:    4,
    title: 'Payroll Charges',
    type:  'payroll',
  },
  {
    id:    6,
    title: 'Loan Payments',
    type:  'loan_payments',
  },
  {
    id:    5,
    title: 'Equity / Liability / Asset',
    type:  'equity',
  },
];

const CategorizedTransactions = () => {
  const { businessId, transactionType } = useParams();

  return (
    <Section.Provider
      section={ { businessId: Number(businessId), section: 'categorized_transactions_tabs' } }
    >
      <div className={ styles['header-region'] }>
        <div className={ styles['action-content'] }>
          <div className={ styles['tabs-content'] }>
            { TABS.map((tab: { id: number, title: string, type: string }) => (
              <Button
                key={ tab.id }
                className={ classNames(styles.tab, { [styles.active]: tab.type === transactionType }) }
                variant="link"
                onClick={ () => {
                  Backbone.history.navigate(
                    categorizedTransactionsTabsPath(Number(businessId), tab.type),
                    { trigger: true },
                  );
                } }
              >
                { tab.title }
              </Button>
            )) }
          </div>
        </div>
      </div>

    </Section.Provider>
  );
};

export default React.memo(CategorizedTransactions);
