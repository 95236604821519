import React from 'react';

import {
  rcMonthEndDocmentsBankStatementsPath,
  rcMonthEndDocmentsOtherDocumentsPath,
} from '@src/routes';
import { TID } from '@src/types/common';

import Root from '@src/components/root';
import ServiceSidebar from '@src/components/ui_v2/service_sidebar';

import { getReconciliationCenterSectionName } from './utils';

interface IReconciliationCenterGlobalNavigationSidebarProps {
  monthEndItem: JSX.Element | null
  transactionsWaitingForApprovalItem: JSX.Element | null
  transactionsForReviewItem: JSX.Element | null,
  businessId: TID,
}

const ReconciliationCenterGlobalNavigationSidebar = ({
  monthEndItem,
  transactionsWaitingForApprovalItem,
  transactionsForReviewItem,
  businessId,
}: IReconciliationCenterGlobalNavigationSidebarProps) => {
  const sectionName = getReconciliationCenterSectionName(window.location.pathname + window.location.search);

  switch (sectionName) {
    case 'Tasks':
      return (
        <Root>
          <ServiceSidebar>
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/transactions` }
              title="Uncategorized Transactions"
            />
            { transactionsForReviewItem }
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/documents` }
              title="Uncleared Documents"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/bank_transfers?matched_type=false&from=tasks` }
              title="Unmatched Transfers"
            />
          </ServiceSidebar>
        </Root>
      );
    case 'Closing':
      return (
        <Root>
          <ServiceSidebar>
            { monthEndItem }
            <li
              className="nav"
              data-global-feature-toggle={ window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG }
            >
              <ServiceSidebar.Item
                href={ rcMonthEndDocmentsBankStatementsPath(businessId) }
                title="Bank Statements"
              />
            </li>

            <li
              className="nav"
              data-global-feature-toggle={ window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG }
            >
              <ServiceSidebar.Item
                href={ rcMonthEndDocmentsOtherDocumentsPath(businessId) }
                title="Other Account Documents"
              />
            </li>

          </ServiceSidebar>
        </Root>
      );
    case 'Transactions':
      return (
        <Root>
          <ServiceSidebar>
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/all_transactions` }
              title="All Transactions"
            />
            { transactionsWaitingForApprovalItem }
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/expenses` }
              title="Expenses"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/revenue` }
              title="Income"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/bank_transfers?from=transactions` }
              title="Bank Transfers"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/payroll` }
              title="Payroll Charges"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/loan_payments` }
              title="Loan Payments"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/equity` }
              title="Equity / Liability / Asset"
            />
          </ServiceSidebar>
        </Root>
      );
    case 'Ledger':
      return (
        <Root>
          <ServiceSidebar>
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/accounts?type=categories` }
              title="Chart of Accounts"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/journal_entries` }
              title="Journal Entries"
            />
            <ServiceSidebar.Item
              href={ `/businesses/${businessId}/reconciliation_center/ledger_syncing_centre` }
              title="Syncing Errors"
            />
          </ServiceSidebar>
        </Root>
      );
    default:
      return null;
  }
};

export default ReconciliationCenterGlobalNavigationSidebar;
