import React from 'react';

import HoverTooltip from '@src/components/ui_v2/tooltip/hover_tooltip';

interface IHoverTooltipProps {
  content: React.ReactNode,
  hoverValue: string | React.ReactNode,
}
const HoverIcon = ({ content, hoverValue }:IHoverTooltipProps) => {
  return (
    <HoverTooltip
      keepShowOnHover
      content={ hoverValue }
      popoverParams={ {
        showDelay: 0,
        hideDelay: 0,
      } }
      variant="dark"
    >
      {content}
    </HoverTooltip>
  );
};
export default HoverIcon;
