import React from 'react';

import Table from '@src/components/ui/table';

const TableHead: React.FC = () => {
  return (
    <Table.Head>
      <Table.Row>
        <Table.HCell widthPercent={ 20 }>Date</Table.HCell>
        <Table.HCell widthPercent={ 55 }>Message</Table.HCell>
        <Table.HCell widthPercent={ 25 }>User</Table.HCell>
      </Table.Row>
    </Table.Head>
  );
};

export default TableHead;
