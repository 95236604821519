import { TID } from '@src/types/common';
import { IDocumentRequest } from '@src/types/document_requests';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPatch, apiPost, apiPut } from './helpers';

interface IGetDocumentRequestsParams {
  businessId: TID,
  searchQuery: string,
}

interface IPostDocumentRequestsParams {
  businessId: TID,
  documentRequests: IDocumentRequest[],
}

interface ILinkDocumentToRequestParams {
  documentRequest: IDocumentRequest,
  documentId: TID,
}

interface IUnlinkDocumentRequestParams {
  documentRequestId: TID,
  documentId: TID,
}

interface IReviewDocumentRequestParams {
  documentRequestId: TID;
  notes?: string;
  state: string;
}

interface IMarkNoStatementAvailableParams {
  documentRequestId: TID;
  noStatementAvailable: boolean;
}

interface IAddNotesToDocumentRequestParams {
  documentRequestId: TID;
  notes: string;
}

const getDocumentRequests = (params: IGetDocumentRequestsParams): Promise<IDocumentRequest[]> => {
  return apiGet(
    '/api/v1/document_requests',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.document_requests) as IDocumentRequest[]);
};

const postDocumentRequests = (params: IPostDocumentRequestsParams): Promise<void> => {
  return apiPost(
    '/api/v1/document_requests/create_batch',
    underscoreKeys(params),
  );
};

const postLinkDocumentToRequest = (params: ILinkDocumentToRequestParams): Promise<void> => {
  return apiPost(
    `/api/v1/document_requests/${params.documentRequest.id}/link_document_to_request`,
    underscoreKeys({
      documentId:        params.documentId,
      documentRequestId: params.documentRequest.id,
    }),
  );
};

const postUnlinkDocumentRequest = (params: IUnlinkDocumentRequestParams): Promise<void> => {
  return apiPost(
    `/api/v1/document_requests/${params.documentRequestId}/unlink_document`,
    underscoreKeys(params),
  );
};

export const reviewDocumentRequest = async ({ documentRequestId, notes, state }: IReviewDocumentRequestParams) => {
  return apiPut(`/api/v1/document_requests/${documentRequestId}`, {
    state,
    notes,
  });
};

export const markNoStatementAvailable = async ({ documentRequestId, noStatementAvailable }:
  IMarkNoStatementAvailableParams) => {
  return apiPatch(
    `/api/v1/document_requests/${documentRequestId}/update_statement_availability`,
    {
      no_statement_available: Boolean(noStatementAvailable),
    },
  );
};

export const addNotesToDocumentRequest = async ({ documentRequestId, notes }: IAddNotesToDocumentRequestParams) => {
  return apiPatch(`/api/v1/document_requests/${documentRequestId}`, {
    notes,
  });
};

export type { IReviewDocumentRequestParams, IMarkNoStatementAvailableParams };

export {
  IGetDocumentRequestsParams,
  IPostDocumentRequestsParams,
  ILinkDocumentToRequestParams,
  IUnlinkDocumentRequestParams,
  getDocumentRequests,
  postDocumentRequests,
  postLinkDocumentToRequest,
  postUnlinkDocumentRequest,
};
