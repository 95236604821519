module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<button class="btn dropdown-toggle font-15 font-bold header-icon-button link" type="button" id="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n  <span class="icon icon-more-icon font-22"></span>\n</button>\n<div class="invoice-queue-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="invoice-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n  <button class="dropdown-item font-14 self-print-btn" type="button">\n    <i class="icon icon-print"></i> <span>');
    
      __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_SELF_PRINT_CHECK']));
    
      __out.push('</span>\n  </button>\n  ');
    
      if (this.pre_printed_check_stock) {
        __out.push('\n    <button class="dropdown-item font-14 pre-printed-btn" type="button">\n      <i class="icon icon-print"></i> <span>');
        __out.push(__sanitize(Docyt.Common.Constants.Messages['DOCYT_PRE_PRINT_CHECK']));
        __out.push('</span>\n    </button>\n  ');
      }
    
      __out.push('\n  <button class="dropdown-item font-14 docyt-tracking-btn" type="button">\n    <i class="icon icon-print"></i> <span>Docyt will print & mail with tracking</span>\n  </button>\n</div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}