import React, { memo } from 'react';

import { useAtomValue } from 'jotai';

import { isNavBarVisibleAtom } from '@src/components/ai_chatbot/atoms';

import ChatBody from './components/chat_body';
import NavBar from './components/nav_bar';

import styles from './styles.module.scss';

const AiChatBotMainView = () => {
  const isNavBarVisible = useAtomValue(isNavBarVisibleAtom);

  return (
    <div className={ styles['chat-container'] }>
      {isNavBarVisible && (
        <div className={ styles['chat-container-header'] }>
          <NavBar />
        </div>
      )}
      <ChatBody />
    </div>
  );
};

export default memo(AiChatBotMainView);
