import React from 'react';

import classNames from 'classnames';

import { formatCount } from '@src/utils/count_formatter';

import ChatBubbleIcon from '@src/components/utils/icomoon/chat-bubble';

import styles from './style.module.scss'; // Import the styles

interface ChatIconWithBadgeProps {
  unreadCount: number;
}

const ChatIconWithBadge: React.FC<ChatIconWithBadgeProps> = ({ unreadCount }) => {
  return (
    <div className={ styles['cell-icon-container'] }>
      <ChatBubbleIcon
        className={ classNames(
          styles['bubble-chat-icon'],
          { [styles['bubble-chat-icon--active']]: unreadCount > 0 },
        ) }
        fontSize={ 28 }
      />
      {unreadCount > 0 && (
        <span className={ classNames(styles['badge-count'], 'badge') }>
          {formatCount(unreadCount)}
        </span>
      )}
    </div>
  );
};

export default ChatIconWithBadge;
