import React from 'react';

import { TID } from '@src/types/common';

import Filter from '@src/components/ui_v2/filter';

import ChartOfAccountFilterField from './fields/chart_of_account_filter_field';
import PaymentAccountFilterField from './fields/payment_account_filter_field';
import { PaymentMethodOptions, PaymentStatusOptions, DocumentTypeOptions } from '../options/payment_options';

interface VendorPaymentsFilterProps {
  businessId: TID;
}

const VendorPaymentsFilter: React.FC<VendorPaymentsFilterProps> = ({ businessId }) => {
  return (
    <Filter.TableContainer>
      <Filter.DateField
        name="payment_date"
        placeholder="Payment Date"
      />
      <Filter.TextField
        name="invoice_number"
        placeholder="Invoice Number"
        showTooltip={ false }
      />
      <Filter.TextField
        name="document_id"
        placeholder="Docyt ID"
        showTooltip={ false }
      />
      <Filter.DateField
        name="document_date"
        placeholder="Doc Date"
      />
      <Filter.SelectField
        name="payment_method"
        options={ PaymentMethodOptions }
        placeholder="Payment Method"
      />
      <Filter.SelectField
        name="payment_status"
        options={ PaymentStatusOptions }
        placeholder="Payment Status"
      />
      <PaymentAccountFilterField
        businessId={ businessId }
        placeholder="Payment Account"
      />
      <ChartOfAccountFilterField
        businessId={ businessId }
        name="chart_of_account"
      />
      <Filter.SelectField
        name="document_type"
        options={ DocumentTypeOptions }
        placeholder="All Docs"
      />
    </Filter.TableContainer>
  );
};

export default React.memo(VendorPaymentsFilter);
