import React, { useCallback, useState, Dispatch, SetStateAction } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import Form from './form';
import { IImportMappingValues } from './schema';

interface IImportMappingModalProps extends IUseModalProps {
  setReportTypeId: Dispatch<SetStateAction<number>>;
}

const ImportMappingModal = ({
  isOpen,
  onDone,
  onCancel,
  setReportTypeId,
  ...props
}: IImportMappingModalProps) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const ImportExistingMapping = useCallback((data: IImportMappingValues) => {
    // TODO Create function for get data existing mapping
    console.log(data);
    setReportTypeId(data.revenueReportTypeId);
    onDone();
  }, [onDone]);

  return (
    <Modal
      show={ isOpen }
      title="Import An Existing Mapping"
      { ...props }
    >
      <>
        <Modal.Body>
          <p>Choose a Business and Mapping to import</p>
          <Form
            formId="select_existing_mapping_form"
            onSubmit={ ImportExistingMapping }
            onValidationChange={ setIsFormValid }
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Back
          </Button>
          <Button
            disabled={ !isFormValid }
            form="select_existing_mapping_form"
            type="submit"
            variant="primary"
          >
            Import
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

const useImportMappingModal = makeUseModal(ImportMappingModal);

export {
  useImportMappingModal,
  ImportMappingModal as default,
};
