import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const MultipleFilesIcon = ({ ...props }: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="multiple-files"
      pathsNumber={ 16 }
    />
  );
};

export default MultipleFilesIcon;
