/* eslint-disable no-unreachable-loop */

import React, { useEffect, useMemo, useState } from 'react';

import { useAtom } from 'jotai';

import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetBudgetsComparison } from '@src/hooks/queries/report_service/custom_reports';
import { IReport, IReportBudgetComparison } from '@src/types/report_service/report';
import { IReportFilter } from '@src/types/report_service/report_configurations';

import { IReportDatasCollection } from '@src/components/business_reports/custom_reports/hooks';
import { IFilterFieldUIProps, useFilterFieldProps } from '@src/components/ui/filter';
import { multiSelectAddValueType, budgetsSelectAddValueType, forecastSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import Wrapper from '@src/components/ui_v2/filter/filter_field_wrapper';
import { TSubOption } from '@src/components/ui_v2/inputs';

import BusinessReportsMultiSelect from './multi_select/multi_select';

interface IMultiColumnAddFieldProps
  extends Omit<React.ComponentPropsWithoutRef<typeof BusinessReportsMultiSelect>, 'options' | 'value' | 'onChange'>,
    IFilterFieldUIProps {
  reportFilters:  IReportFilter[],
  report:         IReport,
  collection?:     IReportDatasCollection,
  placeholder?:   string,
  kind?:          string,
  comparerIds?:   string[]
}

const MultiColumnAddField = ({
  reportFilters,
  report,
  collection,
  placeholder,
  kind,
  comparerIds,
  ...props
}: IMultiColumnAddFieldProps) => {
  const [wrapperProps, inputProps] = useFilterFieldProps(props);
  const [multiValue, setMultiValue] = useAtom(multiSelectAddValueType);
  const [budgetsSelectValue, setBudgetsSelectValue] = useAtom(budgetsSelectAddValueType);
  const [forecastSelectValue, setForecastSelectValue] = useAtom(forecastSelectAddValueType);
  const reportService = useReportServiceContext();
  const [ids, setIds] = useState<string[]>([]);

  useEffect(() => {
    if (localStorage.getItem(`multiValue-${report?.id}`)) {
      setMultiValue(JSON.parse(localStorage.getItem(`multiValue-${report?.id}`) as string));
    }

    if (localStorage.getItem(`budgetsSelectValue-${report?.id}`)) {
      setBudgetsSelectValue(JSON.parse(localStorage.getItem(`budgetsSelectValue-${report?.id}`) as string));
    }

    if (localStorage.getItem(`forecastSelectValue-${report?.id}`)) {
      setForecastSelectValue(JSON.parse(localStorage.getItem(`forecastSelectValue-${report?.id}`) as string));
    }

    if (kind === 'reportDetail') {
      if (collection?.records && collection?.records?.length !== 0) {
        if (collection.records[0].businessForecastIds && collection.records[0].businessBudgetIds) {
          setIds([...collection.records[0].businessForecastIds, ...collection.records[0].businessBudgetIds]);
        }
      }
    }

    if (kind === 'reportOtherDetail' && comparerIds) {
      setIds(comparerIds);
    }
  }, [collection, comparerIds, kind]);

  const budgetComparison = useGetBudgetsComparison({
    report_service_id: reportService?.id,
    'report_comparer_ids[]': ids as string[],
  });

  const childBudgetItems = useMemo(() => {
    const children: { label: string; value: string }[] = [];

    if (kind === 'reportDetail') {
      budgetComparison?.data?.forEach((item: IReportBudgetComparison) => {
        if (collection?.records) {
          for (let index = 0; index < collection?.records?.[0]?.businessBudgetIds?.length; index += 1) {
            const id = collection?.records?.[0]?.businessBudgetIds?.[index];
    
            if (item.id === id) {
              const val = item.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : '';
              children.push({
                label: `${val}-${item?.year}`,
                value: item?.id,
              }); 
            }
          }
        }
      });
    }

    if (kind === 'reportOtherDetail' && comparerIds) {
      budgetComparison?.data?.forEach((item: IReportBudgetComparison) => {
        for (let index = 0; index < comparerIds.length; index += 1) {
          const id = comparerIds[index];
  
          if (item.id === id && item.type === 'Budget') {
            const val = item.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : '';
            children.push({
              label: `${val}-${item?.year}`,
              value: item?.id,
            }); 
          }
        }
      });
    }

    return children;
  }, [budgetComparison, collection?.records, comparerIds, kind]);

  const childForecastItems = useMemo(() => {
    const children: { label: string; value: string }[] = [];

    if (kind === 'reportDetail') {
      budgetComparison?.data?.forEach((item: IReportBudgetComparison) => {
        if (collection?.records) {
          for (let index = 0; index < collection?.records?.[0]?.businessForecastIds?.length; index += 1) {
            const id = collection?.records?.[0]?.businessForecastIds?.[index];
    
            if (item.id === id) {
              const val = item.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : '';
              children.push({
                label: `${val}-${item?.year}`,
                value: item?.id,
              }); 
            }
          }
        }
      });
    }

    if (kind === 'reportOtherDetail' && comparerIds) {
      budgetComparison?.data?.forEach((item: IReportBudgetComparison) => {
        for (let index = 0; index < comparerIds.length; index += 1) {
          const id = comparerIds[index];
  
          if (item.id === id && item.type === 'Forecast') {
            const val = item.name ? item.name.charAt(0).toUpperCase() + item.name.slice(1) : '';
            children.push({
              label: `${val}-${item?.year}`,
              value: item?.id,
            }); 
          }
        }
      });
    }

    return children;
  }, [budgetComparison?.data, collection?.records, comparerIds, kind]);

  const options = useMemo(() => {
    const retData: TSubOption[] = [];

    for (let i = 0; i < reportFilters.length; i += 1) {
      const filter = reportFilters[i];

      if (report.version === 2) {
        for (let j = 0; j < filter.columnFilter.length; j += 1) {
          let isPush = false;
          const colFilter = filter.columnFilter[j];

          for (let k = 0; k < report.ptdColumns.length; k += 1) {
            const column = report.ptdColumns[k];

            if (colFilter.id === column.identifier) {
              if (colFilter.id.search('budget') !== -1) {
                retData.push({
                  label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                  value: filter.id,
                  children: childBudgetItems,
                });
                isPush = true;
                break;
              }

              if (colFilter.id.search('forecast') !== -1) {
                retData.push({
                  label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                  value: filter.id,
                  children: childForecastItems,
                });
                isPush = true;
                break;
              }

              retData.push({
                label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                value: filter.id,
              });
              isPush = true;
              break;
            }
          }

          if (isPush) break;
        }
      } else {
        for (let j = 0; j < filter.columnFilter.length; j += 1) {
          let isPush = false;
          const colFilter = filter.columnFilter[j];
  
          for (let k = 0; k < report.ptdColumns.length; k += 1) {
            const column = report.ptdColumns[k];
  
            if (colFilter.type === column.type) {
              if (colFilter.type.search('budget') !== -1) {
                retData.push({
                  label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                  value: filter.id,
                  children: childBudgetItems,
                });
                isPush = true;
                break;
              }
  
              if (colFilter.type.search('forecast') !== -1) {
                retData.push({
                  label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                  value: filter.id,
                  children: childForecastItems,
                });
                isPush = true;
                break;
              }
  
              retData.push({
                label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                value: filter.id,
              });
              isPush = true;
              break;
            }
  
            if (colFilter.perMetric === column.perMetric) {
              retData.push({
                label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                value: filter.id,
              });
              isPush = true;
              break;
            }
  
            if (colFilter.year === column.year) {
              retData.push({
                label: filter.name.charAt(0).toUpperCase() + filter.name.slice(1),
                value: filter.id,
              });
              isPush = true;
              break;
            }
          }
          
          if (isPush) break;
        }
      }
    }

    return retData;
  }, [childBudgetItems, childForecastItems, report.ptdColumns, reportFilters, report.version]);

  const handleChange = (selectedOptions: string[]) => {
    setMultiValue(selectedOptions);
    localStorage.setItem(`multiValue-${report.id}`, JSON.stringify(selectedOptions));
  };

  const handleBudgetsSelectChange = (selectedOptions: string[]) => {
    setBudgetsSelectValue(selectedOptions);
    localStorage.setItem(`budgetsSelectValue-${report.id}`, JSON.stringify(selectedOptions));
  };

  const handleForecastSelectChange = (selectedOptions: string[]) => {
    setForecastSelectValue(selectedOptions);
    localStorage.setItem(`forecastSelectValue-${report.id}`, JSON.stringify(selectedOptions));
  }

  return (
    <Wrapper { ...wrapperProps }>
      <BusinessReportsMultiSelect
        budgetsSelectValue={ budgetsSelectValue }
        forecastSelectValue={ forecastSelectValue }
        { ...inputProps }
        kind={ kind }
        options={ options }
        placeholder={ placeholder }
        value={ multiValue }
        onBudgetsSelectChange={ handleBudgetsSelectChange }
        onChange={ handleChange }
        onForecastSelectChange={ handleForecastSelectChange }
      />
    </Wrapper>
  );
}

export default MultiColumnAddField;