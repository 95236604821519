import React from 'react';

import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon/qbo_status_icon';
import { Badge } from '@src/components/ui_v2/badge/badge';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';
import AccountsPayableIcon from '@src/components/utils/icomoon/accounts_payable';

import CopyDocumentLink from './copy_document_link';

import styles from './styles.module.scss';

interface IDocumentHeaderProps {
  document: IDocument;
  serviceDocument: IAccountsPayableServiceDocument;
  onClose?: () => void;
}

const DocumentHeader = ({ document, serviceDocument, onClose }: IDocumentHeaderProps) => {
  return (
    <div className={ styles['document-header'] }>
      <div className={ styles['document-header-left'] }>
        <div>
          <AccountsPayableIcon className={ styles['document-icon'] } fontSize={ 22 } />
        </div>
        <div className={ styles['document-business-name'] }>
          { document.businesses[0].displayName }
          {' '}
          /
        </div>
        <div className={ styles['document-document-id'] }>
          <Badge
            label={ document.docytId }
            variant="secondary"
          />
        </div>
        <div>
          <CopyDocumentLink document={ document } />
        </div>
      </div>
      <div className={ styles['document-header-right'] }>
        <QBOStatusIcon
          linkToQBO
          showLabel
          skipQboLinkForIsSynced
          fontSize={ 18 }
          model={ {
            qboSyncStatus: serviceDocument.qboBadges.qboMainBadgeStatus,
            qboError:      serviceDocument.qboError,
            qboLink:       serviceDocument.qboLink,
          } }
        />
        { onClose && (
          <NotAChargebackIcon
            pointer
            color="secondary"
            fontSize={ 18 }
            onClick={ onClose }
          />
        ) }
      </div>
    </div>
  );
};

export default React.memo(DocumentHeader);
