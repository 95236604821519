import { useMemo } from 'react';

import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import SendBird from 'sendbird';

const sendbirdInstance = new SendBird({
  appId: window.configData.ai_chatbot.app_id,
});

const useSendbirdHelper = () => {
  const context = useSendbirdStateContext();
  const memoizedContext = useMemo(() => context, [context]);
  const memoizedSdk = useMemo(
    () => sendbirdSelectors.getSdk(memoizedContext),
    [memoizedContext],
  );

  return {
    sendbirdInstance,
    context: memoizedContext,
    sdk:     memoizedSdk,
  };
};

export default useSendbirdHelper;
