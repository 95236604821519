import React, { useMemo } from 'react';

import classNames from 'classnames';

import { currencyLocaleFormatter, localeFormatter } from '@src/utils/currency';

interface ICriticalNumberViewProps {
  className?: string,
  criticalNumber: number,
  unit?: string,
}

const CriticalNumberView = ({
  className,
  criticalNumber,
  unit,
}: ICriticalNumberViewProps) => {
  const classes = classNames(className);

  const resultNumber = useMemo(() => {
    switch (unit) {
      case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.DOLLAR:
        return currencyLocaleFormatter(criticalNumber);
      case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.PERCENT:
        return `${localeFormatter((criticalNumber / 100), 'percent')}`;
      case window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.COUNT:
        return `${localeFormatter(criticalNumber)}`;
      default:
        return `${localeFormatter(criticalNumber)}`;
    }
  }, [unit, criticalNumber]);

  return (
    <span className={ classes }>
      { resultNumber }
    </span>
  );
};

export default CriticalNumberView;
