import React, { useCallback, useEffect } from 'react';

import { useDisconnectIntegration } from '@src/hooks/queries/integrations/integrations';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { useDropdown } from '@src/components/ui_v2/dropdown';
import { PauseCircleIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

interface DisconectIntegrationActionProps {
  integrationId: TID,
}

const DisconectIntegrationAction = ({
  integrationId,
}: DisconectIntegrationActionProps) => {
  const popover = useDropdown();
  const disconnect = useDisconnectIntegration();
  const confirmModal = useConfirmDeleteModal({
    onDone: useCallback(() => {
      disconnect.mutate({ integrationId });
    }, [disconnect, integrationId]),
  });

  useEffect(() => {
    if (confirmModal.props.isOpen && popover) {
      popover.hide();
    }
  }, [confirmModal.props.isOpen, popover]);

  return (
    <>
      <MutationStatus
        mutation={ disconnect }
        successMessage="Integration has been disconnected"
        successTitle="Delete Successful"
      />
      <confirmModal.Component
        confirmTitle="Disconnect"
        text={ (
          <span>
            Disconnecting this revenue system will convert it into a Manual Revenue System. Do you want to proceed?
          </span>
      ) }
        title="Confirmation"
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <PauseCircleIcon fontSize={ 20 } /> }
        title="Disconnect"
        onSelect={ confirmModal.open }
      />
    </>
  );
};

export default React.memo(DisconectIntegrationAction);
