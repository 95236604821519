import React, { useMemo, useState } from 'react';

import ConfigForm from '@src/components/operational_dashboard/config_form';
import { TViewOperationalDashboard } from '@src/components/operational_dashboard/types';
import { Button } from '@src/components/ui_v2/buttons';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import { OdConfigIcon, SwitchViewIcon } from '@src/components/utils/icomoon';

import { useAddBusinessClientModal } from './add_business_client/add_business_client_modal';
import { useAddInviteClientModal } from './add_invite_client/add_invite_client_modal';

import styles from './styles.module.scss';

interface IHeaderProps {
  switchToNewDashboard?: boolean,
  isMultiBusinessDashboardEnabled: boolean,
  searchQuery?: string,
  onSearched: (value: string) => void,
  onSwitchToNewDashboard: () => void,
}

const Header = ({
  switchToNewDashboard = false,
  isMultiBusinessDashboardEnabled = false,
  searchQuery,
  onSearched,
  onSwitchToNewDashboard,
}: IHeaderProps): JSX.Element => {
  const isAccountantUser: TViewOperationalDashboard = window.Docyt.currentAdvisor.isBookgptAccountantUser()
    ? 'accountant'
    : 'business';

  const [open, setOpen] = useState<boolean>(false);
  const handleShow = () => setOpen(true);
  const handleHide = () => setOpen(false);

  const addBusinessClientModal = useAddBusinessClientModal();
  const addInviteClientModal = useAddInviteClientModal();

  const canSwitchToNewDashboard = useMemo(() => {
    return window.Docyt.currentAdvisor.isPremiumFirmClosingSuite() && isMultiBusinessDashboardEnabled;
  }, [isMultiBusinessDashboardEnabled]);

  return (
    <>
      <addBusinessClientModal.Component
        { ...addBusinessClientModal.props }
      />
      <addInviteClientModal.Component
        { ...addInviteClientModal.props }
      />
      <div className={ styles['header-region'] }>
        <div className={ styles['header-region-right'] }>
          {!switchToNewDashboard && (
            <>
              <SearchInput
                value={ searchQuery }
                onChange={ onSearched }
              />
              <div className={ styles['horizontal-divider'] } />
            </>
          )}
          {canSwitchToNewDashboard && (
            <div className={ styles['switch-view-button-container'] }>
              <Button
                className={ styles['switch-view-button'] }
                variant="outline"
                onClick={ onSwitchToNewDashboard }
              >
                <SwitchViewIcon className={ styles['switch-view-icon'] } fontSize={ 24 } />
                {switchToNewDashboard ? 'Switch to Old View' : 'Try the New View'}
              </Button>
            </div>
          )}
          {switchToNewDashboard && (
            <div className={ styles['od-config-icon-container'] }>
              <OdConfigIcon
                fontSize={ 20 }
                onClick={ handleShow }
              />
              <ConfigForm handleHide={ handleHide } open={ open } />
            </div>
          )}

          { isAccountantUser === window.configData.accountant_user_onboarding_status && (
            <Button
              variant="primary"
              onClick={ addInviteClientModal.open }
            >
              Add&nbsp;Client
            </Button>
          ) }
        </div>
      </div>
    </>
  );
};

export default Header;
