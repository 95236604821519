import React from 'react';

import { Routes, Route } from 'react-router-dom';

import InvoiceQueue from './invoice_queue';
import DocumentViewPage from '../documents/document_view_page';

const InvoiceQueueRouter = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/businesses/:businessId/accounts_payable/new_queue">
        <Route element={ <InvoiceQueue /> } path=":queueType" />
        <Route index element={ <InvoiceQueue /> } />
      </Route>
      <Route element={ <DocumentViewPage /> } path="/businesses/:businessId/accounts_payable/documents/:documentId" />
    </Routes>
  );
};

export default React.memo(InvoiceQueueRouter);
