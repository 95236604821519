import React from 'react';

import moment from 'moment';
import { useSetAtom } from 'jotai';

import { useBankAccountReconciliationContext } from '@src/hooks/contexts/bank_account_reconciliation_context';
import { useBankStatementContext } from '@src/hooks/contexts/bank_statement_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useCreateDataExport } from '@src/hooks/queries/data_exports';
import { IDataExportData } from '@src/types/data_exports';
import { MOMENT_DATE_FORMAT } from '@src/utils/date_helpers';

import { isExportCSVCreatedFamily } from '@src/components/common/actions/export_csv/atoms';
import { ErrorNotification } from '@src/components/ui/notification';
import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { IIcomoonProps } from '@src/components/utils/icomoon/icomoon';

import DownloadDocumentIcon from '../../../utils/icomoon/download_document';
import { getReconciliationDateRange } from '../utils';

const exportMap: { [key: string]: IDataExportData } = {
  transactions_pushed_by_outside: {
    name:       'Transactions pushed to GL outside of Docyt',
    exportType: 'export_transactions_pushed_by_outside_csv',
  },
  uncleared_bill_payments: {
    name:       'Uncleared Expense and Revenue',
    exportType: 'export_uncleared_expense_and_revenue_csv',
  },
  uncleared_transfers: {
    name:       'Uncleared Transfers',
    exportType: 'export_uncleared_transfers_csv',
  },
  uncleared_bill_payments_current: {
    name:       'Uncleared Expense and Revenue without Carried-Over',
    exportType: 'export_uncleared_expense_and_revenue_current_csv',
  },
  uncleared_transfers_current: {
    name:       'Uncleared Transfers without Carried-Over',
    exportType: 'export_uncleared_transfers_current_csv',
  },
  excluded_transactions: {
    name:       'Excluded Transactions',
    exportType: 'export_transactions_csv',
  },
  not_pushed_transactions: {
    name:       'Transactions not pushed to the ledger',
    exportType: 'export_transactions_csv',
  },
  transactions_pushed_by_docyt: {
    name:       'Transactions pushed to GL by Docyt',
    exportType: 'export_transactions_pushed_by_docyt_csv',
  },
  additional_uncleared_balance: {
    name:       'Additional Uncleared Balance',
    exportType: 'export_additional_uncleared_balance_csv',
  },
  uncategorized_transactions: {
    name:       'Uncategorized Transactions',
    exportType: 'export_transactions_csv',
  },
  flagged_transactions: {
    name:       'Flagged Transactions',
    exportType: 'export_transactions_csv',
  },
  unmatched_transfers: {
    name:       'Unmatched Transfers',
    exportType: 'export_transactions_csv',
  },
};

const columnMappings: [string, string][] = [
  ['transaction_date', 'Date'],
  ['transaction_type', 'Txn Type'],
  ['docyt_id', 'Ref Num'],
  ['name', 'Name [Class]'],
  ['vendor_name', 'Vendor'],
  ['memo', 'Memo'],
  ['split', 'Split'],
  ['amount', 'Amount'],
];

interface DownloadIconProps {
  type: string;
  exportParams: object;
  columnMappings?: [string, string][];
  fontSize?: number;
  inColor?: IIcomoonProps['inColor'];
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
  type,
  exportParams,
  fontSize = 12,
  inColor = 'grey-500',
  ...props
}) => {
  const reconciliation = useBankAccountReconciliationContext();
  const bankStatement = useBankStatementContext();
  // eslint-disable-next-line max-len
  const [startDate, endDate] = getReconciliationDateRange(reconciliation, bankStatement).map((it) => moment(it).format(MOMENT_DATE_FORMAT));

  const { id: businessId } = useBusinessContext();
  const setIsCreated = useSetAtom(isExportCSVCreatedFamily('monthEndTransactions'));

  const mutation = useCreateDataExport();
  const { mutate, isLoading, error } = mutation;

  const handleClick = () => {
    const { name, exportType } = exportMap[type];
    mutate({
      businessIds: [businessId],
      dataExport:  {
        name,
        exportType,
        columnMappings: props.columnMappings || columnMappings,
        exportParams,
        startDate,
        endDate,
      },
    }, {
      onSuccess: () => setIsCreated(true),
    });
  };

  if (isLoading) return <SpinnerIcon spin fontSize={ fontSize } />;

  return (
    <>
      { error && <ErrorNotification error={ error } />}
      <DownloadDocumentIcon
        pointer
        fontSize={ fontSize }
        inColor={ inColor }
        onClick={ handleClick }
      />
    </>
  );
};

export default DownloadIcon;
