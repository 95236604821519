import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Footer from './footer';
import Header from './header';
import Provider from './provider';

import styles from './styles.module.scss';

interface IDocumentViewProps {
  children: React.ReactNode;
}

const DocumentView = ({ children }: IDocumentViewProps): JSX.Element => {
  const documentViewLayoutContentRef = useRef<HTMLDivElement>(null);
  const [documentViewLayoutContentSize, setDocumentViewLayoutContentSize] = useState<{ width: number, height: number }>(
    { width: 0, height: 0 },
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (documentViewLayoutContentRef.current) {
        setDocumentViewLayoutContentSize({
          width:  documentViewLayoutContentRef.current.offsetWidth,
          height: documentViewLayoutContentRef.current.offsetHeight,
        });
      }
    };
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const childrenArray = React.Children.toArray(children) as ReactElement[];

  // Separate the header and footer from the rest of the children
  const header = childrenArray.find((child) => child.type === Header);
  const footer = childrenArray.find((child) => child.type === Footer);
  const content = childrenArray.filter((child) => child.type !== Header && child.type !== Footer);

  return (
    <Provider parentElementSize={ documentViewLayoutContentSize }>
      <div className={ styles['document-view-layout'] }>
        {header}
        <div ref={ documentViewLayoutContentRef } className={ styles['document-view-layout-content'] }>
          {content}
        </div>
        {footer}
      </div>
    </Provider>
  );
};

export default React.memo(DocumentView);
