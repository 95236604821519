import React, { createContext, useContext } from 'react';

interface IDocumentViewWidthContextProps {
  documentWidthPercentage: number;
  setDocumentWidthPercentage: React.Dispatch<React.SetStateAction<number>>;
  parentElementSize: { width: number, height: number };
}

const DocumentViewWidthContext = createContext<IDocumentViewWidthContextProps | null>(null);

const useDocumentViewWidth = (): IDocumentViewWidthContextProps => {
  const context = useContext(DocumentViewWidthContext);

  if (!context) {
    throw new Error('useDocumentViewWidth must be used within a DocumentViewWidthProvider');
  }

  return context;
};

export { DocumentViewWidthContext, useDocumentViewWidth, IDocumentViewWidthContextProps };
