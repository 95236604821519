/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';
import { useSplitCategory } from '@src/components/reconciliation_center/all_transactions/sidebar/transactionType/use_split_category';
import CategorySplitsField from '@src/components/reconciliation_center/match_documents/add_adjustment/category_splits_field';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '../../styles.module.scss';

interface IOtherIncomeProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
  Verify: (boolean: boolean) => void;
  setRowData: (data: ITransactionServiceDocument) => void;
}

const OtherIncome = ({ businessId, rowData, Verify, setRowData }: IOtherIncomeProps) => {
  const [categorySplits, setCategorySplits] = useState<ICategorySplit[]>([]);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);

  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } =
    useTxnDoc({ rowData, setRowData });

  const {
    isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo,
  } = commonProps;

  const createServiceDocumentSplits = useCreateServiceDocumentSplits();

  const { getCategorySplits, handleCategorySplitsChange, categoryLength } = useSplitCategory({
    rowData,
    businessId,
    setCategorySplits,
    setSuccessMsg,
  });

  useEffect(() => {
    const loadCategorySplits = async () => {
      const splits = await getCategorySplits();
      setCategorySplits(splits);
    };
    loadCategorySplits();
  }, [getCategorySplits]);

  useEffect(() => {
    let disabled = true;
    if (((noDocumentNeeded && documentNote !== '') || !noDocumentNeeded) && categoryLength > 0) {
      disabled = false;
    }

    Verify(disabled);
  }, [categoryLength, noDocumentNeeded, documentNote, Verify]);

  return (
    <div key={ rowData.id }>
      {isSubmitting && <Spinner />}
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <div className={ styles['sidebar-data'] }>
          <CategorySplitsField
            isBusinessReadonly
            adjustmentAmount={ rowData.amount }
            isReadonly={ isDisabled }
            label="Category*"
            queryParams={ { searchAccType: ['Other Income', 'Income'] } }
            summaryTitle="Invoice"
            value={ categorySplits }
            onChange={ handleCategorySplitsChange }
          />
        </div>

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </div>
  );
};

export default OtherIncome;
