import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpsertIntegration } from '@src/hooks/queries/integrations/integrations';
import { useCreateRevenueReportType } from '@src/hooks/queries/revenue_report_types';
import { useUpdateRevenueSystem } from '@src/hooks/queries/revenue_systems';
import { IUpsertIntegrationResponse } from '@src/requests/integrations/integrations';
import { ICreateRevenueReportTypeResponse } from '@src/requests/revenue_report_types';
import { revenueServiceMappingPath } from '@src/routes';
import { TID } from '@src/types/common';
import { IIntegration } from '@src/types/integrations/integrations';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';
import { Button } from '@src/components/ui_v2/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAddReportTypeValues } from './schema';
import { usePullIntegrationSuccessModal }
  from '../../new_system/pull_integration_connect/pull_integration_success_modal';
import { usePushIntegrationSuccessModel } from
  '../../new_system/push_integration_connect/push_integration_success_model';
import {
  useChangeMappingRevenueReportModal,
} from '../../revenue_capture/modal/change_mapping_revenue_report_action_modal';

interface IAddReportTypeModalProps extends IUseModalProps {
  changeMapping?: boolean,
  integration?: IIntegration,
  isConnect?: string,
  UpsertIntegrationResponse?: IUpsertIntegrationResponse | null,
  revenueService?: IRevenueService,
  revenueSystem?: IRevenueSystem,
}

const AddReportTypeModal = ({
  isOpen,
  onDone,
  onCancel: onCloseModal,
  changeMapping = false,
  integration,
  isConnect,
  UpsertIntegrationResponse,
  revenueSystem,
  ...props
}: IAddReportTypeModalProps) => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();

  const mutation = useCreateRevenueReportType();
  const { mutate: createRevenueReportType } = mutation;

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const updateRevenueSystem = useUpdateRevenueSystem();
  const { mutate: updateRevenueSystemMutate } = updateRevenueSystem;

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const changeMappingRevenueReportModal = useChangeMappingRevenueReportModal();
  const pullIntegrationSuccessModel = usePullIntegrationSuccessModal();
  const pushIntegrationSuccessModel = usePushIntegrationSuccessModel();

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, [setErrorMessage]);

  const handleSuccess = useCallback((revenueReportTypeId: TID) => {
    const url = revenueServiceMappingPath(business.id, revenueReportTypeId);
    Backbone.history.navigate(url, { trigger: true });
    onDone();
  }, [business.id, onDone]);

  const handleSubmit = useCallback(
    (values: IAddReportTypeValues) => {
      createRevenueReportType(
        {
          revenueId:         revenueService.id,
          revenueReportType: {
            name: values.name,
          },
        },
        {
          onSuccess: (data: ICreateRevenueReportTypeResponse) => {
            if (integration) {
              mutate({
                businessId:  business.id,
                integration: {
                  externalSystemId:     integration.externalSystemId,
                  startDate:            integration.externalSystemConfig?.startDate?.toString() || '',
                  revenueReportTypeId:  data.revenueReportType.id,
                  externalSystemConfig: {},
                  location:             integration.externalSystemConfig?.location?.toString() || '',
                  id:                   integration.id || undefined,
                },
              }, {
                onSuccess: () => {
                  handleSuccess(data.revenueReportType.id);
                },
                onError: handleError,
              });
            } else if (revenueSystem) {
              updateRevenueSystemMutate({
                revenueServiceId: revenueService.id,
                id:               revenueSystem.id,
                data:             {
                  revenueReportTypeId: data.revenueReportType.id,
                },
              }, {
                onSuccess: () => {
                  handleSuccess(data.revenueReportType.id);
                },
                onError: handleError,
              });
            } else {
              handleSuccess(data.revenueReportType.id);
            }
          },
          onError: handleError,
        },
      );
    },
    [
      createRevenueReportType,
      revenueService.id,
      handleError,
      handleSuccess,
      integration,
      business.id,
      mutate,
      revenueSystem,
      updateRevenueSystemMutate,
    ],
  );

  const handleCancel = useCallback(() => {
    onCloseModal();
    if (changeMapping) {
      changeMappingRevenueReportModal.open();
    }
    if (isConnect === 'pull') {
      pullIntegrationSuccessModel.open();
    }
    if (isConnect === 'push') {
      pushIntegrationSuccessModel.open();
    }
  }, [
    onCloseModal,
    changeMapping,
    changeMappingRevenueReportModal,
    isConnect,
    pushIntegrationSuccessModel,
    pullIntegrationSuccessModel,
  ]);

  return (
    <>
      <MutationStatus mutation={ mutation } />
      {errorMessage && <ErrorNotification message={ errorMessage } title="Mapping could not be created." />}
      <Modal
        show={ isOpen }
        title={ changeMapping ? 'Name New Mapping' : 'Add New Revenue Mapping' }
        { ...props }
      >
        <>
          <Modal.Body>
            <Form
              changeMapping={ changeMapping }
              formId="add_report_type_form"
              onSubmit={ handleSubmit }
            />
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ handleCancel }
            >
              Go Back
            </Button>
            <Button
              form="add_report_type_form"
              type="submit"
              variant="primary"
            >
              Save and Next
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      {changeMapping && integration && (
        <changeMappingRevenueReportModal.Component
          integration={ integration }
          revenueService={ revenueService }
          revenueSystem={ revenueSystem }
          { ...changeMappingRevenueReportModal.props }
        />
      )}
      {isConnect === 'pull' && UpsertIntegrationResponse && (
        <pullIntegrationSuccessModel.Component
          UpsertIntegrationResponse={ UpsertIntegrationResponse }
          { ...pullIntegrationSuccessModel.props }
        />
      )}
      {isConnect === 'push' && UpsertIntegrationResponse && (
        <pushIntegrationSuccessModel.Component
          UpsertIntegrationResponse={ UpsertIntegrationResponse }
          { ...pushIntegrationSuccessModel.props }
        />
      )}
    </>
  );
};

const useAddReportTypeModal = makeUseModal(AddReportTypeModal);

export {
  useAddReportTypeModal,
  AddReportTypeModal as default,
};
