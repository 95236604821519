import React, { useMemo } from 'react';

import { TAuditScanStatus } from '@src/types/operational_dashboard/audit_scan';

import AnomaliesModal from '@src/components/operational_dashboard/components/custom_cells/anomalies_modal';
import HoverTooltipWrapper from '@src/components/operational_dashboard/components/custom_cells/hover_tooltip_wrapper';
import NumberBox from '@src/components/operational_dashboard/components/custom_cells/number_box';
import AuditScanTooltip from '@src/components/operational_dashboard/components/tooltips/audit_scan_tooltip';
import { IListItem } from '@src/components/operational_dashboard/types';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import { CheckMarkIcon } from '@src/components/utils/icomoon';

interface IReconciliationItemProps {
  businessName?: string;
  tooltipData: IListItem
  value: number;
  lastAuditedOn?: string | number;
  auditScanStatus: TAuditScanStatus;
  disconnectedGeneralLedger: boolean;
  businessId?: number
}

const AuditScanItem = ({
  businessName,
  tooltipData,
  value,
  lastAuditedOn,
  auditScanStatus,
  disconnectedGeneralLedger,
  businessId,
}: IReconciliationItemProps) => {
  const { isOpen, open, close } = useDrawer();

  const renderValue = useMemo(() => {
    if (disconnectedGeneralLedger) {
      return <NumberBox value={ { value: true, type: 'indicator' } } />;
    }

    if (auditScanStatus === 'completed' && value === 0) {
      return <CheckMarkIcon color="gray" fontSize={ 20 } />;
    }

    return <NumberBox isWarning={ value > 0 } value={ { value, type: 'number' } } />;
  }, [value, auditScanStatus, disconnectedGeneralLedger]);

  return (
    <>
      <HoverTooltipWrapper
        content={ (
          value !== undefined && (
            <AuditScanTooltip
              auditScanCount={ value }
              businessName={ businessName }
              item={ tooltipData }
              lastAuditedOn={ lastAuditedOn }
              openModal={ open }
            />
          )
        ) }
      >
        {renderValue}
      </HoverTooltipWrapper>
      { value > 0 && isOpen && (
        <AnomaliesModal
          businessId={ businessId }
          businessName={ businessName }
          close={ close }
          isOpen={ isOpen }
          lastAuditedOn={ lastAuditedOn }
        />
      )}
    </>
  );
};

export default AuditScanItem;
