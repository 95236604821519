import React from 'react';

import { useAtom } from 'jotai';

import { Button } from '@src/components/ui_v2/buttons';

import { toggleViewState } from '../atoms';

const ToggleViewAction = (): JSX.Element => {
  const [isChartView, setIsChartView] = useAtom(toggleViewState);

  const handleViewToggle = () => {
    setIsChartView(!isChartView);
  };

  const title = isChartView ? 'Data Table' : 'Visual Analytics';

  return (
    <Button
      variant="outline"
      onClick={ handleViewToggle }
    >
      { title }
    </Button>
  );
};

export default ToggleViewAction;
