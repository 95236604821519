import React, { memo, useCallback } from 'react';

import { flatten } from 'lodash';
import { InfiniteData } from 'react-query';
import { useAtom } from 'jotai';

import toastr from '@lib/toastr';
import { useScroll } from '@src/hooks/contexts/ai_chatbot_scroll_context';
import {
  IGlobalThreadListResponse,
  useGetListOfThreads,
} from '@src/hooks/queries/ai_chat/ai_chatbot';
import { useInfiniteScroll } from '@src/hooks/scroll';

import { historyItemsAtom } from '@src/components/ai_chatbot/atoms';
import ChatListSection from '@src/components/ai_chatbot/components/chat_list_section';

export const RecentChats = () => {
  const { chatThreadsInfiniteScrollRef } = useScroll();
  const [historyItems, setHistoryItems] = useAtom(historyItemsAtom);

  const handleThreadListSuccess = useCallback(
    (data: InfiniteData<IGlobalThreadListResponse>) => {
      setHistoryItems((prevHistoryItems) => ({
        ...prevHistoryItems,
        data: flatten(data.pages.map((page) => page.collection)),
      }));
    },
    [setHistoryItems],
  );

  const handleThreadListError = useCallback(
    (error: Error) => {
      toastr.error('Error fetching thread list:', error.message);
    },
    [], // No dependencies needed as toastr is stable
  );

  const query = useGetListOfThreads(
    { pageSize: 20 },
    {
      onSuccess: handleThreadListSuccess,
      onError:   handleThreadListError,
    },
  );

  useInfiniteScroll({
    elementRef: chatThreadsInfiniteScrollRef,
    query,
  });

  return (
    <ChatListSection
      header="Recent"
      infiniteScrollRef={ chatThreadsInfiniteScrollRef }
      isLoading={ query.isFetching }
      list={ historyItems.data }
    />
  );
};

export default memo(RecentChats);
