import React from 'react';

import styles from './styles.module.scss';

interface IDetailsProps {
  children: React.ReactNode;
}

const Details = ({ children }: IDetailsProps): JSX.Element => {
  return <div className={ styles['document-details'] }>{children}</div>;
};

export default React.memo(Details);
