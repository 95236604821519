function getReconciliationCenterSectionName(pathname: string): 'Tasks' | 'Closing' | 'Transactions' | 'Ledger' | '' {
  const taskUrls = [
    '/reconciliation_center/transactions',
    '/reconciliation_center/documents',
    '/reconciliation_center/transactions_for_review',
    '/reconciliation_center/bank_transfers?matched_type=false&from=tasks',
  ];

  const closingUrls = [
    '/reconciliation_center/month_end_reconciliation',
    '/reconciliation_center/month_end_documents/bank_statements',
    '/reconciliation_center/month_end_documents/other_documents',
  ];

  const transactionsUrls = [
    '/reconciliation_center/all_transactions',
    '/reconciliation_center/categorized_transactions',
    '/reconciliation_center/expenses',
    '/reconciliation_center/revenue',
    '/reconciliation_center/bank_transfers',
    '/reconciliation_center/payroll',
    '/reconciliation_center/equity',
    '/reconciliation_center/loan_payments',
  ];

  const ledgerUrls = [
    '/reconciliation_center/accounts',
    '/reconciliation_center/journal_entries',
    '/reconciliation_center/ledger_syncing_centre',
  ];

  if (taskUrls.some((url) => pathname.includes(url))) {
    return 'Tasks';
  }

  if (closingUrls.some((url) => pathname.includes(url))) {
    return 'Closing';
  }

  if (transactionsUrls.some((url) => pathname.includes(url))) {
    return 'Transactions';
  }

  if (ledgerUrls.some((url) => pathname.includes(url))) {
    return 'Ledger';
  }

  return '';
}

export {
  getReconciliationCenterSectionName,
};
