import React, { useCallback } from 'react';

import Spinner from '@src/components/ui/spinner';
import TextAreaInput from '@src/components/ui_v2/inputs/text_area_input';

import styles from './document_list.module.scss';

interface NotesSectionProps {
  note: string;
  isReviewed: boolean;
  isSaving?: boolean;
  onNoteChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onNoteBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const NotesSection: React.FC<NotesSectionProps> = ({
  note,
  isReviewed,
  isSaving = false,
  onNoteChange,
  onNoteBlur,
}) => {
  const renderNoteContent = useCallback(() => {
    if (!isReviewed) {
      return (
        <div className={ styles['note-input-container'] } data-testid="notes-container">
          <TextAreaInput
            className={ styles['note-input'] }
            placeholder="Add note"
            value={ note }
            onBlur={ onNoteBlur }
            onChange={ onNoteChange }
          />
          {isSaving && (
            <div className={ styles['notes-spinner'] }>
              <Spinner />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={ styles['no-notes-message'] }>
        {note || 'No notes added'}
      </div>
    );
  }, [isReviewed, note, onNoteChange, onNoteBlur, isSaving]);

  return (
    <section>
      <div className={ styles['section-header'] }>
        <h1 className={ styles['notes-section-header'] }>Notes</h1>
      </div>
      {renderNoteContent()}
    </section>
  );
};

export default NotesSection;
