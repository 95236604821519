import React, { useCallback } from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';
import { TSection } from '@src/types/common';
import { IVendorPayment, TVendorPaymentsSortColumn } from '@src/types/vendor_payments';

import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { ReceiptDocumentIcon, RevenueNavIcon } from '@src/components/utils/icomoon';
import { ApServiceLogoIcon } from '@src/components/utils/img_icons';

import { PaymentFilter } from '../filter';
import { useVendorPaymentsCollection } from '../hooks';
import { PaymentStatusItem } from '../status_item';
import SummaryInfoItem from '../summary_info_item/summary_info_item';
import { formatPaymentMethod, ICON_FONT_SIZE } from '../utils';

import styles from '../styles.module.scss';

interface IVendorPaymentsTableProps {
  businessVendor: IBusinessVendor,
  section: TSection,
}

const VendorPaymentsTable = ({ businessVendor, section }: IVendorPaymentsTableProps) => {
  const collection = useVendorPaymentsCollection({ businessVendor, section });

  const invoiceNoLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <SummaryInfoItem columnName="invoiceNo" vendorPayment={ vendorPayment } />
    );
  }, []);

  const paymentStatusLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <PaymentStatusItem vendorPayment={ vendorPayment } />
    );
  }, []);

  const charOfAccountLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <SummaryInfoItem columnName="chartOfAccount" vendorPayment={ vendorPayment } />
    );
  }, []);

  const accrualDateLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <SummaryInfoItem columnName="accrualDate" vendorPayment={ vendorPayment } />
    );
  }, []);

  const vendorPaymentIcon = useCallback((vendorPayment: IVendorPayment) => {
    const linkHref = `/document/${vendorPayment.documentId[0]}`;

    if (vendorPayment.businessAdvisorType === 'receipts') {
      return (
        <AppLink newWindow href={ linkHref }>
          <ReceiptDocumentIcon fontSize={ ICON_FONT_SIZE } />
        </AppLink>
      );
    }

    if (vendorPayment.businessAdvisorType === 'invoices') {
      return (
        <AppLink newWindow href={ linkHref }>
          <ApServiceLogoIcon size="50%" />
        </AppLink>
      );
    }

    return (
      <AppLink newWindow href={ linkHref }>
        <RevenueNavIcon className={ styles['icon-label'] } fontSize={ ICON_FONT_SIZE } />
      </AppLink>
    );
  }, []);

  return (
    <CollectionTable<IVendorPayment, TVendorPaymentsSortColumn>
      isRegionScroll
      className={ styles['payment-table'] }
      filter={ <PaymentFilter businessId={ businessVendor.businessId } /> }
      height="calc(100vh - 310px)"
      query={ collection.query }
      records={ collection.records }
      showSettings={ false }
      sorting={ collection.sorting }
    >
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        name="payment_date"
        sortColumn="payment_date"
        title="Payment Date"
        value={ (payment) => payment?.paymentDate || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        name="invoice_number"
        title="Invoice Number"
        value={ (payment) => payment?.invoiceNumber || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="invoiceNo"
        sortColumn="invoice_number"
        title="Docyt ID"
        value={ invoiceNoLabel }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        name="docDate"
        sortColumn="document_date"
        title="Doc Date"
        value={ (payment) => payment?.documentDate || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="paymentMethod"
        title="Payment Method"
        value={ (payment) => formatPaymentMethod(payment?.paymentMethod) || '-' }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="paymentStatus"
        title="Payment Status"
        value={ paymentStatusLabel }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        name="paymentAccount"
        title="Payment Account"
        value={ (payment) => `${payment.bankName || '-'} ${payment.paymentAccountLast4 ? `- ${payment.paymentAccountLast4}` : ''}` }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="chartOfAccount"
        title="Chart of Account"
        value={ charOfAccountLabel }
        width="12%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="accountNo"
        title="Account No"
        value={ (payment) => payment?.accountNumber || '-' }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="accrualPeriod"
        title="Accrual Period"
        value={ accrualDateLabel }
        width="8%"
      />
      <CollectionTable.AmountColumn<IVendorPayment, TVendorPaymentsSortColumn>
        className={ styles['payment-amount-label'] }
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ (payment) => payment.amount }
        width="5%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="icon"
        title=""
        value={ vendorPaymentIcon }
        width="3%"
      />
    </CollectionTable>
  );
};

export default React.memo(VendorPaymentsTable);
