import React from 'react';

import FaIcon, { IFaIconProps } from './fa_icon';

interface IAngleLeftIconProps extends Omit<IFaIconProps, 'icon'> {}

const AngleLeftIcon = (props: IAngleLeftIconProps): JSX.Element => {
  return <FaIcon icon="angle-left" { ...props } />;
};

export default AngleLeftIcon;
