import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import {
  IUploadCSVResponse,
  IUploadCSVParams,
  uploadCSV,
  IJournal,
  IGetJournalsParams,
  IGetJournalsResponse,
  getJournals,
  IGetJournalResponse,
  getJournal,
  IDeleteJournalParams,
  deleteJournal,
  IManagementGroupsResponse,
  IJournalNamesResponse,
  getJournalNames,
  getFilterManagementGroups,
  getJournalEntriesCSV,
  JournalEntriesCSVParams,
  getSingleJournalEntriesCSV,
  SingleJournalEntriesParams,
  getAttachments,
  putAttachmentRename,
  deleteAttachment,
  uploadAttachments,
  IUploadAttachmentParams,
  IAttachmentRename,
  getDocumentActivities,
  IGetDocumentActivitiesResponse,
} from '@src/requests/joural_entries';
import { TID } from '@src/types/common';

const JOURNALS_QUERY = 'journals';
const JOURNAL_QUERY = 'journal';
const JOURNAL_NAMES_QUERY = 'journal_names';
const J_FILTER_MANAGEMENT_GROUPS_QUERY = 'j_filter_management_groups';
const JOURNAL_ENTRY_ATTACHMENTS = 'journal_entry_attachments';

const useUploadCSV = () => {
  return useMutation<IUploadCSVResponse, Error, IUploadCSVParams>(uploadCSV);
};

const useDeleteJournal = () => {
  return useMutation<void, Error, IDeleteJournalParams>(deleteJournal);
};

const useGetJournals = createUseGetInfiniteCollection<
  IJournal,
  IGetJournalsParams,
  IGetJournalsResponse
>({
  queryKey: JOURNALS_QUERY,
  request:  getJournals,
});

const useGetJournal = (id: number, businessId: number) => {
  return useQuery<IGetJournalResponse, Error>({
    queryKey: JOURNAL_QUERY,
    queryFn:  () => getJournal(id, businessId),
  });
};

const useGetJournalNames = (businessId: number) => {
  return useQuery<IJournalNamesResponse, Error>({
    queryKey: JOURNAL_NAMES_QUERY,
    queryFn:  () => getJournalNames(businessId),
  });
};

const useGetFilterManagementGroups = (businessId: number) => {
  return useQuery<IManagementGroupsResponse, Error>({
    queryKey: J_FILTER_MANAGEMENT_GROUPS_QUERY,
    queryFn:  () => getFilterManagementGroups(businessId),
  });
};
const useGetJournalEntriesCSV = () => {
  return useMutation<Blob, Error, JournalEntriesCSVParams>(getJournalEntriesCSV);
};

const useGetSingleJournalEntries = () => {
  return useMutation<Blob, Error, SingleJournalEntriesParams>(getSingleJournalEntriesCSV);
};

const useUploadAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUploadAttachmentParams>(
    uploadAttachments,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(JOURNAL_ENTRY_ATTACHMENTS);
      },
    },
  );
};

const useGetAttachments = (recordId : number) => {
  return useQuery<void, Error, any>(
    [JOURNAL_ENTRY_ATTACHMENTS, recordId],
    () => getAttachments(recordId),
    {
      enabled: Boolean(recordId),
    },
  );
};

const usePutAttachmentRename = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, TID, IAttachmentRename>(
    putAttachmentRename as any,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(JOURNAL_ENTRY_ATTACHMENTS);
      },
    },
  );
};

const useDeleteAttachment = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, any>(
    deleteAttachment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(JOURNAL_ENTRY_ATTACHMENTS);
      },
    },
  );
};

const useGetDocumentActivities = (documentId: TID) => {
  return useQuery<IGetDocumentActivitiesResponse[], Error>({
    queryKey: QueryKey.documentActivities,
    queryFn:  () => getDocumentActivities(documentId),
    enabled:  !!documentId,
  });
};

export {
  useUploadCSV,
  useGetJournals,
  useGetJournal,
  JOURNALS_QUERY,
  useDeleteJournal,
  useGetJournalNames,
  useGetFilterManagementGroups,
  useGetJournalEntriesCSV,
  useGetSingleJournalEntries,
  useUploadAttachment,
  useGetAttachments,
  usePutAttachmentRename,
  useDeleteAttachment,
  useGetDocumentActivities,
};
