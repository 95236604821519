import React from 'react';

import ConnectLedgerForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const QBO_CONNECT_TITLE = 'Connect Your QuickBooks Accountant Account';

const ConnectLedgerView = () => {
  return (
    <OnboardingPage isHideBorder title={ QBO_CONNECT_TITLE }>
      <ConnectLedgerForm />
    </OnboardingPage>
  );
};

export default React.memo(ConnectLedgerView);
