import React from 'react';

import { IDocument } from '@src/types/documents';

import DocumentRenderer from '@src/components/common_v2/document/document_renderer';

import FileUpload from './file_upload';

import styles from '../styles.module.scss';

interface IDocumentViewProps {
  document: IDocument;
}

const DocumentView = ({ document }: IDocumentViewProps) => {
  return (
    <div className={ styles['document-view-container'] }>
      { document.finalFileKey ? (
        <DocumentRenderer className={ styles['document-renderer'] } document={ document } />
      ) : (
        <FileUpload />
      )}
    </div>
  );
};

export default React.memo(DocumentView);
