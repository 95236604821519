import React from 'react';

import RevenueCenteryEmpty from '@assets/images/revenue_service/empty.png';

import ImgIcon, { IImgIconProps } from './img_icon';

const MissingREvenueMappingLogo = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="Revenue Center Empty Logo"
      src={ RevenueCenteryEmpty }
      { ...props }
    />
  );
};

export default MissingREvenueMappingLogo;
