import { object, string } from 'yup';

interface IAddNewPaymentProcessorValues {
  namePaymentProcessor: string,
}

const selectAddNewPaymentProcessorValidation = object({
  namePaymentProcessor: string().label('Name Payment Processor').required(),
});

export {
  IAddNewPaymentProcessorValues,
  selectAddNewPaymentProcessorValidation,
};
