import { useEffect, useState } from 'react';

export const useSelectedPortfolio = () => {
  const PORTFOLIO_PAGES = window.Docyt.Common.Constants.PORTFOLIO_PAGES;
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<number | null>(null);
  const [currentActivePage, setCurrentActivePage] = useState<string>(PORTFOLIO_PAGES.dashboard);

  const path = window.location.pathname.split('/');

  useEffect(() => {
    if (path.length > 2 && path[1] === 'portfolio_dashboards') {
      setSelectedPortfolioId(Number(path[2]));
      if (path[3]) {
        setCurrentActivePage(path[3]);
      } else {
        setCurrentActivePage(PORTFOLIO_PAGES.dashboard);
      }
    }
  }, [PORTFOLIO_PAGES.dashboard, path]);

  return { selectedPortfolioId, currentActivePage };
};
