import React, { memo } from 'react';

import { useAtom, useAtomValue } from 'jotai';

import { activeThreadIdAtom, isNavBarVisibleAtom } from '@src/components/ai_chatbot/atoms';
import ChatView from '@src/components/ai_chatbot/components/chat_view';
import StartNewThread from '@src/components/ai_chatbot/components/start_new_thread';
import WelcomeView from '@src/components/ai_chatbot/components/welcome_view';
import { PlusIcon } from '@src/components/utils/fa_icons';
import { MinimizeIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const ChatContainer = () => {
  const [isNavBarVisible, setIsNavBarVisible] = useAtom(isNavBarVisibleAtom);
  const activeThreadId = useAtomValue(activeThreadIdAtom);

  const handleMinimize = () => {
    setIsNavBarVisible(false);
  };

  return (
    <>
      <div className={ styles['chat-message-container'] }>
        {isNavBarVisible && (
          <div className={ styles['chat-message-navbar'] }>
            <MinimizeIcon
              fontSize={ 24 }
              onClick={ handleMinimize }
            />
            <StartNewThread
              buttonText="New Chat"
              prefixIcon={ <PlusIcon fontSize={ 12 } /> }
            />
          </div>
        )}
      </div>
      <div className={ styles['view-container'] }>
        {activeThreadId ? <ChatView /> : <WelcomeView />}
      </div>
    </>
  );
};

export default memo(ChatContainer);
