import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getReportByIdentifier,
  getReportItems,
  getItemLineItemDetails,
} from '@src/requests/report_service/report_items';
import { TMongoID } from '@src/types/common';
import { IReport } from '@src/types/report_service/report';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

const useGetReportItems = (reportId: TMongoID, endPoint: string) => {
  return useQuery<IReportItem[], Error>(
    [QueryKey.reportItems, reportId],
    () => getReportItems(reportId, endPoint),
    {
      enabled: Boolean(reportId),
    },
  );
};

const useGetReportByIdentifier = (reportId: string, reportIdentifier: string) => {
  return useQuery<IReportItem, Error>(
    [QueryKey.reportIdentifierItem, reportId, reportIdentifier],
    () => getReportByIdentifier(reportId, reportIdentifier),
    {
      enabled: Boolean(reportId) && Boolean(reportIdentifier),
    },
  );
};

const useGetLineItemDetails = (report: IReport, item: IItemAccount, currentPage: number, lastPage: number) => {
  return useQuery<IReportServiceQBOLineItemDetails[], Error>(
    [QueryKey.itemLineItemDetails, report, item, currentPage],
    () => getItemLineItemDetails(report, item, currentPage),
    {
      enabled: Boolean(report.slug) && Boolean(item.itemId) && currentPage < lastPage,
    },
  );
};

export {
  useGetReportItems,
  useGetReportByIdentifier,
  useGetLineItemDetails,
};
