import React, { useCallback } from 'react';

import { GroupChannelListOrder } from '@sendbird/chat/groupChannel';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';

import ChannelListHeader from '@src/components/chat_central/components/channel_list/header';
import ChannelListItem from '@src/components/chat_central/components/channel_list/item';
import Loader from '@src/components/chat_central/components/channel_list/loader';
import { IChannelListProps, IRenderChannelPreviewProps } from '@src/components/chat_central/types';
import Tooltip from '@src/components/ui_v2/tooltip';

import styles from '@src/components/chat_central/styles.module.scss';

interface IChannelListViewProps extends IChannelListProps {
  setChannelUrl: (channelUrl: string) => void;
  currentChannelUrl?: string;
  disableAutoSelect?: boolean;
}

const ChannelListView = (
  {
    currentChannelUrl,
    disableAutoSelect = false,
    setChannelUrl,
  }
  :IChannelListViewProps,
) => {
  const [gotChannelFromUrl] = React.useState(false);

  const renderChannelListItem = useCallback((item: IRenderChannelPreviewProps) => {
    const channelName = item?.channel?.name;
    return (
      <Tooltip.HoverContainer>
        <Tooltip.Hover content={ channelName } popoverParams={ { showDelay: 0 } } variant="dark">
          <ChannelListItem { ...item } currentChannelUrl={ currentChannelUrl } />
        </Tooltip.Hover>
      </Tooltip.HoverContainer>
    );
  }, [currentChannelUrl]);

  return (
    <GroupChannelList
      channelListQueryParams={ {
        customTypesFilter: ['business_channel'],
        includeEmpty:      true,
        limit:             30,
        order:             GroupChannelListOrder.LATEST_LAST_MESSAGE,
      } }
      className={ styles['chat-list-container'] }
      disableAutoSelect={ gotChannelFromUrl || disableAutoSelect }
      renderChannelPreview={ (item) => {
        return renderChannelListItem(item);
      } }
      renderHeader={ () => <ChannelListHeader /> }
      renderPlaceHolderLoading={ () => <Loader /> }
      onChannelCreated={ (_channel) => {
      } }
      onChannelSelect={ (channel) => {
        if (channel?.url) {
          setChannelUrl(channel.url);
        }
      } }
    />
  );
};

export default ChannelListView;
