import React, { useCallback, useEffect } from 'react';

import { useAtom, useAtomValue } from 'jotai';

import toastr from '@lib/toastr';

import {
  activeChannelAtom,
  activeChannelIdAtom,
  disableChatInputAtom,
} from '@src/components/ai_chatbot/atoms';
import Chat from '@src/components/ai_chatbot/components/chat';
import ChatInput from '@src/components/ai_chatbot/components/chat_input';
import { useChatMessageHandler } from '@src/components/ai_chatbot/hooks/use_chat_message_handler';
import useSendbirdHelper from '@src/components/ai_chatbot/hooks/use_sendbird_helper';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const ChatView = () => {
  const { sdk } = useSendbirdHelper();
  const [disableChatInput, setDisableChatInput] = useAtom(disableChatInputAtom);

  const [activeChannel, setActiveChannel] = useAtom(activeChannelAtom);
  const activeChannelId = useAtomValue(activeChannelIdAtom);

  const { handleMessageSend } = useChatMessageHandler();

  const getChannel = useCallback(async (channelId: string) => {
    try {
      const channel = await sdk.groupChannel.getChannel(channelId);
      setActiveChannel(channel);
    } catch {
      toastr.error(
        'Failed to fetch channel',
        'Error',
      );
      setActiveChannel(undefined);
    }
  }, [sdk, setActiveChannel]);

  useEffect(() => {
    if (!activeChannel && activeChannelId) {
      getChannel(activeChannelId);
    }
  }, [activeChannelId, activeChannel, getChannel]);

  // set loader to false when ChatView is mounted
  useEffect(() => {
    setDisableChatInput(false);
  }, [setDisableChatInput]);

  return (
    <>
      <div className={ styles['chat-top-section'] }>
        <Chat />
      </div>
      <div className={ styles['chat-bottom-section'] }>
        <ChatInput
          disabled={ disableChatInput }
          handleMessageSend={ handleMessageSend }
        />
      </div>
    </>
  );
};

export default React.memo(ChatView);
