import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import { AngleLeftIcon, AngleRightIcon } from '@src/components/utils/fa_icons';

import styles from '../styles.module.scss';

interface IActionsProps {
  showNavButtons?: boolean;
  onPrevDocument?: () => void;
  onNextDocument?: () => void;
}

const Actions = ({
  showNavButtons,
  onPrevDocument,
  onNextDocument,
}: IActionsProps) => {
  return (
    <div className={ styles['footer-actions'] }>
      { showNavButtons && (
        <>
          <Button
            prefixIcon={ <AngleLeftIcon className={ styles['button-icon-nav'] } /> }
            variant="link"
            onClick={ onPrevDocument }
          />
          <Button
            prefixIcon={ <AngleRightIcon className={ styles['button-icon-nav'] } /> }
            variant="link"
            onClick={ onNextDocument }
          />
        </>
      ) }
      <Button size="compact" variant="link">
        Verify Only
      </Button>
      <Button size="compact" variant="primary">
        Verify and Next
      </Button>
    </div>
  );
};

export default React.memo(Actions);
