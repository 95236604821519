import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { IBusinessVendor } from '@src/types/business_vendors';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';

import { useMergeVendorModal } from '../views/merge_vendor';

interface IMergeOptionAvailableActionProps {
  businessId: number,
  vendor: object,
  onVendorNoMerged: () => void,
}

const MergeOptionAvailableAction = ({
  businessId,
  vendor,
  onVendorNoMerged,
}: IMergeOptionAvailableActionProps): JSX.Element | null => {
  const [localVendor, setLocalVendor] =
    useState<IBusinessVendor>(camelizeKeys(vendor) as IBusinessVendor);
  const [mergeableId, setMergeableId] = useState<number | null>(localVendor.mergeableId);

  const handleNoMergedVendor = useCallback(() => {
    setMergeableId(null);
    onVendorNoMerged();
  }, [onVendorNoMerged]);

  const handleMergedVendor = useCallback(() => {
    setLocalVendor((oldVendor) => ({ ...oldVendor, isMerging: true }));
  }, [setLocalVendor]);

  const merge = useMergeVendorModal({});

  let actionTitle;
  if (localVendor.isMerging) {
    actionTitle = 'Merging Vendor';
  } else if (mergeableId) {
    actionTitle = 'Possible Duplicate Vendor';
  } else if (!mergeableId) {
    return null;
  }

  const classes = classNames(
    'block-wrapper font-semi-bold',
    { 'disabled-merge-link': localVendor.isMerging },
  );

  return (
    <Root>
      <merge.Component
        { ...merge.props }
        businessId={ businessId }
        vendor={ localVendor }
        onMergedVendor={ handleMergedVendor }
        onNoMergedVendor={ handleNoMergedVendor }
      />
      <a
        className={ classes }
        role="button"
        tabIndex={ -1 }
        onClick={ merge.open }
      >
        { actionTitle }
      </a>
    </Root>
  );
};

export default MergeOptionAvailableAction;
