import React, { useMemo, useEffect, useState } from 'react';

import { Viewer, ViewerProps, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import classNames from 'classnames';

import { useDownloadDocumentById } from '@src/hooks/queries/documents';
import { IDocument } from '@src/types/documents';

import QueryStatus from '@src/components/utils/query_status';

import styles from './styles.module.scss';

interface IDocumentRendererProps extends Partial<Omit<ViewerProps, 'fileUrl'>> {
  className?: string;
  document: IDocument;
}

const DocumentRenderer = ({ document, className, ...props }: IDocumentRendererProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [zoom, setZoom] = useState<number | SpecialZoomLevel>(SpecialZoomLevel.PageWidth);

  const downloadDocumentQuery = useDownloadDocumentById({
    documentId: document.id,
    type:       'extracted',
  });

  const fileUrl = useMemo(() => {
    if (!downloadDocumentQuery.data) {
      return null;
    }

    return downloadDocumentQuery.data.fileData;
  }, [downloadDocumentQuery.data]);

  useEffect(() => {
    const handleResize = () => {
      setZoom(SpecialZoomLevel.PageWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const classes = classNames(
    styles['document-renderer-wrapper'],
    className,
  );

  return (
    <div className={ classes } id="document-renderer-wrapper">
      <QueryStatus query={ downloadDocumentQuery } />
      { fileUrl ? (
        <Viewer
          withCredentials
          defaultScale={ zoom }
          fileUrl={ fileUrl }
          plugins={ [defaultLayoutPluginInstance] }
          { ...props }
        />
      ) : !downloadDocumentQuery.isLoading && (
        <div className={ styles['document-renderer-error'] }>
          <p>Failed to load document</p>
        </div>
      ) }
    </div>
  );
};

export default React.memo(DocumentRenderer);
