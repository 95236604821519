import { InfiniteData, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  getDocumentRequests,
  IGetDocumentRequestsParams,
  ILinkDocumentToRequestParams, IPostDocumentRequestsParams,
  IUnlinkDocumentRequestParams, postDocumentRequests,
  postLinkDocumentToRequest,
  postUnlinkDocumentRequest,
  reviewDocumentRequest,
  markNoStatementAvailable,
  addNotesToDocumentRequest,
} from '@src/requests/document_requests';
import { IGetInboxServiceDocumentsResponse } from '@src/requests/inbox_service_documents';
import { IDocumentRequest } from '@src/types/document_requests';
import { IInboxServiceDocument } from '@src/types/inbox_service_documents';

import { INBOX_SERVICE_DOCUMENTS_QUERY } from './inbox_service_documents';
import { updateItemsInInfiniteCollection } from './infinite_collection_queries';

const DOCUMENT_REQUESTS_QUERY = 'document_requests';

const useGetDocumentRequests = (params: IGetDocumentRequestsParams) => {
  return useQuery<IDocumentRequest[], Error>(
    [DOCUMENT_REQUESTS_QUERY, params],
    () => getDocumentRequests(params),
  );
};

const usePostDocumentRequests = () => {
  return useMutation<void, Error, IPostDocumentRequestsParams>(postDocumentRequests);
};

const useLinkDocumentToRequest = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, ILinkDocumentToRequestParams>(
    postLinkDocumentToRequest,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          INBOX_SERVICE_DOCUMENTS_QUERY,
          (
            data?: InfiniteData<IGetInboxServiceDocumentsResponse>,
          ): InfiniteData<IGetInboxServiceDocumentsResponse> => {
            return updateItemsInInfiniteCollection<
              IInboxServiceDocument,
              IGetInboxServiceDocumentsResponse
            >(
              data,
              [params.documentId],
              {
                documentRequestId:   params.documentRequest.id,
                documentRequestName: params.documentRequest.name,
              },
            );
          },
        );
      },
    },
  );
};

const useUnlinkDocumentToRequest = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUnlinkDocumentRequestParams>(
    postUnlinkDocumentRequest,
    {
      onSuccess: (response, params) => {
        queryClient.setQueriesData(
          INBOX_SERVICE_DOCUMENTS_QUERY,
          (
            data?: InfiniteData<IGetInboxServiceDocumentsResponse>,
          ): InfiniteData<IGetInboxServiceDocumentsResponse> => {
            return updateItemsInInfiniteCollection<
              IInboxServiceDocument,
              IGetInboxServiceDocumentsResponse
            >(
              data,
              [params.documentId],
              {
                documentRequestId:   undefined,
                documentRequestName: undefined,
              },
            );
          },
        );
      },
    },
  );
};

export const useReviewDocumentRequest = () => {
  return useMutation({
    mutationFn: reviewDocumentRequest,
  });
};

export const useMarkNoStatementAvailable = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: markNoStatementAvailable,
    onSuccess:  () => {
      queryClient.invalidateQueries(DOCUMENT_REQUESTS_QUERY);
    },
  });
};

export const useAddNotesToDocumentRequest = () => {
  return useMutation({
    mutationFn: addNotesToDocumentRequest,
  });
};

export {
  useGetDocumentRequests,
  usePostDocumentRequests,
  useLinkDocumentToRequest,
  useUnlinkDocumentToRequest,
};
