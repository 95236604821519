import React, { useCallback, useEffect, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetBalanceSheetGroupedAccounts } from '@src/hooks/queries/balance_sheet_informations';
import { IGetBalanceSheetGroupAccountsResponse } from '@src/requests/balance_sheet_informations';
import { IBalanceSheetGroupAccount } from '@src/types/balance_sheet_information';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import Spinner from '@src/components/ui/spinner';
import { CheckboxInput } from '@src/components/ui_v2/inputs';

import styles from '../styles.module.scss';

interface IAccountsFilterModalProps extends IUseModalProps {
  businessId: TID;
  groupedAccounts: IGetBalanceSheetGroupAccountsResponse[] | undefined;
  setGroupedAccounts: React.Dispatch<React.SetStateAction<IGetBalanceSheetGroupAccountsResponse[] | undefined>>;
}

const AccountsFilterModal = ({
  isOpen,
  onCancel,
  onDone,
  businessId,
  groupedAccounts,
  setGroupedAccounts,
}: IAccountsFilterModalProps) => {
  const [isShowAllAccounts, setIsShowAllAccounts] = useState<boolean>(false);

  const { data, isLoading } = useGetBalanceSheetGroupedAccounts({
    businessId,
    page:    1,
    perPage: 10000000000000,
  }, isOpen);

  useEffect(() => {
    if (isOpen) {
      setGroupedAccounts(data);
    }
  }, [data, isOpen, setGroupedAccounts]);

  useEffect(() => {
    let isChecked = true;

    groupedAccounts?.forEach((group) => {
      group.accounts.forEach((account) => {
        if (!account.visible) {
          isChecked = false;
        }
      });
    });

    setIsShowAllAccounts(isChecked);
  }, [groupedAccounts, setIsShowAllAccounts]);

  const handleChangeCheckbox = useCallback(
    (checked: boolean, index: number, idx: number) => {
      if (!groupedAccounts) return;
      const newGroupedAccounts = [...groupedAccounts];
      newGroupedAccounts[index].accounts[idx].visible = checked;
      setGroupedAccounts(newGroupedAccounts);
    },
    [groupedAccounts, setGroupedAccounts],
  );

  const handleChangeShowAllAccounts = useCallback((checked: boolean) => {
    if (!groupedAccounts) return;
    const newGroupedAccounts = [...groupedAccounts];
    newGroupedAccounts.forEach((group) => {
      group.accounts.forEach((account) => {
        account.visible = checked;
      });
    });
    setGroupedAccounts(newGroupedAccounts);
  }, [groupedAccounts, setGroupedAccounts]);

  return (
    <Modal.Standard
      className={ styles['modal-container'] }
      proceedTitle="Save"
      show={ isOpen }
      title="Show/hide Accounts"
      onCancel={ onCancel }
      onProceed={ onDone }
    >
      {() => (
        <>
          { isLoading && <Spinner /> }
          <p>Select the accounts you want to be shown by default.</p>
          <div className={ styles['accounts-body'] }>
            <div className={ styles['accounts-group-left'] }>
              <p>Accounts:</p>
            </div>
            <div className={ styles['accounts-group-right'] }>
              <CheckboxInput
                checked={ isShowAllAccounts }
                title="Show all accounts"
                onChange={ (checked) => {
                  handleChangeShowAllAccounts(checked);
                } }
              />
              { groupedAccounts?.map((group: IGetBalanceSheetGroupAccountsResponse, index: number) => (
                <>
                  <p key={ group.accTypeName } className={ styles.name }>{ group.accTypeName }</p>
                  <div className={ styles['accounts-group-item'] }>
                    { group.accounts.map((account: IBalanceSheetGroupAccount, idx: number) => (
                      <CheckboxInput
                        key={ account.id }
                        checked={ account.visible }
                        title={ account.name }
                        onChange={ (checked) => {
                          handleChangeCheckbox(checked, index, idx);
                        } }
                      />
                    )) }
                  </div>
                </>
              )) }
            </div>
          </div>
        </>
      )}
    </Modal.Standard>
  );
};

const useAccountsFilterModal = makeUseModal(AccountsFilterModal);

export {
  IAccountsFilterModalProps,
  useAccountsFilterModal,
  AccountsFilterModal as default,
};
