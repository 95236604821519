import React, { useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useAtomValue } from 'jotai';

import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';

import { pickMultiMonths } from '@src/components/business_reports/atoms';
import {
  budgetsSelectAddValueType,
  forecastSelectAddValueType,
} from '@src/components/ui_v2/filter/atoms';
import Table from '@src/components/ui_v2/table';

import Body from './body';
import { dummyItemAccountValue } from './constants';
import { useGenerateReportOtherDetailTableData } from '../../hooks';
import { useReportOtherDetailDataCollection, useReportOtherDetailHideZeroRows } from '../../tableHooks';

interface IDrillDownTableProps {
  report: IReport,
  itemIdentifier: IReportItem,
  configurations: IReportConfiguration[],
  hideZeroRows: boolean,
  currentDepth: number,
}

const DrillDownTable = ({
  report,
  configurations,
  itemIdentifier,
  hideZeroRows,
  currentDepth,
}: IDrillDownTableProps) => {
  const collection = useReportOtherDetailDataCollection(report, itemIdentifier?.identifier);
  const budgetsComparison = useAtomValue(budgetsSelectAddValueType);
  const forecastComparison = useAtomValue(forecastSelectAddValueType);
  const showMultiMonths = useAtomValue(pickMultiMonths);

  const data = useGenerateReportOtherDetailTableData(
    report,
    configurations,
    budgetsComparison?.[0],
    forecastComparison?.[0],
  );

  const items = useMemo(() => {
    let id = 1;
    const retData: IItemAccount[] = [];

    collection.itemAccountValues.forEach((item) => {
      const find = retData.find(
        (f: IItemAccount) => f.chartOfAccountId === item.chartOfAccountId
          && f.accountingClassId === item.accountingClassId,
      );

      if (!find) {
        item.id = `item-${id}`;
        retData.push(item);
        id += 1;
      }
    });

    retData.push(dummyItemAccountValue);

    return retData;
  }, [collection.itemAccountValues]);

  useReportOtherDetailHideZeroRows(
    report,
    hideZeroRows,
    items,
    data.multiMonthColumns,
    data.ptdColumns,
    data.ytdColumns,
    collection.itemAccountValues,
    data.headerTopRowColumns,
  );

  if (collection.query.isLoading) {
    const columnCount = showMultiMonths ? data.headerTopRowColumns.length : data.headerBottomRowColumns.length;
    return (
      <>
        {
          Array.from({ length: 4 }).map((r, i) => (
            <Table.Row key={ `skeleton-row-${i + 1}-${itemIdentifier.identifier}` }>
              {Array.from({ length: columnCount + 1 }).map((c, j) => (
                <Table.TextCell key={ `skeleton-cell-${j + 1}-${itemIdentifier.identifier}` }>
                  <Skeleton />
                </Table.TextCell>
              ))}
            </Table.Row>
          ))
        }
      </>
    );
  }

  return (
    <Body
      currentDepth={ currentDepth }
      headerBottomRowColumns={ data.headerBottomRowColumns }
      headerTopRowColumns={ data.headerTopRowColumns }
      itemAccountValues={ collection.itemAccountValues }
      itemIdentifier={ itemIdentifier }
      itemValues={ collection.itemValues }
      items={ items }
      multiMonthColumns={ data.multiMonthColumns }
      ptdColumns={ data.ptdColumns }
      report={ report }
      ytdColumns={ data.ytdColumns }
    />
  );
};

export default DrillDownTable;
