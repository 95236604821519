import React, { useState } from 'react';

import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useVerifyTransaction } from '@src/hooks/reconciliation_center/use_verify_transaction';
import { verifyTransactionServiceDocument } from '@src/requests/transaction_service_documents';
import {
  ITransactionServiceDocument,
  IUpdateTransactionServiceDocumentData,
} from '@src/types/transaction_service_documents';

import useProgressModal from '@src/components/reconciliation_center/all_transactions/sidebar/modals/progress_modal';
// eslint-disable-next-line max-len
import useQuickbooksWarningModal from '@src/components/reconciliation_center/all_transactions/sidebar/modals/quickbooks_warning_modal';
import useResultModal from '@src/components/reconciliation_center/all_transactions/sidebar/modals/result_modal';

type TAllTransactionsListCategorizeActionsProps = {
  document: ITransactionServiceDocument,
  payrollBCOAId: number | null,
}

const AllTransactionsListCategorizeActions = ({
  document,
  payrollBCOAId,
}: TAllTransactionsListCategorizeActionsProps) => {
  const business = useBusinessContext();
  const warningModal = useQuickbooksWarningModal();
  const progressModal = useProgressModal();
  const resultModal = useResultModal();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [doneCount, setDoneCount] = useState(0);
  const isButtonDisabled = useVerifyTransaction(document, payrollBCOAId);

  const handleCategorize = async () => {
    // Check if the transaction date is before the reconciliation start date
    if (
      business.reconciliationStartDate
      && moment(document.transactionDate) < moment(business.reconciliationStartDate)
    ) {
      warningModal.open();
      return;
    }

    progressModal.open();
    setDoneCount(0);

    try {
      const updateData: IUpdateTransactionServiceDocumentData = {
        ...document,
        transactionIds:       [document.id],
        userVendorId:         document.vendorId,
        userChartOfAccountId: document.chartOfAccountId,
        doNotLearn:           document.doNotLearn,
        noPushQbo:            document.noPushQbo,
        noDocumentRequired:   document.noDocumentRequired,
        // eslint-disable-next-line max-len
        chargebackWonAmount:  document.chargebackWonAmount ? Number(document.chargebackWonAmount) : null,
        // eslint-disable-next-line max-len
        chargebackLostAmount: document.chargebackLostAmount ? Number(document.chargebackLostAmount) : null,
      };

      await verifyTransactionServiceDocument({
        transactionId: document.id,
        data:          updateData,
      });
      setDoneCount(1);
      setSuccess(true);
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
    } finally {
      progressModal.props.onDone?.();
      resultModal.open();
    }
  };
  return (
    document?.state === 'unverified' ? (
      <>
        <div className="display-flex justify-content-start">
          { !isButtonDisabled ? (
            <span className="in-purple-1000" role="button" tabIndex={ 0 } onClick={ handleCategorize }>
              Categorize
            </span>
          ) : (
            <span className="in-purple-1000">Review</span>
          )}
        </div>
        <progressModal.Component
          doneCount={ doneCount }
          totalCount={ 1 }
          { ...progressModal.props }
        />
        <resultModal.Component
          errorMessage={ errorMessage }
          success={ success }
          onClose={ () => { window.location.reload(); } }
          onRefreshData={ () => { window.location.reload(); } }
          { ...resultModal.props }
        />
        <warningModal.Component
          actionDateStr={ moment(document.transactionDate).format('MM/DD/YYYY') }
          actionDateTitle="Transaction Date"
          actionType="updated"
          documentType="transaction"
          paymentAccountStartDateStr=""
          reconciliationClosingDateStr={ moment(business.reconciliationStartDate).format('MM/DD/YYYY') }
          title=" Verify Bank Transaction"
          { ...warningModal.props }
        />
      </>
    ) : null
  );
};

export default AllTransactionsListCategorizeActions;
