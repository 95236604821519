import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetAuditScanNotesResponseInfinite } from '@src/requests/operational_dashboard/audit_scan';
import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import Table from '@src/components/ui/table';

import BookClosedItem from './book_closed_item';

import styles from './style.module.scss';

interface IFinancialInstitutionConnectionTableProps {
  query: UseInfiniteQueryResult<IGetAuditScanNotesResponseInfinite, Error>,
  bookCloseNotes: AuditScanDetails[],
}
const BookClosedNoteTable = ({
  query,
  bookCloseNotes,
}: IFinancialInstitutionConnectionTableProps) => {
  return (
    <Table
      className={ styles.operationalDashboardNoteSideViewTable }
      infiniteQuery={ query }
    >
      <Table.Body>
        { bookCloseNotes.map((note) => (
          <Table.Row key={ note.id } className={ styles.tableRow }>
            <Table.Cell className={ styles.tableCell }>
              <div key={ note.id }>
                <BookClosedItem auditScan={ note } />
              </div>
            </Table.Cell>
          </Table.Row>
        )) }
      </Table.Body>
    </Table>
  );
};

export default React.memo(BookClosedNoteTable);
