import React, { memo, useMemo } from 'react';

import classNames from 'classnames';

import { NumberBoxValue, TSetupStatus } from '@src/components/operational_dashboard/types';

import { isSetupStatusComplete } from '../../helper/functional';

import styles from './style.module.scss';

interface INumCommonProps {
  value?: NumberBoxValue;
  isWarning?: boolean;
}

interface INumberInBoxProps extends INumCommonProps {
  setupStatus?: TSetupStatus;
  isDanger?: boolean;
}

interface IValueSpanProps extends INumCommonProps {
  isDanger?: boolean;
}

const ValueSpan = ({ value, isWarning, isDanger }: IValueSpanProps) => {
  const dash = useMemo(() => {
    return <span className={ styles.line }><strong>-</strong></span>;
  }, []);

  if (!value || value.type === 'empty') {
    return dash;
  }

  if (value.type === 'indicator') {
    return value.value ? (
      <span className={ styles['dot-red'] } />
    ) : dash;
  }

  if (value.type === 'number') {
    return value.value ? (
      <span className={ classNames({
        [styles.warning]: isWarning,
        [styles.red]:     isDanger,
      }) }
      >
        {value.value > 99 ? '99+' : value.value}
      </span>
    )
      : dash;
  }

  return null;
};

const NumberBox = ({
  value,
  setupStatus,
  isDanger = false,
  isWarning = false,
}: INumberInBoxProps) => {
  if (!value || value.type === 'empty') {
    return (
      <div className={ classNames(styles['number-box-container'], styles.empty) }>
        <span className={ styles['table-font'] }>-</span>
      </div>
    );
  }

  return (
    <div
      className={ classNames(styles['number-box-container'], {
        [styles.red]:     isDanger,
        [styles.warning]: isWarning,
        [styles.empty]:   Boolean(!isDanger && !isWarning),
      }) }
    >
      {setupStatus ? (
        <span className={ styles['table-font'] }>
          {isSetupStatusComplete(setupStatus) ? 'NA' : value.value ?? '-'}
        </span>
      ) : (
        <ValueSpan isDanger={ isDanger } isWarning={ isWarning } value={ value } />
      )}
    </div>
  );
};

export default memo(NumberBox);
