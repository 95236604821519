import React, { useCallback, useState } from 'react';

import { QueryObserverResult } from 'react-query';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreatePaymentProcessors } from '@src/hooks/queries/revenue_report_types';
import { IGetRevenueReportTypeDetailResponse } from '@src/requests/revenue_report_types';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import Form from './form';
import { IAddNewPaymentProcessorValues } from './schema';

interface IAddPaymentProcessorsModalProps extends IUseModalProps {
  reportTypeId:TID,
  refetchRevenueReport: () => Promise<QueryObserverResult<IGetRevenueReportTypeDetailResponse, Error>>;
}

const AddPaymentProcessorsModal = ({
  isOpen,
  onDone,
  onCancel,
  reportTypeId,
  refetchRevenueReport,
  ...props
}: IAddPaymentProcessorsModalProps) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const create = useCreatePaymentProcessors();
  const { mutate } = create;

  const handleError = useCallback((response) => {
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const createPaymentProcessor = useCallback((data: IAddNewPaymentProcessorValues) => {
    mutate({
      revenueReportTypeId: reportTypeId,
      paymentProcessors:   [{
        id:   -1,
        name: data.namePaymentProcessor,
      }],
    }, {
      onSuccess: (response) => {
        if (response) {
          refetchRevenueReport?.();
          onDone();
          toastr.success(
            'Configuration Payments processor have been created ',
            'Successfully created',
          );
        }
      },
      onError: handleError,
    });
  }, [mutate, reportTypeId, handleError, refetchRevenueReport, onDone]);

  return (
    <Modal
      show={ isOpen }
      title="Add New Payment Processor"
      { ...props }
    >
      <>
        <Modal.Body>
          <Form
            formId="add_payment_processor"
            onSubmit={ createPaymentProcessor }
            onValidationChange={ setIsFormValid }
          />
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <Button
            className="btn-outline"
            variant="link"
            onClick={ onCancel }
          >
            Back
          </Button>
          <Button
            disabled={ !isFormValid }
            form="add_payment_processor"
            type="submit"
            variant="primary"
          >
            Done
          </Button>
        </Modal.Footer>
      </>
    </Modal>
  );
};

const useAddPaymentProcessorsModal = makeUseModal(AddPaymentProcessorsModal);

export {
  useAddPaymentProcessorsModal,
  AddPaymentProcessorsModal as default,
};
