import React, { useEffect } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { ITSDComponentProps } from '@src/types/transaction_service_documents';

import BusinessChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';
import Spinner from '@src/components/ui/spinner';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import SidebarSelectInput from './sidebar_select_input';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

const investmentTypeOptions = [
  { value: 'ach_transfer_from', label: 'ACH Transfer from another bank' },
  { value: 'cash_check_deposit', label: 'Cash / Check Deposits' },
  { value: 'vendor_refund', label: 'Vendor refund for personal expense' },
];

const EquityInvestment = ({ rowData, setRowData, verify }: ITSDComponentProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } =
    useTxnDoc({ rowData, setRowData });
  const {
    isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo, documentRelated,
  } = commonProps;

  const business = useBusinessContext();
  useEffect(() => {
    let disabled = true;
    if (rowData.subType && rowData.chartOfAccountId && documentRelated) {
      disabled = false;
    }
    verify(disabled);
  }, [rowData.subType, rowData.chartOfAccountId, documentRelated, verify]);
  const investmentType = investmentTypeOptions.find((i) => i.value === rowData.subType);

  return (
    <>
      {isSubmitting && <Spinner />}
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="Investment Type*"
          options={ investmentTypeOptions }
          placeholder="Select Withdrawal Type"
          value={ investmentType }
          onChange={ (val) => updateFields({ subType: val }) }
        />
        <BusinessChartOfAccountField
          hideClear
          businessId={ business.id }
          isDisabled={ isDisabled }
          label="Category*"
          labelClass={ styles['select-label'] }
          placeholder="Enter Category Name or Code"
          queryParams={ { searchClassification: ['Equity'] } }
          value={ rowData.chartOfAccountId }
          onChange={ (id) => updateFields({ userChartOfAccountId: id }) }
        />
        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>
      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </>
  );
};
export default EquityInvestment;
