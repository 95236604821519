import { IBusinessChartOfAccount } from '@src/types/business_chart_of_accounts';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetBusinessChartOfAccountsParams {
  accountingClassId?: TID,
  businessId: TID,
  search?: string,
  searchAccType?: string | string[],
  searchClassification?: string[],
  withoutAccType?: string,
  simple?: boolean,
}

interface IGetBusinessChartOfAccountsResponse {
  collection: IBusinessChartOfAccount[],
}

const getBusinessChartOfAccounts = (
  params: IGetBusinessChartOfAccountsParams,
): Promise<IGetBusinessChartOfAccountsResponse> => {
  return apiGet(
    '/api/web/v1/business_chart_of_accounts',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.businessChartOfAccounts as IBusinessChartOfAccount[],
    };
  });
};

interface IGetBusinessChartOfAccountParams {
  businessId: TID,
  chartOfAccountId: TID,
}

interface IGetBusinessChartOfAccountResponse {
  chartOfAccount: IBusinessChartOfAccount,
}

const getBusinessChartOfAccount = ({
  chartOfAccountId,
  ...params
}: IGetBusinessChartOfAccountParams): Promise<IGetBusinessChartOfAccountResponse> => {
  return apiGet(
    `/api/web/v1/business_chart_of_accounts/${chartOfAccountId}`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IGetBusinessChartOfAccountResponse;
  });
};

export {
  IGetBusinessChartOfAccountsParams,
  IGetBusinessChartOfAccountsResponse,
  IGetBusinessChartOfAccountParams,
  IGetBusinessChartOfAccountResponse,
  getBusinessChartOfAccounts,
  getBusinessChartOfAccount,
};
