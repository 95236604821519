import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetTransactionMessagesParams,
  IGetTransactionMessagesResponseInfinite,
  IMarkAllChatsAsReadParams,
  IMarkAllChatsAsReadResponse,
  getTransactionMessages,
  markAllAsRead,
} from '@src/requests/transaction_messages';
import { IChat } from '@src/types/transaction_message';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetTransactionMessages = createUseGetInfiniteCollection<
  IChat,
  IGetTransactionMessagesParams,
  IGetTransactionMessagesResponseInfinite
>({
  queryKey: QueryKey.transactionMessages,
  request:  getTransactionMessages,
});

const useMarkAllChatsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation<IMarkAllChatsAsReadResponse, Error, IMarkAllChatsAsReadParams>(
    (params) => markAllAsRead(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.transactionMessages);
      },
    },
  );
};

export { useGetTransactionMessages, useMarkAllChatsAsRead };
