/* eslint-disable react/jsx-no-useless-fragment */

import React, { useCallback, useMemo, useState } from 'react';

import moment from 'moment';
import { useAtomValue } from 'jotai';

import { useExportReportAsExcel, useExportReportData } from '@src/hooks/queries/report_service/custom_reports';
import { IAdvancedReportsFileParams } from '@src/requests/report_service/custom_reports';
import { TDateFilter } from '@src/types/filter';
import { IReport, ITemplate } from '@src/types/report_service/report';
import { API_DATE_FORMAT } from '@src/utils/date_helpers';

import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import { useFilterData } from '@src/components/ui_v2/filter';
import { useSection } from '@src/components/utils_v2/section';

import { reportsExportValueType } from '../atoms';

interface IBusinessReportsExportFooterProps {
  type:             string,
  reportServiceId:  number,
  include:          boolean,
  exclude:          boolean,
  reports:          IReport[],
  templates:        ITemplate[],
  category:         string,
  slug:             string,
}

const BusinessReportsExportFooter = ({
  type,
  reportServiceId,
  include,
  exclude,
  reports,
  templates,
  category,
  slug,
}: IBusinessReportsExportFooterProps) => {
  const section = useSection();
  const filterData = useFilterData(section);

  const reportValue = useAtomValue(reportsExportValueType);
  const filterDate = useMemo(() => (
    filterData?.reportDataExport as any
  ), [filterData]);

  const report = useMemo(() => 
    reports.find((f: IReport) => f.id === reportValue[0]),
  [reportValue, reports]);

  const hideZeroRows = useMemo(() =>
    localStorage.getItem(`hideZeroRows-${report?.id}`) 
      ? localStorage.getItem(`hideZeroRows-${report?.id}`) 
      : '1',
  [report?.id]);
  const multiValue = useMemo(() =>
    localStorage.getItem(`multiValue-${report?.id}`)
      ? JSON.parse(localStorage.getItem(`multiValue-${report?.id}`) as string)
      : [],
  [report?.id]);
  const budgetsSelectValue = useMemo(() =>
    localStorage.getItem(`budgetsSelectValue-${report?.id}`)
      ? JSON.parse(localStorage.getItem(`budgetsSelectValue-${report?.id}`) as string)
      : [],
  [report?.id]);
  const forecastSelectValue = useMemo(() =>
    localStorage.getItem(`forecastSelectValue-${report?.id}`)
      ? JSON.parse(localStorage.getItem(`forecastSelectValue-${report?.id}`) as string)
      : [],
  [report?.id]);

  const selectedReports = useMemo(() => {
    return reports?.filter((rep: IReport) => {
      if (type === 'report') {
        return rep.slug === slug;
      }

      return templates.find((template: ITemplate) => (
        template.id === rep.templateId && template.category === category
      ));
    });
  }, [reports, type, templates, slug, category]);

  const reportIds = useMemo(() => (
    selectedReports.map((rep: IReport) => rep.id)
  ), [selectedReports]);

  const downloadReports = useExportReportAsExcel();
  const { mutate } = downloadReports;
  const downloadReport = useExportReportData();
  const { mutate: mutateReport } = downloadReport;

  const [loading, setLoading] = useState<boolean>(false);

  const dateRange: TDateFilter | undefined = useMemo(() => {
    if (type === 'consolidated_report') {
      if (filterDate && filterDate.toString() !== '') {
        return {
          gte: moment(filterDate.toString()).startOf('month').format(API_DATE_FORMAT),
          lte: moment(filterDate.toString()).endOf('month').format(API_DATE_FORMAT),
        }
      }
    }

    if (type === 'report') {
      if (filterDate && filterDate.gte !== '' && filterDate.lte !== '') {
        return {
          gte: moment(filterDate.gte).startOf('month').format(API_DATE_FORMAT),
          lte: moment(filterDate.lte).endOf('month').format(API_DATE_FORMAT),
        }
      }
    }

    return undefined;
  }, [type, filterDate]);

  const handleClick = useCallback(() => {
    setLoading(true);

    if (type === 'consolidated_report') {
      mutate(
        {
          reportServiceId,
          startDate: dateRange?.gte as string,
          endDate: dateRange?.lte as string,
          exportType: 'consolidated_report',
          reportIds
        },
        { onSuccess: () => setLoading(false), onError: () => setLoading(false) }
      );
    }

    if (type === 'report' && report && dateRange) {
      let params: IAdvancedReportsFileParams;

      if (report.templateId === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.DEPARTMENTAL_REPORT) {
        params = {
          export_type:        'report',
          report_id:          report.id,
          start_date:         dateRange.gte as string,
          end_date:           dateRange.lte as string,
          include_drill_down: include,
          file_type:          'xls',
          filter: {
            is_zero_rows_hidden: hideZeroRows === '1',
            accounting_class_id: ''
          }
        };
      } else {
        params = {
          export_type:         'report',
          report_id:           report.id,
          start_date:          dateRange.gte as string,
          end_date:            dateRange.lte as string,
          include_drill_down:  include,
          is_daily:            false,
          file_type:           'xls',
          filter:              {}
        };
    
        if (report.periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
          params.is_daily = true
          params.filter = {
            is_zero_rows_hidden: hideZeroRows === '1'
          }
        } else {
          params.filter = {
            is_zero_rows_hidden:  hideZeroRows === '1',
            budgets_comparison:   false,
            forecast_comparison:  false,
            last_year:            multiValue.includes('last_year'),
            per_available_room:   multiValue.includes('per_available_room'),
            per_occupied_room:    multiValue.includes('per_occupied_room'),
            percentage_column:    multiValue.includes('percentage_column'),
            budget:               budgetsSelectValue.length !== 0 ? budgetsSelectValue[0] : null,
            forecast:             forecastSelectValue.length !== 0 ? forecastSelectValue[0] : null,
            ...(budgetsSelectValue.length !== 0 ? {[`checkbox-budget-${budgetsSelectValue[0]}`]: true} : {}),
            ...(forecastSelectValue.length !== 0 ? {[`checkbox-forecast-${forecastSelectValue[0]}`]: true} : {})
          }
        };
      }

      mutateReport(params, 
        { onSuccess: () => setLoading(false),
          onError: () => setLoading(false)
        },
      );
    }
    
  }, [
    type,
    report,
    dateRange,
    mutate,
    reportServiceId,
    mutateReport,
    include,
    hideZeroRows,
    multiValue,
    budgetsSelectValue,
    forecastSelectValue,
  ]);

  return (
    <>
      {loading 
        ? (<Spinner />) 
        : (
          <div className="reports-export-footer">
            <div className="action-content">
              <a className="pointer text-color" role="button" tabIndex={ -1 }>
                Save Details
              </a>
              
              {!dateRange || (type === 'report' && !include && !exclude) ? (
                <Button disabled variant="primary">Export</Button>
              ) : (
                <Button variant="primary" onClick={ handleClick }>Export</Button>
              )}
            </div>
          </div>
        )}
    </>
  );
}

export default BusinessReportsExportFooter;