/* eslint-disable key-spacing */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { QueryObserverResult } from 'react-query';
import { SingleValue } from 'react-select';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useManageRevenueReportTypeMapping } from '@src/hooks/queries/revenue_report_types';
import {
  IManageRevenueReportTypeMappingResponse,
  IDataCategoryPayload,
  IDataPaymentPayload,
  IGetRevenueReportTypeDetailResponse,
} from '@src/requests/revenue_report_types';
import { IRevenueCategoriesType } from '@src/types/revenue_report_types';
import { toTitleCase } from '@src/utils/transform_keys';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button } from '@src/components/ui_v2/buttons';

import Table from './editable_table';
import { RowData, RowDataValidation, SelectOption } from './schema';

import styles from './styles.module.scss';

interface IEditableTableProps {
  reportTypeId: number;
  title: string;
  data: IRevenueCategoriesType[];
  refetchRevenueReport: () => Promise<QueryObserverResult<IGetRevenueReportTypeDetailResponse, Error>>;
  setMappingErrors: (value: string) => void;
  setMappingNoData: (value: boolean) => void;
  flagImport: boolean;
}

const EditableTable = forwardRef(({
  reportTypeId,
  title,
  data,
  refetchRevenueReport,
  setMappingErrors,
  setMappingNoData,
  flagImport,
}: IEditableTableProps, ref) => {
  const business = useBusinessContext();
  const { mutate: manageRevenueReportTypeMapping } = useManageRevenueReportTypeMapping();
  const [rows, setRows] = useState<RowData[]>([]);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<keyof RowData | undefined>(undefined);
  const [errors, setErrors] = useState<RowDataValidation[]>([]);
  const [deletedData, setDeletedData] = useState<(RowData & { rowIndex: number }) | null>(null);
  const prevDataRef = useRef<IRevenueCategoriesType[] | null>(null);

  useEffect(() => {
    setRows((prevRows) => {
      const existingNullRows = prevRows?.filter((row) => row.id === null) || [];

      const updatedRows = data.map((val) => ({
        id:            val.id,
        itemsServices: val?.lineItemName ? toTitleCase(val?.lineItemName) : '',
        department:    {
          value: val?.departmentId || null,
          label: val?.departmentId ? toTitleCase(val?.departmentId?.toString()) : null,
        },
        vendor: {
          value: val?.vendorId || null,
          label: val?.vendorName ? toTitleCase(val?.vendorName) : null,
        },
        chartOfAccounts: {
          value: val?.chartOfAccount?.id || null,
          label: val?.chartOfAccount?.name ? toTitleCase(val?.chartOfAccount?.name) : null,
        },
        journalEntryPosition: {
          value: val?.isDebit,
          label: val?.isDebit ? 'Debit' : 'Credit',
        },
        lineItemName:     val?.lineItemName ? toTitleCase(val?.lineItemName) : null,
        paymentProcessor: {
          value: val?.paymentProcessorId,
          label: val?.paymentProcessorName ? toTitleCase(val?.paymentProcessorName) : null,
        },
      }));

      return [...updatedRows, ...existingNullRows];
    });
    prevDataRef.current = data;
  }, [data]);

  const handleSort = (column: keyof RowData) => {
    const isAscending = sortColumn === column && sortDirection === 'asc';
    setSortColumn(column);
    setSortDirection(isAscending ? 'desc' : 'asc');

    const sortedRows = [...rows].sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA === null || valueA === undefined) return isAscending ? 1 : -1;
      if (valueB === null || valueB === undefined) return isAscending ? -1 : 1;

      const getValue = (val: any) => (typeof val === 'object' && val?.label ? val.label.toLowerCase() : val);
      const compareA = getValue(valueA);
      const compareB = getValue(valueB);

      if (typeof compareA === 'number' && typeof compareB === 'number') {
        return isAscending ? compareA - compareB : compareB - compareA;
      }

      if (compareA < compareB) return isAscending ? -1 : 1;
      if (compareA > compareB) return isAscending ? 1 : -1;
      return 0;
    });

    setRows(sortedRows);
  };

  const clearSorting = () => {
    setSortColumn(undefined);
    setSortDirection(undefined);
    setRows([...rows]);
  };

  const handleUpdateErrors = useCallback(
    (updatedRowIndex: number, field: keyof typeof rows[number], value: any) => {
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        const isValid =
          (field === 'chartOfAccounts' && title !== 'Payment Processors' ? value !== null : true)
          && (field === 'journalEntryPosition' ? value !== null : true)
          && (field === 'lineItemName' ? value !== '' && value !== null : true);
        if (isValid) {
          updatedErrors[updatedRowIndex] = { ...updatedErrors[updatedRowIndex], [field]: false };
        }
        return updatedErrors;
      });
    },
    [title],
  );

  const handleChange = <K extends keyof RowData>(index: number, field: K, value: RowData[K]): void => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      return updatedRows;
    });
    handleUpdateErrors(index, field, value);
  };

  const handleSelectChange = <K extends keyof RowData>(
    index: number,
    field: K,
    selectedOption: SingleValue<SelectOption>,
  ): void => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: selectedOption };
      return updatedRows;
    });
    handleUpdateErrors(index, field, selectedOption?.value);
  };
  const handleCheckErrors = useCallback(() => {
    const checkErrors = rows.map((row) => ({
      chartOfAccounts: title !== 'Payment Processors' ? row.chartOfAccounts.value === null : false,
      journalEntryPosition: row.journalEntryPosition.value === null,
      lineItemName: row.lineItemName === '' || row.lineItemName === null,
    }));

    setErrors(checkErrors);

    const hasError = checkErrors.some((error) => error.chartOfAccounts
      || error.journalEntryPosition
      || error.lineItemName);

    if (hasError) {
      setMappingErrors('Error Validation');
    }

    return hasError;
  }, [rows, setMappingErrors, title]);

  const addNewRow = (): void => {
    if (handleCheckErrors()) {
      return;
    }
    setRows((prevRows) => [
      ...prevRows,
      {
        id: null,
        itemsServices: '',
        department: { value: null, label: null },
        vendor: { value: null, label: null },
        chartOfAccounts: { value: null, label: null },
        journalEntryPosition: { value: null, label: null },
        lineItemName: '',
        paymentProcessor: { value: null, label: null },
      },
    ]);
  };

  const handleUpdateData = (dataUpdate: IManageRevenueReportTypeMappingResponse) => {
    const nullRows = rows?.filter((o) => flagImport || o.id === null) || [];

    nullRows.forEach((val: RowData, index: number) => {
      manageRevenueReportTypeMapping(
        {
          revenueId: reportTypeId,
          type:      'update_category',
          payload:   {
            revenue_service_category_id:
              dataUpdate?.revenueReportType?.revenueReportTypeCategories[
                (nullRows.length - 1) - index
              ]?.revenueServiceCategoryId,
            line_item_name: val?.lineItemName || '',
            is_debit:       val?.journalEntryPosition?.value,
          },
        },
        {
          onSuccess: () => {
            setMappingErrors('Success');
            refetchRevenueReport?.();
          },
          onError: (error : any) => {
            const errorMsg = error?.message || 'Something went wrong. Please try again.';
            setMappingErrors(errorMsg);
            toastr.error(
              errorMsg,
              'Error',
            );
          },
        },
      );
    });
  };

  useImperativeHandle(ref, () => ({
    async handleSubmit() {
      try {
        if (handleCheckErrors()) {
          return;
        }
        const section = {
          'Revenue Categories':        'categories',
          'Tax Categories':            'taxes',
          'Other Ledger Categories': 'other_ledgers',
          'Expense Adjustments':     'payment_processors_adjustment',
        } as const;

        // Add Category
        const payloadCategory = rows?.map((val) => ({
          ...(val?.id != null ? { id: val?.id } : {}),
          ...(val?.paymentProcessor?.value != null ? { payment_processor_id: val?.paymentProcessor?.value } : {}),
          ...(val?.vendor?.value != null ? { vendor_id: val?.vendor?.value } : {}),
          category_id:    val?.chartOfAccounts.value,
          accountClass:   null,
          line_item_name: val?.lineItemName,
          is_debit:       val?.journalEntryPosition?.value,
        })) as IDataCategoryPayload[];

        const addCategory = {
          section:      section[title as keyof typeof section] ?? '',
          category_ids: payloadCategory,
        };

        // Add Payment
        const payloadPayment = rows?.filter((o) => flagImport || o.id === null)?.map((val) => ({
          id:                           val?.paymentProcessor?.value,
          name:                         val?.paymentProcessor?.label,
          business_id:                  business?.id,
          selected_in_current_business: false,
          is_debit:                     val?.journalEntryPosition?.value,
          line_item_name:               val?.lineItemName,
        })) as unknown as IDataPaymentPayload[];

        const hasData = title === 'Payment Processors'
          ? payloadPayment.length === 0
          : addCategory.category_ids.length === 0;

        setMappingNoData(hasData);

        const prevData = prevDataRef.current;

        const isDataSame =
        prevData
        && prevData.length === rows.length
        && prevData.every((prev, index) => prev.id === rows[index]?.id);

        if (isDataSame && !flagImport) {
          setMappingErrors('Success');
          return;
        }

        const addPayment = {
          payment_processors: payloadPayment,
        };

        manageRevenueReportTypeMapping(
          {
            revenueId: reportTypeId,
            type:      title === 'Payment Processors' ? 'add_payment_processors ' : 'add_categories ',
            payload:   (title === 'Payment Processors' ? addPayment : addCategory),
          },
          {
            onSuccess: (res) => {
              setMappingErrors('Success');
              const excludeNullRows = rows?.filter((row) => row?.id !== null) || [];
              setRows(excludeNullRows);
              if (title === 'Payment Processors') {
                handleUpdateData(res);
              } else {
                refetchRevenueReport?.();
              }
            },
            onError: (error) => {
              const errorMsg = error?.message || 'Something went wrong. Please try again.';
              setMappingErrors(errorMsg);
              toastr.error(
                errorMsg,
                'Error',
              );
            },
          },
        );
      } catch (error) {
        const errorMessage = (error as Error)?.message || 'An unknown error occurred';
        toastr.error(`Failed to create: ${errorMessage}`, 'Error');
      }
    },
  }));

  const handleConfirmDelete = () => {
    if (deletedData !== null) {
      if (deletedData.id === null) {
        setRows((prevRows) => prevRows.filter((_, index) => index !== deletedData.rowIndex));
        setDeletedData(null);
      } else {
        manageRevenueReportTypeMapping(
          {
            revenueId: reportTypeId,
            type:      'remove_categories',
            payload:   {
              id:                           reportTypeId,
              revenue_service_category_ids: deletedData?.id ?? 0,
            },
          },
          {
            onSuccess: () => {
              refetchRevenueReport?.();
              setDeletedData(null);
            },
            onError: () => {
              refetchRevenueReport?.();
            },
          },
        );
      }
    }
  };

  const confirmModal = useConfirmDeleteModal({
    onDone:   handleConfirmDelete,
    onCancel: () => setDeletedData(null),
  });

  const deleteRow = (rowIndex: number) => {
    setDeletedData({
      ...rows[rowIndex],
      rowIndex,
    });

    confirmModal.open();
  };

  return (
    <div className={ styles['editable-table-container'] }>
      <h1>
        <b>{title}</b>
      </h1>
      <br />
      <confirmModal.Component
        confirmTitle="Yes, Delete"
        text={ `All Revenue Report amount of this ${title} will be deleted too. Are you sure you want to continue?` }
        title={ `Delete ${deletedData?.lineItemName}` }
        { ...confirmModal.props }
      />
      <Table
        deleteRow={ deleteRow }
        errors={ errors }
        handleChange={ handleChange }
        handleSelectChange={ handleSelectChange }
        refetchRevenueReport={ refetchRevenueReport }
        reportTypeId={ reportTypeId }
        rowData={ rows }
        sorting={ {
          data:                   { orderColumn: sortColumn, orderDirection: sortDirection },
          getColumnSortDirection: (column) => (column === sortColumn ? sortDirection : undefined),
          toggleColumSort:        handleSort,
          sortColum:              (column: keyof RowData, direction: 'asc' | 'desc') => {
            setSortColumn(column);
            setSortDirection(direction);
            handleSort(column);
          },
          clear: clearSorting,
        } }
        title={ title }
      />
      <Button className={ styles['add-button'] } variant="link" onClick={ addNewRow }>
        Add New Item/Service
      </Button>
    </div>
  );
});

EditableTable.displayName = 'EditableTable';

export default EditableTable;
