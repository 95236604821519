import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { notAuthorizedApiRequest, apiGet, apiPost } from '../helpers';

interface IPostIntegrationAuthorizationResponse {
  id: TID
  businessId: TID,
  isAuthorized: boolean
}

interface IGetIntegrationAuthorizationUrlParams {
  integrationId: TID,
}

interface IGetIntegrationAuthorizationUrlResponse {
  authorizationUrl: string
}

interface ITriggerIntegrationAuthorizationParams {
  integrationId: TID,
  authorizationType: string,
}

const postIntegrationAuthorization = (
  params: object,
): Promise<IPostIntegrationAuthorizationResponse> => {
  return notAuthorizedApiRequest({
    method: 'POST',
    url:    '/integrations/api/v1/integration_authorizations/callback',
    params,
  }).then((data) => {
    return camelizeKeys(data) as IPostIntegrationAuthorizationResponse;
  });
};

const getIntegrationAuthorizationUrl = (
  params: IGetIntegrationAuthorizationUrlParams,
): Promise<IGetIntegrationAuthorizationUrlResponse> => {
  return apiGet(
    `integrations/api/v1/integrations/${params.integrationId}/integration_authorizations/new`,
  ).then((data) => <any>camelizeKeys(data));
};

const triggerIntegrationAuthorization = (
  params: ITriggerIntegrationAuthorizationParams,
): Promise<IGetIntegrationAuthorizationUrlResponse> => {
  return apiPost(
    '/integrations/api/v1/integration_authorizations',
    underscoreKeys(params),
  ).then((data) => <any>camelizeKeys(data));
};

export {
  postIntegrationAuthorization,
  getIntegrationAuthorizationUrl,
  IGetIntegrationAuthorizationUrlParams,
  IGetIntegrationAuthorizationUrlResponse,
  triggerIntegrationAuthorization,
  ITriggerIntegrationAuthorizationParams,
};
