import React, { useEffect, useMemo, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { useGetPaymentProcessors } from '@src/hooks/queries/bank_account_reconciliations';
import { ITSDComponentProps } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';
import {
  useSplitCategory,
} from '@src/components/reconciliation_center/all_transactions/sidebar/transactionType/use_split_category';
// eslint-disable-next-line max-len
import CategorySplitsField from '@src/components/reconciliation_center/match_documents/add_adjustment/category_splits_field/index';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import SidebarSelectInput from './sidebar_select_input';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

const Revenue = ({ rowData, setRowData, verify }: ITSDComponentProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } =
    useTxnDoc({ rowData, setRowData });

  const {
    isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo, documentRelated,
  } = commonProps;

  const business = useBusinessContext();
  const [categorySplits, setCategorySplits] = useState<ICategorySplit[]>([]);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);

  const { revenueCenterEnabled } = business;
  const processorsQuery = useGetPaymentProcessors({
    businessId: business.id,
  });

  const processorOptions = useMemo(() => {
    const processors = processorsQuery.data?.paymentProcessors;
    if (!processors) return [];
    return processors.filter((i) => i.selectedInCurrentBusiness).map((i) => ({ value: String(i.id), label: i.name }));
  }, [processorsQuery.data?.paymentProcessors]);

  const processorValue = processorOptions.find((i) => i.value === String(rowData.userPaymentProcessorId));

  const createServiceDocumentSplits = useCreateServiceDocumentSplits();

  const { categoryLength, getCategorySplits, handleCategorySplitsChange } = useSplitCategory({
    rowData,
    businessId: business.id,
    setCategorySplits,
    setSuccessMsg,
  });

  useEffect(() => {
    const loadCategorySplits = async () => {
      const splits = await getCategorySplits();
      setCategorySplits(splits);
    };

    loadCategorySplits();
  }, [getCategorySplits, setCategorySplits]);

  useEffect(() => {
    let disabled = true;

    if (((!revenueCenterEnabled && categoryLength > 0) || revenueCenterEnabled) && processorValue && documentRelated) {
      disabled = false;
    }

    verify(disabled);
  }, [categoryLength, revenueCenterEnabled, processorValue, documentRelated, verify]);

  return (
    <>
      {isSubmitting && <Spinner />}
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="Payment Processor*"
          options={ processorOptions }
          placeholder="Select Payment Processor"
          value={ processorValue }
          onChange={ (val) => updateFields({ paymentProcessorId: val, userPaymentProcessorId: val }) }
        />
        {!revenueCenterEnabled
            && (
              <div className={ styles['sidebar-data'] }>
                <CategorySplitsField
                  isBusinessReadonly
                  adjustmentAmount={ rowData.amount }
                  isReadonly={ isDisabled }
                  label="Category*"
                  summaryTitle="Invoice"
                  value={ categorySplits }
                  onChange={ handleCategorySplitsChange }
                />
              </div>
            )}

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </>
  );
};

export default Revenue;
