import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpsertIntegration } from '@src/hooks/queries/integrations/integrations';
import { useUpdateRevenueSystem } from '@src/hooks/queries/revenue_systems';
import { IIntegration } from '@src/types/integrations/integrations';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import Form from './form';
import { IChangeMappingValues } from './schema';
import { useAddReportTypeModal } from '../../report_type/add_report_type/add_report_type_modal';

import styles from './styles.module.scss';

interface IChangeMappingRevenueReportModalProps extends IUseModalProps {
  integration?: IIntegration,
  revenueSystem?: IRevenueSystem,
  revenueService: IRevenueService,
}

const ChangeMappingRevenueReportModal = ({
  isOpen,
  onDone,
  onCancel,
  integration,
  revenueSystem,
  revenueService,
  ...props
}: IChangeMappingRevenueReportModalProps) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [createMapping, setCreateMapping] = useState(false);

  const addReportTypeModal = useAddReportTypeModal();

  const business = useBusinessContext();

  const upsert = useUpsertIntegration();
  const { mutate } = upsert;

  const updateRevenueSystem = useUpdateRevenueSystem();
  const { mutate: updateRevenueSystemMutate } = updateRevenueSystem;

  const handleError = useCallback((response) => {
    toastr.error(response?.response?.data?.errors[0], 'Error');
  }, []);

  const ImportExistingMapping = useCallback((data: IChangeMappingValues) => {
    if (integration) {
      mutate({
        businessId:  business.id,
        integration: {
          externalSystemId:     integration.externalSystemId,
          startDate:            integration.externalSystemConfig?.startDate?.toString() || '',
          revenueReportTypeId:  data.revenueReportTypeId,
          externalSystemConfig: {},
          location:             integration.externalSystemConfig?.location?.toString() || '',
          id:                   integration.id || undefined,
        },
      }, {
        onSuccess: () => {
          onDone();
          toastr.success(
            'Integration has been updated',
            'Success',
          );
        },
        onError: handleError,
      });
    } else if (revenueSystem) {
      updateRevenueSystemMutate(
        {
          revenueServiceId: revenueService.id,
          id:               revenueSystem.id,
          data:             {
            revenueReportTypeId: data.revenueReportTypeId,
          },
        },
        {
          onSuccess: () => {
            onDone();
            toastr.success(
              'Integration has been updated',
              'Success',
            );
          },
          onError: handleError,
        },
      );
    }
  }, [
    business.id,
    handleError,
    mutate,
    onDone,
    integration,
    revenueSystem,
    revenueService,
    updateRevenueSystemMutate,
  ]);

  const createNewMapping = useCallback(() => {
    setCreateMapping(true);
    addReportTypeModal.open();
    onDone();
  }, [addReportTypeModal, onDone]);

  return (
    <>
      <Modal
        show={ isOpen }
        title="Change Mapping"
        { ...props }
      >
        <>
          <Modal.Body>
            <p className={ styles['description-text'] }>
              New Revenue Mapping will be updated and the previous mapping will be disabled.
              You can select an already existing mapping or create a new mapping. If you choose
              to create a new mapping, your current mapping will be saved in our logs
            </p>
            <Form
              formId="select_existing_mapping_form"
              onSubmit={ ImportExistingMapping }
              onValidationChange={ setIsFormValid }
            />
            <div className={ styles['divider-or'] }>
              <span>OR</span>
            </div>
            <div className={ styles['wrapper-button'] }>
              <Button variant="link" onClick={ createNewMapping }>Create New Mapping</Button>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-footer-v2">
            <Button
              className="btn-outline"
              variant="link"
              onClick={ onCancel }
            >
              Go Back
            </Button>
            <Button
              disabled={ !isFormValid }
              form="select_existing_mapping_form"
              type="submit"
              variant="primary"
            >
              Continue
            </Button>
          </Modal.Footer>
        </>
      </Modal>
      {createMapping && (
        <addReportTypeModal.Component
          changeMapping
          integration={ integration }
          revenueService={ revenueService }
          revenueSystem={ revenueSystem }
          { ...addReportTypeModal.props }
        />
      )}
    </>
  );
};

const useChangeMappingRevenueReportModal = makeUseModal(ChangeMappingRevenueReportModal);

export {
  useChangeMappingRevenueReportModal,
  ChangeMappingRevenueReportModal as default,
};
