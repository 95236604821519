import React, { useEffect, useMemo, useState } from 'react';

import { useGetDefaultAccountingFirm, useImportClientsFromLedger } from '@src/hooks/queries/signup';
import { setBusinessInfo } from '@src/requests/signup';
import { IBusiness } from '@src/types/businesses';

import { ErrorNotification } from '@src/components/ui/notification';

import OnboardingPage from '../layout/onboarding_page';

import styles from '../styles.module.scss';

const ConnectLedgerProgress = () => {
  const [progress, setProgress] = useState<number>(0);

  const query = useGetDefaultAccountingFirm();
  const business = useMemo(() => (query?.data as IBusiness) || {}, [query]);
  const { isError, isSuccess, error } = useImportClientsFromLedger(business.id);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (!isSuccess) {
        setProgress((prev) => prev + 20);
      } else {
        clearInterval(progressInterval);
        setProgress(100);

        setBusinessInfo({
          setupConfig: {
            importClientsFromLedgerDone: true,
          },
          businessId: business.id,
        }).then(() => {
          window.Docyt.currentAdvisor.get(
            'default_accounting_firm',
          ).setup_config.import_clients_from_ledger_done = true;
          Backbone.history.navigate('setup_accounting_firm?step=billing', {
            trigger: true,
          });
        });
      }
    }, 1000);

    return () => clearInterval(progressInterval);
  }, [isSuccess, setProgress, business.id]);

  return (
    <>
      { isError && <ErrorNotification error={ error } /> }
      <OnboardingPage isHideBorder title="Setting up your account...">
        <div className={ styles['onboarding-form'] }>
          <div className={ styles['onboarding-body'] }>
            <div className="display-flex p-b-20 font-14 justify-content-center">
              Please wait while we setup your account...
            </div>
            <div className={ styles['progress-container'] }>
              <div
                className={ styles['progress-bar'] }
                style={ { width: `${progress}%` } }
              />
            </div>
          </div>
        </div>
      </OnboardingPage>
    </>
  );
};

export default React.memo(ConnectLedgerProgress);
