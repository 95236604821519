import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { IBusiness } from '@src/types/businesses';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import Form from '@src/components/ui_v2/form';

import {
  IManualRevenueSystemValues,
  createManualRevenueSystemValidation,
} from './schema';

interface IManualRevenueSystemProps {
  business: IBusiness,
  formId: string,
  valueForm?: IManualRevenueSystemValues,
  onSubmit: (data: IManualRevenueSystemValues) => void;
  onValidationChange: (isValid: boolean) => void;
}

const ManualRevenueSystemForm = ({
  business,
  formId,
  valueForm,
  onSubmit,
  onValidationChange,
}: IManualRevenueSystemProps) => {
  const featureSkipRevenueReportTypeValidation = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.SKIP_REVENUE_REPORT_MAPPING,
  );
  // Determine whether to validate revenueReportTypeId
  const validationSchema = createManualRevenueSystemValidation(
    !featureSkipRevenueReportTypeValidation.data,
  );

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<IManualRevenueSystemValues>({
    mode:          'onChange',
    resolver:      yupResolver(validationSchema),
    defaultValues: {
      startDate: valueForm ? valueForm.startDate : '',
      name:      valueForm ? valueForm.name : '',
    },
  });

  const dateControl = useController({ control, name: 'startDate' });
  const rrtControl = useController({ control, name: 'revenueReportTypeId' });
  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Form.TextField
        hideClear
        readOnly
        label="Business"
        value={ business.name }
      />

      <Form.TextField
        error={ errors.name?.message }
        label="Revenue System Name"
        placeholder="Enter Revenue System Name"
        { ...register('name') }
      />

      <Form.DateField
        error={ errors.startDate?.message }
        label="Start Date"
        { ...omit(dateControl.field, 'ref') }
      />

      {!featureSkipRevenueReportTypeValidation.data && (
        <RevenueReportTypeField
          businessId={ business.id }
          error={ errors.revenueReportTypeId?.message }
          label="Revenue Mapping"
          placeholder="Select Revenue Mapping"
          { ...omit(rrtControl.field, 'ref') }
        />
      )}
    </Form>
  );
};

export default React.memo(ManualRevenueSystemForm);
