import React from 'react';

import { IDocument } from '@src/types/documents';

import { Accordion } from '@src/components/ui_v2/accordion';

import { useRelatedDocumentsCollection } from './hooks';
import RelatedDocumentsContent from './related_documents_content';

interface IRelatedDocumentsProps {
  document: IDocument,
  showAccordion?: boolean,
}

const RelatedDocuments = ({
  document,
  showAccordion = true,
}: IRelatedDocumentsProps) => {
  const collection = useRelatedDocumentsCollection({ documentId: document.id });

  return showAccordion ? (
    <Accordion title="Related Documents">
      { () => <RelatedDocumentsContent collection={ collection } document={ document } /> }
    </Accordion>
  ) : (
    <RelatedDocumentsContent collection={ collection } document={ document } />
  );
};

export default React.memo(RelatedDocuments);
