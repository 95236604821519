import React from 'react';

import { uiStyleProps } from '@src/utils/ui_style_helpers';

import styles from './styles.module.scss';

export interface BadgeProps {
  label: string;
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'warning' | 'success' | 'error';
}

export const Badge: React.FC<BadgeProps> = ({
  label,
  variant = 'primary',
  children,
  ...props
}) => {
  const [classes, spanProps] = uiStyleProps(styles.badge, props, styles[variant]);

  return (
    <span className={ classes } { ...spanProps }>
      {label || children}
    </span>
  );
};
