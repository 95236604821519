import React, { useCallback } from 'react';

import { components, GroupBase, StylesConfig } from 'react-select';
import { LoadOptions } from 'react-select-async-paginate';

import { useGetAllChartOfAccountsForBusiness } from '@src/hooks/queries/chart_of_accounts';
import { getAllChartOfAccountsForBusiness } from '@src/requests/chart_of_accounts';
import { IChartOfAccount } from '@src/types/chart_of_accounts';
import { TID } from '@src/types/common';

import { ChartOfAccountAvatar } from '@src/components/ui/avatars';
import { AsyncSelectPaginateInput } from '@src/components/ui/form';

interface IChartOfAccountInputProps
{
  businessId: TID,
  handleSelected: (items: any) => void,
  initValue?: string,
  selectedValue?: string,
}

const formatOptionLabel = (item: IChartOfAccount): JSX.Element => {
  return (
    <div
      className="display-inline-flex width-100-percent"
      style={ { padding: '8px 12px' } }
    >
      <ChartOfAccountAvatar item={ item } />
      <span className="m-t-2 m-l-10">{ item.displayName }</span>
    </div>
  );
};

const getOptionLabel = (item: IChartOfAccount) => item.displayName;
const getOptionValue = (item: IChartOfAccount) => String(item.id);

const customStyles: StylesConfig<IChartOfAccount, any> = {
  control: (provided) => (
    {
      ...provided,
      'backgroundColor': 'white',
      'border':          '1px solid #070c251f',
      'padding':         '0',
      'borderRadius':    '6px',
      'boxShadow':       'none',
      '&:hover':         {
        border: '1px solid #4d4eff4d',
      },
    }
  ),

  valueContainer: (provided) => (
    {
      ...provided,
      backgroundColor: 'white',
      border:          'none',
    }
  ),

  option: (provided) => (
    {
      ...provided,
      padding: 0,
    }
  ),

  menu: (provided) => (
    {
      ...provided,
      minWidth: '300px',
      width:    '300px',
      margin:   '2px 0 0',
    }
  ),

  menuList: (provided) => (
    {
      ...provided,
      padding: '0 0 2px',
    }
  ),

  clearIndicator: (provided) => (
    {
      ...provided,
      color: '#5b6875',
    }
  ),
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator { ...props }>
      <i className="fa fa-remove" />
    </components.ClearIndicator>
  );
};

const ChartOfAccountInput = ({
  businessId,
  handleSelected,
  initValue,
  selectedValue,
}: IChartOfAccountInputProps) => {
  const { data: arr } = useGetAllChartOfAccountsForBusiness(
    { businessId,
      includeMappedClasses: true,
      withDocumentCounts:   true,
      chartOfAccountIds:    [initValue as string] },
    { enabled: Boolean(initValue) },
  );

  const initialItem = arr && arr[0];

  const handleSource: LoadOptions<IChartOfAccount, GroupBase<IChartOfAccount>, any> = useCallback(
    (query, options, { page }) => {
      if (!businessId) return { options: [] };

      return getAllChartOfAccountsForBusiness({
        businessId,
        search:               query,
        includeMappedClasses: true,
        withDocumentCounts:   true,
        page,
      }).then((data) => {
        const hasMore = data.length > 0;

        return {
          hasMore,
          options:    data,
          additional: {
            page: page + 1,
          },
        };
      });
    },
    [businessId],
  );

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(selectedValue as string);
      }
    }
  }, [selectedValue]);

  return (
    <AsyncSelectPaginateInput
      key={ String(Boolean(initialItem)) }
      isClearable
      additional={ {
        page: 1,
      } }
      components={ {
        DropdownIndicator: null,
        ClearIndicator,
      } }
      debounceTimeout={ 300 }
      defaultValue={ initialItem }
      formatOptionLabel={ formatOptionLabel }
      getOptionLabel={ getOptionLabel }
      getOptionValue={ getOptionValue }
      loadOptions={ handleSource }
      placeholder="Add Account"
      styles={ customStyles }
      onChange={ handleSelected }
      onKeyDown={ handleKeyDown }
    />
  );
};

export default ChartOfAccountInput;
