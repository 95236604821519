import React, { useCallback, useMemo, useState } from 'react';

import { useAtomValue } from 'jotai';

import { useGetWidgets } from '@src/hooks/queries/dashboards/widgets';
import { TDateRange, TID, TMongoID } from '@src/types/common';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';

import { toggleTargetsState, toggleViewState } from '@src/components/business_dashboards/atoms';
import { DefaultTargets } from '@src/components/business_dashboards/edit_targets/default_targets';
import Spinner from '@src/components/ui/spinner';
import { DateRangeInput } from '@src/components/ui_v2/inputs';

import Table from '../tabular_view';
import BriefChartView from './brief_chart_view';
import { getDefaultDateRange } from '../charts/chart_helpers';

import styles from './styles.module.scss';

interface IBriefViewProps {
  businessId: TID,
  dashboardId: TMongoID,
  targets: Record<string, number>,
  standardCategoryId: number,
  onChange?: (dateRange: TDateRange) => void,
}

const BriefView = ({
  businessId,
  dashboardId,
  targets,
  standardCategoryId,
}: IBriefViewProps) => {
  const defaultRange = getDefaultDateRange(window.Docyt.Common.Constants.DASHBOARD_WIDGET_SCALE.MONTHLY);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: formatDate(defaultRange.from, API_DATE_FORMAT),
    endDate:   formatDate(defaultRange.to, API_DATE_FORMAT),
  });
  const [chartViewDateRange, setChartViewDateRange] = useState<TDateRange>(dateRange);

  const query = useGetWidgets({
    dashboardId,
    from: chartViewDateRange.startDate,
    to:   chartViewDateRange.endDate,
  }, () => {
    setIsLoading(false);
  });
  const widgets = useMemo(() => {
    return query.data?.collection || [];
  }, [query.data?.collection]);

  const handleDateRange = useCallback((range: TDateRange) => {
    setDateRange(range);
    if (!!range.startDate && !!range.endDate) {
      setIsLoading(true);
      setChartViewDateRange(range);
    }
  }, [setDateRange, setChartViewDateRange]);

  const getDefaultTarget = (identifier: string) => {
    const defaultTarget = DefaultTargets[standardCategoryId.toString()];
    if (!defaultTarget) return 0;

    return defaultTarget[identifier]?.value || 0;
  };

  const isChartView = useAtomValue(toggleViewState);
  const showTargets = useAtomValue(toggleTargetsState);
  const renderTableView = () => (
    <Table collection={ widgets } standardCategoryId={ standardCategoryId } targets={ targets } />
  );

  const renderChartView = () => (
    <>
      <div className={ styles.range }>
        <DateRangeInput
          value={ dateRange }
          onChange={ handleDateRange }
        />
      </div>
      { isLoading && <Spinner /> }
      {
        widgets.map((widget) => (
          <BriefChartView
            key={ widget.id }
            businessId={ businessId }
            className="col-sm-6 col-md-4 col-lg-4 pointer"
            dashboardId={ dashboardId }
            dateRange={ chartViewDateRange }
            target={ showTargets ? (targets[widget.identifier] || getDefaultTarget(widget.identifier)) : undefined }
            widget={ widget }
          />
        ))
      }
    </>
  );

  return (
    isChartView ? renderChartView() : renderTableView()
  );
};

export default BriefView;
