import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import ReconciliationCenterProvider from '@src/components/providers/reconciliation_center_provider';
import Root from '@src/components/root';

import AllTransactions from './all_transactions';

interface TransactionsForReviewPageProps {
  businessId: TID,
  type?: string,
}

const TransactionsForReviewPage = ({
  businessId,
  type,
}: TransactionsForReviewPageProps) => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <ReconciliationCenterProvider businessId={ businessId }>
          <AllTransactions
            title={
              type === window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTION_FOR_REVIEW
                ? 'Transactions for Review'
                : ''
            }
            type={ type }
          />
        </ReconciliationCenterProvider>
      </BusinessProvider>
    </Root>
  );
};

export default TransactionsForReviewPage;
