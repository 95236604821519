import { SelectOption } from './schema';

export const SelectOnTableStyles = (hasError: boolean) => ({
  control: (base: any) => ({
    ...base,
    background:   '#eceded',
    border:       hasError ? '1px solid #ff4848' : 'none',
    boxShadow:    'none',
    cursor:       'pointer',
    borderRadius: '10px',
    alignContent: 'center',
    fontSize:     12,
    paddingLeft:  '2rem',
    paddingRight: '2rem',
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 9999999,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999999,
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f0f0f0' : '#fff',
    color:           '#333',
    cursor:          'pointer',
  }),
  container: (base: any) => ({
    ...base,
    width: '100%',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
  }),
});

export const getNormalizedValue = (value?: SelectOption | null): SelectOption | null => {
  // Return the value if it's not null/undefined and either:
  // - value.value is explicitly true or false
  // - both value.label and value.value are truthy
  return value && (value.value === true || value.value === false || (value.label && value.value))
    ? value
    : null;
};
