import React, { useMemo } from 'react';

import { IMonthEndClosingProps } from '@src/types/common_operational_dashboard';
import { formatDate } from '@src/utils/date_helpers';

import NumberBox from '@src/components/operational_dashboard/components/custom_cells/number_box';
import { auditScanTooltipData, missingBankStatementTooltipData } from '@src/components/operational_dashboard/helper';
import { ICommonProps } from '@src/components/operational_dashboard/types';
import { Button } from '@src/components/ui_v2/buttons';
import { NotepadIcon } from '@src/components/utils/icomoon';

import AuditScanItem from './audit_scan_item';
import HoverTooltipWrapper from './hover_tooltip_wrapper';
import ReconciliationItem from './reconciliation_item';
import { useMarkBookAsClosedModal } from '../mark_book_as_closed';

import styles from './style.module.scss';

type IMonthEndClosingCellProps = IMonthEndClosingProps & ICommonProps & {
  handleNotesViewClick: () => void;
};

const MonthEndClosingCell = ({
  missingBankStatements,
  auditScan,
  auditScanStatus,
  lastAuditScanDate,
  notes,
  preferences,
  setupStatus,
  handleNotesViewClick,
  disconnectedGeneralLedger,
  auditId,
  businessId,
  businessName,
  lastBookLockDate,
  auditStartDate,
  businessIntent,
}: IMonthEndClosingCellProps) => {
  const {
    Component: MarkBookAsClosedModal,
    props: markBookAsClosedModalProps,
    openWithValue: openMarkBookAsClosedModalWithValue,
  } = useMarkBookAsClosedModal();

  const renderGeneralLedgerConnectionIssues = useMemo(() => {
    if (disconnectedGeneralLedger) {
      return <NumberBox value={ { value: true, type: 'indicator' } } />;
    }
    return <NumberBox value={ { value: 0, type: 'number' } } />;
  }, [disconnectedGeneralLedger]);

  const auditScanCount = useMemo(() => {
    return auditScan ?? 0;
  }, [auditScan]);

  return (
    <div className={ `${styles['business-reconciliation-container']} ${styles['expense-header-container']}` }>
      <MarkBookAsClosedModal
        { ...markBookAsClosedModalProps }
      />
      {preferences?.monthEndClosing?.missingBankStatements
        && (missingBankStatements != null ? (
          <div className={ styles['header-item'] }>
            <ReconciliationItem
              setupStatus={ setupStatus }
              tooltipData={ missingBankStatementTooltipData }
              tooltipValue={ missingBankStatements }
              value={ missingBankStatements }
            />
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            {renderGeneralLedgerConnectionIssues}
          </div>
        ))}
      {preferences?.monthEndClosing?.auditScan && (auditScan != null ? (
        <div className={ styles['header-item'] }>
          {businessIntent === window.configData.business_intent.FULL_BOOKKEEPING
            || businessIntent === window.configData.business_intent.PREMIUM_FIRM_CLOSING_SUITE ? (
              <NumberBox value={ { value: 0, type: 'number' } } />
            ) : (
              <AuditScanItem
                auditScanStatus={ auditScanStatus ?? 'completed' }
                businessId={ businessId }
                businessName={ businessName }
                disconnectedGeneralLedger={ disconnectedGeneralLedger ?? false }
                lastAuditedOn={ formatDate(lastAuditScanDate) }
                tooltipData={ auditScanTooltipData }
                value={ auditScanCount }
              />
            )}
        </div>
      ) : (
        <div className={ styles['header-item'] }>
          {renderGeneralLedgerConnectionIssues}
        </div>
      ))}
      {preferences?.monthEndClosing?.lastCloseDate && (
        !disconnectedGeneralLedger ? (
          <div className={ styles['header-item'] }>
            {businessIntent === window.configData.business_intent.FULL_BOOKKEEPING
              || businessIntent === window.configData.business_intent.PREMIUM_FIRM_CLOSING_SUITE ? (
                <NumberBox value={ { value: 0, type: 'number' } } />
              ) : (
                <HoverTooltipWrapper
                  content={ (
                    <div className={ styles['hover-tooltip-content-last-close-date'] }>
                      <Button
                        size="compact"
                        variant="link"
                        onClick={ () => {
                          openMarkBookAsClosedModalWithValue({
                            auditId:          auditId ?? 0,
                            businessId:       businessId ?? 0,
                            lastBookLockDate: lastBookLockDate ?? '',
                            auditStartDate:   auditStartDate ?? '',
                          });
                        } }
                      >
                        Mark Books As Closed
                      </Button>
                    </div>
                  ) }
                >
                  { lastBookLockDate
                    ? formatDate(lastBookLockDate)
                    : <NumberBox value={ { value: 0, type: 'number' } } />}
                </HoverTooltipWrapper>
              )}
          </div>
        ) : (
          <div className={ styles['header-item'] }>
            <NumberBox value={ { value: true, type: 'indicator' } } />
          </div>
        )
      )}

      { preferences?.monthEndClosing?.notes && (
        <div className={ styles['header-item'] }>
          { notes != null ? (
            <HoverTooltipWrapper
              content={ (
                <div className={ styles['hover-tooltip-content-last-close-date'] }>
                  <Button
                    size="compact"
                    variant="link"
                    onClick={ handleNotesViewClick }
                  >
                    View Notes Log
                  </Button>
                </div>
                ) }
            >
              <NotepadIcon fontSize={ 16 } onClick={ handleNotesViewClick } />
            </HoverTooltipWrapper>
          ) : (
            <NumberBox value={ { value: 0, type: 'number' } } />
          ) }
        </div>
      ) }

    </div>
  );
};
export default MonthEndClosingCell;
