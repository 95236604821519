import React, { useCallback, useMemo } from 'react';

import { flatten } from 'lodash';

import { useGetAuditScanNotesInfiniteScroll } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { TID } from '@src/types/common';

import ProgressActivity from '@src/components/ui_v2/progress_activity_loader/progress_activity_loader';

import BookClosedNoteTable from './book_closed_table';

import styles from './style.module.scss';

interface IBookClosedNoteListProps {
  businessId: TID;
}

const BookClosedNoteList = ({ businessId }: IBookClosedNoteListProps) => {
  const NoNotes = useCallback(() => {
    return (
      <div className={ styles.noNotes }>
        No notes found
      </div>
    );
  }, []);

  const query = useGetAuditScanNotesInfiniteScroll({ businessId });

  const closedNotesPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const closedNotes = useMemo(() => {
    return flatten(closedNotesPages.map((item) => {
      return item.collection;
    }));
  }, [closedNotesPages]);

  if (query.isLoading) {
    return (
      <div className={ styles.noNotes }>
        <ProgressActivity size="xlarge" />
      </div>
    );
  }

  if (query.data?.pages[0].meta.totalCount === 0) {
    return <NoNotes />;
  }

  return (
    <div>
      <BookClosedNoteTable
        bookCloseNotes={ closedNotes }
        query={ query }
      />
    </div>
  );
};

export default BookClosedNoteList;
