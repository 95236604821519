import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import { addReportTypeValidation, IAddReportTypeValues } from './schema';

import styles from './styles.module.scss';

interface IAddReportTypeFormProps {
  changeMapping: boolean;
  formId: string;
  onSubmit: (data: IAddReportTypeValues) => void;
}

const AddReportTypeForm = ({
  changeMapping,
  formId,
  onSubmit,
}: IAddReportTypeFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IAddReportTypeValues>({
    defaultValues: {
      category_ids: [],
      tax_ids:      [],
      ledger_ids:   [],
    },
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(addReportTypeValidation),
  });

  const handleSubmitForm = (data: IAddReportTypeValues) => {
    const finalData: IAddReportTypeValues = { ...data };
    onSubmit(finalData);
  };

  return (
    <Form id={ formId } onSubmit={ handleSubmit(handleSubmitForm) }>
      <p className={ styles['description-text'] }>
        {changeMapping
          ? 'New Revenue Mapping will be updated and the previous mapping will be archived.'
          : 'Please add your revenue mapping name'}
      </p>
      <Form.TextField
        error={ errors.name?.message }
        label={ changeMapping ? 'New Map Name' : 'Name' }
        placeholder="Type Here"
        { ...register('name') }
      />
    </Form>
  );
};

export default AddReportTypeForm;
