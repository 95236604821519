/* eslint-disable import/order */
import React, {
  useMemo,
  useRef,
  useEffect,
  memo,
  useCallback,
} from 'react';
import { InfiniteData, useQueryClient } from 'react-query';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { flatten, unionBy } from 'lodash';
import { useGetMessageHistory } from '@src/hooks/queries/ai_chat/ai_chatbot';
import { useInfiniteScroll } from '@src/hooks/scroll';
import { QueryKey } from '@src/constants/query_keys';

import {
  threadsAtom,
  threadLoadingStatesAtom,
  activeThreadIdAtom,
} from '@src/components/ai_chatbot/atoms';
import {
  ChatMessageStatus,
  IGlobalMessageHistoryResponse,
} from '@src/components/ai_chatbot/types';
import styles from '@src/components/ai_chatbot/styles.module.scss';
import ChatMessageItem from '@src/components/ai_chatbot/components/chat_message_item';
import { useScroll } from '@src/hooks/contexts/ai_chatbot_scroll_context';

const Chat = () => {
  const queryClient = useQueryClient();
  const { chatAutoScrollRef, chatOuterContainerRef, scrollToBottom } = useScroll();

  const activeThreadId = useAtomValue(activeThreadIdAtom);
  const [threads, setThreads] = useAtom(threadsAtom);
  const setLoadingStates = useSetAtom(threadLoadingStatesAtom);

  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  const handleMessageHistorySuccess = useCallback(
    (data: InfiniteData<IGlobalMessageHistoryResponse>) => {
      // Save current scroll position before updating messages
      const container = chatOuterContainerRef.current;
      const scrollHeightBeforeFetch = container?.scrollHeight;
      const scrollTopBeforeFetch = container?.scrollTop;
      const shouldScrollToBottom = threads.data[activeThreadId]?.length === 0;

      const messageResponse = data?.pages || [];
      const fetchedMessages = flatten(
        messageResponse.map((p) => p.collection),
      ).map((msg) => ({
        ...msg,
        chatMessageStatus: ChatMessageStatus.AnswerReceivedFromSendbird,
      }));

      setThreads((prevThreads) => {
        const currentThreadMessages = prevThreads.data[activeThreadId] || [];

        // Find any pending messages (messages with messageSignature)
        const pendingMessages = currentThreadMessages.filter(
          (msg) => !msg.id
              && (msg.chatMessageStatus
                === ChatMessageStatus.QuestionSentToSendbird
                || msg.chatMessageStatus
                  === ChatMessageStatus.QuestionRegisteredInSendbird),
        );

        // Find any existing messages (messages with id)
        const existingMessages = currentThreadMessages.filter(
          (msg) => !!msg.id
              && msg.chatMessageStatus
                === ChatMessageStatus.AnswerReceivedFromSendbird,
        );

        // Combine existing messages with fetched messages and sort by timestamp
        // because this query is called also from useInfiniteScroll hook
        // and so it becomes necessary to combine the messages and sort them
        const combinedMessages = unionBy(
          [...fetchedMessages, ...existingMessages, ...pendingMessages],
          'channelQuestionMessageId',
        ).sort(
          (a, b) => new Date(b.timestamp!).getTime()
              - new Date(a.timestamp!).getTime(),
        );

        return {
          ...prevThreads,
          data: {
            ...prevThreads.data,
            [activeThreadId]: combinedMessages,
          },
        };
      });

      // Restore scroll position after state update
      setTimeout(() => {
        if (container) {
          const scrollTopToSet =
            container.scrollHeight - (scrollHeightBeforeFetch ?? 0) + (scrollTopBeforeFetch ?? 0);
          container.scrollTop = scrollTopToSet;
        }
      }, 0);

      if (shouldScrollToBottom) {
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }

      setLoadingStates((prev) => ({
        ...prev,
        [activeThreadId]: 'success',
      }));
    },
    [
      activeThreadId,
      setLoadingStates,
      setThreads,
      chatOuterContainerRef,
      scrollToBottom,
      threads.data,
    ],
  );

  const query = useGetMessageHistory(
    { chatThreadId: activeThreadId },
    {
      enabled:   !!activeThreadId,
      onSuccess: handleMessageHistorySuccess,
    },
  );

  /**
     * We cancel the query when the activeThreadId changes,
     * this happens when the user switches between threads
     * and the query is being made for the previous thread.
     */
  useEffect(() => {
    return () => {
      if (activeThreadId) {
        queryClient.cancelQueries([
          QueryKey.AIChatThreadMessages,
          activeThreadId,
        ]);
      }
    };
  }, [activeThreadId, queryClient]);

  useEffect(() => {
    if (activeThreadId && threads.data[activeThreadId]) {
      const hasPendingMessages = threads.data[activeThreadId].some(
        (msg) => msg.chatMessageStatus === ChatMessageStatus.QuestionSentToSendbird,
      );
      if (hasPendingMessages) {
        scrollToBottom();
      }
    }
  }, [activeThreadId, scrollToBottom, threads.data]);

  // update loading state for the active thread
  useEffect(() => {
    if (activeThreadId) {
      setLoadingStates((prev) => ({
        ...prev,
        [activeThreadId]: query.status,
      }));

      if (query.status === 'success') {
        setTimeout(() => {
          setLoadingStates((prev) => ({
            ...prev,
            [activeThreadId]: 'idle',
          }));
        }, 0);
      }
    }

    return () => {
      if (activeThreadId) {
        setLoadingStates((prev) => ({
          ...prev,
          [activeThreadId]: 'idle',
        }));
      }
    };
  }, [activeThreadId, query.status, setLoadingStates]);

  useInfiniteScroll({
    elementRef:   infiniteScrollRef,
    query,
    isTopReached: true,
  });

  const reversedMessages = useMemo(() => {
    if (!threads.data[activeThreadId]) return [];
    return threads.data[activeThreadId].slice().reverse();
  }, [activeThreadId, threads.data]);

  const renderSpinner = useCallback(() => {
    if (query.isLoading) {
      return (
        <div className={ styles['chat-no-message'] }>
          <div className={ styles['no-message-content'] }>
            <div className={ styles.spinner } />
          </div>
        </div>
      );
    }

    return null;
  }, [query.isLoading]);

  return (
    <div ref={ chatOuterContainerRef } className={ styles['chat-message-scroll-outer'] }>
      {!threads.data[activeThreadId] ? (
        renderSpinner()
      ) : (
        <div
          ref={ infiniteScrollRef }
          className={ styles['chat-message-scroll-inner'] }
        >
          {query.isFetchingNextPage && (
          <div className={ styles['chat-message-next-page-loader'] }>
            <div className={ styles.spinner } />
          </div>
          )}
          {reversedMessages?.map((item) => (
            <ChatMessageItem
              key={ item.id + item.messageSignature }
              answer={ item.answer }
              channelAnswerMessageId={ item.channelAnswerMessageId }
              channelQuestionMessageId={ item.channelQuestionMessageId }
              id={ item.id }
              messageSignature={ item.messageSignature }
              question={ item.question }
            />
          ))}
        </div>
      )}
      <div ref={ chatAutoScrollRef } />
    </div>
  );
};

Chat.displayName = 'Chat';

export default memo(Chat);
