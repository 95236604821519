import React from 'react';

import HoverTooltip from '@src/components/ui_v2/tooltip/hover_tooltip';

import styles from './style.module.scss';

interface IHoverTooltipWrapperProps{
    content:React.ReactNode
    children:React.ReactNode
}

const HoverTooltipWrapper = ({ content, children }:IHoverTooltipWrapperProps) => {
  return (
    <HoverTooltip
      keepShowOnHover
      content={ content }
      popoverParams={ {
        showDelay: 0,
      } }
      tooltipClassName={ styles['cell-tooltip'] }
      variant="light"
    >
      {children}
    </HoverTooltip>
  );
};

export default HoverTooltipWrapper;
