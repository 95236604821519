import React, { ReactNode, useMemo } from 'react';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { Button } from '@src/components/ui_v2/buttons';
import { WarningTriangleIcon } from '@src/components/utils/icomoon';

import HoverTooltipWrapper from '../hover_tooltip_wrapper';

import styles from './style.module.scss';

interface ReRunAuditScanButtonProps {
  status?: AuditScanDetails['status'];
  onRunAudit: () => void;
}

const ReRunAuditScanButton = ({ onRunAudit, status }: ReRunAuditScanButtonProps) => {
  const toolTipContent:ReactNode = useMemo(() => {
    if (status === 'failed') {
      return (
        <div>
          Audit scan failed. Click to retry and run the audit again.
        </div>
      );
    }
    if (status === 'broken') {
      return (
        <div>
          Changes detected in the general ledger. Re-run the audit to view the updated data.
        </div>
      );
    }
    return null;
  }, [status]);

  return (
    <HoverTooltipWrapper
      content={ toolTipContent }
    >
      <Button
        className={ styles['re-run-audit-scan-btn'] }
        size="compact"
        variant="outline"
        onClick={ onRunAudit }
      >
        <WarningTriangleIcon fontSize={ 20 } />
        Re-run Audit Scan
      </Button>
    </HoverTooltipWrapper>
  );
};

export default ReRunAuditScanButton;
