import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TID } from '@src/types/common';

import SideView from '@src/components/ui/side_view';

import BookClosedNoteList from './book_closed_note_list';

type TOpenValue = {
  businessId: TID;
};

type INotesSideViewProps = IUseModalProps<TOpenValue>;

const NotesSideView = ({
  isOpen,
  onCancel,
  openValue,
}: INotesSideViewProps) => {
  return (
    <SideView.Content isOpen={ isOpen }>
      <SideView.Header
        title="Book Closing Notes"
        onClose={ onCancel }
      />
      <SideView.Body>
        <BookClosedNoteList businessId={ openValue?.businessId ?? 0 } />
      </SideView.Body>
    </SideView.Content>
  );
};

const MemoizedNotesSideView = React.memo(NotesSideView);

const useNotesSideView = makeUseModal<typeof MemoizedNotesSideView, TOpenValue>(MemoizedNotesSideView);

export {
  useNotesSideView,
  MemoizedNotesSideView as default,
};
