import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { settingsIntegrationExternalSystemsPath } from '@src/routes';

import Sidemenu from '@src/components/revenue_center/sidemenu/sidemenu';
import { Button } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container/page_container';
import Panel from '@src/components/ui_v2/panel';
import { RevenueCenterLogo } from '@src/components/utils/img_icons';

import AddSystem from './add_system';
import { useConnectRevenueSystemModal } from './connect_system/connect_system_modal';

const RevenueCenterLanding = () => {
  const navigate = useNavigate();
  const service = useRevenueServiceContext();
  const featureQuery = useLDBusinessFeatureQuery(
    service.businessId,
    window.Docyt.Common.Constants.REVENUE_CENTER_LEFT_NAVIGATION,
  );

  const connectSystemModal = useConnectRevenueSystemModal();

  const handleConnectButton = () => {
    if (featureQuery?.data) {
      connectSystemModal.open();
    } else {
      navigate(settingsIntegrationExternalSystemsPath(service.businessId));
    }
  };

  return (
    <PageContainer sidemenu={ <Sidemenu /> }>
      <DetailsRegion>
        <DetailsRegion.Header title="Revenue System" />
        <Panel.Landing
          image={ <RevenueCenterLogo /> }
          text={
            'Docyt offers continuous reconciliation of merchant processor deposits. View your revenue in detail.'
              + ' Understand what you earn by the payment processor, by spend category, or by day.'
              + ' Connect your revenue system to get started!'
          }
        >
          <Button
            variant="primary"
            onClick={ handleConnectButton }
          >
            Connect Revenue System
          </Button>
          {!featureQuery?.isFetching && !featureQuery?.data && (
            <AddSystem revenueService={ service } />
          )}
          <connectSystemModal.Component
            { ...connectSystemModal.props }
          />
        </Panel.Landing>
      </DetailsRegion>
    </PageContainer>
  );
};

export default React.memo(RevenueCenterLanding);
