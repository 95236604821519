import React from 'react';

import { TID } from '@src/types/common';
import { IIssuesProps } from '@src/types/common_operational_dashboard';

import { DisconnectedBankAccountsTooltipData, DuplicateVendorsTooltipData,
  GeneralLedgerDisconnectedTooltipData, GeneralLedgerConnectionIssuesTooltipData,
  RevenueIntegrationsTooltipData, CoaSyncErrorsData } from '@src/components/operational_dashboard/helper';
import { isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps, TBusinessIntent } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import NumberBox from './number_box';
import CorporateCardsTooltip from '../tooltips/corporate_cards_tooltip';

import styles from './style.module.scss';

interface IIssuesCellProps extends ICommonProps {
    businessId?: TID;
    issuesData?: IIssuesProps;
    businessIntent?: TBusinessIntent;
}
const IssueCell = ({
  issuesData,
  setupStatus,
  businessId,
  preferences,
  businessIntent,
}: IIssuesCellProps) => {
  if (isSetupStatusComplete(setupStatus)) {
    return <h2 className={ styles['setup-heading'] }>Complete Setup</h2>;
  }

  const disconnectedGeneralLedger = issuesData?.disconnectedGeneralLedger ?? null;
  const disconnectedRevenueIntegration = issuesData?.disconnectedRevenueIntegration ?? null;
  const disconnectedBankAccounts = issuesData?.disconnectedBankAccounts ?? null;
  const generalLedgerConnectionIssues = issuesData?.generalLedgerConnectionIssues ?? null;
  const duplicateVendors = issuesData?.duplicateVendors ?? null;
  const chartOfAccountSyncErrors = issuesData?.chartOfAccountSyncErrors ?? null;

  return (
    <div className={ styles['business-issue-container'] }>
      <div className={ styles['issue-icon-list'] }>
        {preferences?.issues?.disconnectedLedger && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ disconnectedGeneralLedger ? (
                <CorporateCardsTooltip
                  businessId={ businessId }
                  item={ GeneralLedgerDisconnectedTooltipData }
                />
              ) : null }
            >
              <NumberBox
                value={ disconnectedGeneralLedger === null
                  ? { type: 'empty' }
                  : { value: disconnectedGeneralLedger, type: 'indicator' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.disconnectedRevenue && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ disconnectedRevenueIntegration ? (
                <CorporateCardsTooltip
                  businessId={ businessId }
                  item={ RevenueIntegrationsTooltipData }
                  value={ disconnectedRevenueIntegration }
                />
              ) : null }
            >
              <NumberBox
                isDanger={ Boolean(disconnectedRevenueIntegration && disconnectedRevenueIntegration > 0) }
                value={ disconnectedRevenueIntegration === null
                  ? { type: 'empty' }
                  : { value: disconnectedRevenueIntegration, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.disconnectedBank && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ (
                  disconnectedBankAccounts
                  && (
                  <CorporateCardsTooltip
                    businessId={ businessId }
                    item={ DisconnectedBankAccountsTooltipData }
                    value={ disconnectedBankAccounts }
                  />
                  )
                ) }
            >
              <NumberBox
                isDanger={ Boolean(disconnectedBankAccounts && disconnectedBankAccounts > 0) }
                value={ disconnectedBankAccounts === null
                  ? { type: 'empty' }
                  : { value: disconnectedBankAccounts, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.ledgerIssues && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ (
              generalLedgerConnectionIssues && (
              <CorporateCardsTooltip
                businessId={ businessId }
                item={ GeneralLedgerConnectionIssuesTooltipData }
                value={ generalLedgerConnectionIssues }
              />
              )
            ) }
            >
              <NumberBox
                isWarning={ Boolean(generalLedgerConnectionIssues && generalLedgerConnectionIssues > 0) }
                value={ generalLedgerConnectionIssues === null
                  ? { type: 'empty' }
                  : { value: generalLedgerConnectionIssues, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}

        {preferences?.issues?.duplicateVendor && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper content={ (
           duplicateVendors && (
           <CorporateCardsTooltip
             businessId={ businessId }
             businessIntent={ businessIntent }
             item={ DuplicateVendorsTooltipData }
             value={ duplicateVendors }
           />
           )
            ) }
            >
              <NumberBox
                isWarning={ Boolean(duplicateVendors && duplicateVendors > 0) }
                value={ duplicateVendors === null
                  ? { type: 'empty' }
                  : { value: duplicateVendors, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.chartOfAccountSyncErrors && (
          <div className={ styles['header-item'] }>

            <HoverTooltipWrapper content={ (
              chartOfAccountSyncErrors && (
              <CorporateCardsTooltip
                businessId={ businessId }
                businessIntent={ businessIntent }
                item={ CoaSyncErrorsData }
                value={ chartOfAccountSyncErrors }
              />
              )
                ) }
            >
              <NumberBox
                isWarning={ Boolean(chartOfAccountSyncErrors && chartOfAccountSyncErrors > 0) }
                value={ chartOfAccountSyncErrors === null
                  ? { type: 'empty' }
                  : { value: chartOfAccountSyncErrors, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
export default IssueCell;
