import React from 'react';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { RestoreIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

const ActivityAction = ({
  setIsShowAuditHistory,
}: {
  setIsShowAuditHistory?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <ActionsDropdown className={ styles['activity-action'] }>
      <ActionsDropdown.Action
        icon={ <RestoreIcon fontSize={ 27 } /> }
        title="Activity"
        onSelect={ () => {
          if (setIsShowAuditHistory) {
            setIsShowAuditHistory(true);
          }
        } }
      />
    </ActionsDropdown>
  );
};

export default React.memo(ActivityAction);
