import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';
import NewDocumentIcon from '@src/components/utils/img_icons/new_document_icon';

import styles from '../styles.module.scss';

const FileUpload = () => {
  return (
    <div className={ styles['file-upload-container'] }>
      <NewDocumentIcon />
      <p className={ styles['file-upload-text'] }>Drag and drop a document here.</p>
      <p className={ styles['file-upload-text-or'] }>OR</p>
      <Button variant="primary">
        Select from your computer
      </Button>
    </div>
  );
};

export default React.memo(FileUpload);
