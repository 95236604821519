import React, { useCallback, useMemo } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import toastr from '@lib/toastr';

import {
  activeChannelAtom,
  activeThreadIdAtom,
  firstMessageAtom,
} from '@src/components/ai_chatbot/atoms';
import { chatChannelCreationMessage, FAQ } from '@src/components/ai_chatbot/constants';
import { useChatMessageHandler } from '@src/components/ai_chatbot/hooks/use_chat_message_handler';
import useChatProvider from '@src/components/ai_chatbot/hooks/use_chat_provider';
import { Button } from '@src/components/ui_v2/buttons';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const FAQSection = () => {
  const { startNewThread } = useChatProvider();
  const { handleMessageSend } = useChatMessageHandler();
  const activeChannel = useAtomValue(activeChannelAtom);
  const activeThreadId = useAtomValue(activeThreadIdAtom);
  const setFirstMessage = useSetAtom(firstMessageAtom);

  const handleFAQClick = useCallback(
    (message: string) => async () => {
      if (!activeThreadId) {
        const messageSignature = crypto.randomUUID();
        setFirstMessage({ message, messageSignature });
        try {
          // Create a new channel with user + bot
          await startNewThread(message, messageSignature);
        } catch (error) {
          toastr.error(
            chatChannelCreationMessage.errorSendingMessage.message,
            chatChannelCreationMessage.errorSendingMessage.title
          );
        }
      } else {
        // In ChatView, just send the message
        if (activeChannel) {
          handleMessageSend(message);
        }
      }
    },
    [
      activeChannel,
      activeThreadId,
      handleMessageSend,
      setFirstMessage,
      startNewThread,
    ]
  );

  const faqButtons = useMemo(
    () => (
      <div>
        {FAQ.map((item) => (
          <Button
            key={ item.summary }
            className={ `${styles['chat-btn']} ${styles['is-ellipsis']}` }
            variant="link"
            onClick={ handleFAQClick(item.message) }
          >
            {item.summary}
          </Button>
        ))}
      </div>
    ),
    [handleFAQClick],
  ); // Only re-create when handleFAQClick changes

  return (
    <div className={ styles['chat-btn-section'] }>
      <div className={ styles['chat-list-header'] }>
        <h4 className={ styles['chat-list-heading'] }>PINNED</h4>
      </div>
      {faqButtons}
    </div>
  );
};

export default FAQSection;
