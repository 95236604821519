import React from 'react';

import styles from './styles.module.scss';

interface IFullWidthModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

const FullWidthModal = ({ isOpen, onClose, children, className }: IFullWidthModalProps) => {
  if (!isOpen) return <div className={ className }>{children}</div>;
  return (
    <div className={ `${styles['fullwidth-modal']} ${styles['modal-overlay']}` } role="dialog" onClick={ onClose }>
      <div className={ styles['modal-content'] } role="dialog" onClick={ (e) => e.stopPropagation() }>
        <div className={ `${styles['modal-body']} ${className}` }>
          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(FullWidthModal);
