import React from 'react';

import { FieldArrayWithId, UseFormRegister, FieldErrors } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';
import { TrashIcon } from '@src/components/utils/icomoon';

import { ITeamMemberFormValues } from '../schema';

import styles from '../styles.module.scss';

interface ITeamMemberFormItemProps {
  index: number;
  errors: FieldErrors<ITeamMemberFormValues>;
  register: UseFormRegister<ITeamMemberFormValues>;
  fields: FieldArrayWithId<ITeamMemberFormValues, 'members', 'id'>[]
  remove: (index?: number | number[]) => void;
}

const TeamMemberFormItem = ({ index, errors, register, fields, remove }: ITeamMemberFormItemProps) => {
  return (
    <div className={ styles['team-members-form-item-wrapper'] }>
      <Form.TextField
        hiddenLabel
        hideClear
        error={ errors.members?.[index]?.name?.message }
        label=""
        readOnly={ index === 0 }
        { ...register(`members.${index}.name`) }
      />
      <Form.TextField
        hiddenLabel
        hideClear
        error={ errors.members?.[index]?.email?.message }
        label=""
        readOnly={ index === 0 }
        { ...register(`members.${index}.email`) }
      />
      { index > 0 && (
        <TrashIcon className="pointer" fontSize={ 27 } onClick={ () => remove(index) } />
      ) }
    </div>
  );
};

export default React.memo(TeamMemberFormItem);
