import React from 'react';

import { IReport } from '@src/types/report_service/report';
import { IReportItemAccountValue } from '@src/types/report_service/report_item_value_account';
import { currencyFormater3 } from '@src/utils/currency';

import { useSelectedPortfolio } from '@src/components/portfolio_dashboard/hooks';
import Table from '@src/components/ui_v2/table';

interface IMultiEntityItemAccountValueListItemProps {
  value: IReportItemAccountValue,
  businessItemAccountValues: IReportItemAccountValue[],
  reports: IReport[],
  itemIdentifier: string,
  from: string,
  to: string,
  multiBusinessReportId: string,
}

const MultiEntityItemAccountValueListItem = ({
  value,
  businessItemAccountValues,
  reports,
  itemIdentifier,
  from,
  to,
  multiBusinessReportId,
}: IMultiEntityItemAccountValueListItemProps): JSX.Element => {
  const { selectedPortfolioId } = useSelectedPortfolio();
  const businessAccountValues = businessItemAccountValues.filter(
    (itemAccountValue) => value.name === itemAccountValue.name,
  );

  const valueLink = (businessAccountValue: IReportItemAccountValue, index: number) => {
    return `portfolio_dashboards/${
      selectedPortfolioId
    }/multi_entity_reports/custom_reports/${multiBusinessReportId}/reports/${
      reports[index].id
    }/item_value_detail?item_id=${itemIdentifier}&chart_of_account_id=${
      businessAccountValue.chartOfAccountId}&accounting_class_id=${
      businessAccountValue.accountingClassId
    }&start_date=${from}&end_date=${to}`;
  };

  return (
    <Table.Row>
      <Table.TextCell
        textAlign="left"
        tooltip={ value.name }
      >
        <span>
          {value.name}
        </span>
      </Table.TextCell>
      <Table.Cell
        textAlign="center"
      >
        <span>
          {currencyFormater3(value.value)}
        </span>
      </Table.Cell>
      {
        businessAccountValues.map((businessAccountValue, index) => {
          return (
            <Table.Cell
              key={ `${businessAccountValue.name}-${businessAccountValue.id}-${businessAccountValue.reportId}` }
              pointer
              textAlign="center"
            >
              { businessAccountValue.dynamicReport
                ? (
                  <span>
                    {currencyFormater3(businessAccountValue.value)}
                  </span>
                )
                : (
                  <a
                    className="in-blue-900"
                    href={ valueLink(businessAccountValue, index) }
                  >
                    {currencyFormater3(businessAccountValue.value)}
                  </a>
                ) }
            </Table.Cell>
          );
        })
      }
    </Table.Row>
  );
};

export default MultiEntityItemAccountValueListItem;
