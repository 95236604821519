import React, { useMemo } from 'react';

import { IBillPayProps, IExpenseProps, IExpenseReportsProps } from '@src/types/common_operational_dashboard';

import LineGraph from '@src/components/operational_dashboard/components/custom_cells/line_graph';
import NumberBox from '@src/components/operational_dashboard/components/custom_cells/number_box';
import CorporateCardsTooltip from '@src/components/operational_dashboard/components/tooltips/corporate_cards_tooltip';
import ExpenseReportTooltip from '@src/components/operational_dashboard/components/tooltips/expense_report_tooltip';
import { billPayColors,
  corporateCardTooltipData,
  employeeReimbursementsTooltipData,
  expenseReportTooltipData,
  IBusinessOwnerConfigFormType,
  myReimbursementsTooltipData } from '@src/components/operational_dashboard/helper';
import { filterTooltipData, hasActivePreference,
  isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps, TSetupStatus } from '@src/components/operational_dashboard/types';

import AuthWrapper from './auth_wrapper';
import HoverTooltipWrapper from './hover_tooltip_wrapper';
import { isObjectEmpty, progressCorporateCardsData } from './utils';

import styles from './style.module.scss';

interface IProcessExpenseReportsDataProps{
  expenseReportsProp: IExpenseReportsProps;
  preferences:IBusinessOwnerConfigFormType;
  setupStatus?:TSetupStatus;
}

const ProcessExpenseReportsData = ({ expenseReportsProp, preferences, setupStatus }:
  IProcessExpenseReportsDataProps) => {
  const employeeReimbursementsActive = useMemo(() => {
    const total = expenseReportsProp?.employeeReimbursements?.total;
    return preferences.expense?.expenseReports?.employeeReimbursements
      && total !== null
      && total !== undefined;
  }, [preferences.expense?.expenseReports?.employeeReimbursements, expenseReportsProp?.employeeReimbursements?.total]);

  const myReimbursementsActive = useMemo(() => {
    const total = expenseReportsProp?.myReimbursements?.total;
    return preferences.expense?.expenseReports?.myReimbursements
      && total !== null;
  }, [preferences.expense?.expenseReports?.myReimbursements, expenseReportsProp?.myReimbursements?.total]);

  const shouldShowPlaceholder = useMemo(() => {
    const hasUndefinedEmployeeReimbursements = preferences.expense?.expenseReports?.employeeReimbursements
      && expenseReportsProp?.employeeReimbursements?.total === undefined;

    const hasNullMyReimbursements = preferences.expense?.expenseReports?.myReimbursements
      && expenseReportsProp?.myReimbursements?.total === null;

    return hasUndefinedEmployeeReimbursements || hasNullMyReimbursements;
  }, [
    preferences.expense?.expenseReports?.employeeReimbursements,
    preferences.expense?.expenseReports?.myReimbursements,
    expenseReportsProp?.employeeReimbursements?.total,
    expenseReportsProp?.myReimbursements?.total,
  ]);

  return (
    <div className={ styles['container-flex'] }>
      {employeeReimbursementsActive && (
        expenseReportsProp?.employeeReimbursements?.total !== 0 ? (
          <HoverTooltipWrapper
            content={ (
              <ExpenseReportTooltip
                cellData={ expenseReportsProp?.employeeReimbursements }
                items={ employeeReimbursementsTooltipData }
                title="Employee Reimbursements"
              />
            ) }
          >
            <div className={ styles['box-container'] }>
              <span className={ !(isSetupStatusComplete(setupStatus))
                ? styles['table-font-warning'] : styles['table-font'] }
              >
                {isSetupStatusComplete(setupStatus) ? 'NA'
                  : expenseReportsProp?.employeeReimbursements?.total}
              </span>
            </div>
          </HoverTooltipWrapper>
        ) : (
          <NumberBox
            setupStatus={ setupStatus }
            value={ {
              type:  'number',
              value: 0,
            } }
          />
        )
      )}
      {myReimbursementsActive && (
        expenseReportsProp?.myReimbursements?.total !== 0 ? (
          <HoverTooltipWrapper
            content={ (
              <ExpenseReportTooltip
                cellData={ expenseReportsProp?.myReimbursements }
                items={ myReimbursementsTooltipData }
                title="My Reimbursements"
              />
            ) }
          >
            <div className={ styles[!(isSetupStatusComplete(setupStatus))
              ? 'red-box-container' : 'box-container'] }
            >
              <span className={ styles['table-font'] }>
                {isSetupStatusComplete(setupStatus) ? 'NA'
                  : expenseReportsProp?.myReimbursements?.total}
              </span>
            </div>
          </HoverTooltipWrapper>
        ) : (
          <NumberBox
            setupStatus={ setupStatus }
            value={ {
              type:  'number',
              value: 0,
            } }
          />
        )
      )}
      {shouldShowPlaceholder && (
        <>
          <div className={ styles['box-container'] }>
            <span className={ styles['table-font'] }>-</span>
          </div>
          <div className={ styles['box-container'] }>
            <span className={ styles['table-font'] }>-</span>
          </div>
        </>
      )}
    </div>
  );
};

interface IExpenseCellProps extends IExpenseProps, ICommonProps {
  businessId?: number;
}

interface IProcessBillPayDataProps {
  billPay: IBillPayProps;
  preferences?: IBusinessOwnerConfigFormType;
  setupStatus?: TSetupStatus;
  businessId?: number;
}

const ProcessBillPayData = ({ billPay, preferences, setupStatus, businessId }: IProcessBillPayDataProps) => {
  const progressBillPayData = useMemo(() => {
    if (billPay && preferences?.expense?.billPay) {
      return (Object.keys(billPay) as (keyof IBillPayProps)[])
        .filter((key) => preferences?.expense?.billPay?.[key])
        .map((key) => ({
          value: billPay[key] === null ? undefined : (billPay[key] ?? 0),
          color: billPayColors[key],
        }));
    }
    return [];
  }, [billPay, preferences]);

  const hasNonNullData = useMemo(() => {
    if (!billPay) return false;
    return Object.values(billPay).some((value) => value !== null);
  }, [billPay]);

  return (
    <AuthWrapper isObjectEmpty={ isObjectEmpty(billPay) }>
      <HoverTooltipWrapper
        content={
          billPay && hasNonNullData && (
            <ExpenseReportTooltip
              businessId={ businessId }
              cellData={ billPay }
              items={ filterTooltipData(
                expenseReportTooltipData,
                preferences?.expense?.billPay,
              ) }
            />
          )
        }
      >
        <LineGraph
          segments={ progressBillPayData }
          setupStatus={ setupStatus }
        />
      </HoverTooltipWrapper>
    </AuthWrapper>
  );
};

interface IProcessCorporateCardsDataProps {
  corporateCards: any;
  setupStatus?: TSetupStatus;
  businessId?: number;
}

const ProcessCorporateCardsData = ({ corporateCards, setupStatus, businessId }: IProcessCorporateCardsDataProps) => {
  const hasNonNullData = useMemo(() => {
    if (!corporateCards) return false;
    return Object.values(corporateCards).some((value) => value !== null);
  }, [corporateCards]);

  return (
    <AuthWrapper isObjectEmpty={ isObjectEmpty(corporateCards) }>
      {!corporateCards || !hasNonNullData ? (
        <div className={ styles['box-container'] }>
          <span className={ styles['table-font'] }>-</span>
        </div>
      ) : (
        <HoverTooltipWrapper
          content={ (
            <CorporateCardsTooltip
              isArray
              businessId={ businessId }
              cellData={ corporateCards }
              item={ corporateCardTooltipData }
            />
          ) }
        >
          <LineGraph
            segments={ progressCorporateCardsData(corporateCards) }
            setupStatus={ setupStatus }
          />
        </HoverTooltipWrapper>
      )}
    </AuthWrapper>
  );
};

const ExpenseCell = ({
  billPay,
  corporateCards,
  expenseReports,
  preferences,
  setupStatus,
  businessId,
}: IExpenseCellProps) => {
  return (
    <div className={ `${styles['business-column-container']} ${styles['expense-header-container']}` }>
      {hasActivePreference(
        'billPay' as keyof IBusinessOwnerConfigFormType,
        preferences?.expense as IBusinessOwnerConfigFormType,
      ) && billPay && (
        <div className={ styles['header-item'] }>
          <ProcessBillPayData
            billPay={ billPay }
            businessId={ businessId }
            preferences={ preferences }
            setupStatus={ setupStatus }
          />
        </div>
      )}
      {preferences?.expense?.corporateCards?.unapprovedReceipts && (
        <div className={ styles['header-item'] }>
          <ProcessCorporateCardsData
            businessId={ businessId }
            corporateCards={ corporateCards }
            setupStatus={ setupStatus }
          />
        </div>
      )}
      {expenseReports && preferences
        && hasActivePreference('expenseReports', preferences.expense as IBusinessOwnerConfigFormType) && (
        <div className={ styles['header-item'] }>
          <AuthWrapper isObjectEmpty={ isObjectEmpty(expenseReports) }>
            <ProcessExpenseReportsData
              expenseReportsProp={ expenseReports }
              preferences={ preferences }
              setupStatus={ setupStatus }
            />
          </AuthWrapper>
        </div>
      )}
    </div>
  );
};

export default ExpenseCell;
