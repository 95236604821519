import React from 'react';

import {
  businessSettingsPath,
  rcChartOfAccountsPath,
  rcJournalEntriesPath, rcLedgerSyncingCentrePath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const LedgerItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled,
}: IServiceMenuProps) => {
  const canVisibleRcService = info.isBusinessAdmin || info.canAccessReconciliationCenter;

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.LEDGER }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.LEDGER }
    >
      <li className={ styles['navigation-item-header-title'] }>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.LEDGER.toUpperCase() }
        </span>
      </li>
      {canVisibleRcService && (
        <
        >
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcChartOfAccountsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Chart of Accounts"
          />
          <ServiceItem
            hasBorderBottom
            serviceUrl={
              info.reconciliationCenterReady
                ? rcJournalEntriesPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Journal Entries"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcLedgerSyncingCentrePath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Syncing Errors"
          />
        </>
      )}
    </ModuleHeaderMenu>
  );
};

export default LedgerItem;
