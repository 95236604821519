import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetRevenueReportTypeDetail } from '@src/hooks/queries/revenue_report_types';
import { IRevenueSystem } from '@src/types/revenue_systems';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import ChangeMappingIntegrationAction from './actions/change_mapping_integration_action';
import ConfigureIntegrationAction from './actions/configure_integration_action';
import DisconectIntegrationAction from './actions/disconnect_integration_action';
import IntegrationHistoryAction from './actions/integration_history_action';
import ToggleIntegrationPauseAction from './actions/toggle_integration_pause_action';
import ViewMapping from './actions/view_mapping_action';

import styles from './styles.module.scss';

interface RevenueCaptureIntegrationSubmenuProps {
  revenueSystem: IRevenueSystem,
}

const RevenueCaptureIntegrationSubmenu = ({
  revenueSystem,
}: RevenueCaptureIntegrationSubmenuProps) => {
  const business = useBusinessContext();
  const integrationId = revenueSystem.integrationId;
  const revenueService = useRevenueServiceContext();
  const integrationFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_INTEGRATION_OPERATIONS,
  );
  const integrationMappingFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );

  const hasRevenueReportCategory = (revenueReportData: any): boolean => {
    if (revenueReportData) {
      const { revenueReportType } = revenueReportData;
      return (
        revenueReportType.revenueCategories.length > 0
        || revenueReportType.taxCategories.length > 0
        || revenueReportType.otherLedgerCategories.length > 0
        || revenueReportType.paymentProcessors.length > 0
        || revenueReportType.expenseAdjustments.length > 0
      );
    }
    return false;
  };

  const integration = useGetIntegration({
    integrationId,
    businessId: business.id,
  });

  const {
    data: revenueReportData,
  } = useGetRevenueReportTypeDetail(revenueSystem.revenueReportTypeId);

  const revenueReportCategoryExists = hasRevenueReportCategory(revenueReportData);

  if (
    (!integrationId && (!integrationMappingFeatureQuery.data
      || !(revenueSystem.revenueReportTypeId && revenueReportCategoryExists)))
    || (integrationId && !integration.data)
    || !integrationFeatureQuery.data
  ) {
    return null;
  }

  return (
    <ActionsDropdown className={ styles['ml-auto'] }>
      {integration.data && integrationId && (
        <ConfigureIntegrationAction
          externalSystemConfig={ integration.data?.externalSystemConfig }
          externalSystemId={ integration.data?.externalSystemId }
          externalSystemName={ integration.data?.externalSystemName }
          id={ integrationId }
          integrationType={ integration.data?.integrationType }
        />
      )}
      {integrationMappingFeatureQuery.data && (
        (revenueSystem.revenueReportTypeId && revenueReportCategoryExists)
        || (integration.data?.externalSystemConfig?.revenueReportTypeId
          && revenueReportCategoryExists
          && !integration.data?.skipRevenueMapping)
      ) && (
        <ViewMapping revenueSystem={ revenueSystem } />
      )}
      {integration.data && integrationId && (
        ((integration.data?.externalSystemConfig?.revenueReportTypeId
          && revenueReportCategoryExists
          && !integration.data?.skipRevenueMapping)
          || (integration.data?.skipRevenueMapping))
        && (
          <IntegrationHistoryAction
            integrationId={ integrationId }
          />
        )
      )}
      {integration.data && (
        (integration.data.externalSystemConfig?.revenueReportTypeId && revenueReportCategoryExists)
        || integration.data.skipRevenueMapping) && (
        <ToggleIntegrationPauseAction integration={ integration.data } />
      )}
      {integration.data && integrationId && (
        <DisconectIntegrationAction
          integrationId={ integrationId }
        />
      )}
      {integrationMappingFeatureQuery.data && (
        (revenueSystem.revenueReportTypeId && revenueReportCategoryExists)
        || (integration.data?.externalSystemConfig?.revenueReportTypeId
          && revenueReportCategoryExists
          && !integration.data?.skipRevenueMapping)
      ) && (
        <ChangeMappingIntegrationAction
          integration={ integration.data }
          revenueService={ revenueService }
          revenueSystem={ revenueSystem }
        />
      )}
    </ActionsDropdown>
  );
};

export default React.memo(RevenueCaptureIntegrationSubmenu);
