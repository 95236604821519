/* eslint-disable react/jsx-wrap-multilines */

import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { useAtomValue } from 'jotai';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IReport } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IBudgetValue, IItemValue, TColumnType } from '@src/types/report_service/report_data';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import { pickMultiMonths } from '@src/components/business_reports/atoms';
import { AppLink } from '@src/components/ui_v2/buttons';
import { budgetsSelectAddValueType, forecastSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import Table from '@src/components/ui_v2/table';

import { IHeaderColumn, isForecastColumn, isBudgetColumn, itemIdentifierValueFormatter } from '../../hooks';

interface IReportOtherDetailTableRowProps {
  report: IReport,
  item: IItemAccount,
  itemAccountValues: IItemAccount[],
  multiMonthColumns: IReportColumn[],
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[],
  itemValues: IItemValue[],
  itemIdentifier: IReportItem,
  headerTopRowColumns: IHeaderColumn[],
}

const balanceSheetReports = [
  window.Docyt.Common.Constants.REPORT_TEMPLATES.ADVANCED_BALANCE_SHEET,
  window.Docyt.Common.Constants.REPORT_TEMPLATES.UPS_ADVANCED_BALANCE_SHEET,
  window.Docyt.Common.Constants.REPORT_TEMPLATES.OTHER_BALANCE_SHEET,
  window.Docyt.Common.Constants.REPORT_TEMPLATES.SAAS_BALANCE_SHEET,
  window.Docyt.Common.Constants.REPORT_TEMPLATES.QSR_BALANCE_SHEET
]

const ReportOtherDetailTableRow = ({
  report,
  item,
  itemAccountValues,
  multiMonthColumns,
  ptdColumns,
  ytdColumns,
  itemValues,
  itemIdentifier,
  headerTopRowColumns,
}: IReportOtherDetailTableRowProps) => {
  const business = useBusinessContext();
  const showMultiMonths = useAtomValue(pickMultiMonths);
  const budgetsComparison = useAtomValue(budgetsSelectAddValueType);
  const forecastComparison = useAtomValue(forecastSelectAddValueType);

  const totalColumnIndex = useMemo(() => {
    return headerTopRowColumns.findIndex((col) => col.name === 'TOTAL');
  }, [headerTopRowColumns]);

  const lineItemCellClasses = uiStyleClassNames(
    `p-l-18 line-item-cell`,
    {
      fontVariant: item.name === 'Total' ? 'bold' : undefined,
    }
  );

  const classesActiveColumns = useCallback((name: string) => {
    return classNames(`value-cell`, {
      'background': name === 'PTD $' || name === 'YTD $',
      'font-bold': item.name === 'Total'
    });
  }, [item.name]);

  const columns = useMemo(
    () => (showMultiMonths ? multiMonthColumns : [...ptdColumns, ...ytdColumns]),
    [multiMonthColumns, ptdColumns, ytdColumns, showMultiMonths]
  );

  const ptdColumn = useMemo(
    () => ptdColumns.find((c: IReportColumn) => c.type === 'actual' && c.year === 'current'),
    [ptdColumns]
  );

  const colSpanBottom = useMemo(() => {
    return ytdColumns.length !== 0 && !showMultiMonths ? ptdColumns.length - ytdColumns.length + 1 : 1;
  }, [ptdColumns, ytdColumns, showMultiMonths]);

  const datePeriod = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = formatDate(params.get('from'), API_DATE_FORMAT);
    const endDate = formatDate(params.get('to'), API_DATE_FORMAT);

    return {
      startDate,
      endDate
    }
  }, []);

  const getRedirectUrl = useCallback((val: IItemAccount, columnId: string, colType: string, isTotalColumn: boolean) => {
    let retLink = '';

    if (
      itemIdentifier?.type === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.QUICKBOOKS_LEDGER
      && colType === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.ACTUAL
      && item.name !== 'Total'
    ) {
      if (
        balanceSheetReports.includes(
          report.templateId as
            | 'advanced_balance_sheet'
            | 'ups_advanced_balance_sheet'
            | 'other_balance_sheet'
            | 'saas_balance_sheet'
            | 'qsr_balance_sheet'
        ) && isTotalColumn
      ) {
        retLink = `/businesses/${business?.id}/reports/${report?.slug}/report_datas/${val?.reportDataId}/line_item_details?item_id=${val?.itemId}&column_id=${columnId}&chart_of_account_id=${val?.chartOfAccountId}&accounting_class_id=${val?.accountingClassId}&start_date=${datePeriod.startDate}&end_date=${datePeriod.endDate}`;
      } else if (isTotalColumn) {
        retLink = '';
      } else {
        retLink = `/businesses/${business?.id}/reports/${report?.slug}/report_datas/${val?.reportDataId}/line_item_details?item_id=${val?.itemId}&column_id=${columnId}&chart_of_account_id=${val?.chartOfAccountId}&accounting_class_id=${val?.accountingClassId}`;
      }
    }

    return retLink;
  }, [business?.id, report?.slug, datePeriod.startDate, datePeriod.endDate, report?.templateId, itemIdentifier?.type, item.name]);

  const getValueWithColumnTotal = useCallback((col: IReportColumn) => itemValues.find(((it: IItemValue) => it.columnId === col?.id)), [itemValues]);
  const getValueWithOutColumnTotal = useCallback((col: IReportColumn) => itemValues.filter(((it: IItemValue) => it.columnId === col?.id)), [itemValues]);

  const renderRowCell = useCallback((value: string, valueLink: string) => {
    if (value.indexOf('$') !== -1) {
      return (
        <>
          <div>$</div>
          <div>{ valueLink !== '' ? <AppLink href={ valueLink }>{ value.replace('$', '') }</AppLink> : value.replace('$', '') }</div>
        </>
      )
    }

    return (
      <>
        <div />
        <div>{ valueLink !== '' ? <AppLink href={ valueLink }>{ value }</AppLink> : value }</div>
      </>
    )
  }, []);

  const getValue = useCallback((col: IReportColumn | undefined, val: IItemAccount | IItemValue | undefined) => {
    if (isBudgetColumn(col as IReportColumn)) {
      const budgetValue = val?.businessBudgetValues?.find(
        (v: IBudgetValue) => v.reportComparerId === budgetsComparison?.[0],
      );

      return budgetValue?.value ?? null;
    }

    if (isForecastColumn(col as IReportColumn)) {
      const forecastValue = val?.businessForecastValues?.find(
        (v: IBudgetValue) => v.reportComparerId === forecastComparison?.[0],
      );

      return forecastValue?.value ?? null;
    }

    return val?.value ?? null;
  }, [budgetsComparison, forecastComparison]);

  const getValueOneMonth = useCallback((col: IReportColumn, idx: number) => {
    const totalVal = getValueWithColumnTotal(col);
    const val = itemAccountValues.find(
      (it: IItemAccount) => it.columnId === col.id
        && it.chartOfAccountId === item.chartOfAccountId
        && it.accountingClassId === item.accountingClassId
    );
    const url = getRedirectUrl(val as IItemAccount , col.id, col.type, false);

    if (!val && item.name !== 'Total') {
      return (
        <Table.TextCell
          hideValueTooltip
          className={ classesActiveColumns(col?.name as string) }
          { ...(idx === (columns?.length || 0) - 1 ? { colSpan: colSpanBottom } : {}) }
        >
          <div>{ renderRowCell('-', '') }</div>
        </Table.TextCell>
      );
    }

    if (!totalVal && item.name === 'Total') {
      return (
        <Table.TextCell
          hideValueTooltip
          className={ classesActiveColumns(col?.name as string) }
          { ...(idx === (columns?.length || 0) - 1 ? { colSpan: colSpanBottom } : {}) }
        >
          <div>{ renderRowCell('-', '') }</div>
        </Table.TextCell>
      );
    }

    if (val && item.name !== 'Total') {
      return (
        <Table.TextCell
          hideValueTooltip
          className={ classesActiveColumns(col?.name as string) }
          { ...(idx === (columns?.length || 0) - 1 ? { colSpan: colSpanBottom } : {}) }
        >
          <div>{ renderRowCell(itemIdentifierValueFormatter(val.columnType, getValue(col, val)), url) }</div>
        </Table.TextCell>
      );
    }

    return (
      <Table.TextCell
        hideValueTooltip
        className={ classesActiveColumns(col?.name as string) }
        { ...(idx === (columns?.length || 0) - 1 ? { colSpan: colSpanBottom } : {}) }
      >
        <div>
          { renderRowCell(
            itemIdentifierValueFormatter(
              totalVal?.columnType as TColumnType,
              getValue(col, totalVal),
            ),
            '',
          )}
        </div>
      </Table.TextCell>
    );
  }, [
    getValueWithColumnTotal,
    itemAccountValues,
    item.name,
    item.chartOfAccountId,
    getRedirectUrl,
    classesActiveColumns,
    renderRowCell
  ]);

  const getDetailValue = useCallback((vals: { id: number, vals: IItemAccount }[], idx: number) => {
    const totalColumnName = headerTopRowColumns[idx];

    if (vals?.length === 0) {
      return (
        <>
          { columns.length !== 0 && (columns.map((col: IReportColumn) => (
            <Table.TextCell key={ col.id } hideValueTooltip className={ classesActiveColumns(col?.name as string) }>
              <div>{ renderRowCell('-', '') }</div>
            </Table.TextCell>
          ))) }

          { columns.length === 0 && (
            <Table.TextCell hideValueTooltip className={ classesActiveColumns(ptdColumn?.name as string) }>
              <div>{ renderRowCell('-', '') }</div>
            </Table.TextCell>
          ) }
        </>
      )
    }

    return (
      <>
        { vals.map((val: { id: number, vals: IItemAccount }, key: number) => {
          const url = getRedirectUrl(
            val?.vals as IItemAccount,
            val?.vals?.columnId,
            val?.vals?.columnType,
            totalColumnName.name === 'TOTAL'
          );
          const col = columns.length !== 0 ? columns[key] : ptdColumn;

          return (
            <Table.TextCell key={ val ? `reportDetailsRow-${val.id}-${key}` : key } hideValueTooltip className={ classesActiveColumns(col?.name as string) }>
              <div>
                { renderRowCell(
                  itemIdentifierValueFormatter(
                    val?.vals?.columnType as TColumnType,
                    getValue(col, val?.vals),
                  ),
                  url,
                )}
              </div>
            </Table.TextCell>
          )
        }) }
      </>
    );
  }, [columns, getRedirectUrl, classesActiveColumns, renderRowCell, ptdColumn, headerTopRowColumns]);

  const getValueMultiMonth = useCallback(() => {
    const totalRowValues = headerTopRowColumns.map((_, key: number) => {
      const vals = [];

      if (columns.length !== 0) {
        for (let i = 0; i < columns.length; i += 1) {
          const col: IReportColumn = columns[i];
          const totalData = getValueWithOutColumnTotal(col);
          vals.push({
            subId: i + 1,
            vals: totalData[key]
          });
        }
      } else {
        const totalData = getValueWithOutColumnTotal(ptdColumn as IReportColumn);
        vals.push({
          subId: key + 1,
          vals: totalData[key]
        });
      }

      return {
        id: key + 1,
        vals
      };
    });

    const totalColValues = columns.map((col, colIndex) => {
      const tempVal: { id: number, vals: IItemAccount }[] = [];
      for (let j = 0; j < itemAccountValues.length; j += 1) {
        const itemAccountValue: IItemAccount = itemAccountValues[j];
        if (itemAccountValue.columnId === col.id
          && itemAccountValue.reportDataId === itemAccountValues[0].reportDataId
          && itemAccountValue.chartOfAccountId === item.chartOfAccountId
          && itemAccountValue.accountingClassId === item.accountingClassId
        ) {
          tempVal.push({
            id: colIndex,
            vals: itemAccountValue
          });
          break;
        }
      }

      return {
        id: colIndex,
        itemValues: tempVal
      }
    })

    const cellValues = totalRowValues.map((col, colIndex) => {
      const tempVal: { id: number, vals: IItemAccount }[] = [];

      for (let i = 0; i < col.vals.length; i += 1) {
        let add = false;
        for (let j = 0; j < itemAccountValues.length; j += 1) {
          const itemAccountValue: IItemAccount = itemAccountValues[j];
          if (itemAccountValue.columnId === col.vals[i].vals?.columnId
            && itemAccountValue.reportDataId === col.vals[i].vals?.reportDataId
            && itemAccountValue.chartOfAccountId === item.chartOfAccountId
            && itemAccountValue.accountingClassId === item.accountingClassId
          ) {
            tempVal.push({
              id: colIndex,
              vals: itemAccountValue
            });
            add = true;
          }
        }
        if(!add){
          tempVal.push({
            id: colIndex,
            vals: {
              id: 'TMongoID',
              accountingClassId: 1,
              chartOfAccountId: 2,
              columnId: 'TMongoID',
              columnType: 'variance',
              itemId: 'TMongoID',
              itemName: 'TMongoID',
              reportDataId: 'TMongoID',
              valueType: 'TMongoID',
              name: 'TMongoID',
              value: null,
              reportMonth: 1,
              reportYear: 2024,
              createdAt: 'TDateTime',
              updatedAt: 'TDateTime',
              show: true,
              search: false
            }
          });
        }
      }

      return {
        id: colIndex,
        itemValues: tempVal
      }
    })

    if (totalColumnIndex !== -1 && item.name !== 'Total') {
      const totalCellItems = totalColValues.map((totalVal) => totalVal.itemValues[0]);
      cellValues[totalColumnIndex].itemValues = totalCellItems;
    }

    if (cellValues.length !== 0) {
      return (
        <>
          { cellValues.map((val: { id: number, itemValues: { id: number, vals: IItemAccount }[] }, idx: number) => (
            <React.Fragment key={ `ReportDetailsTableValue-${val.id}` }>{ getDetailValue(val.itemValues, idx) }</React.Fragment>
          )) }
        </>
      )
    }

    return (
      <>
        { totalRowValues.map((val: { id: number, vals: { subId: number, vals: IItemValue }[] }) => (
          val.vals.map((subVal: { subId: number, vals: IItemValue }) => (
            <Table.TextCell
              key={ `${val.id}-${subVal.subId}` }
              hideValueTooltip
              className={ classesActiveColumns(ptdColumn?.name as string) }
            >
              <div>{ renderRowCell(itemIdentifierValueFormatter(subVal?.vals?.columnType, getValue(columns?.[subVal.subId - 1], subVal?.vals)), '') }</div>
            </Table.TextCell>
          ))
        )) }
      </>
    );
  }, [
    headerTopRowColumns,
    item.name,
    item.chartOfAccountId,
    columns,
    getValueWithOutColumnTotal,
    ptdColumn,
    itemAccountValues,
    getDetailValue,
    classesActiveColumns,
    renderRowCell
  ]);

  return (
    <Table.Row isClickable className={ `table-row ${item.name === 'Total' ? 'border-top' : ''}` }>
      <Table.TextCell className={ lineItemCellClasses } tooltip={ item.name }>
        {item.name}
      </Table.TextCell>

      { !showMultiMonths && columns.map((col: IReportColumn, idx: number) => (
        <React.Fragment key={ col.id }>{ getValueOneMonth(col, idx) }</React.Fragment>
      )) }

      { !showMultiMonths && columns.length === 0 && (
        <Table.TextCell hideValueTooltip>
          <div style={ {textAlign: 'right'} }>-</div>
        </Table.TextCell>
      ) }

      { showMultiMonths && getValueMultiMonth() }
    </Table.Row>
  );
}

export default React.memo(ReportOtherDetailTableRow);
