import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IBalanceSheetAccounts } from '@src/types/balance_sheet_information';
import { TStatementSortColumn } from '@src/types/balance_sheet_statements';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { ISorting } from '@src/types/sorting';

import DetailsPageTitle from '@src/components/ui/details_page_title';
import { Button } from '@src/components/ui_v2/buttons';
import { PlusIcon } from '@src/components/utils/icomoon';

import Filter, { IStatementViewFilter } from './filter';
import Table from './table';

import styles from './styles.module.scss';

interface IStatementsListProps {
  businessId: TID,
  filter: IFilter<IStatementViewFilter>;
  statements: IBalanceSheetAccounts[],
  months: number,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TStatementSortColumn>,
  onUploadFile: () => void,
  isShowAllAccounts: boolean,
  setIsShowAllAccounts: React.Dispatch<React.SetStateAction<boolean>>,
  setIsHandleCheckbox: React.Dispatch<React.SetStateAction<boolean>>,
}

const StatementsList = ({
  businessId,
  filter,
  statements,
  months,
  query,
  sorting,
  onUploadFile,
  isShowAllAccounts,
  setIsShowAllAccounts,
  setIsHandleCheckbox,
}: IStatementsListProps): JSX.Element => {
  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        className="border-none"
        title="Other Documents"
      >
        <Button
          className={ styles['upload-statement-button'] }
          prefixIcon={ <PlusIcon className="font-bold" fontSize={ 14 } /> }
          variant="primary"
          onClick={ onUploadFile }
        >
          Upload
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper m-t-10 m-r-20">
        <Filter
          businessId={ businessId }
          filter={ filter }
          isShowAllAccounts={ isShowAllAccounts }
          setIsHandleCheckbox={ setIsHandleCheckbox }
          setIsShowAllAccounts={ setIsShowAllAccounts }
        />
        <Table
          businessId={ businessId }
          months={ months }
          query={ query }
          sorting={ sorting }
          statements={ statements }
          year={ filter.data?.year }
        />
      </div>
    </div>
  );
};

export default StatementsList;
