import { GroupChannel } from '@sendbird/chat/groupChannel';
import { InfiniteQueryObserverResult } from 'react-query';
import { atom } from 'jotai';

import { ChatBotAtomKeys } from '@src/components/ai_chatbot/constants/atom_keys';
import {
  IFirstMessageAtom,
  IHistoryItemsRequestState,
  IThreadsRequestState,
  TChatMessageLoaderAtom,
} from '@src/components/ai_chatbot/types';

// Active Channel atom
export const activeChannelAtom = atom<GroupChannel | undefined>(undefined);

// Active Channel ID atom
export const activeChannelIdAtom = atom('');

// Active Thread ID atom
export const activeThreadIdAtom = atom('');

// channels atom
export const channelsAtom = atom<Record<GroupChannel['url'], GroupChannel>>({});

// Active Thread Messages atom
export const threadsAtom = atom<IThreadsRequestState>({
  data:      {},
  isLoading: false,
  error:     null,
});

// History Items atom
export const historyItemsAtom = atom<IHistoryItemsRequestState>({
  data:      [],
  isLoading: false,
  error:     null,
});

// Thread Loading States atom
export const threadLoadingStatesAtom = atom<Record<string, InfiniteQueryObserverResult['status']>>({});

// Conversation Loader atom
export const conversationLoaderAtom = atom<boolean>(false);

// Disable Chat Input atom
export const disableChatInputAtom = atom<boolean>(false);

/**
 * any question/text that is being sent to the chatbot
 * it's messageSignature will be put in this array
 * once the answer is received,
 * the messageSignature will be removed from the array
 */
export const chatMessageLoaderAtom = atom<TChatMessageLoaderAtom>([]);

export const firstMessageAtom = atom<IFirstMessageAtom>({ 
  message: '', 
  messageSignature: '' 
});

export const isNavBarVisibleAtom = atom<boolean>(true);
