import React, { useState, useCallback, useRef } from 'react';

import Dropzone from 'react-dropzone';

import configData from '@config/app_config.json';
import toastr from '@lib/toastr';
import { makeBulkRequest } from '@src/hooks/queries/bulk_mutations';
import { useUploadDocument, useDestroyDocument } from '@src/hooks/queries/documents';
import { getAccountsPayableServiceDocument } from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import { Button } from '@src/components/ui_v2/buttons';
import { MultipleFilesIcon, SingleFileIcon } from '@src/components/utils/icomoon';

import InvoiceUploadStatusPopover, { TFileStatus } from './invoice_upload_status_popover';

import styles from './styles.module.scss';

interface IUploadFileProps {
  businessId: number;
  setIsUploading: (isUploading: boolean) => void;
  setIsUploadDone: (isUploadDone: boolean) => void;
  setDocumentUploadCount: React.Dispatch<React.SetStateAction<number>>;
  onSingleDocumentUpload: (serviceDocument: IAccountsPayableServiceDocument) => void;
  showAddInvoiceModal: (showAddInvoiceModal: boolean) => void;
}

interface IFileStatusInfo {
  name: string;
  status: TFileStatus;
  errorMessage: string;
}

const MAX_FILES = 25;

const UploadFile = ({ businessId,
  setIsUploading,
  setIsUploadDone,
  setDocumentUploadCount,
  onSingleDocumentUpload,
  showAddInvoiceModal }: IUploadFileProps) => {
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  const [draggedFilesCount, setDraggedFilesCount] = useState<number>(0);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileStatuses, setFileStatuses] = useState<IFileStatusInfo[]>([]);
  const isStoppedUploadRef = useRef<boolean>(false);
  const uploadDocument = useUploadDocument();
  const destroyDocument = useDestroyDocument();

  const resetUploadState = useCallback(() => {
    setIsPopoverVisible(false);
    setIsUploading(false);
    setIsUploadDone(true);
  }, [setIsPopoverVisible, setIsUploading, setIsUploadDone]);

  const deleteDocument = useCallback((documentId: number) => {
    return destroyDocument.mutateAsync({
      id: documentId,
    });
  }, [destroyDocument]);

  const afteUploadDone = useCallback(() => {
    setTimeout(() => {
      resetUploadState();
    }, 2000);
  }, [resetUploadState]);

  const handleFileUploadError = useCallback(() => {
    toastr.error(`You can only upload up to ${MAX_FILES} files at a time. 
      Please reduce the number of files and try again.`, 'Error');
    showAddInvoiceModal(true);
    setIsUploadDone(false);
    setIsUploading(false);
    setIsPopoverVisible(false);
    setDraggedFilesCount(0);
  }, [showAddInvoiceModal,
    setIsUploadDone,
    setIsUploading,
    setIsPopoverVisible,
    setDraggedFilesCount,
  ]);

  const onDropUploadFiles = useCallback(async (acceptedFiles: File[]) => {
    setSelectedFiles(acceptedFiles);
    setIsPopoverVisible(true);
    setIsHighlighted(false);
    setIsUploading(true);
    setDraggedFilesCount(0);
    isStoppedUploadRef.current = false;

    if (acceptedFiles.length > MAX_FILES || acceptedFiles.length === 0) {
      handleFileUploadError();
      return;
    }

    setFileStatuses(acceptedFiles.map((file) => ({ name: file.name, status: 'uploading', errorMessage: '' })));

    const onStepProcessed = (response: IDocument, params: { file: File, metadata: any }) => {
      setFileStatuses((prevStatuses) => prevStatuses.map((f) => (f.name === params.file.name
        ? { ...f, status: 'success' }
        : f)));
      if (isStoppedUploadRef.current) {
        deleteDocument(response.id);
        return;
      }
      setDocumentUploadCount((prev) => prev + 1);
      if (response.serviceDocumentId) {
        getAccountsPayableServiceDocument(response.serviceDocumentId).then((data) => {
          onSingleDocumentUpload(data);
        });
      }
    };

    const onStepFailed = (error: Error, params: { file: File, metadata: any }) => {
      setFileStatuses((prevStatuses) => prevStatuses.map((f) => (f.name === params.file.name
        ? { ...f, status: 'error', errorMessage: error.message }
        : f)));
    };

    const bulkUpload = makeBulkRequest(async (params: { file: File, metadata: any }) => {
      return uploadDocument.mutateAsync(
        {
          documentParams: {
            document: {
              originalFileName:   params.file.name,
              fileContentType:    params.file.type,
              storageSize:        params.file.size,
              standardDocumentId: configData.account_payable_invoice_id,
            },
            businessId,
            inboxDocument: false,
          },
          file: params.file,
        },
      );
    });

    await acceptedFiles.reduce(async (promise, file) => {
      await promise;
      if (isStoppedUploadRef.current) {
        return Promise.resolve(); // Exit the chain
      }
      await bulkUpload({
        params: [{ file, metadata: { type: file.type } }],
        onStepProcessed,
        onStepFailed,
      });

      return Promise.resolve();
    }, Promise.resolve());

    afteUploadDone();
  }, [businessId,
    uploadDocument,
    afteUploadDone,
    deleteDocument,
    setDocumentUploadCount,
    setIsUploading,
    onSingleDocumentUpload,
    handleFileUploadError,
  ]);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.items.length > 0) {
      setIsHighlighted(true);
      setDraggedFilesCount(e.dataTransfer.items.length);
    }
  }, []);

  const handleDragLeave = useCallback((_e: React.DragEvent<HTMLDivElement>) => {
    setIsHighlighted(false);
    setDraggedFilesCount(0);
  }, []);

  const handleStopUpload = useCallback(async () => {
    isStoppedUploadRef.current = true;
    resetUploadState();
  }, [resetUploadState]);

  return (
    <div className={ isPopoverVisible ? styles['upload-file-dropzone'] : styles['upload-file-container'] }>
      { isPopoverVisible ? (
        <InvoiceUploadStatusPopover
          files={ fileStatuses }
          isExpanded={ selectedFiles.length > 1 }
          onStop={ handleStopUpload }
        />
      ) : (
        <Dropzone
          maxFiles={ MAX_FILES }
          onDragEnter={ handleDragEnter }
          onDragLeave={ handleDragLeave }
          onDrop={ onDropUploadFiles }
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className={ styles['upload-file-dropzone'] }
              { ...getRootProps() }
            >
              <input { ...getInputProps() } />
              { !isHighlighted ? (
                <>
                  <p className={ styles['upload-file-text'] }>Drag and drop document(s) here.</p>
                  <p className={ styles['upload-file-text-or'] }>OR</p>
                  <Button className={ styles['add-data-manually'] } size="compact" variant="primary">
                    Add Data Manually
                  </Button>
                  <p>
                    <Button variant="link">Select from Computer</Button>
                  </p>
                </>

              ) : (
                <div>
                  {draggedFilesCount === 1 ? (
                    <SingleFileIcon fontSize={ 96 } />
                  ) : (
                    <MultipleFilesIcon fontSize={ 96 } />
                  )}
                  <div className={ styles['selected-file-preview-text-container'] }>
                    <Button className={ styles['selected-file-preview-text'] } size="compact" variant="primary">
                      {draggedFilesCount}
                      {' '}
                      selected
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default UploadFile;
