import React, { useEffect } from 'react';

import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { useDropdown } from '@src/components/ui_v2/dropdown';
import { ConfigureIcon } from '@src/components/utils/icomoon';

import { useGetIntegrationExternalSystemDataById } from '../../new_system/connect_system/hooks';
import {
  usePullIntegrationConnectModal,
} from '../../new_system/pull_integration_connect/pull_integration_connect_modal';
import {
  usePushIntegrationConnectModel,
} from '../../new_system/push_integration_connect/push_integration_connect_model';
import { IPushIntegrationConnectValues } from '../../new_system/push_integration_connect/schema';

interface ConfigureIntegrationActionProps {
  externalSystemId: TID,
  externalSystemName: string,
  integrationType: string,
  id: TID,
  externalSystemConfig: {[key: string]: string | number | null},
}

const ConfigureIntegrationAction = ({
  externalSystemId,
  externalSystemName,
  integrationType,
  externalSystemConfig,
  id,
}: ConfigureIntegrationActionProps) => {
  const popover = useDropdown();
  const configSchema = useGetIntegrationExternalSystemDataById(externalSystemId);
  const pullIntegrationConnectModel = usePullIntegrationConnectModal();
  const pushIntegrationConnectModel = usePushIntegrationConnectModel();
  const openIntegrationModal = () => {
    if (integrationType === 'pull') {
      pullIntegrationConnectModel.open();
    }
    if (integrationType === 'email') {
      pushIntegrationConnectModel.open();
    }
  };

  useEffect(() => {
    if ((pullIntegrationConnectModel.props.isOpen || pushIntegrationConnectModel.props.isOpen) && popover) {
      popover.hide();
    }
  }, [pullIntegrationConnectModel.props.isOpen, pushIntegrationConnectModel.props.isOpen, popover]);

  return (
    <>
      <ActionsDropdown.Action
        icon={ <ConfigureIcon fontSize={ 18 } /> }
        title="Configure"
        onClick={ openIntegrationModal }
      />
      {configSchema && externalSystemConfig && integrationType === 'pull'
      && (
        <pullIntegrationConnectModel.Component
          isEdit
          externalSystemConfigSchema={ configSchema.configSchema }
          externalSystemId={ externalSystemId }
          externalSystemName={ externalSystemName }
          id={ id }
          valueForm={ externalSystemConfig }
          { ...pullIntegrationConnectModel.props }
        />
      )}
      {configSchema && externalSystemConfig && integrationType === 'email'
      && (
        <pushIntegrationConnectModel.Component
          isEdit
          externalSystemConfigSchema={ configSchema.configSchema }
          externalSystemId={ externalSystemId }
          externalSystemName={ externalSystemName }
          id={ id }
          valueForm={
            {
              startDate:           externalSystemConfig.startDate,
              revenueReportTypeId: externalSystemConfig.revenueReportTypeId,
              location:            externalSystemConfig.location,
            } as IPushIntegrationConnectValues
          }
          { ...pushIntegrationConnectModel.props }
        />
      )}
    </>
  );
};

export default React.memo(ConfigureIntegrationAction);
