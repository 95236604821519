import React, { useEffect, useMemo, useState } from 'react';

import { useSetAtom } from 'jotai';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import { useGetReports, useGetReportTemplates } from '@src/hooks/queries/report_service/custom_reports';
import { IReport } from '@src/types/report_service/report';

import Section from '@src/components/utils_v2/section';

import BusinessReportsExportContent from './content';
import BusinessReportsExportFooter from './footer';
import BusinessReportsExportHeader from './header';
import { reportDataPeriodType } from '../atoms';

interface IBusinessReportsExportProps {
  category: string,
  type:     string,
  slug:     string
}

const BusinessReportsExport = ({ category, type, slug }: IBusinessReportsExportProps) => {
  const business = useBusinessContext();
  const reportService = useReportServiceContext();
  const reportQuery = useGetReports(reportService.id);
  const setPeriodType = useSetAtom(reportDataPeriodType);

  const [include, setInclude] = useState<boolean>(false);
  const [exclude, setExclude] = useState<boolean>(false);

  const reports = useMemo(() => reportQuery.data || [], [reportQuery.data]);

  const reportTemplatesQuery = useGetReportTemplates(business.standardCategoryId);
  const templates = useMemo(() => reportTemplatesQuery.data || [], [reportTemplatesQuery]);

  useEffect(() => {
    if (reports && reports.length !== 0 && slug) {
      const findReport = reports.find((rep: IReport) => rep.slug === slug);
      if (findReport) setPeriodType(findReport.periodType);
    }
  }, [reports, setPeriodType, slug]);
  
  return (
    <Section.Provider section={ { businessId: business.id, section: 'reports-export', skipURLParams: true } }>
      <div className="reports-export">
        <BusinessReportsExportHeader businessId={ business.id } category={ category } slug={ slug } />
        <BusinessReportsExportContent
          business={ business }
          category={ category }
          exclude={ exclude }
          include={ include }
          query={ reportQuery }
          reports={ reports }
          setExclude={ setExclude }
          setInclude={ setInclude }
          slug={ slug }
          templates={ templates }
          type={ type }
        />
        <BusinessReportsExportFooter
          category={ category }
          exclude={ exclude }
          include={ include }
          reportServiceId={ reportService.id }
          reports={ reports }
          slug={ slug }
          templates={ templates }
          type={ type }
        />
      </div>
    </Section.Provider>
  );
}

export default BusinessReportsExport;
