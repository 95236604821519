import React from 'react';

import { TDateRange } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table';

import Row from './row';

interface ITableBodyProps {
  widgets: IWidget[];
  hideEmptyRows?: boolean;
  targets: Record<string, number>;
  standardCategoryId: number,
  dateRange: TDateRange;
}

const TableBody = ({
  widgets,
  hideEmptyRows = false,
  targets,
  standardCategoryId,
  dateRange,
}: ITableBodyProps): JSX.Element => {
  const filteredWidgets = hideEmptyRows
    ? widgets.filter((widget) => widget.dataSets?.[0]?.values?.some((data) => data.value !== 0))
    : widgets;

  return (
    <Table.Body>
      {filteredWidgets.map((widget) => (
        <Row
          key={ widget.id }
          dateRange={ dateRange }
          standardCategoryId={ standardCategoryId }
          targets={ targets }
          widget={ widget }
        />
      ))}
    </Table.Body>
  );
};

export default TableBody;
