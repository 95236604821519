import React, { useState } from 'react';

import classNames from 'classnames';

import { IDocument } from '@src/types/documents';

import Chats from '@src/components/common_v2/chats';
import RelatedDocuments from
  '@src/components/accounts_payable/invoice_detail_side_menu/related_documents/related_documents';
import RelatedJournalEntriesSection from
  '@src/components/common/related_journal_entries/related_journal_entries_section';
import { TOption } from '@src/components/ui_v2/inputs';
import { TabItem } from '@src/components/ui_v2/tabs';

import Activities from './activities';

import styles from '../styles.module.scss';

const tabs: TOption[] = [
  { label: 'Chat', value: 'chat' },
  { label: 'Related Docs', value: 'related-docs' },
  { label: 'Related Journal Entries', value: 'related-journal-entries' },
  { label: 'Activities', value: 'activities' },
];

interface IDocumentDetailsTabsProps {
  isCollapsed: boolean;
  document: IDocument;
}

const DocumentDetailsTabs = ({ isCollapsed, document }: IDocumentDetailsTabsProps) => {
  const [activeTab, setActiveTab] = useState<TOption>(tabs[0]);

  const className = classNames(styles['document-details-tabs-container'], {
    [styles.collapsed]: isCollapsed,
  });

  return (
    <div className={ className }>
      <div className={ styles['document-details-tabs-links'] }>
        {tabs.map((tab) => (
          <TabItem
            key={ tab.value }
            activeTab={ activeTab }
            tab={ tab }
            onTabChange={ setActiveTab }
          />
        ))}
      </div>
      <div className={ styles['document-details-tabs-content'] }>
        {activeTab.value === 'chat' && (
          <Chats document={ document } />
        )}
        {activeTab.value === 'related-docs' && (
          <RelatedDocuments document={ document } showAccordion={ false } />
        )}
        {activeTab.value === 'related-journal-entries' && (
          <RelatedJournalEntriesSection
            documentId={ document.id }
            isCollapseSection={ false }
          />
        )}
        {activeTab.value === 'activities' && (
          <Activities document={ document } />
        )}
      </div>
    </div>
  );
};

export default React.memo(DocumentDetailsTabs);
