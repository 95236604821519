import React from 'react';

import { useDocumentTableItems, IBankStatementWithDocytId, IBalanceSheetStatementWithDocytId }
  from '@src/hooks/use_document_table_items';
import { TID } from '@src/types/common';
import { IDocument } from '@src/types/documents';

import { Button } from '@src/components/ui_v2/buttons';
import AppLink from '@src/components/ui_v2/buttons/app_link';
import Table from '@src/components/ui_v2/table';

import styles from './document_list.module.scss';

interface DocumentTableProps {
  documents: IDocument[];
  bankStatement: IBankStatementWithDocytId | null;
  balanceSheetStatements: IBalanceSheetStatementWithDocytId[];
  isMailroomRequest: boolean;
  isBankStatementRequest: boolean;
  isBalanceSheetRequest: boolean;
  isReviewed: boolean;
  isJustUploaded?: boolean;
  businessId: TID;
  onDocumentClick: (e: React.MouseEvent, docId: number, type: string) => void;
  onDeleteClick: (docId: TID) => void;
}

const DocumentTable: React.FC<DocumentTableProps> = ({
  documents,
  bankStatement,
  balanceSheetStatements,
  isMailroomRequest,
  isBankStatementRequest,
  isBalanceSheetRequest,
  isReviewed,
  isJustUploaded,
  businessId,
  onDocumentClick,
  onDeleteClick,
}) => {
  const documentItems = useDocumentTableItems({
    documents,
    bankStatement,
    balanceSheetStatements,
    isMailroomRequest,
    isBankStatementRequest,
    isBalanceSheetRequest,
    isJustUploaded,
    _isReviewed: isReviewed,
  });

  if (!documentItems.length) {
    return (
      <div className={ styles['no-documents-message'] }>
        No document available
      </div>
    );
  }

  return (
    <Table
      aria-label="Documents"
      className={ styles['documents-table'] }
    >
      <Table.Head>
        <Table.Row>
          <Table.HCell widthPercent={ 40 }>Document ID</Table.HCell>
          <Table.HCell widthPercent={ 40 }>Name</Table.HCell>
          <Table.HCell className={ styles['amount-header'] }>Amount</Table.HCell>
          <Table.HCell className={ styles['actions-header'] }><span className="sr-only">Actions</span></Table.HCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {documentItems.map((doc) => (
          <Table.Row key={ doc.id } className={ styles['document-row'] }>
            <Table.Cell>
              <div className={ styles['document-id'] }>
                <AppLink
                  href={ `/businesses/${businessId}/documents/${doc.id}` }
                  onClick={ (e: React.MouseEvent) => onDocumentClick(e, doc.id, doc.type) }
                >
                  {doc.docytId}
                </AppLink>
              </div>
            </Table.Cell>
            <Table.Cell>{doc.name}</Table.Cell>
            <Table.Cell align="center">-</Table.Cell>
            <Table.Cell align="center">
              {!isReviewed && (
                <Button
                  aria-label={ `Delete document ${doc.name}` }
                  className={ styles['delete-button'] }
                  variant="link"
                  onClick={ () => onDeleteClick(doc.id) }
                >
                  <i aria-hidden="true" className="fa fa-trash text-gray" />
                </Button>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default DocumentTable;
