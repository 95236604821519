/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useResendEmail } from '@src/hooks/queries/signup';
import { confirmEmail } from '@src/requests/signup';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import { NumberInput } from '@src/components/ui_v2/inputs';

import { ONBOARDING_PAGE_KEYS } from '../constant';
import OnboardingForm from '../layout/onboarding_form';
import { useOnboarding } from '../provider';

import styles from '../styles.module.scss';

interface IVerifyEmailFormProps {
  formId: string;
}

const VerifyEmailForm = ({ formId }: IVerifyEmailFormProps) => {
  const { setPage, email, emailAccessToken, setEmail, setEmailAccessToken, setFullName } = useOnboarding();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { handleSubmit } = useForm<any>({});
  const { mutate: resendEmail, isLoading: isResending } = useResendEmail();
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const onSubmit = useCallback(() => {
    const allValues = inputRefs.map((ref) => ref.current?.value || '');

    if (allValues.every((val) => val !== '')) {
      const code = allValues.join('');

      if (code.length === 6) {
        setIsLoading(true);
        confirmEmail(emailAccessToken, code)
          .then((response) => {
            if (response.success) {
              toastr.success(window.Docyt.Common.Constants.Messages.EMAIL_VERIFIED_SUCCESSFULLY, 'Success.');
              setPage(ONBOARDING_PAGE_KEYS.SET_PASSWORD);
            }

            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
            setErrorMsg(window.Docyt.Common.Constants.Messages.INVALID_EMAIL_CONFIRM_CODE);
          });
      }
    }
  }, [setIsLoading, inputRefs, setErrorMsg]);

  const handleGoBack = useCallback(() => {
    setPage(ONBOARDING_PAGE_KEYS.SIGN_UP);
    setEmailAccessToken('');
  }, [setPage, setEmail, setEmailAccessToken, setFullName]);

  const handleResendCode = useCallback(() => {
    setErrorMsg('');
    resendEmail(emailAccessToken, {
      onSuccess: () => {
        toastr.success(window.Docyt.Common.Constants.Messages.EMAIL_VERIFICATION_CODE_SENT, 'Success.');
      },
      onError: (error) => {
        setErrorMsg(error.message);
      },
    });
  }, [resendEmail, setErrorMsg]);

  const handleChange = useCallback(
    (value: string | number, index: number) => {
      if (value && index < 5) {
        inputRefs[index + 1].current?.focus();
      } else if (!value && index > 0) {
        inputRefs[index - 1].current?.focus();
      }

      if (index === 5 && value) {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const pastedData = event.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);

      if (pastedData) {
        const digits = pastedData.split('');

        digits.forEach((digit, index) => {
          if (inputRefs[index]?.current) {
            inputRefs[index].current.value = digit;
          }
        });

        const focusIndex = Math.min(digits.length, 5);
        inputRefs[focusIndex]?.current?.focus();

        if (digits.length === 6) {
          onSubmit();
        }
      }
    },
    [onSubmit]
  );

  const renderEmailCodeContent = useMemo(() => {
    return (
      <div className={ styles['number-input-wrapper'] }>
        { inputRefs.map((ref, index) => (
          <NumberInput
            // eslint-disable-next-line react/no-array-index-key
            key={ index }
            ref={ ref }
            className="text-center"
            maxLength={ 1 }
            onChange={ (value) => handleChange(value, index) }
            onPaste={ handlePaste }
          />
        )) }
      </div>
    );
  }, []);

  return (
    <>
      { errorMsg && <ErrorNotification message={ errorMsg } /> }
      { (isLoading || isResending) && <Spinner /> }
      <OnboardingForm
        backButtonLabel="Go Back"  
        formId={ formId }
        handleSubmit={ handleSubmit }
        nextButtonLabel="Next"
        onClickBackButton={ handleGoBack }
        onSubmit={ onSubmit }
      >
        <div className="display-flex p-b-20 p-l-10 p-r-10 font-14 justify-content-center">
          Enter the 6-digit verification code sent to
          {' '}
          { email.slice(0, 3) }
          ****@
          { email.split('@')[1] }
        </div>
        { renderEmailCodeContent }
        <div className="p-t-20 p-l-10 p-r-10">
          <Button
            variant="link"
            onClick={ handleResendCode }
          >
            Resend Code
          </Button>
        </div>
      </OnboardingForm>
    </>
  );
};

export default VerifyEmailForm;
