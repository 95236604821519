import { useState } from 'react';

import toastr from '@lib/toastr';
import { useUpdateTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { useCreateDocumentNote, useDeleteDocumentNote } from '@src/requests/all_transactions';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

interface Params {
  rowData: ITransactionServiceDocument;
  setRowData: (data: ITransactionServiceDocument) => void;
}

export default function useTxnDoc({ rowData, setRowData }: Params) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: updateDoc } = useUpdateTransactionServiceDocument();
  const { mutateAsync: createDocNote } = useCreateDocumentNote();
  const { mutateAsync: deleteDocNote } = useDeleteDocumentNote();
  const [documentNote, setDocumentNote] = useState<string | undefined>(rowData?.documentNote?.note);

  const updateFields = async (
    updates: Record<string, string | number | boolean | null>,
  ) => {
    setIsSubmitting(true);
    try {
      const data = await updateDoc({ id: rowData.id, ...updates });
      setRowData(data.transactionServiceDocument);
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error).message || 'Unknown'}`, 'Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCreateDocumentNote = async (note: string) => {
    setIsSubmitting(true);
    try {
      const res = await createDocNote({ documentId: rowData.documentId, note });
      setDocumentNote(res.documentNote?.note || '');
    } catch (error) {
      toastr.error(`Failed to create note: ${(error as Error).message || 'Unknown'}`, 'Error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteDocumentNote = async () => {
    if (!rowData.documentNote?.id) return;
    try {
      await deleteDocNote({ documentId: rowData.documentId, noteId: rowData.documentNote.id });
    } catch (error) {
      toastr.error(`Failed to delete note: ${(error as Error).message || 'Unknown'}`, 'Error');
    }
  };

  const handleNdnChange = async (checked: boolean) => {
    if (!checked) await handleDeleteDocumentNote();
    await updateFields({
      noDocumentRequired: checked,
      noPushQbo:          rowData.noPushQbo,
    });
  };

  const isDisabled = rowData.state === 'verified';
  const { doNotLearn, noDocumentRequired: noDocumentNeeded, noPushQbo } = rowData;
  const documentRelated = (noDocumentNeeded && documentNote) || !noDocumentNeeded;

  const commonProps = {
    isSubmitting,
    isDisabled,
    doNotLearn,
    noDocumentNeeded,
    documentNote,
    noPushQbo,
    documentRelated,
  };

  return {
    updateFields,
    handleCreateDocumentNote,
    handleNdnChange,
    commonProps,
  };
}
