import React, { useCallback, useState } from 'react';

import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useBulkAddTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { TDate } from '@src/types/common';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { currencyFormater3 } from '@src/utils/currency';
import { todayApiDate } from '@src/utils/date_helpers';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';
import Modal from '@src/components/ui/modal';
import { ErrorNotification } from '@src/components/ui/notification';

import { addTransactionFieldsAtom } from './atoms';
import Form from './form';
import AddTransactionsResult from './result';
import { IAddTransactionInput, IFormValues } from './schema';

import styles from './styles.module.scss';

interface IAddTransactionProps extends IUseModalProps {
  startDate?: TDate,
  endDate?: TDate,
  reconciliationPaymentAccount?: IReconciliationPaymentAccount,
}

const addTransactionResult = (status: IBulkActionStatus<any, any>) => (
  <AddTransactionsResult status={ status } />
);

const AddTransaction = ({
  startDate,
  endDate,
  reconciliationPaymentAccount,
  isOpen,
  onCancel,
  onDone,
}: IAddTransactionProps): JSX.Element => {
  const [bulkResponseIndexes, setBulkResponseIndexes] = useState<number[] | undefined>(undefined);
  const [formData, setFormData] = useState<IFormValues | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [totalTransactionAmount, setTotalTransactionAmount] = useState<number>(0);

  const [addTransactionFields, setAddTransactionFields] = useAtom(addTransactionFieldsAtom);
  const resetAddTransactionFieldsAtom = useResetAtom(addTransactionFieldsAtom);

  const business = useBusinessContext();

  const add = useBulkAddTransactionServiceDocument();
  const bulkAction = useBulkActionModal({
    mutation: add,
  });

  const { runMutation } = bulkAction;

  const checkInvalidAmount = useCallback((data: IFormValues) => {
    const index = data.newTransactions.findIndex(
      (addedTransaction) => Number(addedTransaction.amount) < 0,
    );

    if (index > -1) {
      setErrorMessage(
        window.Docyt.Common.Constants.Messages.INVALID_AMOUNT_TRANSACTION_ERROR_MSG,
      );
      return false;
    }
    setErrorMessage(undefined);
    return true;
  }, []);

  const handleSubmit = useCallback((data: IFormValues) => {
    if (!checkInvalidAmount(data)) return;

    const params = data.newTransactions.map((addedTransaction: IAddTransactionInput) => ({
      index:                          addedTransaction.index,
      businessId:                     business.id,
      paymentAccountId:               addedTransaction.selectedAccount.paymentAccountId,
      reconciliationPaymentAccountId: addedTransaction.selectedAccount
        .reconciliationPaymentAccountId,
      transactionServiceDocument: {
        transactionDate:       addedTransaction.transactionDate,
        description:           addedTransaction.description,
        amount:                addedTransaction.amount,
        transactionAmountType: addedTransaction.amountType,
      },
    }));

    runMutation(params);
  }, [business.id, checkInvalidAmount, runMutation]);

  const handleDone = useCallback((status?: IBulkActionStatus<any, any>) => {
    if (status && status.errors.length > 0) {
      const response = status.responses.map((res) => res[0]);
      setBulkResponseIndexes(response.map((res) => res.index));
    } else {
      setBulkResponseIndexes(undefined);
      resetAddTransactionFieldsAtom();
      onDone();
    }
    bulkAction.props.onDone();
  }, [bulkAction.props, onDone, resetAddTransactionFieldsAtom]);

  const onChange = useCallback((data: IFormValues) => {
    setFormData(data);
    const totalAmount = data.newTransactions.reduce((acc, curr) => {
      let sumAmount = 0;
      
      if (curr.amountType === 'deposit') {
        sumAmount = acc + Number(curr.amount);
      } else if (curr.amountType === 'withdrawal') {
        sumAmount = acc - Number(curr.amount);
      } else if (curr.amountType === 'charge') {
        sumAmount = acc - Number(curr.amount);
      } else if (curr.amountType === 'payment') {
        sumAmount = acc + Number(curr.amount);
      }

      return sumAmount;
    }, 0);
    setTotalTransactionAmount(totalAmount);
  }, [setFormData, setTotalTransactionAmount]);

  const handleCancel = useCallback(() => {
    if (formData) {
      setAddTransactionFields(formData);
    }
    onCancel();
  }, [formData, onCancel, setAddTransactionFields]);

  const renderFooterContent = useCallback(() => {
    return (
      <span className={ styles['modal-footer-total-content'] }>
        Total of transactions added:
        {' '}
        { currencyFormater3(Number(totalTransactionAmount)) }
      </span>
    );
  }, [totalTransactionAmount]);

  return (
    <>
      { errorMessage && <ErrorNotification message={ errorMessage } title="Invalid amount!" onHidden={ () => setErrorMessage(undefined) } /> }
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Transactions"
        result={ addTransactionResult }
        title="Add Transactions"
        onDone={ handleDone }
      />
      <Modal.Form
        showClose
        className={ styles['add-transactions-modal'] }
        footerContent={ renderFooterContent() }
        proceedTitle="Add"
        show={ isOpen }
        title="Add Transaction"
        onCancel={ handleCancel }
      >
        { ({ formId }) => (
          <div className="add-transaction-view">
            <Form
              bulkResponseIndexes={ bulkResponseIndexes }
              businessId={ business.id }
              endDate={ todayApiDate() }
              formId={ formId }
              isOpen={ isOpen }
              oldTransactions={ addTransactionFields?.newTransactions }
              reconciliationPaymentAccount={ reconciliationPaymentAccount }
              startDate={ startDate }
              onChange={ onChange }
              onSubmit={ handleSubmit }
            />
          </div>
        )}
      </Modal.Form>
    </>
  );
};

const MemoizedAddTransaction = React.memo(AddTransaction);

const useAddTransaction = makeUseModal(MemoizedAddTransaction);

export {
  useAddTransaction,
  MemoizedAddTransaction as default,
};
