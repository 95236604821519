import React from 'react';

import TeamMemberForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const TEAM_MEMBER_TITLE = 'Add Your Team Members';

const TeamMemberView = () => {
  return (
    <OnboardingPage isHideBorder title={ TEAM_MEMBER_TITLE }>
      <TeamMemberForm formId="team-member-form" />
    </OnboardingPage>
  );
};

export default React.memo(TeamMemberView);
