import React from 'react';

import Badge from '@src/components/ui_v2/badge';

import DocumentField from './document_field';

import styles from './styles.module.scss';

const PaymentAddressField = () => {
  return (
    <div className={ styles['payment-address-field'] }>
      <DocumentField
        isRequired
        input={ (
          <div>
            <p>1600 Amphitheatre Pkwy Mountain View, CA 94043, United States</p>
            <p><Badge label="Authorised" variant="success" /></p>
            <p><span className={ styles['document-field-button'] }>Edit</span></p>
          </div>
        ) }
        label="Payment Address"
      />
    </div>
  );
};

export default React.memo(PaymentAddressField);
