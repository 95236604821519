import React, { useCallback, useMemo } from 'react';

import { portfolioDashboardsWidgetPath } from '@src/routes';
import { TDateRange, TID } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { IPortfolioDashboard } from '@src/types/portfolio';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';
import { backboneNavigateToUrl } from '@src/utils/navigate';
import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

import Header from './kpi_header_view';
import BriefNoDataView from './no_data_view';
import AggregateView from '../aggregate_view';
import { getDefaultDateRange, getRealDateRange } from '../charts/chart_helpers';
import { TChartScale } from '../charts/types';

import styles from './styles.module.scss';

interface IKPIChartViewProps extends IUIStyleProps {
  portfolioId: TID,
  dashboard: IPortfolioDashboard,
  dateRange: TDateRange,
  widget: IWidget,
}

const KPIChartView = ({
  portfolioId,
  dashboard,
  dateRange,
  widget,
  ...props
}: IKPIChartViewProps): JSX.Element | null => {
  const [classes] = uiStyleProps(
    styles['brief-view-wrapper'],
    props,
  );

  const handleClick = useCallback(() => {
    backboneNavigateToUrl(
      portfolioDashboardsWidgetPath(portfolioId, dashboard.id, widget.identifier),
    );
  }, [portfolioId, dashboard.id, widget.identifier]);

  return (
    <div
      className={ classes }
      role="navigation"
      onClick={ handleClick }
    >
      <div className={ styles.content }>
        <Header
          standardCategoryId={ dashboard.standardCategoryId }
          widget={ widget }
        />
        <div className={ styles.body }>
          {
          widget.dataSets
          && widget.dataSets.find((data) => data.values && data.values.length > 0) ? (
            <AggregateView
              briefView
              businesses={ dashboard.businesses }
              chartType="bar"
              dataSets={ widget.aggregatedDataSet ? [widget.aggregatedDataSet] : widget.dataSets }
              dateRange={ dateRange }
              scale={ widget?.scale as TChartScale }
            />
            ) : (
              <BriefNoDataView />
            )
        }
        </div>
      </div>
    </div>
  );
};

export default KPIChartView;
