/* eslint-disable max-len */
import React, { useEffect, useCallback } from 'react';

import toastr from '@lib/toastr';
import { useUpdateTransactionServiceDocument } from '@src/hooks/queries/transaction_service_documents';
import { BusinessChartOfAccounts } from '@src/requests/system_categories';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import ChartOfAccountField from '@src/components/common_v2/form_fields/business_chart_of_account_field';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '../../styles.module.scss';

interface IPayrollChargesProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
  Verify: (boolean: boolean) => void;
  setRowData: (data: ITransactionServiceDocument) => void;
  systemCategories?: BusinessChartOfAccounts | null;
  reloadSystemCategories: () => void;
}

const PayrollCharges = ({ businessId, rowData, Verify, setRowData, systemCategories, reloadSystemCategories }: IPayrollChargesProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } =
    useTxnDoc({ rowData, setRowData });

  const {
    isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo,
  } = commonProps;

  const category = systemCategories?.payrollBusinessChartOfAccount?.chartOfAccountId || null;

  const { mutateAsync } = useUpdateTransactionServiceDocument();

  useEffect(() => {
    let disabled = true;
    if (((noDocumentNeeded && documentNote !== '') || !noDocumentNeeded) && category !== null) {
      disabled = false;
    }
    Verify(disabled);
  }, [noDocumentNeeded, documentNote, Verify, category]);

  const handleChartOfAccountChange = useCallback(async (value: TID | null): Promise<void> => {
    try {
      const params = { id: rowData.id, payrollChartOfAccountId: value, chartOfAccountId: null, userChartOfAccountId: null };
      const result = await mutateAsync(params);
      if (result) {
        reloadSystemCategories();
      }
    } catch (error) {
      toastr.error(`Failed to update: ${(error as Error)?.message || 'An unknown error occurred'}`, 'Error');
    }
  }, [mutateAsync, rowData.id, reloadSystemCategories]);

  return (
    <div key={ rowData.id }>
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />

        <div className={ styles['sidebar-data'] }>
          <span>Category*</span>
          <ChartOfAccountField
            businessId={ businessId }
            className={ styles['sidebar-data-category'] }
            isDisabled={ isDisabled }
            label="Chart of Account"
            layout="table"
            placeholder="Select Chart of Account"
            queryParams={ {
              searchClassification: ['Asset', 'Liability'],
            } }
            value={ category }
            onChange={ handleChartOfAccountChange }
          />
        </div>

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </div>
  );
};

export default PayrollCharges;
