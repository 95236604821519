import { useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getWidgets,
  IGetWidgetsParams,
  IGetWidgetsResponse,
} from '@src/requests/dashboards/widgets';

const useGetWidgets = (params: IGetWidgetsParams, onSuccess?: () => void) => {
  return useQuery<IGetWidgetsResponse, Error>(
    [QueryKey.dashboardWidgets, params],
    () => getWidgets(params),
    {
      enabled:   !!params.dashboardId && !!params.from && !!params.to,
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );
};

export {
  useGetWidgets,
};
