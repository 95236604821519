import { useQuery, useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetAccountsPayableServiceDocumentsParams,
  IGetAccountsPayableServiceDocumentsResponse,
  IGetRemainingApproverNamesResponse,
  getAccountsPayableServiceDocuments,
  getAccountsPayableServiceDocumentsWithPossibleMatches,
  getAccountsPayableServiceDocument,
  getRemainingApproverNames,
  cancelAchPayment,
  IMarkAsPaidServiceDocumentParams,
  markAsPaidServiceDocument,
  ISearchBusinessVendorParams,
  ISearchBusinessVendorResponse,
  getSearchBusinessVendors,
  getCompliancePolicies,
  updateCompliancePolicyServiceDocument,
  createCompliancePolicyServiceDocument,
  deleteCompliancePolicy,
  updateCompliancePolicy,
  createCompliancePolicy,
  getAccountsPayableRelatedJournal,
} from '@src/requests/accounts_payable/accounts_payable_service_documents';
import { IAccountsPayableRelatedJournal, IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';

import {
  IBulkMutationParams,
  IBulkMutationReturn,
  makeBulkRequest,
} from '../bulk_mutations';
import { createUseGetInfiniteCollection } from '../infinite_collection_queries';

const useGetAccountsPayableServiceDocuments = createUseGetInfiniteCollection<
    IAccountsPayableServiceDocument,
    IGetAccountsPayableServiceDocumentsParams,
    IGetAccountsPayableServiceDocumentsResponse
>({
  queryKey: QueryKey.accountsPayableServiceDocuments,
  request:  getAccountsPayableServiceDocuments,
});

const useGetAccountsPayableServiceDocumentsWithPossibleMatches =
    createUseGetInfiniteCollection<
        IAccountsPayableServiceDocument,
        IGetAccountsPayableServiceDocumentsParams,
        IGetAccountsPayableServiceDocumentsResponse
    >({
      queryKey: QueryKey.accountsPayableServiceDocuments,
      request:  getAccountsPayableServiceDocumentsWithPossibleMatches,
    });

const useGetLimitedAccountsPayableServiceDocuments = (
  params: IGetAccountsPayableServiceDocumentsParams,
) => {
  return useQuery<IGetAccountsPayableServiceDocumentsResponse, Error>(
    [QueryKey.accountsPayableServiceDocuments, params],
    () => getAccountsPayableServiceDocuments(params),
  );
};

const useGetAccountsPayableServiceDocument = (id: number, enabled: boolean = true) => {
  return useQuery<IAccountsPayableServiceDocument, Error>(
    [QueryKey.accountsPayableServiceDocument, id],
    () => getAccountsPayableServiceDocument(id),
    {
      enabled: enabled && Boolean(id),
    },
  );
};

const useGetAccountsPayableRelatedJournal = (id: TID) => {
  return useQuery<IAccountsPayableRelatedJournal, Error>(
    [QueryKey.accountsPayableRelatedJournal, id],
    () => getAccountsPayableRelatedJournal(id),
  );
};

const useGetRemainingApproverNames = (id: number) => {
  return useQuery<IGetRemainingApproverNamesResponse, Error>(
    [QueryKey.remainingApproverNames, id],
    () => getRemainingApproverNames(id),
  );
};

const useCancelAchPayment = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, TID>(cancelAchPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        QueryKey.accountsPayableAchTransactions,
      );
    },
  });
};

const useMarkAsPaidServiceDocument = () => {
  return useMutation<void, Error, IMarkAsPaidServiceDocumentParams>(
    markAsPaidServiceDocument,
    {
      onSuccess: () => {},
    },
  );
};

const useBulkMarkAsPaidServiceDocument = () => {
  return useMutation<
        IBulkMutationReturn,
        Error,
        IBulkMutationParams<IMarkAsPaidServiceDocumentParams>
    >(makeBulkRequest(markAsPaidServiceDocument), {
      onSettled: (res) => {
        if (!res?.responses || res?.responses.length === 0) return;

        res.responses.forEach((response: any) => {
          response.accounts_payable_service_documents.forEach(
            (serviceDocument: IAccountsPayableServiceDocument) => {
              window.Docyt.vent.trigger(
                'invoice:state:changed',
                serviceDocument,
              );
            },
          );
        });
      },
    });
};

const useGetSearchBusinessVendors = (params: ISearchBusinessVendorParams) => {
  return useQuery<ISearchBusinessVendorResponse, Error>(
    [QueryKey.accountsPayableServiceDocuments, params],
    () => getSearchBusinessVendors(params),
  );
};

interface IGetCompliancePoliciesParams {
    businessId: TID;
    policyType : string;
}

interface ICompliancePolicyRule {
    vendor_id?: number | string | undefined | null;
    amount?: string | null;
    amountOperator?: string | null;
}

const useGetCompliancePolicies = (params: IGetCompliancePoliciesParams) => {
  return useQuery<any, Error>(
    [QueryKey.accountsPayableServiceDocuments, params],
    () => getCompliancePolicies(params),
  );
};
interface IDeleteCompliancePoliciesParams {
    id: TID;
    business_id: TID;
    policy_type: string;
}

const useDeleteCompliancePolicy = () => {
  return useMutation<void, Error, IDeleteCompliancePoliciesParams>(
    deleteCompliancePolicy,
  );
};

interface ICreateCompliancePoliciesParams {
    business_id: TID;
    policy_type: string;
    approve_all_invoice: boolean;
    compliance_policy_rule: ICompliancePolicyRule;
}

const useCreateCompliancePolicy = () => {
  return useMutation<void, Error, ICreateCompliancePoliciesParams>(
    createCompliancePolicy,
  );
};

interface IEditCompliancePoliciesParams {
    id?: string | number;
    business_id: TID;
    policy_type?: string;
    compliance_policy_rule?: ICompliancePolicyRule;
}

const useUpdateCompliancePolicy = () => {
  return useMutation<void, Error, IEditCompliancePoliciesParams>(
    updateCompliancePolicy,
  );
};

interface IUpdateCompliancePolicyServiceResponse {
    policy: {
        accountsPayableServiceId: TID;
        approveAllInvoice: boolean;
        id: TID;
        policyType: string;
    };
}

const useUpdateApproverCompliances = () => {
  const queryClient = useQueryClient();
  return useMutation<IUpdateCompliancePolicyServiceResponse, Error, any>(
    updateCompliancePolicyServiceDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.accountsPayableServiceDocuments,
        );
      },
    },
  );
};

interface ICreateCompliancePolicyServiceResponse {
    policy: {
        accountsPayableServiceId: TID;
        approveAllInvoice: boolean;
        id: TID;
        policyType: string;
    };
}

const useCreateApproverCompliances = () => {
  const queryClient = useQueryClient();
  return useMutation<ICreateCompliancePolicyServiceResponse, Error, any>(
    createCompliancePolicyServiceDocument,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          QueryKey.accountsPayableServiceDocuments,
        );
      },
    },
  );
};

export {
  IDeleteCompliancePoliciesParams,
  ICreateCompliancePoliciesParams,
  IEditCompliancePoliciesParams,
  useGetAccountsPayableServiceDocuments,
  useGetAccountsPayableServiceDocumentsWithPossibleMatches,
  useGetLimitedAccountsPayableServiceDocuments,
  useGetAccountsPayableServiceDocument,
  useGetAccountsPayableRelatedJournal,
  useGetRemainingApproverNames,
  useCancelAchPayment,
  useMarkAsPaidServiceDocument,
  useBulkMarkAsPaidServiceDocument,
  useGetSearchBusinessVendors,
  useGetCompliancePolicies,
  useUpdateApproverCompliances,
  useCreateApproverCompliances,
  useDeleteCompliancePolicy,
  useCreateCompliancePolicy,
  useUpdateCompliancePolicy,
};
