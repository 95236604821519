import React, { useCallback, useState } from 'react';

import Dropzone from 'react-dropzone';

import { IBankStatement } from '@src/types/bank_statements';

import { Button } from '@src/components/ui/buttons';
import { ErrorNotification } from '@src/components/ui/notification';
import Tooltip from '@src/components/ui_v2/tooltip';
import { fileSizeInMB } from '@src/components/utils/bytes_to_mb';
import { WarningIcon } from '@src/components/utils/fa_icons';
import { NewDocumentIcon } from '@src/components/utils/img_icons';

import styles from '../styles.module.scss';

interface IBankStatementDropInputProps {
  bankStatement?: IBankStatement,
  error?: string,
  isFileUploading?: boolean,
  onUploaded: (data: File) => void,
}

const allowedFileFormat = window.configData.allowed_file_upload_format;
const allowedFileSize = window.configData.allowed_file_upload_size;

const BankStatementDropInput = ({
  bankStatement,
  error,
  isFileUploading,
  onUploaded,
}: IBankStatementDropInputProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(error);

  const handleFileSelected = useCallback((files) => {
    const file = files[0];
    if (bankStatement && bankStatement.statementFileUrl
      && bankStatement.state !== window.Docyt.Common.Constants.BANK_STATEMENT_STATES.UPLOADED) {
      setErrorMsg(`You can not upload file because status of statement is ${bankStatement.state}.`);
      return;
    }
    if (file.size > allowedFileSize) {
      setErrorMsg(`File size should be less than ${fileSizeInMB(allowedFileSize)}MB.`);
      return;
    }
    if (!allowedFileFormat.includes(file.name.split('.').pop().toLowerCase())) {
      setErrorMsg(`File you uploaded is not supported. You can only upload in one of these formats: ${allowedFileFormat.join(', ')}`);
      return;
    }

    onUploaded(file);
  }, [bankStatement, onUploaded]);

  return (
    <>
      {errorMsg
        && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } />}
      <div className="height-100-percent">
        {
          isFileUploading ? (
            <div className={ styles['progress-status'] }>
              <p className="file-upload-status-text font-18">
                Uploading...
              </p>
            </div>
          ) : (
            <>
              {
                error && (
                  <p className="in-red-400 main-input-hint upload-validation-error">
                    <WarningIcon mr={ 10 } />
                    {' '}
                    {error}
                  </p>
                )
              }
              {bankStatement?.name && !bankStatement.statementFileUrl
                && (
                  <div>
                    <span className="font-bold font-18">
                      {bankStatement?.name}
                      (
                      <span className="text-danger">Deleted</span>
                      )
                      <Tooltip.Hover
                        className="display-inline-block"
                        content="The bank statement that was uploaded has been removed. Please re-upload."
                      >
                        <span className="icon icon-unverified font-16 m-t-3 m-l-5">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                        </span>
                      </Tooltip.Hover>
                    </span>
                  </div>
                )}
              <Dropzone multiple onDrop={ handleFileSelected }>
                {({ getRootProps, getInputProps }) => (
                  <section className={ styles['drop-zone-section'] }>
                    <div className={ styles['drop-zone-section-details'] } { ...getRootProps() }>
                      <NewDocumentIcon />
                      <p className="m-t-20 font-16">Drag and drop a statement here.</p>
                      <p className="m-t-10 m-b-10 font-16">OR</p>
                      <Button
                        bsStyle="blue-on-hover"
                        className="fileinput-button"
                        fontSize={ 16 }
                      >
                        Select from your computer
                        <input { ...getInputProps() } />
                      </Button>
                    </div>
                  </section>
                )}
              </Dropzone>
            </>
          )
        }
      </div>
    </>
  );
};

export default BankStatementDropInput;
