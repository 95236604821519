import React from 'react';

import { DropzoneRootProps } from 'react-dropzone';

import styles from './document_list.module.scss';

interface DocumentDropZoneProps {
  isReviewed: boolean;
  isUploading: boolean;
  isMailroomRequest: boolean;
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: () => JSX.IntrinsicElements['input'];
  isDragActive: boolean;
}

const DocumentDropZone: React.FC<DocumentDropZoneProps> = ({
  isReviewed,
  isUploading,
  isMailroomRequest,
  getRootProps,
  getInputProps,
  isDragActive,
}) => {
  if (isReviewed) {
    return null;
  }

  return (
    <div
      { ...getRootProps() }
      className={ `${styles['document-drop-zone']} ${isDragActive ? styles.active : ''}` }
    >
      <input { ...getInputProps() } />
      <div className={ styles['drop-zone-content'] }>
        <span className={ styles['add-document-text'] }>Add Document</span>
        <span className={ styles['drag-drop-text'] }>
          {isUploading && 'Uploading...'}
          {!isUploading && isMailroomRequest && 'Drag and drop your files here'}
          {!isUploading && !isMailroomRequest && 'Drag and drop your file here'}
        </span>
      </div>
    </div>
  );
};

export default DocumentDropZone;
