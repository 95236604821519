import React from 'react';

import Skeleton from 'react-loading-skeleton';

import Table from '@src/components/ui_v2/table';

import { IColumnNamesAndWidths } from './types';

interface ILoadingSkeletonRowProps {
  columnNamesAndWidths: IColumnNamesAndWidths,
  rowId: string,
  refObserver?: React.RefObject<HTMLTableRowElement>,
}

const LoadingSkeletonRow = ({ rowId, columnNamesAndWidths, refObserver }: ILoadingSkeletonRowProps) => {
  return (
    <Table.Row key={ rowId } ref={ refObserver }>
      <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths?.date }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
      <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths?.transaction_type }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
      <Table.TextCell className="text-right" widthPercent={ columnNamesAndWidths?.num }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
      <Table.TextCell className="text-left" widthPercent={ columnNamesAndWidths?.name }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
      <Table.TextCell hideValueTooltip className="text-right" widthPercent={ columnNamesAndWidths?.amount }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
      <Table.TextCell hideValueTooltip className="text-left" widthPercent={ columnNamesAndWidths?.memo }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
      <Table.TextCell hideValueTooltip className="text-left" widthPercent={ columnNamesAndWidths?.split }>
        <Skeleton
          className="third-drill-down-table"
        />
      </Table.TextCell>
    </Table.Row>
  );
};

export default LoadingSkeletonRow;
