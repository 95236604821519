import forEach from 'lodash/forEach';
import { atom } from 'jotai';
import { atomFamily, atomWithReset, RESET } from 'jotai/utils';

import { TSection } from '@src/types/common';
import { TAmountFilter, TDateFilter, TFilterData } from '@src/types/filter';
import {
  restoreFilterFromStorage,
  storeFilterToStorage,
  overrideFromURLParams,
  reflectToURLParams,
} from '@src/utils/filter';

const makeSyncFilterWithStorageEffect = (section: TSection) => {
  const baseAtom = atomWithReset<TFilterData>(restoreFilterFromStorage(section) as TFilterData);

  return atom(
    (get) => overrideFromURLParams(section, get(baseAtom)),
    (get, set, update: TFilterData | typeof RESET | ((prev: TFilterData) => TFilterData)) => {
      if (update === RESET) {
        set(baseAtom, {});
        storeFilterToStorage(section, get(baseAtom));
        if (!section.skipURLParams) {
          reflectToURLParams(section, get(baseAtom));
        }
      } else {
        const newValue = typeof update === 'function' ? update(get(baseAtom)) : update;
        storeFilterToStorage(section, newValue);
        if (!section.skipURLParams) {
          reflectToURLParams(section, newValue);
        }
        set(baseAtom, newValue);
      }
    }
  );
}

const filterData = atomFamily((section: TSection) => makeSyncFilterWithStorageEffect(section));

const filterCounter = atomFamily((section: TSection) => 
  atom((get) => {
    const filter = get(filterData(section));

    if (!filter) return 0;
    
    let count = 0;
    forEach(filter, (value, key) => {
      if (key === 'transaction_date' || key === 'invoice_date') {
        const filterValue = filter[key] as TDateFilter;
        if (!filterValue) return;

        if (filterValue.gte && filterValue.gte !== '') count += 1;
        if (filterValue.lte && filterValue.lte !== '') count += 1;
      } else if (key === 'amount') {
        const filterValue = filter[key] as TAmountFilter;
        if (!filterValue) return;

        if (
          (filterValue.val && filterValue.val !== '')
          || (filterValue.gte && filterValue?.gte !== '')
          || (filterValue.lte && filterValue?.lte !== '')
        ) {
          count += 1;
        }
      } else if (key === 'excluded') {
        if (filter[key] === 'true') count += 1;
      } else if (filter[key]) count += 1;
    });

    return count;
  })
);

const searchColumnValueType = atom<string>('');
const multiSelectAddValueType = atom<string[]>([]);
const budgetsSelectAddValueType = atom<string[]>([]);
const forecastSelectAddValueType = atom<string[]>([]);
const hideZeroRowsType = atom<boolean>(true);

export {
  filterData,
  filterCounter,
  searchColumnValueType,
  multiSelectAddValueType,
  hideZeroRowsType,
  budgetsSelectAddValueType,
  forecastSelectAddValueType,
};
