import { createContext, useContext } from 'react';

import { TScrollContext } from '@src/types/ai_chatbot';

export const ScrollContext = createContext<TScrollContext | null>(null);

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
