import { uploadAttachmentToS3, downloadFromS3 } from '@src/requests/aws';
import { getDocumentSymmetricKey } from '@src/requests/documents';
import { apiPut, apiDelete, apiGet, apiPost, authorizedApiRequest } from '@src/requests/helpers';
import { TID } from '@src/types/common';
import { IJournal } from '@src/types/journal_entries';
import { IManagementGroup } from '@src/types/management_groups';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

interface IUploadCSVParams {
  file: File,
  name: string;
  description: string;
  managementGroupId: number;
  businessId: number;
  onUploadProgress: (progressEvent: any) => void
}

interface IUploadCSVResponse {
  journal: IJournal;
}

const uploadCSV = (params: IUploadCSVParams): Promise<IUploadCSVResponse> => {
  const formData = new FormData();

  const { onUploadProgress, file, ...tmp } = params;
  const payload = underscoreKeys(tmp);
  Object.keys(payload).forEach((key) => {
    const typedKey = key as keyof typeof payload;
    const value = payload[typedKey];
    formData.append(key, value);
  });
  formData.append('file', file);

  return apiPost(
    '/api/v1/journals',
    formData,
    onUploadProgress,
  );
};

interface IGetJournalsParams {
  filters?: object,
  page?: number,
  businessId: number,
}

interface IGetJournalsResponse {
  meta: {
    totalCount: number,
  },
  collection: IJournal[],
}
interface JournalEntriesCSVParams {
  businessId: number;
  entryDate: string;
  endDate: string;
}
interface SingleJournalEntriesParams {
  businessId: number;
  id: number;
}

const getJournals = (
  params: IGetJournalsParams,
): Promise<IGetJournalsResponse> => {
  return apiGet(
    '/api/v1/journals',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.journals as IJournal[],
    };
  });
};

interface IGetJournalResponse {
  journal: IJournal;
}

const getJournal = (id: TID, businessId: number): Promise<IGetJournalResponse> => {
  return apiGet(
    `/api/v1/journals/${id}`,
    { business_id: businessId },
  ).then((data) => <any>camelizeKeys(data));
};

interface IManagementGroupsResponse {
  managementGroups: IManagementGroup[];
}
interface IDeleteJournalParams {
  id: TID,
  businessId: TID
}

const deleteJournal = (params: IDeleteJournalParams): Promise<void> => {
  return apiDelete(
    `/api/v1/journals/${params.id}`,
    { business_id: params.businessId },
  );
};

interface IJournalNamesResponse {
  names: string[];
}

const getJournalNames = (businessId: number): Promise<IJournalNamesResponse> => {
  return apiGet(
    '/api/v1/journals/names',
    { business_id: businessId },
  );
};

const getFilterManagementGroups = (businessId: number): Promise<IManagementGroupsResponse> => {
  return apiGet(
    '/api/v1/journals/management_groups_filter',
    { business_id: businessId },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return cdata;
  });
};
const getJournalEntriesCSV = async (queryParams:JournalEntriesCSVParams): Promise<Blob> => {
  const params = {
    filter:      { entry_date: { gte: queryParams.entryDate, lte: queryParams.endDate } },
    business_id: queryParams.businessId,
  };
  const url = 'api/v1/adjustment_entries.csv';
  return authorizedApiRequest({
    url,
    method:       'GET',
    responseType: 'blob',
    params,
  }).then((blob) => {
    return blob;
  });
};

// eslint-disable-next-line max-len
const getSingleJournalEntriesCSV = async (queryParams:SingleJournalEntriesParams): Promise<Blob> => {
  const params = {
    business_id: queryParams.businessId,
  };
  const url = `api/v1/adjustment_entries/${queryParams.id}.csv`;
  return authorizedApiRequest({
    url,
    method:       'GET',
    responseType: 'blob',
    params,
  }).then((blob) => {
    return blob;
  });
};

interface IPostAttachmentParams {
  record_type: string,
  business_id: TID,
  record_id: TID,
  name: string
}

interface IUploadAttachmentParams {
  attachmentParams: IPostAttachmentParams,
  file: File,
  documentId: number,
}

interface IUploadAttachment {
  attachment: Object
}

interface IDownloadAttachmentParams {
  documentId: TID
  s3ObjectKey: string
}

// interface IDownloadFileParams {
//   documentIds: number[],
//   type: 'original' | 'extracted'
// }

interface IDownloadAttachmentResponse {
  fileData: ArrayBuffer
}

const downloadAttachment = (
  params: IDownloadAttachmentParams,
): Promise<IDownloadAttachmentResponse> => {
  return getDocumentSymmetricKey(params.documentId)
    .then((symmetricKey) => {
      return downloadFromS3({ s3ObjectKey: params.s3ObjectKey!, symmetricKey });
    })
    .then(({ fileData }) => {
      return {
        fileData,
      };
    });
};

const postAttachments = (params: IUploadAttachment): Promise<any> => {
  return apiPost(
    '/api/v1/attachments',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.document) as any);
};

const uploadAttachments = (params: IUploadAttachmentParams): Promise<void> => {
  return getDocumentSymmetricKey(params.documentId).then((symmetricKey) => {
    return uploadAttachmentToS3({
      s3ObjectKey: `adjustment-entry/attachments/${params.attachmentParams.record_id}/${params.file.name}`,
      file:        params.file,
      symmetricKey,
    }).then((data) => {
      const mergeParams = {
        attachment: {
          ...params.attachmentParams,
          blob: {
            key:      data.key,
            filename: params.file.name,
            checksum: data.ETag,
            bytesize: 1000,
          },
        },
      };
      return postAttachments(mergeParams);
    });
  });
};

const deleteAttachment = (attachmentId: TID): Promise<void> => {
  return apiDelete(
    `/api/v1/attachments/${attachmentId}`,
  );
};

interface IAttachmentRename {
  name: string
}

const putAttachmentRename = (
  attachmentId: TID,
  attachment: IAttachmentRename,
): Promise<void> => {
  return apiPut(
    `/api/v1/attachments/${attachmentId}`,
    underscoreKeys({ attachment }),
  );
};

const getAttachments = (recordId: number): Promise<any> => {
  return apiGet(`/api/v1/attachments?record_type=AdjustmentEntry&record_id=${recordId}`)
    .then((data) => camelizeKeys(data) as any);
};

interface IGetDocumentActivitiesResponse {
  id: TID;
  documentId: TID;
  performedAt: string;
  parsedActivity: string;
  performedBy: {
    id: TID;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    middleName: string;
    parsedFullname: string;
    avatar: {
      avatarUrl: string;
    };
  };
  activityType: string;
}

const getDocumentActivities = (documentId: TID): Promise<IGetDocumentActivitiesResponse[]> => {
  return apiGet(`/api/v1/document_activities?document_id=${documentId}`).then(
    (data) => camelizeKeys(data) as IGetDocumentActivitiesResponse[],
  );
};

export {
  IGetDocumentActivitiesResponse,
  IUploadCSVParams,
  IUploadCSVResponse,
  uploadCSV,
  IJournal,
  IGetJournalsParams,
  IGetJournalsResponse,
  getJournals,
  IGetJournalResponse,
  getJournal,
  IDeleteJournalParams,
  deleteJournal,
  IManagementGroupsResponse,
  IJournalNamesResponse,
  getJournalNames,
  getFilterManagementGroups,
  getJournalEntriesCSV,
  getSingleJournalEntriesCSV,
  JournalEntriesCSVParams,
  SingleJournalEntriesParams,
  IUploadAttachmentParams,
  IAttachmentRename,
  uploadAttachments,
  getAttachments,
  putAttachmentRename,
  deleteAttachment,
  downloadAttachment,
  getDocumentActivities,
};
