import React, { useMemo, useCallback } from 'react';

import { businessInboxPath } from '@src/routes';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';
import { IBankStatement } from '@src/types/bank_statements';
import { TID } from '@src/types/common';
import { IDocumentModel } from '@src/types/document_model';
import { IDocument } from '@src/types/documents';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { LinkButton } from '@src/components/ui/buttons/';
import { Button } from '@src/components/ui_v2/buttons';

import DocumentUploader from './document_uploader';

import styles from './document_list.module.scss';

interface DocumentsSectionProps {
  model: IDocumentModel;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  documents: IDocument[];
  balanceSheetStatements: IBalanceSheetStatement[];
  noStatementAvailable: boolean;
  setNoStatementAvailable: (value: boolean) => void;
  isDocumentsExpanded: boolean;
  toggleDocumentsSection: () => void;
  onDocumentsUpdate?: (newDocuments: IDocument[]) => void;
  bankStatement: IBankStatement;
  businessId?: TID;
  isReviewed?: boolean;
  setDocuments?: React.Dispatch<React.SetStateAction<IDocument[]>>;
  notes?: string;
  isMailroomRequest?: boolean;
}

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  model,
  isUploading,
  setIsUploading,
  documents,
  bankStatement,
  balanceSheetStatements,
  noStatementAvailable,
  setNoStatementAvailable,
  isDocumentsExpanded,
  toggleDocumentsSection,
  onDocumentsUpdate,
  businessId,
  isReviewed = false,
  setDocuments,
  notes,
  isMailroomRequest,
}) => {
  const documentCount = useMemo(() => {
    if (noStatementAvailable) {
      return 0;
    }

    if (model.isBankStatementRequest() && bankStatement) {
      if (bankStatement.state === 'requested') {
        return 0;
      }
      return 1;
    }

    if (model.isBalanceSheetStatementRequest()) {
      const hasRequestedState = balanceSheetStatements?.some((stmt) => stmt.state === 'requested');
      if (hasRequestedState) {
        return 0;
      }
      return 1;
    }

    if (model.isMailroomRequest()) {
      return documents?.length || 0;
    }

    return 0;
  }, [model, documents, bankStatement, balanceSheetStatements, noStatementAvailable]);

  let documentText = 'No documents available';
  if (noStatementAvailable) {
    documentText = 'No Statement Needed';
  } else if (documentCount > 0) {
    documentText = `${documentCount} ${documentCount === 1 ? 'Document' : 'Documents'}`;
  }

  const handleDocumentsUpdate = useCallback((updatedDocs: IDocument[]) => {
    setDocuments?.(updatedDocs);
    onDocumentsUpdate?.(updatedDocs);
  }, [setDocuments, onDocumentsUpdate]);

  const handleMailroomClick = useCallback(() => {
    backboneNavigateToUrl(businessInboxPath(model.get('business_id') as TID));
  }, [model]);

  return (
    <section className={ styles['documents-section'] }>
      <div className={ styles['section-header'] }>
        <h1 className={ styles['documents-section-header'] }>Documents</h1>
        {!isDocumentsExpanded && (
          <span className={ styles['document-count'] }>{documentText}</span>
        )}

        <LinkButton
          aria-label={ isDocumentsExpanded ? 'Collapse documents section' : 'Expand documents section' }
          title=""
          onClick={ toggleDocumentsSection }
        >
          <i className={ `fa ${isDocumentsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}` } />
        </LinkButton>
      </div>

      {isDocumentsExpanded && (
        <DocumentUploader
          balanceSheetStatements={ balanceSheetStatements }
          bankStatement={ bankStatement }
          documents={ documents }
          isReviewed={ isReviewed }
          isUploading={ isUploading }
          model={ {
            id:                             model.id,
            business_id:                    businessId ?? model.business_id,
            bank_statement:                 bankStatement,
            isBankStatementRequest:         model.isBankStatementRequest,
            isBalanceSheetStatementRequest: model.isBalanceSheetStatementRequest,
            isMailroomRequest:              model.isMailroomRequest,
            get:                            (key: string) => {
              return key === 'business_id' ? (businessId ?? model.business_id) : null;
            },
          } }
          noStatementAvailable={ noStatementAvailable }
          notes={ notes }
          setDocuments={ setDocuments || undefined }
          setIsUploading={ setIsUploading }
          setNoStatementAvailable={ setNoStatementAvailable }
          onDocumentsUpdate={ handleDocumentsUpdate }
        />
      )}
      {isMailroomRequest && !isReviewed && (
        <div className={ styles['mailroom-link'] }>
          <Button
            variant="link"
            onClick={ handleMailroomClick }
          >
            <i className="icon icon-inbox" />
            <span>Add Document from Business Mailroom</span>
          </Button>
        </div>
      )}
    </section>
  );
};

export default DocumentsSection;
