import React, { useCallback, useMemo } from 'react';

import { myNotificationsPath, settingsPath } from '@src/routes';

import { NavigateBtn } from '@src/components/ai_chatbot';
import { LinkButton } from '@src/components/ui/buttons';
import { AppLink } from '@src/components/ui_v2/buttons';
import { HeaderBellIcon, SettingsCogIcon } from '@src/components/utils/icomoon';
import LogoIcon from '@src/components/utils/icomoon/logo';

import AvatarDropdown from '../avatar_dropdown/avatar_dropdown';

import styles from './styles.module.scss';

interface IBookGptHeaderContainerProps {
  onOpenEditProfile: () => void;
}

const ICON_SIZE = 25;

const BookGptHeaderContainer = ({ onOpenEditProfile }: IBookGptHeaderContainerProps) => {
  const handleClickBell = useCallback(() => {
    window.open(myNotificationsPath(), '_blank');
  }, []);

  const canAccessAskDocytAI: boolean = useMemo(() => {
    return window.Docyt.currentAdvisor.getBusinessIntent() !== window.configData.business_intent.FIRM_CLOSING_SUITE;
  }, []);

  return (
    <div className={ styles['header-container'] }>
      <div>
        <AppLink href="/">
          <LogoIcon className={ styles['logo-icon'] } fontSize={ 20 } />
        </AppLink>
      </div>
      {canAccessAskDocytAI && (
        <div>
          <NavigateBtn />
        </div>
      )}
      <div className={ styles['header-right-section'] }>
        <div className={ styles['header-bell-icon'] }>
          {window.Docyt.currentAdvisor.get('unread_notifications_count') > 0 && (
            <div className={ styles['header-bell-icon-count'] }>
              {window.Docyt.currentAdvisor.get('unread_notifications_count')}
            </div>
          )}
          <LinkButton title="" onClick={ handleClickBell }>
            <HeaderBellIcon fontSize={ ICON_SIZE } />
          </LinkButton>
        </div>
        <div className={ styles['settings-icon'] }>
          <LinkButton href={ settingsPath() } title="">
            <SettingsCogIcon fontSize={ ICON_SIZE } />
          </LinkButton>
        </div>
        <div className={ styles['avatar-icon'] }>
          <AvatarDropdown onOpenEditProfile={ onOpenEditProfile } />
        </div>
      </div>
    </div>
  );
};

export default BookGptHeaderContainer;
