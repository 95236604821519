import React from 'react';

import { relatedDocumentPath } from '@src/routes';

import { IRelatedDocument, TRelatedDocumentsSortColumn } from '@src/types/related_documents';
import { ISorting } from '@src/types/sorting';

import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';

import styles from '../styles.module.scss';

interface IRelatedDocumentsTableProps {
  records: IRelatedDocument[],
  sorting: ISorting<TRelatedDocumentsSortColumn>,
  isRelatedToExpenseReceipt?: boolean,
}

const RelatedDocumentsTable = ({
  records,
  sorting,
  isRelatedToExpenseReceipt,
}: IRelatedDocumentsTableProps) => {
  const documentIdLink = (document: IRelatedDocument) => {
    const docytId = document.documentRelative.docytId;
    return docytId ? (
      <AppLink newWindow href={ relatedDocumentPath(docytId) }>
        {docytId}
      </AppLink>
    ) : (
      '-'
    );
  };

  return (
    <CollectionTable<IRelatedDocument, TRelatedDocumentsSortColumn>
      className={ styles['related-documents-table'] }
      isRowClickable={ () => false }
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<IRelatedDocument>
        name="docyt_id"
        title="Document ID"
        value={ documentIdLink }
        width="120px"
      />
      {isRelatedToExpenseReceipt ? (
        <CollectionTable.TextColumn<IRelatedDocument>
          name="uploader_name"
          title="Uploader Name"
          value={ (document) => document.documentRelative.uploaderName || '-' }
        />
      ) : (
        <CollectionTable.TextColumn<IRelatedDocument>
          name="business_name"
          title="Business"
          value={ (document) => document.relativeBusinessName || '-' }
        />
      )}
      <CollectionTable.AmountColumn<IRelatedDocument>
        name="invoice_amount"
        title="Amount"
        value={ (document) => document.relativeDocumentAmount || '-' }
        width="120px"
      />
    </CollectionTable>
  );
};

export default React.memo(RelatedDocumentsTable);
