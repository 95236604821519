import { useMutation, UseMutationResult } from 'react-query';

import {
  IUploadBankStatementAttachmentParams,
  IUploadBankStatementAttachmentResponse,
  uploadBankStatementAttachment,
} from '@src/requests/attachments';

export const useUploadBankStatementAttachment = (): UseMutationResult<
  IUploadBankStatementAttachmentResponse,
  Error,
  IUploadBankStatementAttachmentParams
> => {
  return useMutation(uploadBankStatementAttachment);
};
