import React, { useCallback, useLayoutEffect } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

const BOTTOM_OFFSET = 300; // pixels
const OFFSET_TOP = 80; // pixels

interface IUseScrollSpyParams {
  elementRef: React.RefObject<any>,
  onBottomReached: () => void,
  isTopReached?: boolean;
}

const useScrollSpy = ({
  elementRef,
  onBottomReached,
  isTopReached = false,
}: IUseScrollSpyParams): void => {
  const handleScroll = useCallback(() => {
    if (!elementRef.current) return;
    const windowTop = window.scrollY;
    const element = $(elementRef.current);
    const elementPosition = (element.offset()?.top || 0)
      + element.outerHeight()
      - window.innerHeight;

    if (isTopReached) {
      const offsetPostition = element.offset()?.top || 0;
      if (offsetPostition >= OFFSET_TOP) {
        onBottomReached(); // Call the function when the elementPosition reaches the top
      }
    } else if (windowTop >= elementPosition - BOTTOM_OFFSET) {
      onBottomReached();
    }
    // elementRef is a ref, but refs don't change and should not be in dependencies.
    // It's false-positive because ref is passed as parameter.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBottomReached]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);
};

const useRegionScrollSpy = ({
  elementRef,
  onBottomReached,
}: IUseScrollSpyParams): void => {
  const handleScroll = useCallback(() => {
    if (!elementRef.current) return;

    const element = $(elementRef.current);
    const childElement = elementRef.current.children[0];
    if (!childElement) return;

    const bodyHeight = element.height();
    const contentScrolled = element.scrollTop();

    if (contentScrolled + bodyHeight >= childElement.offsetHeight - BOTTOM_OFFSET) {
      onBottomReached();
    }
    // elementRef is a ref, but refs don't change and should not be in dependencies.
    // It's false-positive because ref is passed as parameter.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBottomReached]);

  useLayoutEffect(() => {
    const currentElement = elementRef.current;
    if (!currentElement) return undefined;

    currentElement.addEventListener('scroll', handleScroll, true);
    return () => {
      currentElement.removeEventListener('scroll', handleScroll, true);
    };
  }, [elementRef, handleScroll]);
};

interface IUseInfiniteScrollParams extends Omit<IUseScrollSpyParams, 'onBottomReached'> {
  query: UseInfiniteQueryResult,
  isTopReached?: boolean;
}

const useInfiniteScrollBase = ({
  query,
  elementRef,
  spyHook,
  isTopReached,
  isRegionScroll,
}: IUseInfiniteScrollParams & {
  spyHook: typeof useScrollSpy | typeof useRegionScrollSpy,
  isRegionScroll?: boolean,
}) => {
  const { isFetchingNextPage, hasNextPage, fetchNextPage } = query;
  const handleBottomReached = useCallback(() => {
    if (isFetchingNextPage) return;
    if (!hasNextPage) return;

    fetchNextPage();
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  // Check if we need to load more content
  const checkContent = useCallback(() => {
    if (!elementRef.current) return;
    const element = $(elementRef.current);
    const elementHeight = element.outerHeight() || 0;
    const windowHeight = window.innerHeight;
    // If the element height is less than viewport height, we should load more
    if (!isRegionScroll && (elementHeight < windowHeight)) {
      handleBottomReached();
    }
  }, [elementRef, handleBottomReached, isRegionScroll]);

  // Check content on mount and window resize
  useLayoutEffect(() => {
    checkContent();
    const handleResize = () => {
      checkContent();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [checkContent]);

  spyHook({
    elementRef,
    onBottomReached: handleBottomReached,
    isTopReached,
  });
};

const useInfiniteScroll = (params: IUseInfiniteScrollParams) => {
  useInfiniteScrollBase({ ...params, spyHook: useScrollSpy });
};

const useInfiniteRegionScroll = (params: IUseInfiniteScrollParams) => {
  useInfiniteScrollBase({ ...params, spyHook: useRegionScrollSpy, isRegionScroll: true });
};

export {
  useInfiniteScroll,
  useInfiniteRegionScroll,
  useScrollSpy,
  useRegionScrollSpy,
};
