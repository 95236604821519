import { number, object, string } from 'yup';

import { TDate, TID } from '@src/types/common';

interface IAddRevenueSystemValues {
  name?: string,
  startDate?: TDate,
  revenueReportTypeId?: TID,
}

const addRevenueSystemValidation = object({
  name: string().trim().label('Revenue System Name').max(25)
    .required(),
  startDate:           string().trim().label('Start Date').required(),
  revenueReportTypeId: number().nullable().label('Revenue Mapping').required(),
});

export {
  IAddRevenueSystemValues,
  addRevenueSystemValidation,
};
