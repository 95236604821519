import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { revenueServiceMappingPath } from '@src/routes';
import { IRevenueSystem } from '@src/types/revenue_systems';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ConfigureIcon } from '@src/components/utils/icomoon';

interface IViewMappingProps {
  revenueSystem: IRevenueSystem,
}

const ViewMapping = ({ revenueSystem }: IViewMappingProps) => {
  const business = useBusinessContext();
  return (
    <ActionsDropdown.LinkAction
      href={ revenueServiceMappingPath(business.id, revenueSystem.revenueReportTypeId) }
      icon={ <ConfigureIcon fontSize={ 20 } /> }
      title="View Mapping"
    />
  );
};

export default ViewMapping;
