import React from 'react';

import styles from './styles.module.scss';

interface IDocumentFieldProps {
  label: string;
  input: React.ReactNode;
  isRequired?: boolean;
}

const DocumentField = ({ label, input, isRequired }: IDocumentFieldProps) => {
  return (
    <div className={ styles['document-field'] }>
      <p className={ styles['document-field-label'] }>
        { label }
        { isRequired && <span className={ styles['document-field-required'] }>*</span> }
      </p>
      <div className={ styles['document-field-input'] }>{ input }</div>
    </div>
  );
};

export default React.memo(DocumentField);
