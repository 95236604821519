import React from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';

import { RowData } from './schema';

import styles from './styles.module.scss';

interface EditableTableItemProps {
  title: string;
  sorting: ISorting<keyof RowData>;
}

const columnDefinitions: Record<string, { columnName: keyof RowData; label: string }[]> = {
  'Revenue Categories': [
    { columnName: 'itemsServices', label: 'Items/Services' },
    { columnName: 'journalEntryPosition', label: 'Journal Entry Position' },
    { columnName: 'chartOfAccounts', label: 'Chart of Accounts' },
    { columnName: 'department', label: 'Departments' },
  ],
  'Tax Categories': [
    { columnName: 'itemsServices', label: 'Items/Services' },
    { columnName: 'journalEntryPosition', label: 'Journal Entry Position' },
    { columnName: 'chartOfAccounts', label: 'Chart of Accounts' },
    { columnName: 'vendor', label: 'Tax Agency' },
  ],
  'Other Ledger Categories': [
    { columnName: 'itemsServices', label: 'Items/Services' },
    { columnName: 'journalEntryPosition', label: 'Journal Entry Position' },
    { columnName: 'chartOfAccounts', label: 'Chart of Accounts' },
  ],
  'Expense Adjustments': [
    { columnName: 'itemsServices', label: 'Items/Services' },
    { columnName: 'journalEntryPosition', label: 'Journal Entry Position' },
    { columnName: 'chartOfAccounts', label: 'Chart of Accounts' },
    { columnName: 'paymentProcessor', label: 'Payment Processor' },
    { columnName: 'department', label: 'Departments' },
  ],
  'Payment Processors': [
    { columnName: 'lineItemName', label: 'Items/Services' },
    { columnName: 'journalEntryPosition', label: 'Journal Entry Position' },
    { columnName: 'paymentProcessor', label: 'Payment Processor' },
  ],
};

const EditableTableItem: React.FC<EditableTableItemProps> = ({ title, sorting }) => {
  const columns = columnDefinitions[title] || [];
  const totalColumns = 5;
  const emptyColumns = totalColumns - columns.length;

  return (
    <Table.Row>
      {columns.map(({ columnName, label }) => (
        <Table.HCell<keyof RowData>
          key={ columnName }
          columnName={ columnName }
          sorting={ sorting }
          width={ `${97 / totalColumns}%` }
        >
          <div className={ styles['thead-short'] }>
            <span>{label}</span>
          </div>
        </Table.HCell>
      ))}
      {/* Add empty columns to fill up to 5 columns */}
      {Array.from({ length: emptyColumns }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Table.HCell key={ `empty-column-${index}` } width={ `${97 / totalColumns}%` } />
      ))}
      {/* Delete Button Column */}
      <Table.HCell width="3%" />
    </Table.Row>
  );
};

export default React.memo(EditableTableItem);
