import React, { useMemo } from 'react';

import { IItemAccount } from '@src/types/report_service/report_item';
import { IReportServiceQBOLineItemDetails } from '@src/types/report_service/report_item_value_account';

import Table from '@src/components/ui_v2/table';

import LoadingSkeletonRow from './loadingSkeletonRow';
import Row from './row';
import { IColumnNamesAndWidths } from './types';

interface IThirdDrillDownBodyProps {
  item: IItemAccount,
  columnNamesAndWidths: IColumnNamesAndWidths,
  lineItemValues: IReportServiceQBOLineItemDetails[],
  isLoading: boolean,
  hasMore: boolean,
  refObserver: React.RefObject<HTMLTableRowElement>,
}

const ThirdDrillDownBody = ({
  item,
  columnNamesAndWidths,
  lineItemValues,
  isLoading,
  hasMore,
  refObserver,
}: IThirdDrillDownBodyProps): JSX.Element => {
  const loadingSkeletonRows = useMemo(() => {
    const rows = [];
    for (let i = 0; i < 5; i += 1) {
      const rowId = `${item.columnId}-${item.itemId}-${item.reportDataId}-row${i}`;
      rows.push(
        <LoadingSkeletonRow key={ rowId } columnNamesAndWidths={ columnNamesAndWidths } rowId={ rowId } />,
      );
    }
    return rows;
  }, [columnNamesAndWidths, item.columnId, item.itemId, item.reportDataId]);

  if (isLoading && lineItemValues.length === 0) {
    return (
      <Table.Body>
        {loadingSkeletonRows}
      </Table.Body>
    );
  }
  const loadingRowId = `${item.columnId}-${item.itemId}-observer-loading-row`;
  return (
    <Table.Body>
      {lineItemValues?.map((value: IReportServiceQBOLineItemDetails) => (
        <Row key={ value.id } columnNamesAndWidths={ columnNamesAndWidths } lineItemValue={ value } />
      ))}

      {(hasMore) && (
        <>
          <LoadingSkeletonRow
            key={ loadingRowId }
            columnNamesAndWidths={ columnNamesAndWidths }
            refObserver={ refObserver }
            rowId={ loadingRowId }
          />
          {loadingSkeletonRows}
        </>
      )}
    </Table.Body>
  );
};

export default ThirdDrillDownBody;
