import React from 'react';

import Root from '@src/components/root';
import { ReconciliationCircleIcon } from '@src/components/utils/icomoon';

import { getReconciliationCenterSectionName } from './utils';

const GlobalNavigationMenu = () => {
  const sectionName = getReconciliationCenterSectionName(window.location.pathname + window.location.search);

  return (
    <Root>
      <div className="service-header btn-group">
        <div className="pull-left">
          <ReconciliationCircleIcon className="icon-title service-icon-font vertical-align-middle" />
        </div>
        <div className="business-service-dropdown-menu-wrapper">
          <span className="service-header-title pointer dropdown-toggle" data-toggle="dropdown">
            { sectionName }
          </span>
        </div>
      </div>
    </Root>
  );
};

export default GlobalNavigationMenu;
