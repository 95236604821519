import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';

import { TID, TDate } from '@src/types/common';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import ComponentForm from '@src/components/ui_v2/form';
import { SelectInput } from '@src/components/ui_v2/inputs';
import Tooltip from '@src/components/ui_v2/tooltip';
import { HelpIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IField {
  type: string | TDate;
  required: boolean;
  title: string;
  readOnly?: boolean;
  maxLength?: number;
  enum?: string[];
  helpText?: string;
}

interface IJsonFormProps {
  formId: string;
  businessId: TID;
  jsonSchema: { [key: string]: IField };
  valueForm?: { [key: string]: string | number | null };
  onSubmit: (data: { [key: string]: string | number | null }) => void;
  onValidationChange: (isValid: boolean) => void;
}

const Form = ({
  formId,
  businessId,
  jsonSchema,
  valueForm,
  onSubmit,
  onValidationChange,
}: IJsonFormProps) => {
  const [interactedFields, setInteractedFields] = useState<{ [key: string]: boolean }>({});
  const [focusFiled, setFocusFiled] = useState<string>('');

  const validationSchema = Yup.object().shape(
    Object.fromEntries(
      Object.entries(jsonSchema).map(([key, field]) => [
        key,
        field.type === 'string'
          ? Yup.string()
            .required(field.required ? `${field.title} is required` : undefined)
            .max(field.maxLength || Infinity, `${field.title} must be at most ${field.maxLength} characters`)
          : Yup.mixed(),
      ]),
    ),
  );

  const { control, handleSubmit, formState: { errors, isValid } } = useForm({
    mode:          'onChange',
    resolver:      yupResolver(validationSchema),
    defaultValues: valueForm,
  });

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);

  const handleFieldInteraction = (key: string) => {
    setFocusFiled('');
    if (!interactedFields[key]) {
      setInteractedFields((prev) => ({ ...prev, [key]: true }));
    }
  };

  const renderLabel = (
    title: string,
    key: string,
    showHelp: boolean,
    focus: string,
    helpText?: string,
    type?: string,
  ) => {
    if (!helpText) return title;

    const isFocused = !showHelp && key === focus;
    if (type === 'select') {
      return (
        <Tooltip.Hover
          key={ `${key}-${isFocused ? 'first-select' : 'default-select'}-control` }
          content={ helpText }
          isControl={ isFocused }
          showTooltip={ isFocused }
        >
          <div className={ styles['label-with-help'] }>
            <span>{title}</span>
            <HelpIcon fontSize={ 18 } />
          </div>
        </Tooltip.Hover>
      );
    }
    return (
      <span className={ styles['label-with-help'] }>
        {title}
        {' '}
        <Tooltip.Hover
          key={ `${key}-${isFocused ? 'first' : 'default'}-control` }
          content={ helpText }
          isControl={ isFocused }
          showTooltip={ isFocused }
        >
          <HelpIcon fontSize={ 18 } />
        </Tooltip.Hover>
      </span>
    );
  };

  const renderField = (key: string, field: IField) => {
    if (key === 'revenueReportTypeId') {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <RevenueReportTypeField
              businessId={ businessId }
              label={ field.title }
              placeholder="Select Revenue Mapping"
              value={ value ? Number(value) : null }
              onBlur={ () => handleFieldInteraction(key) }
              onChange={ (e) => onChange(e?.toString() || '') }
              onFocus={ () => setFocusFiled(key) }
            />
          ) }
        />
      );
    }
    if (field.type === 'string' && Array.isArray(field.enum)) {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <div className={ `${styles['form-field']} ${styles['form-field-normal']}` }>
              <p className={ styles['form-field-label'] }>
                {renderLabel(field.title, key, interactedFields[key], focusFiled, field.helpText, 'select')}
              </p>
              <div className={ styles['form-field-value'] }>
                <SelectInput
                  options={ (field.enum ?? []).map((item) => ({ label: String(item), value: String(item) })) }
                  value={ value ? { label: String(value), value: String(value) } : null }
                  onBlur={ () => handleFieldInteraction(key) }
                  onChange={ (selected) => onChange(selected?.value) }
                  onFocus={ () => setFocusFiled(key) }
                />
              </div>
            </div>

          ) }
        />
      );
    }
    if (field.type === 'string') {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <ComponentForm.TextField
              error={ errors[key]?.message }
              hideClear={ key === 'password' }
              label={ renderLabel(field.title, key, interactedFields[key], focusFiled, field.helpText) }
              maxLength={ field.maxLength }
              placeholder={ `Enter ${field.title}` }
              readOnly={ field.readOnly }
              required={ field.required }
              type={ key === 'password' ? 'password' : 'text' }
              value={ value || '' }
              onBlur={ () => handleFieldInteraction(key) }
              onChange={ (e) => onChange(e.target.value) }
              onFocus={ () => setFocusFiled(key) }
            />
          ) }
        />
      );
    }
    if (field.type === 'date') {
      return (
        <Controller
          key={ key }
          control={ control }
          name={ key }
          render={ ({ field: { onChange, value } }) => (
            <ComponentForm.DateField
              error={ errors[key]?.message }
              label={ renderLabel(field.title, key, interactedFields[key], focusFiled, field.helpText) }
              value={ value ? `${value}` : '' }
              onBlur={ () => handleFieldInteraction(key) }
              onChange={ (date) => onChange(date) }
              onFocus={ () => setFocusFiled(key) }
            />
          ) }
        />
      );
    }
    return null;
  };

  return (
    <ComponentForm id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      {Object.entries(jsonSchema).map(([key, field]) => renderField(key, field))}
    </ComponentForm>
  );
};

export default React.memo(Form);
