import React from 'react';

import { selfOnboardingPath } from '@src/routes';
import { ISelfOnboardingInformation, ISelfOnboardingInvitation } from '@src/types/self_onboarding_invitation';

import ServiceSidebar from '@src/components/ui/service_sidebar';
import { SuccessIcon } from '@src/components/utils/icomoon';

interface ISelfOnboardingSideMenuProps {
  invitation: ISelfOnboardingInvitation,
  step: number,
}

const getBadgeIcon = (onboardingInformation: ISelfOnboardingInformation, step: number) => {
  if (onboardingInformation.completedSteps.includes(step)) {
    return <SuccessIcon />;
  }

  return undefined;
};

const SelfOnboardingSidemenu = ({
  invitation,
  step,
}: ISelfOnboardingSideMenuProps) => {
  const enableQBOStep = invitation.businesses.some(
    (business) => !business.onboardingLedgerConnectionDisabled,
  );

  return (
    <ServiceSidebar>
      <ServiceSidebar.Item
        active={ step === 0 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 0) }
        href={ selfOnboardingPath(0) }
        title="Business Details"
      />
      <ServiceSidebar.Item
        active={ step === 3 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 3) }
        href={ selfOnboardingPath(3) }
        title="Billing Info"
      />
      <ServiceSidebar.Item
        active={ step === 1 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 1) }
        href={ selfOnboardingPath(1) }
        isHidden={ !enableQBOStep }
        title="Accounting Software"
      />
      <ServiceSidebar.Item
        active={ step === 5 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 5) }
        href={ selfOnboardingPath(5) }
        title="Connect Banks"
      />
      <ServiceSidebar.Item
        active={ step === 6 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 6) }
        href={ selfOnboardingPath(6) }
        title="Payment Methods"
      />
      <ServiceSidebar.Item
        active={ step === 2 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 2) }
        href={ selfOnboardingPath(2) }
        title="Add Signature"
      />
      <ServiceSidebar.Item
        active={ step === 4 }
        badgeIcon={ getBadgeIcon(invitation.onboardingInformation, 4) }
        href={ selfOnboardingPath(4) }
        title="Deposit Verification"
      />
    </ServiceSidebar>
  );
};

export default SelfOnboardingSidemenu;
