import React, { useState, useCallback, useEffect } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { TID } from '@src/types/common';
import { IExternalSystemPullProperties } from '@src/types/integrations/integrations';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';
import SearchInput from '@src/components/ui_v2/search_dropdown/search_input';
import Tooltip from '@src/components/ui_v2/tooltip';
import ExternalSystemsIcon from '@src/components/utils/icomoon/external_systems';

import { useGetIntegrationExternalSystemData } from './hooks';
import { useManualRevenueSystemModel } from '../manual_revenue_system/manual_revenue_system_model';
import { usePullIntegrationConnectModal } from '../pull_integration_connect/pull_integration_connect_modal';
import { usePushIntegrationConnectModel } from '../push_integration_connect/push_integration_connect_model';

import styles from './styles.module.scss';

interface IConnectRevenueSystemModalProps extends IUseModalProps {}
const ConnectRevenueSystemModal = ({
  isOpen,
  onDone,
}: IConnectRevenueSystemModalProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const { externalSystemList, refetch } = useGetIntegrationExternalSystemData();
  const [showAddSystem, setShowAddSystem] = useState<boolean>(false);
  const pushIntegrationConnectModel = usePushIntegrationConnectModel();
  const pullIntegrationConnectModel = usePullIntegrationConnectModal();
  const [externalSystemId, setExternalSystemId] = useState<TID | null>(null);
  const [externalSystemName, setExternalSystemName] = useState<string | null>(null);
  const [externalSystemIntegrationType, setexternalSystemIntegrationType] = useState<string | null>(null);
  const [externalSystemConfigSchema, setExternalSystemConfigSchema] = useState<
  IExternalSystemPullProperties | null
  >(null);
  const [externalSystemSkipRevenueMapping, setExternalSystemSkipRevenueMapping] = useState<boolean | null>(null);

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  const filteredSystems = externalSystemList.filter((
    sys,
  ) => sys.label.toLowerCase().includes(searchValue.toLowerCase()));
  const addSystemModal = useManualRevenueSystemModel();
  const revenueService = useRevenueServiceContext();

  const handleDone = useCallback(() => {
    setSearchValue('');
    onDone();
  }, [onDone]);

  const connectPushSystem = useCallback((
    systemId: TID,
    systemName: string,
    integrationType: string,
    configSchema?:IExternalSystemPullProperties,
    skipRevenueMapping?: boolean,
  ) => {
    setExternalSystemId(systemId);
    setExternalSystemName(systemName);
    setexternalSystemIntegrationType(integrationType);
    setExternalSystemSkipRevenueMapping(skipRevenueMapping || false);
    if (configSchema) {
      setExternalSystemConfigSchema(configSchema);
    }

    if (integrationType === 'email') {
      pushIntegrationConnectModel.open();
      handleDone();
    } else if (integrationType === 'pull') {
      pullIntegrationConnectModel.open();
      handleDone();
    }
  }, [pushIntegrationConnectModel, handleDone, pullIntegrationConnectModel]);

  const setupManually = useCallback(() => {
    setShowAddSystem(true);
    addSystemModal.open();
    handleDone();
  }, [addSystemModal, handleDone]);

  const toKebabCase = (str : string) => str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[_\s]+/g, '-')
    .toLowerCase();

  return (
    <>
      <Modal
        className={ styles['modal-template-excel'] }
        show={ isOpen }
        title="Connect Revenue System"
      >
        <Modal.Body>
          <span className={ styles['description-text'] }>
            Select a revenue system or setup manually to continue.
          </span>
          <SearchInput
            hideClear
            aria-label="Search revenue systems"
            placeholder="Search Revenue System"
            value={ searchValue }
            width="100%"
            onChange={ setSearchValue }
          />
          <div className={ styles['revenue-system-list'] }>
            {filteredSystems.map((system) => (
              <div key={ system.id } className={ styles['revenue-system-item'] }>
                <div className={ styles['revenue-system-name-wrapper'] }>
                  <ExternalSystemsIcon type={ toKebabCase(system.label) } />
                  <Tooltip.Hover className={ styles['revenue-system-name'] } content={ system.label }>
                    <span>{system.label}</span>
                  </Tooltip.Hover>
                </div>
                <span className={ styles['revenue-system-status'] }>
                  {system.connected}
                  {' '}
                  Connected
                </span>
                <Button
                  className={ styles['connect-button'] }
                  variant="link"
                  onClick={ () => connectPushSystem(
                    system.id,
                    system.label,
                    system.integrationType,
                    system.configSchema,
                    system.skipRevenueMapping,
                  ) }
                >
                  Connect
                </Button>
              </div>
            ))}
          </div>
          <div className={ styles['setup-wrapper'] }>
            <div className={ styles['separator-text'] }>
              <span>Couldn&#39;t find your revenue system?</span>
            </div>
            <Button
              variant="link"
              onClick={ () => { setupManually(); } }
            >
              Setup Manually
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-v2">
          <div className={ styles['cancel-button'] }>
            <Button
              variant="link"
              onClick={ () => handleDone() }
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {externalSystemId && externalSystemName && externalSystemIntegrationType && (
        <>
          {externalSystemConfigSchema && (
            <pushIntegrationConnectModel.Component
              externalSystemConfigSchema={ externalSystemConfigSchema }
              externalSystemId={ externalSystemId }
              externalSystemName={ externalSystemName }
              skipRevenueMapping={ externalSystemSkipRevenueMapping || false }
              { ...pushIntegrationConnectModel.props }
            />
          )}

          {externalSystemConfigSchema && (
            <pullIntegrationConnectModel.Component
              externalSystemConfigSchema={ externalSystemConfigSchema }
              externalSystemId={ externalSystemId }
              externalSystemName={ externalSystemName }
              skipRevenueMapping={ externalSystemSkipRevenueMapping || false }
              { ...pullIntegrationConnectModel.props }
            />
          )}
        </>
      )}
      {showAddSystem
      && (
      <addSystemModal.Component
        revenueService={ revenueService }
        source="ConnectRevenueSystem"
        { ...addSystemModal.props }
      />
      )}
    </>
  );
};

const useConnectRevenueSystemModal = makeUseModal(ConnectRevenueSystemModal);

export { useConnectRevenueSystemModal, ConnectRevenueSystemModal as default };
