import React, { useMemo, useState, useCallback, useEffect } from 'react';

import { flatten } from 'lodash';

import { useGetClientBusinesses } from '@src/hooks/queries/client_businesses';
import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useSorting } from '@src/hooks/url_params';
import { TClientBusinessesSortColumn } from '@src/types/clients';
import { ISortingParams } from '@src/types/sorting';

import SearchResult from '@src/components/client_dashboard/main_details/search_result';
import { OperationalDashboardTableList } from '@src/components/operational_dashboard';
import SideView from '@src/components/ui/side_view';
import { Button } from '@src/components/ui_v2/buttons';
import QueryStatus from '@src/components/utils/query_status';

import Body from './body';
import Header from './header';
import Spinner from '../ui/spinner';

import styles from './styles.module.scss';

const DefaultSorting: ISortingParams<TClientBusinessesSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

const DetailsPage = (): JSX.Element => {
  const [dashboardUpldated, setDashboardUpldated] = useState<boolean>(false);
  const [switchToNewDashboard, setSwitchToNewDashboard] = useState<boolean>(true);
  const {
    data: isMultiBusinessDashboardEnabled, isFetching,
  } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.MULTI_BUSINESS_DASHBOARD);
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');

  const sorting = useSorting<TClientBusinessesSortColumn>({ defaultSorting: DefaultSorting });

  const query = useGetClientBusinesses({
    searchQuery,
    ...sorting.data,
  }, {
    enabled: !switchToNewDashboard,
  });

  const { refetch: refetchClientBusinesses } = query;

  const clientBusinessesPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const clientBusinesses = useMemo(() => {
    return flatten(clientBusinessesPages.map((p) => p.collection));
  }, [clientBusinessesPages]);

  const totalCount = useMemo(() => {
    return clientBusinessesPages[0]?.meta.totalCount;
  }, [clientBusinessesPages]);

  const searchCount = useMemo(() => {
    return clientBusinessesPages[0]?.meta.searchCount;
  }, [clientBusinessesPages]);

  const handleDashboardUpdated = useCallback(() => {
    setDashboardUpldated(true);
  }, []);

  const handleClickReload = useCallback(() => {
    setDashboardUpldated(false);
    refetchClientBusinesses();
  }, [refetchClientBusinesses]);

  useEffect(() => {
    window.Docyt.vent.on('docyt:faye:notification', handleDashboardUpdated);

    return () => {
      window.Docyt.vent.off('docyt:faye:notification');
    };
  }, [handleDashboardUpdated]);

  const handleSwitchToNewDashboard = useCallback(() => {
    setSwitchToNewDashboard(true);
  }, [setSwitchToNewDashboard]);
  const switchToOldDashboard = useCallback(() => {
    setSwitchToNewDashboard(false);
  }, [setSwitchToNewDashboard]);

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <>
      <QueryStatus query={ query } />
      <div className={ styles['client-dashboard-details-page'] }>
        <div className={ styles['client-dashboard-details-header'] }>
          <Header
            isMultiBusinessDashboardEnabled={ isMultiBusinessDashboardEnabled || false }
            searchQuery={ searchQuery }
            switchToNewDashboard={ switchToNewDashboard }
            onSearched={ setSearchQuery }
            onSwitchToNewDashboard={
              switchToNewDashboard ? switchToOldDashboard : handleSwitchToNewDashboard
            }
          />
        </div>
        <div className={ styles['body-region'] }>
          {
            (dashboardUpldated && !switchToNewDashboard) && (
              <Button
                className={ styles['dashboard-reload-button'] }
                variant="primary"
                onClick={ handleClickReload }
              >
                Dashboard has been updated. Click here to reload
              </Button>
            )
          }
          {
            searchQuery && (
              <SearchResult searchCount={ searchCount } searchQuery={ searchQuery } />
            )
          }
          {
            switchToNewDashboard ? (
              <SideView.Provider>
                <OperationalDashboardTableList />
                <SideView.Render />
              </SideView.Provider>
            ) : (
              query.isFetched && (
                <Body
                  clientBusinesses={ clientBusinesses }
                  query={ query }
                  searchCount={ searchCount }
                  sorting={ sorting }
                  totalCount={ totalCount }
                />
              )
            )
          }
        </div>
      </div>
    </>
  );
};

export default DetailsPage;
