import React, { FC, useEffect } from 'react';

import axios from 'axios';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IGetAdjustmentEntryParams, useGetAdjustmentEntryByParams } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { TID } from '@src/types/common';

import Footer from '@src/components/reconciliation_center/journal_entry/footer';
import { SpinnerIcon } from '@src/components/utils/fa_icons';

import DetailTable from './detail_table';
import EditPanel from './edit_panel';
import NameInfo from './name_info';

import styles from './styles.module.scss';

const DirectFetchContent: FC<{
  params: IGetAdjustmentEntryParams;
  setIsShowAuditHistory: React.Dispatch<React.SetStateAction<boolean>>;
  setDocumentId: React.Dispatch<React.SetStateAction<TID | null>>;
}> = ({ params, setIsShowAuditHistory, setDocumentId }) => {
  const business = useBusinessContext();
  const businessId = business.id;
  const { data, isLoading, isError, error } = useGetAdjustmentEntryByParams(params, businessId);
  const fromDocyt = params.docytId;

  const adjustmentEntry = data?.adjustmentEntry as IAdjustmentEntry;

  useEffect(() => {
    if (adjustmentEntry) {
      setDocumentId(adjustmentEntry.documentId);
    }
  }, [adjustmentEntry, setDocumentId]);

  if (isLoading) {
    return <div className={ styles['loading-container'] }><SpinnerIcon spin fontSize={ 30 } /></div>;
  }

  if (isError) {
    let message = 'An unexpected error occurred';

    if (axios.isAxiosError(error)) {
      if (Array.isArray(error?.response?.data?.errors) && error?.response?.data.errors.length > 0) {
        message = error?.response?.data.errors[0];
      } else if (error?.response?.data?.error) {
        message = error.response.data.error;
      }
    }

    return (
      <div className={ styles['error-container'] }>
        <span>Error:</span>
        { message }
      </div>
    );
  }

  return (
    <>
      <div className={ styles.container }>
        <div className={ styles.header }>
          <NameInfo data={ adjustmentEntry } />
          <EditPanel data={ adjustmentEntry } setIsShowAuditHistory={ setIsShowAuditHistory } />
        </div>

        <DetailTable data={ adjustmentEntry } />
      </div>
      { fromDocyt && <Footer /> }
    </>
  );
};

export default DirectFetchContent;
