import React from 'react';

import toastr from '@lib/toastr';
import { businessSettingsPath, clientBusinessSettingsPath } from '@src/routes';
import { IBusiness, TBusinessIntent, IUsersBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';
import { backboneNavigateToUrl, redirectToBusinessService } from '@src/utils/navigate';

import { AppLink } from '@src/components/ui_v2/buttons';

interface RenderBusinessLinkProps {
  businessId: TID | null | undefined;
  displayName: string | undefined;
  legalName?: string | undefined;
  businessIntent: TBusinessIntent | string | undefined;
  hasBusinessAccess?: boolean;
  renderContent: (displayName: string | undefined, legalName?: string) => React.ReactNode;
}

// Extracted logical conditions
export const hasDefaultAccountingFirm = (): boolean => {
  return !!window.Docyt.currentAdvisor.defaultAccountingFirmId();
};

export const getDefaultAccountingFirmId = (): number => {
  return window.Docyt.currentAdvisor.defaultAccountingFirmId() ?? 0;
};

export const getSpecialIntents = (): string[] => {
  return [
    window.configData.business_intent.BOOKS_GPT,
    window.configData.business_intent.FIRM_CLOSING_SUITE,
    window.configData.business_intent.PREMIUM_FIRM_CLOSING_SUITE,
  ];
};

export const isIntentAccessibleToBusiness = (businessIntent: TBusinessIntent | string | undefined): boolean => {
  return getSpecialIntents().includes(businessIntent as string);
};

export const isBusinessUser = (): boolean => {
  return !!window.Docyt?.currentAdvisor?.isBookgptBusinessUser?.();
};

export const getBusinessNavigationAppLink = ({
  businessId,
  displayName,
  legalName,
  businessIntent,
  hasBusinessAccess,
  renderContent,
}: RenderBusinessLinkProps): React.ReactNode => {
  const businessContent = renderContent(displayName, legalName);
  const defaultAccountingFirmId = getDefaultAccountingFirmId();
  const hrefUrl = clientBusinessSettingsPath(businessId ?? 0, defaultAccountingFirmId);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    toastr.error('You do not have access to this module in this business. Give access to yourself.', 'Error');
    backboneNavigateToUrl(hrefUrl);
  };

  if (!hasBusinessAccess) {
    if (!hasDefaultAccountingFirm()) return businessContent;

    return (
      <AppLink href={ hrefUrl } onClick={ handleClick }>
        {businessContent}
      </AppLink>
    );
  }

  /**
   * Consider only fullbookkeeping/ReceivableOnlyUser to get in business
   */
  if (!isIntentAccessibleToBusiness(businessIntent)) {
    return (
      <AppLink href={ `/businesses/${businessId}` }>
        {businessContent}
      </AppLink>
    );
  }

  if (isBusinessUser()) {
    return (
      <AppLink href={ businessSettingsPath(businessId ?? 0) }>
        {businessContent}
      </AppLink>
    );
  }

  if (!hasDefaultAccountingFirm()) {
    return businessContent;
  }

  return (
    <AppLink href={ clientBusinessSettingsPath(businessId ?? 0, defaultAccountingFirmId) }>
      {businessContent}
    </AppLink>
  );
};

export const navigateBusinessByIntent = (
  businessIntent: TBusinessIntent | string | undefined,
  businessId: string,
  clientBusinessId: string | null,
  withServiceItem: boolean,
  currentBusiness?: IBusiness | IUsersBusiness | null,
  newBusiness?: IBusiness | IUsersBusiness | null,
):void | undefined => {
  const hrefUrl = clientBusinessSettingsPath(Number(clientBusinessId), Number(businessId));
  // If business intent is not accessible to business, redirect to business service
  if (businessIntent && !isIntentAccessibleToBusiness(businessIntent)) {
    redirectToBusinessService(
      businessId,
      clientBusinessId,
      withServiceItem,
      currentBusiness,
      newBusiness,
    );
    return;
  }

  if (isBusinessUser()) {
    backboneNavigateToUrl(businessSettingsPath(Number(businessId)));
    return;
  }

  if (hasDefaultAccountingFirm()) {
    backboneNavigateToUrl(hrefUrl);
  }
};
