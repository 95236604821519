import React from 'react';

import { ISorting } from '@src/types/sorting';

import Table from '@src/components/ui_v2/table';

import { TColumn, TModel } from './types';

interface ICollectionTableHeaderCellProps<
  Model extends TModel,
  TSortColumn extends string = never
> {
  column: TColumn<Model, TSortColumn>
  sorting: ISorting<TSortColumn>,
}

const CollectionTableHeaderCell = <Model extends TModel, TSortColumn extends string = never>({
  column,
  sorting,
}: ICollectionTableHeaderCellProps<Model, TSortColumn>) => {
  if (column.hidden) return null;

  const commonProps = (column.sortColumn && sorting) ? {
    width:      column.width,
    columnName: column.sortColumn,
    sorting,
  } : {
    width: column.width,
  };

  if (column.type === 'amount') {
    return (
      <Table.AmountHCell<TSortColumn> { ...commonProps }>
        { column.title }
      </Table.AmountHCell>
    );
  }

  if (column.type === 'button' && !column?.isVisibleHeader) {
    return (
      <Table.ButtonHCell<TSortColumn> align={ column.align } { ...commonProps }>
        { column.title }
      </Table.ButtonHCell>
    );
  }

  if (column.type === 'icon' || column.type === 'actions' || column.type === 'select_item') {
    return (
      <Table.EmptyHCell<TSortColumn> title={ column.title } { ...commonProps } />
    );
  }

  return (
    <Table.HCell<TSortColumn> { ...commonProps }>
      { column.title }
    </Table.HCell>
  );
};

export default CollectionTableHeaderCell;
