import { IDocument } from '@src/types/documents';

export const getDisplayName = (doc: IDocument, type: string): string => {
  let defaultName = '';
  if (type === 'balance_sheet') {
    defaultName = 'Balance Sheet';
  } else if (type === 'bank_statement') {
    defaultName = 'Bank Statement';
  } else {
    defaultName = 'Document';
  }
  return doc.name
         || doc.originalFileName
         || doc.originalFileKey
         || doc.computedFinalFilename
         || doc.original_file_name
         || doc.final_file_key
         || doc.computed_final_filename
         || defaultName;
};
