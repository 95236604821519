/* eslint-disable max-len */
import Big from 'big.js';
import * as yup from 'yup';

import { FormValues, IJournalEntryLine, RowType } from '@src/types/adjustment_entries';

const MEMO_CHARACTER_LIMIT = 3800;

const rowValidationSchema = yup.object({
  account:     yup.string().required('Chart of Account is required'),
  description: yup.string().trim().required('Description is required'),
  type:        yup.string().nullable(),
  name:        yup.string().nullable(),
  //  name: yup.string().required('Name is required'),
  // department:  yup.number().required('Department is required'),
  department:  yup.number().nullable(),
  debit:       yup.number().nullable(),
  credit:      yup.number().nullable(),
}).test(
  'either-debit-or-credit',
  'Either Debit or Credit is required, but not both',
  function eitherDebitOrCredit(row) {
    const { debit, credit } = row;
    const bothEmpty = debit == null && credit == null;
    const bothFilled = debit != null && credit != null;

    if (bothEmpty || bothFilled) {
      return this.createError({
        path:    `${this.path}.eitherDebitOrCredit`,
        message: "Please ensure only one of 'debit' or 'credit' is chosen for the input.",
      });
    }
    return true;
  },
);

const shouldValidate = (row: RowType) => Object.keys(row).some((it) => it !== 'id' && !!(row as Record<string, any>)[it]);

const skipValidate = (row: RowType) => !shouldValidate(row);

const rowsSchema = yup.array().test(
  'validate-rows',
  '',
  function validateRows(rows) {
    const errors: yup.ValidationError[] = [];
    const nonBlankRows = rows?.filter((row) => shouldValidate(row)) || [];
    const that = this;

    rows?.forEach((row: RowType, index: number) => {
      if (skipValidate(row)) return;

      try {
        rowValidationSchema.validateSync(row, { abortEarly: false });
      } catch (e: any) {
        e?.inner?.forEach((fieldError: any) => {
          const path = `${that.path}[${index}].${fieldError.path}`;
          const message = fieldError.message;
          const error = that.createError({ path, message });
          errors.push(error);
        });
      }

      const { debit, credit } = row;
      const bothBlank = debit == null && credit == null;
      const bothFilled = debit != null && credit != null;

      if (bothBlank || bothFilled) {
        const path = `${this.path}[${index}].eitherDebitOrCredit`;
        const message = "Please ensure only one of 'debit' or 'credit' is chosen for the input.";
        const error = this.createError({ path, message });
        errors.push(error);
      }
    });

    if (errors.length > 0) {
      return new yup.ValidationError(errors);
    }

    const totalDebit = nonBlankRows.reduce((acc, cur) => acc.add(Big(cur.debit || 0)), Big(0)) || Big(0);
    const totalCredit = nonBlankRows.reduce((acc, cur) => acc.add(Big(cur.credit || 0)), Big(0)) || Big(0);
    if (!totalDebit.eq(totalCredit)) {
      return this.createError({
        path:    'totalAmount',
        message: 'Total of debit and total of credit must be balanced.',
      });
    }

    if (nonBlankRows.length < 2) {
      return this.createError({
        path:    'totalLines',
        message: 'At least add two lines',
      });
    }

    return true;
  },
);

const tableFormSchema = yup.object({
  rows: rowsSchema,
  date: yup.string().required('Date is required'),
  memo: yup.string()
    .max(MEMO_CHARACTER_LIMIT, `Memo must not exceed ${MEMO_CHARACTER_LIMIT} characters`)
    .nullable(),
});

const editDataToFormData = (editData: any) => {
  const obj: { rows: RowType[]; date: string; id: string; memo: string } = {
    rows: [],
    date: editData.entryDate,
    id:   editData.id,
    memo: editData.memo,
  };
  obj.rows = editData.lines.map((i: IJournalEntryLine) => {
    const row: RowType = {
      id:          i.id,
      accountObj:  i.chartOfAccount,
      entityObj:   i.entityObj,
      account:     i.chartOfAccount.id,
      type:        i.entityType === 'QuickbooksCustomer' ? 'QuickbooksCustomer' : 'Vendor',
      name:        (i.entityType === 'QuickbooksCustomer' ? i.entityId : i.entityVendorId) as string,
      description: i.memo,
    };
    if (Number(i.debits) !== 0) {
      row.debit = i.debits;
    }
    if (Number(i.credits) !== 0) {
      row.credit = i.credits;
    }
    if (i.accountingClass) {
      row.department = i.accountingClass.id;
    }

    return row;
  });

  return obj;
};

const formDataToApiData =
  (
    data: FormValues,
    editModel: boolean,
    businessId: number,
    additionalParams?: Record<string, any>,
  ) => {
    const splits: RowType[] = [];

    data.rows.forEach((i) => {
      const obj: any = {
        chartOfAccountId: i.account,
        entityType:       i.type as string,
        entityId:         String(i.name ?? ''),
        debits:           i.debit as number,
        credits:          i.credit as number,
        memo:             i.description,
      };

      if (i.department) {
        obj.accountingClassId = String(i.department);
      }

      if (editModel) {
        if (i.id) {
          obj.id = i.id;
        }
        obj.debits = obj.debits || 0;
        obj.credits = obj.credits || 0;
      }

      if (shouldValidate(obj)) splits.push(obj);
    });

    const debits = data.rows.reduce((acc, current) => acc + (Number(current.debit) || 0), 0);
    let params: any = {
      entryDate: data.date,
      businessId,
      splits,
      amount:    debits,
      memo:      data.memo,
    };

    if (editModel) {
      params.id = data.id;
    }

    if (additionalParams) {
      params = { ...params, ...additionalParams };
    }
    return params;
  };

export { tableFormSchema, editDataToFormData, formDataToApiData, MEMO_CHARACTER_LIMIT };
