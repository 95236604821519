import React from 'react';

import moment from 'moment';
import { UseInfiniteQueryResult } from 'react-query';

import { IGetTransactionMessagesResponseInfinite } from '@src/requests/transaction_messages';
import { IChat, TTransactionMessageState } from '@src/types/transaction_message';
import { parseMessage } from '@src/utils/parse_message';

import { UserAvatar } from '@src/components/ui/avatars';
import Table from '@src/components/ui/table';
import Text from '@src/components/ui/text';

import TableHead from './table_head';

import styles from '../styles.module.scss';

interface ITableListProps {
  messages: IChat[];
  hasNoMessages: boolean;
  state: TTransactionMessageState;
  onMessageClick: (message: IChat) => void;
  query: UseInfiniteQueryResult<IGetTransactionMessagesResponseInfinite, Error>;
}

const TableList: React.FC<ITableListProps> = ({ messages, hasNoMessages, state, onMessageClick, query }) => {
  const formatDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY');
  };

  return (
    <Table
      className={ styles['message-table'] }
      infiniteQuery={ query }
    >
      <TableHead />
      <Table.Body>
        { hasNoMessages ? (
          <Table.Row>
            <Table.Cell colSpan={ 3 }>
              <div className={ styles['no-messages-cell'] }>
                No
                {' '}
                {state === 'unread' ? 'unread' : ''}
                {' '}
                transaction messages found for this business
              </div>
            </Table.Cell>
          </Table.Row>
        ) : (
          messages.map((message) => (
            <Table.Row
              key={ message.id }
              className={ styles['message-row'] }
              onClick={ () => onMessageClick(message) }
            >
              <Table.Cell>
                {formatDate(message.lastMessage.createdAt)}
              </Table.Cell>
              <Table.Cell>
                <Text
                  as="span"
                  className={ styles['message-description'] }
                  dangerouslySetInnerHTML={ { __html: parseMessage(message.lastMessage.text) ?? '' } }
                />
              </Table.Cell>
              <Table.Cell>
                <div className={ styles['message-info'] }>
                  <div className={ styles['message-avatar'] }>
                    <UserAvatar
                      avatar={ { avatarUrl: message.lastMessageSenderAvatar || undefined } }
                      size={ 24 }
                    />
                  </div>
                  <div className={ styles['message-user-info'] }>
                    <span className={ styles['message-business-name'] }>
                      {message.lastMessageSenderName ?? 'Former User'}
                    </span>
                  </div>
                </div>
              </Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table>
  );
};

export default TableList;
