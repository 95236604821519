import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetBalanceSheetInformationsParams,
  IGetBalanceSheetInformationsResponse,
  getBalanceSheetInformations,
  IMarkAsNoAvailableBalanceSheetStatementParams,
  postMarkAsNoAvailableBalanceSheetStatement,
  IBalanceSheetStatementParams,
  postUnmarkNoStatementAvailable,
  getBalanceSheetStatements,
  IGetBalanceSheetStatementsParams,
  IGetBalanceSheetStatementsResponse,
  IGetBalanceSheetGroupAccountsResponse,
  IGetBalanceSheetGroupAccountsParams,
  getBalanceSheetGroupedAccounts,
  IUpdateBalanceSheetGroupedAccountsParams,
  updateBalanceSheetGroupedAccounts,
} from '@src/requests/balance_sheet_informations';
import { IBalanceSheetAccounts } from '@src/types/balance_sheet_information';
import { IBalanceSheetStatement } from '@src/types/balance_sheet_statements';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetBalanceSheetStatements = createUseGetInfiniteCollection<
  IBalanceSheetStatement,
  IGetBalanceSheetStatementsParams,
  IGetBalanceSheetStatementsResponse
>({
  queryKey: QueryKey.balanceSheetStatement,
  request:  getBalanceSheetStatements,
});

const useGetBalanceSheetInformations = createUseGetInfiniteCollection<
  IBalanceSheetAccounts,
  IGetBalanceSheetInformationsParams,
  IGetBalanceSheetInformationsResponse
>({
  queryKey: QueryKey.balanceSheetStatement,
  request:  getBalanceSheetInformations,
});

const useGetBalanceSheetGroupedAccounts = (
  params: IGetBalanceSheetGroupAccountsParams,
  isModalOpen: boolean,
) => {
  return useQuery<IGetBalanceSheetGroupAccountsResponse[], Error>(
    [QueryKey.balanceSheetGroupedAccounts, params],
    () => getBalanceSheetGroupedAccounts(params),
    {
      enabled: isModalOpen,
    },
  );
};

const useUpdateBalanceSheetGroupedAccounts = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IUpdateBalanceSheetGroupedAccountsParams>(
    updateBalanceSheetGroupedAccounts,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

const useMarkAsNoAvailableBalanceSheetStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IMarkAsNoAvailableBalanceSheetStatementParams>(
    postMarkAsNoAvailableBalanceSheetStatement,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

const useUnmarkNoStatementAvailableBalanceSheetStatement = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, IBalanceSheetStatementParams>(
    postUnmarkNoStatementAvailable,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.balanceSheetStatement);
      },
    },
  );
};

export {
  useGetBalanceSheetInformations,
  useMarkAsNoAvailableBalanceSheetStatement,
  useUnmarkNoStatementAvailableBalanceSheetStatement,
  useGetBalanceSheetStatements,
  useGetBalanceSheetGroupedAccounts,
  useUpdateBalanceSheetGroupedAccounts,
};
