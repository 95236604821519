import { IChat, TTransactionMessageState } from '@src/types/transaction_message';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut } from './helpers';

interface IGetTransactionMessagesParams {
  page?: number;
  state?: TTransactionMessageState;
  businessId?: number;
}

interface IGetTransactionMessagesResponse {
  chats: IChat[];
  meta: {
    totalCount: number;
  };
}

interface IGetTransactionMessagesResponseInfinite {
  collection: IChat[];
  meta: {
    totalCount: number;
  };
}

interface IMarkAllChatsAsReadParams {
  businessId: number;
}

interface IMarkAllChatsAsReadResponse {
  success: boolean;
}

const getTransactionMessages = async (
  params: IGetTransactionMessagesParams,
): Promise<IGetTransactionMessagesResponseInfinite> => {
  const url = '/api/v1/chats';
  return apiGet(url, underscoreKeys(params)).then((data) => {
    const response = camelizeKeys(data) as IGetTransactionMessagesResponse;
    return {
      collection: response.chats,
      meta:       {
        totalCount: response.meta.totalCount,
      },
    };
  });
};

const markAllAsRead = async (params: IMarkAllChatsAsReadParams): Promise<IMarkAllChatsAsReadResponse> => {
  const url = '/api/v1/chats/mark_all_as_read';
  return apiPut(url, underscoreKeys(params)).then((data) => {
    return data;
  });
};

export {
  IGetTransactionMessagesParams,
  IGetTransactionMessagesResponse,
  IGetTransactionMessagesResponseInfinite,
  IMarkAllChatsAsReadParams,
  IMarkAllChatsAsReadResponse,
  getTransactionMessages,
  markAllAsRead,
};
