import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { useGetMessagesByChatId } from '@src/hooks/queries/documents';
import { getDocument } from '@src/requests/documents';
import { TID } from '@src/types/common';
import { IChatDocument, IDocument } from '@src/types/documents';

import UploadDocument from '@src/components/common_v2/document/upload_document';
import Spinner from '@src/components/ui/spinner';
import { Badge } from '@src/components/ui_v2/badge/badge';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import PlusIcon from '@src/components/utils/fa_icons/plus_icon';

import styles from '../styles.module.scss';

const getChatDocumentName = (document: IChatDocument) => {
  return document.originalFileName.length > 32 ? document.originalFileName.slice(0, 32) : document.originalFileName;
};

interface IDocumentsProps {
  document: IDocument;
  activeDocumentView: IDocument;
  onDocumentChange: (document: IDocument) => void;
}

const Documents = ({ document, activeDocumentView, onDocumentChange }: IDocumentsProps) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: messages, refetch } = useGetMessagesByChatId(document.chatId);
  const messagesWithDocuments = useMemo(() => {
    return messages?.chatMessages.filter((message) => !!message.chatDocument) || [];
  }, [messages]);

  useEffect(() => {
    const onUploadDocumentDone = (uploadedDocument: IDocument) => {
      if (uploadedDocument.id === document.id) {
        refetch();
      }
    };

    window.Docyt.vent.on('upload:document:done', onUploadDocumentDone);

    return () => {
      window.Docyt.vent.off('upload:document:done');
    };
  }, [document.id, refetch]);

  const handleUploadDone = useCallback(() => {
    setShowUploadModal(false);
    refetch();
  }, [refetch]);

  const handleUploadCancel = useCallback(() => {
    setShowUploadModal(false);
  }, []);

  const openUploadModal = useCallback(() => {
    setShowUploadModal(true);
  }, []);

  const viewDocument = useCallback(async (documentId: TID) => {
    if (activeDocumentView.id === documentId) return;
    setIsLoading(true);
    const documentToView = await getDocument(documentId);
    onDocumentChange(documentToView);
    setIsLoading(false);
  }, [onDocumentChange, activeDocumentView.id]);

  const viewMainDocument = useCallback(() => {
    onDocumentChange(document);
  }, [onDocumentChange, document]);

  return (
    <div className={ styles['footer-documents'] }>
      { isLoading && <Spinner /> }
      <div>
        <Tooltip.Hover
          content="Add Document"
        >
          <Button
            variant="link"
            onClick={ openUploadModal }
          >
            <PlusIcon />
          </Button>
        </Tooltip.Hover>
      </div>
      <div className={ styles.separator } />
      {activeDocumentView.id === document.id ? (
        <span className={ styles['main-document-name'] }>
          <Badge
            label={ document.docytId }
            variant="primary"
          />
        </span>
      ) : (
        <span className={ styles['main-document-name'] } role="button" tabIndex={ 0 } onClick={ viewMainDocument }>
          {document.docytId}
        </span>
      )}
      {messagesWithDocuments.length > 0 && <div className={ styles.separator } />}
      <div className={ styles['documents-list'] }>
        { messagesWithDocuments.map((message) => (
          <span
            key={ message.id }
            className={ styles['document-item'] }
            role="button"
            tabIndex={ 0 }
            onClick={ () => viewDocument(message.chatDocument!.id) }
          >
            { activeDocumentView.id === message.chatDocument?.id
              ? <Badge label={ getChatDocumentName(message.chatDocument!) } variant="primary" />
              : getChatDocumentName(message.chatDocument!)}
          </span>
        )) }
      </div>
      <UploadDocument
        document={ document }
        isOpen={ showUploadModal }
        onCancel={ handleUploadCancel }
        onDone={ handleUploadDone }
      />
    </div>
  );
};

export default React.memo(Documents);
