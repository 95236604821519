/* eslint-disable eqeqeq */
import React, { useState } from 'react';

import {
  businessSettingsPath,
  rcAllTransactionsPath,
  rcBankTransfersPath,
  rcCategorizedTransactionsPath, rcEquityPath,
  rcExpensesPath,
  rcLoanPaymentsPath,
  rcMonthEndDocmentsBankStatementsPath,
  rcMonthEndDocmentsOtherDocumentsPath,
  rcMonthEndReconciliationPath,
  rcPayrollPath,
  rcRevenuePath, rcSettingsPath,
  rcTransactionsForReviewPath,
  rcUncategorizedTransactionsPath,
  rcUnclearedDocumentsPathPath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';
import { LDFeatureValueForBusiness, LDFeatureForBusinessEnabled } from '@src/utils/config';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

import styles from '../../navigation_styles.module.scss';

interface IServiceMenuProps {
  businessId: TID,
  activeItem?: string,
  info: IBusinessServicesInfo,
  focusToMyClient: boolean,
  isGlobalNavigationEnabled: boolean
}

const ReconciliationItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient,
  isGlobalNavigationEnabled,
}: IServiceMenuProps) => {
  const [monthEndCloseEnabled, setMonthEndCloseEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.MONTH_END_CLOSE_FLAG,
      String(businessId),
    ),
  );

  if (monthEndCloseEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.MONTH_END_CLOSE_FLAG,
      String(businessId),
    ).then((res) => {
      setMonthEndCloseEnabled(res);
    });
  }

  const [transactionsWaitingForApprovalEnabled,
    setTransactionsWaitingForApprovalEnabled] = useState<string | boolean | undefined>(
      LDFeatureValueForBusiness(
        window.Docyt.Common.Constants.TRANSACTIONS_WAITING_FOR_APPROVAL,
        String(businessId),
      ),
    );

  if (transactionsWaitingForApprovalEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.TRANSACTIONS_WAITING_FOR_APPROVAL,
      String(businessId),
    ).then((res) => {
      setTransactionsWaitingForApprovalEnabled(res);
    });
  }

  let transactionsWaitingForApprovalItem = null;
  let transactionsForReviewItem = null;
  if (transactionsWaitingForApprovalEnabled) {
    transactionsWaitingForApprovalItem = (
      <ServiceItem
        serviceUrl={
          info.reconciliationCenterReady
            ? rcCategorizedTransactionsPath(businessId)
            : businessSettingsPath(businessId)
        }
        title="Categorized Transactions"
      />
    );
    transactionsForReviewItem = (
      <ServiceItem
        serviceUrl={
          info.reconciliationCenterReady
            ? rcTransactionsForReviewPath(businessId)
            : businessSettingsPath(businessId)
        }
        title="Transactions for Review"
      />
    );
  }

  const canVisibleRcService = info.isBusinessAdmin || info.canAccessReconciliationCenter;

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.RECONCILIATION }
      focusToMyClient={ focusToMyClient }
      isGlobalNavigationEnabled={ isGlobalNavigationEnabled }
      title={ window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.RECONCILIATION }
    >
      <li className={ styles['navigation-item-header-title'] }>
        <span className={ `m-l-18 m-b-8 ${styles['dropdown-item-title']}` }>
          { window.Docyt.Common.Constants.NEW_MODULE_HEADER_ITEMS.RECONCILIATION.toUpperCase() }
        </span>
      </li>
      {canVisibleRcService && (
        <ServiceSubMenu
          hasWiderSubmenuList
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.TASKS }
        >
          <ServiceItem
            hasBorderBottom
            serviceUrl={
              info.reconciliationCenterReady
                ? rcUncategorizedTransactionsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title={ (
              <div className={ `${styles['icon-with-text']}` }>
                <span className={ `${styles['sub-menu-text']}` }>Uncategorized Transactions</span>
                <span className={ `${styles['dropdown-item-title']} ${styles['transactions-count']}` }>{ info?.countUncategorizedTransactions }</span>
              </div>
            ) }
          />

          { transactionsForReviewItem }
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcUnclearedDocumentsPathPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Uncleared Documents"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? `${rcBankTransfersPath(businessId)}?matched_type=false&from=tasks`
                : businessSettingsPath(businessId)
            }
            title="Unmatched Transfers"
          />
        </ServiceSubMenu>
      )}
      {canVisibleRcService && (
        <ServiceSubMenu
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.CLOSING }
        >
          {monthEndCloseEnabled && (
            <ServiceItem
              serviceUrl={
                info.reconciliationCenterReady
                  ? rcMonthEndReconciliationPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Month-End Close"
            />
          )}

          <ServiceItem
            launchDarklyFeature={
              window.Docyt.Common.Constants.BANK_STATEMENTS_DOC_REQUEST_FLAG
            }
            serviceUrl={
              info.reconciliationCenterReady
                ? rcMonthEndDocmentsBankStatementsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Bank Statements"
          />
          <ServiceItem
            launchDarklyFeature={
              window.Docyt.Common.Constants.OTHER_STATEMENTS_DOC_REQUEST_FLAG
            }
            serviceUrl={
              info.reconciliationCenterReady
                ? rcMonthEndDocmentsOtherDocumentsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Other Account Documents"
          />
        </ServiceSubMenu>
      )}
      {canVisibleRcService && (
        <ServiceSubMenu
          hasBorderBottom
          title={ window.Docyt.Common.Constants.SERVICE_NAMES.TRANSACTIONS }
        >
          <ServiceItem
            hasBorderBottom
            serviceUrl={
              info.reconciliationCenterReady
                ? rcAllTransactionsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="All Transactions"
          />
          { transactionsWaitingForApprovalItem }

          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcExpensesPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Expenses"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcRevenuePath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Income"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? `${rcBankTransfersPath(businessId)}?from=transactions`
                : businessSettingsPath(businessId)
            }
            title="Bank Transfers"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcPayrollPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Payroll Charges"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcLoanPaymentsPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Loan Payments"
          />
          <ServiceItem
            serviceUrl={
              info.reconciliationCenterReady
                ? rcEquityPath(businessId)
                : businessSettingsPath(businessId)
            }
            title="Equity / Liability / Asset"
          />
        </ServiceSubMenu>
      )}
      <ServiceItem
        serviceUrl={
        info.reconciliationCenterReady
          ? rcSettingsPath(businessId)
          : businessSettingsPath(businessId)
        }
        title={ window.Docyt.Common.Constants.SERVICE_NAMES.DOCYT_AI_SETTINGS }
      />
    </ModuleHeaderMenu>
  );
};

export default ReconciliationItem;
