import React from 'react';

import { BusinessChartOfAccounts } from '@src/requests/system_categories';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import { ITSDCollection } from '../hooks';
import Table from './table';

interface IAllTransactionsListProps {
  collection: ITSDCollection;
  onRowClick: (rowData: any) => void;
  type?: string;
  payrollBCOAId: number | null;
  systemCategories: BusinessChartOfAccounts | null;
}

const AllTransactionsList = ({
  collection,
  onRowClick,
  type,
  payrollBCOAId,
  systemCategories,
}: IAllTransactionsListProps) => (
  <Section.Provider section={ collection.section }>
    <TableSection>
      <Table
        collection={ collection }
        payrollBCOAId={ payrollBCOAId }
        systemCategories={ systemCategories }
        type={ type }
        onRowClick={ onRowClick }
      />
    </TableSection>
  </Section.Provider>
);

export default React.memo(AllTransactionsList);
