import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMultiEntityReportsContext } from '@src/hooks/contexts/multi_entity_reports_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateMultiEntityBusinessReport, useGetReportServicesByBusinessId } from '@src/hooks/queries/multi_entity_reports';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import { IGetReportServicesByBusinessIdResponse, IGetTemplatesResponse } from '@src/requests/multi_entity_reports';
import { IBusiness } from '@src/types/businesses';

import { useSelectedPortfolio } from '@src/components/portfolio_dashboard/hooks';
import MutationStatus from '@src/components/utils/mutation_status';
import QueryStatus from '@src/components/utils/query_status';

import Wizard from './create_report_wizard';
import { ICreateNewReportFormValues } from '../../forms/schema';

interface IPortfolioCreateReportModalProps extends IUseModalProps {
  action: string,
  businesses: IBusiness[],
  templates: IGetTemplatesResponse[],
}

const PortfolioCreateReport = ({
  action,
  businesses,
  templates,
  isOpen,
  onCancel,
  onDone,
}: IPortfolioCreateReportModalProps) => {
  const multiEntityReportsContext = useMultiEntityReportsContext();
  const createMultiEntityReport = useCreateMultiEntityBusinessReport();
  const { selectedPortfolioId } = useSelectedPortfolio();
  const { mutate } = createMultiEntityReport;

  const [formValue, setFormValue] = useState<ICreateNewReportFormValues | null>(null);
  const [name, setName] = useState<string>('');
  const [templateId, setTemplateId] = useState<string>('');
  const [step, setStep] = useState<'reportType' | 'business'>('reportType');

  const reportServicesQuery = useGetReportServicesByBusinessId({ 'business_ids[]': formValue?.reportServiceIds as number[] });
  const reportIds = useMemo(() => reportServicesQuery.data || [], [reportServicesQuery.data]);
  const reportServiceIds = useMemo(
    () => reportIds.map((val: IGetReportServicesByBusinessIdResponse) => val.id),
    [reportIds],
  );

  const isPortfolioFeatureEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION, true);

  useEffect(() => {
    const param = isPortfolioFeatureEnabled ? {
      portfolioId: String(selectedPortfolioId),
    } : {};
    if (reportIds.length !== 0 && action === 'create') {
      mutate(
        {
          multiBusinessReportServiceId: multiEntityReportsContext.id,
          name:                         formValue?.name as string,
          reportServiceIds,
          templateId:                   formValue?.templateId as string,
          ...param,
        },
        {
          onSuccess: () => {
            setName('');
            setTemplateId('');
            setStep('reportType');
            setFormValue(null);
            onDone();
          },
        },
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportIds,
    multiEntityReportsContext,
    reportServiceIds,
    action,
    mutate,
    setName,
    setTemplateId,
    onDone,
    setStep,
    setFormValue,
  ]);

  const handleDone = useCallback((data: ICreateNewReportFormValues) => {
    setFormValue(data);
  }, [setFormValue]);

  return (
    <>
      <QueryStatus query={ reportServicesQuery } />
      <MutationStatus mutation={ createMultiEntityReport } />
      <Wizard
        businesses={ businesses }
        name={ name }
        setName={ setName }
        setStep={ setStep }
        setTemplateId={ setTemplateId }
        show={ isOpen }
        step={ step }
        templateId={ templateId }
        templates={ templates }
        onCancel={ onCancel }
        onDone={ handleDone }
      />
    </>
  );
};

const useCreateNewReportModal = makeUseModal(PortfolioCreateReport);

export {
  IPortfolioCreateReportModalProps,
  useCreateNewReportModal,
  PortfolioCreateReport as default,
};
