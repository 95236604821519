import React, { useMemo } from 'react';

import { Section as SectionConstants } from '@src/constants/sections';
import { useSorting } from '@src/hooks/url_params';
import { TSelectAddressVendorSortColumn } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';

import Section from '@src/components/utils_v2/section';

import Table from './options/address_option';

interface IVendorAddressBookProps
{
  businessId: TID,
  businessVendor: IBusinessVendor,
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>,
}

const VendorAddressBook = ({
  businessId,
  businessVendor,
  setVendorData,
}: IVendorAddressBookProps) => {
  const section = useMemo(() => ({
    section: SectionConstants.SelectMergeableVendor,
  }), []);

  const sorting = useSorting<TSelectAddressVendorSortColumn>({
    section:        section.section,
    defaultSorting: {
      orderColumn:    'name',
      orderDirection: 'asc',
    },
  });

  return (
    <Section.Provider section={ section }>
      <Table
        businessId={ businessId }
        setVendorData={ setVendorData }
        sorting={ sorting }
        vendor={ businessVendor }
      />
    </Section.Provider>
  );
};

export default React.memo(VendorAddressBook);
