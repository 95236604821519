import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useBulkCreateCheck } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { downloadPrintableChecks } from '@src/requests/accounts_payable/service_payment_transactions';
import { getNeedReauthentication } from '@src/requests/users';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { TID } from '@src/types/common';
import { formatDate } from '@src/utils/date_helpers';

import { bulkPaymentModalTitle } from '@src/components/accounts_payable/review_payment/utils/review_payment_utils';
import { useVerifyIdentityModal } from '@src/components/accounts_payable/verifiy_identity_modal';
import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';
import Modal from '@src/components/ui/modal';

import BulkActionResult from './bulk_action_result';
import { groupInvoicesProp, splitInvoices } from '../utils/regroup_invoices_helper';

type TOpenValue = {
  type: string,
  serviceDocumentIds: Array<Array<number>>
  vendorAddressIds: Array<Array<number | undefined>>
  limitCountToPay: number,
}

interface IPayByDocytCheckModalProps extends IUseModalProps<TOpenValue> {
  businessId: TID;
  apServiceId: TID;
  serviceDocuments: IAccountsPayableServiceDocument[];
  groupedServiceDocuments: groupInvoicesProp;
}

const payByDocytCheckResult = (status: IBulkActionStatus<any, any>) => (
  <BulkActionResult status={ status } />
);

const PayByDocytCheckModal = ({
  businessId,
  apServiceId,
  isOpen,
  openValue,
  onDone,
  onCancel,
  serviceDocuments,
  groupedServiceDocuments,
}: IPayByDocytCheckModalProps) => {
  const { type, serviceDocumentIds, vendorAddressIds, limitCountToPay } = openValue || {};
  const [servicePaymentTransactionIds, setServicePaymentTransactionIds] =
    useState<Array<number>>([]);

  const createCheck = useBulkCreateCheck();
  const bulkAction = useBulkActionModal({
    mutation: createCheck,
  });

  const { runMutation } = bulkAction;

  const areVendorsDifferent = useMemo(() => {
    const vendorIds = serviceDocuments.map((doc) => doc.vendor?.id);
    return new Set(vendorIds).size > 1;
  }, [serviceDocuments]);

  const areAccountsDifferent = Object.keys(groupedServiceDocuments).length > 1;

  const handleDone = useCallback(() => {
    document.querySelector('.bulk-pay-by-docyt-cyeck-modal')?.classList.remove('hide');
    onDone();
  }, [onDone]);

  const handleDownloadPrintableChecks = useCallback((ids: Array<number>) => {
    const params = {
      ids,
      serviceId: apServiceId,
    };

    downloadPrintableChecks(params).then(() => {
      window.Docyt.vent.trigger('data_export:requested');
    }).finally(() => { handleDone(); });
  }, [apServiceId, handleDone]);

  const handlePayByDocytCheck = useCallback(() => {
    if (!serviceDocumentIds || !type) return;
    document.querySelector('.bulk-pay-by-docyt-cyeck-modal')?.classList.add('hide');

    const isSelfPrintCheck = type === window.Docyt.Common.Constants.BULK_PAY_BY_SELF_PRINT_CHECK;
    const params: any = [];
    serviceDocumentIds.forEach((ids: Array<number>, index: number) => {
      if (limitCountToPay && ids.length > limitCountToPay) {
        const chunkedIds = splitInvoices(ids, limitCountToPay);
        chunkedIds.forEach((chunkedId: Array<number>) => {
          params.push({
            addressId:                  vendorAddressIds?.[index][0],
            attachmentPages:            [],
            businessId,
            checkMemo:                  'Inv: Several',
            isPrePrintedCheck:          false,
            paidAt:                     formatDate(new Date()),
            selfPrintCheck:             isSelfPrintCheck,
            attachmentServiceDocuments: chunkedId,
          });
        });
      } else {
        params.push({
          addressId:                  vendorAddressIds?.[index][0],
          attachmentPages:            [],
          businessId,
          checkMemo:                  'Inv: Several',
          isPrePrintedCheck:          false,
          paidAt:                     formatDate(new Date()),
          selfPrintCheck:             isSelfPrintCheck,
          attachmentServiceDocuments: ids,
        });
      }
    });

    runMutation(params).then((result) => {
      if (result.responses.length === 0 || !isSelfPrintCheck) return;

      result.responses.forEach((res: any) => {
        setServicePaymentTransactionIds((prev) => {
          return prev.concat(res.service_documents[0].service_payment_transaction_id);
        });
      });
    });
  }, [serviceDocumentIds, type, runMutation, limitCountToPay, vendorAddressIds, businessId]);

  const verifyIdentity = useVerifyIdentityModal({
    onDone: () => handlePayByDocytCheck(),
  });
  const { open: openVerifyIdentityModal } = verifyIdentity;

  const handleProceed = useCallback(() => {
    getNeedReauthentication()
      .then((res) => {
        if (res.needReauthentication) {
          openVerifyIdentityModal();
        } else {
          handlePayByDocytCheck();
        }
      });
  }, [handlePayByDocytCheck, openVerifyIdentityModal]);

  const handleBulkDone = useCallback(() => {
    if (type === window.Docyt.Common.Constants.BULK_PAY_BY_SELF_PRINT_CHECK) {
      handleDownloadPrintableChecks(servicePaymentTransactionIds);
    } else {
      handleDone();
    }
  }, [type, handleDownloadPrintableChecks, servicePaymentTransactionIds, handleDone]);

  useEffect(() => {
    if (!areVendorsDifferent && !areAccountsDifferent && isOpen) {
      handleProceed();
    }
  }, [areVendorsDifferent, areAccountsDifferent, isOpen, handleProceed]);

  if (type === undefined) return null;

  return (
    <>
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="Payments"
        needTitleSuffix={ false }
        result={ payByDocytCheckResult }
        title="Processing Payment.."
        onDone={ handleBulkDone }
      />
      <verifyIdentity.Component
        { ...verifyIdentity.props }
        email={ window.Docyt.currentAdvisor.get('email') }
      />
      {(areVendorsDifferent || areAccountsDifferent) && (
        <Modal.Standard
          className="bulk-pay-by-docyt-cyeck-modal"
          proceedTitle="Continue"
          show={ isOpen }
          title={ bulkPaymentModalTitle(type) }
          onCancel={ onCancel }
          onProceed={ handleProceed }
        >
          {() => (
            <div className="text-center">
              The invoices you have selected have different vendors or account numbers.
              { ' ' }
              { (type === window.Docyt.Common.Constants.BULK_PAY_BY_SELF_PRINT_CHECK)
                ? 'Self-print check will be generated for each of them.'
                : 'A Docyt Check will be generated for each of them.' }
              { ' ' }
              <br />
              Are you sure you wish to continue?
            </div>
          )}
        </Modal.Standard>
      )}
    </>
  );
};

const MemoizedPayByDocytCheckModal = React.memo(PayByDocytCheckModal);
const usePayByDocytCheckModal = makeUseModal<
 typeof MemoizedPayByDocytCheckModal, TOpenValue>(MemoizedPayByDocytCheckModal);

export {
  IPayByDocytCheckModalProps,
  usePayByDocytCheckModal,
  PayByDocytCheckModal as default,
};
