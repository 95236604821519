import React, { useCallback } from 'react';

import { ResizableBox, ResizeCallbackData, ResizeHandle } from 'react-resizable';

import FitPageWidthIcon from '@src/components/utils/icomoon/fit_page_width';

import {
  DOCUMENT_WIDTH_PERCENTAGE_HALF,
  MAX_DOCUMENT_WIDTH_PERCENTAGE,
  MIN_DOCUMENT_WIDTH_PERCENTAGE,
} from './constant';
import { useDocumentViewWidth } from './hooks';

import 'react-resizable/css/styles.css';

import styles from './styles.module.scss';

interface IViewProps {
  children: React.ReactNode;
}

const View = ({ children }: IViewProps): JSX.Element => {
  const {
    documentWidthPercentage,
    setDocumentWidthPercentage,
    parentElementSize,
  } = useDocumentViewWidth();

  const handleResize = useCallback((event: React.SyntheticEvent, data: ResizeCallbackData) => {
    const newDocumentWidth = (data.size.width / parentElementSize.width) * 100;
    setDocumentWidthPercentage(newDocumentWidth);
  }, [parentElementSize.width, setDocumentWidthPercentage]);

  // Toggle the document width
  const handleResizeIconClick = useCallback(() => {
    setDocumentWidthPercentage(
      (prev) => (
        prev === DOCUMENT_WIDTH_PERCENTAGE_HALF ? MAX_DOCUMENT_WIDTH_PERCENTAGE : DOCUMENT_WIDTH_PERCENTAGE_HALF
      ),
    );
  }, [setDocumentWidthPercentage]);

  const renderHandle = useCallback((resizeHandle: ResizeHandle, ref: React.RefObject<any>) => {
    return (
      <div ref={ ref } className={ styles['resize-handle-button'] }>
        <FitPageWidthIcon
          className={ styles['resize-handle-icon'] }
          fontSize={ 18 }
          onDoubleClick={ handleResizeIconClick }
        />
      </div>
    );
  }, [handleResizeIconClick]);

  const width = parentElementSize.width * (documentWidthPercentage / 100);

  // Minimum width is MIN_DOCUMENT_WIDTH_PERCENTAGE of the parent width
  const minWidth = parentElementSize.width * (MIN_DOCUMENT_WIDTH_PERCENTAGE / 100);

  // Maximum width is MAX_DOCUMENT_WIDTH_PERCENTAGE of the parent width
  const maxWidth = parentElementSize.width * (MAX_DOCUMENT_WIDTH_PERCENTAGE / 100);

  return (
    <ResizableBox
      axis="x"
      className={ styles['document-view'] }
      handle={ renderHandle }
      height={ parentElementSize.height }
      maxConstraints={ [maxWidth, parentElementSize.height] }
      minConstraints={ [minWidth, parentElementSize.height] }
      width={ width }
      onResize={ handleResize }
    >
      {children}
    </ResizableBox>
  );
};

export default React.memo(View);
