import React, { useMemo, useCallback, useState } from 'react';

import { useAtomValue } from 'jotai';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useReportServiceContext } from '@src/hooks/contexts/reports_service_context';
import {
  useCreateReport,
  useGetLightReportsList,
  useGetReportTemplates,
} from '@src/hooks/queries/report_service/custom_reports';
import { TReportCategory } from '@src/types/report_service/report';

import { ExportReportBanner } from '@src/components/common_v2/banner';
import MutationStatus from '@src/components/utils/mutation_status';
import QueryStatus from '@src/components/utils/query_status';

import BlankPage from './blank_page';
import Header from './header';
import ReportsList from './reports_list';
import { reportUpdatingState } from '../../atoms';
import { ReportStateBanner } from '../banner';
import { ICreateNewReportFormValues } from '../forms/schema';
import { useHideReportsForBusiness } from '../hooks';
import { useCreateNewReportModal } from '../modals/create_new_report';

interface IReportListPageProps {
  category: TReportCategory,
}

const ReportsListPage = ({
  category,
}: IReportListPageProps): JSX.Element => {
  const [showExportReportBanner, setShowExportReportBanner] = useState<boolean>(false);
  const state = useAtomValue(reportUpdatingState);
  const business = useBusinessContext();
  const reportService = useReportServiceContext();
  const reportsQuery = useGetLightReportsList(reportService.id);
  const allReports = useMemo(() => reportsQuery.data || [], [reportsQuery]);
  const reportTemplatesQuery = useGetReportTemplates(business.standardCategoryId);
  const templates = useMemo(() => reportTemplatesQuery.data || [], [reportTemplatesQuery]);

  // Desc: This function retrieves a list of templates that should be hidden for the given business ID
  const hideReports = useHideReportsForBusiness(business.id);

  const reports = useMemo(() => (
    allReports.filter((report) => (
      !hideReports.includes(report.templateId)
      && !window.Docyt.Common.Constants.EXCLUDED_REPORTS.includes(report.templateId)
      && templates.find((template) => (
        template.id === report.templateId && template.category === category
      ))
    ))
  ), [allReports, category, templates, hideReports]);

  const canCreateNewReport = useMemo(() => {
    if (category === 'department') {
      return true;
    }
    const temp = templates.filter(
      (template) => !template.draft
        && !hideReports.includes(template.id)
        && template.category === category
        && !window.Docyt.Common.Constants.EXCLUDED_REPORTS.includes(template.id),
    );
    return reports.length !== temp.length;
  }, [category, reports.length, templates, hideReports]);

  const createReport = useCreateReport();
  const { mutate } = createReport;
  const handleSubmit = useCallback((values: ICreateNewReportFormValues) => {
    mutate({
      reportServiceId: reportService.id,
      name:            values.name,
      templateId:      values.templateId,
    });
  }, [mutate, reportService.id]);

  const modal = useCreateNewReportModal({ onDone: handleSubmit });

  return (
    <>
      <Header
        businessId={ business.id }
        canCreateNewReport={ canCreateNewReport }
        category={ category }
        reports={ reports }
        onCreateExportData={ setShowExportReportBanner }
        onOpenCreateNewReportModal={ modal.open }
      />
      { showExportReportBanner && (<ExportReportBanner className="m-t-5" />) }
      { state && (<ReportStateBanner />) }
      { reports.length > 0
        ? (
          <ReportsList
            category={ category }
            reports={ reports }
            templates={ templates }
          />
        ) : (
          <BlankPage
            onOpenCreateNewReportModal={ modal.open }
          />
        )}
      <QueryStatus query={ reportsQuery } />
      <MutationStatus mutation={ createReport } />
      <modal.Component
        { ...modal.props }
        reports={ allReports }
        templates={ templates.filter((template) => template.category === category) }
      />
    </>
  );
};

export default ReportsListPage;
