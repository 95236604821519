import React from 'react';

import Root from '@src/components/root';

import MainView from './main_view';
import { ScrollProvider } from './provider/ai_chat_bot_scroll_provider';
import { SendbirdChatProvider } from './provider/sendbird_chat_provider';

const AiChatBotPage = (): JSX.Element => {
  return (
    <Root>
      <ScrollProvider>
        <SendbirdChatProvider>
          <MainView />
        </SendbirdChatProvider>
      </ScrollProvider>
    </Root>
  );
};

export default AiChatBotPage;
