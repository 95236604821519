import { atom } from 'jotai';

import { TDateFilter } from '@src/types/filter';
import { TReportDataPeriodType } from '@src/types/report_service/report';

const reportUpdatingState = atom<string | null>(null);

const reportDataPeriodType = atom<TReportDataPeriodType>('monthly');

const pickMultiMonths = atom<boolean>(false);

const pickMultiDaily = atom<boolean>(false);

const reportFilePreparing = atom<boolean>(false);

const reportFilterDateRange = atom<TDateFilter | undefined>(undefined);

export const reportsExportValueType = atom<string[]>([]);

const reportRefreshState = atom<boolean>(false);

const reportRefreshJobId = atom<string | null>(null);

export const reportsAccountingMethod = atom<string>('ACCRUAL_ACCOUNTING_METHOD');

export {
  reportUpdatingState,
  reportDataPeriodType,
  pickMultiMonths,
  pickMultiDaily,
  reportFilePreparing,
  reportFilterDateRange,
  reportRefreshState,
  reportRefreshJobId,
};
