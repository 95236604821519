import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  completeOnboarding,
  createTeamMember,
  createUserAccount,
  getDefaultAccountingFirm,
  getBillingPaymentMethodStatus,
  ICreateTeamMemberParams,
  ICreateUserAccountParams,
  resendEmail,
  addBillingMethod,
  setBusinessInfo,
  ISetBusinessInfoParams,
  importClientsFromLedger,
  ICreateBusinessPartnerParams,
  createBusinessPartner,
} from '@src/requests/signup';
import { IBillingPaymentMethodStatus } from '@src/types/billing_payment_methods';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

const useResendEmail = () => {
  return useMutation<void, Error, string>(resendEmail);
};

const useCreateUserAccount = () => {
  return useMutation<void, Error, ICreateUserAccountParams>(createUserAccount);
};

const useGetDefaultAccountingFirm = () => {
  return useQuery<IBusiness, Error>([QueryKey.defaultAccountingFirm], () =>
    getDefaultAccountingFirm()
  );
};

const useGetBillingPaymentMethodStatus = (params: { businessId: TID | null }) => {
  return useQuery<IBillingPaymentMethodStatus, Error>([QueryKey.billingPaymentMethodStatus], () =>
    getBillingPaymentMethodStatus(params)
  );
};

const useCreateTeamMember = () => {
  return useMutation<void, Error, ICreateTeamMemberParams>(createTeamMember);
};

const useCreateBusinessPartner = () => {
  return useMutation<void, Error, ICreateBusinessPartnerParams>(createBusinessPartner);
};

const useCompleteOnboarding = () => {
  return useMutation<void, Error, {}>(completeOnboarding);
};

const useAddBillingMethod = (params: {
  businessId: TID | null;
  returnUrl: string | null;
  isCall: boolean;
}) => {
  return useQuery<{ url: string }, Error>(
    [QueryKey.addBillingMethod, params],
    () => addBillingMethod(params),
    {
      enabled: !!params.isCall,
    }
  );
};

const useSetBusinessInfo = () => {
  return useMutation<void, Error, ISetBusinessInfoParams>(setBusinessInfo);
};

const useImportClientsFromLedger = (businessId: TID) => {
  return useQuery<any, Error>({
    queryKey: [QueryKey.importClientsFromQbo, businessId],
    queryFn:  () => importClientsFromLedger(businessId),
    enabled:  !!businessId,
  });
};

export {
  useResendEmail,
  useCreateUserAccount,
  useGetDefaultAccountingFirm,
  useCreateTeamMember,
  useCompleteOnboarding,
  useAddBillingMethod,
  useGetBillingPaymentMethodStatus,
  useSetBusinessInfo,
  useImportClientsFromLedger,
  useCreateBusinessPartner,
};
