import React from 'react';

import classNames from 'classnames';

import HCell, { TTableHeaderCellProps } from './hcell';

import styles from './styles.module.scss';

const TableButtonHCell = <TSortColumn extends string = never>({
  align = 'left',
  ...props
}: TTableHeaderCellProps<TSortColumn>) => {
  const classes = classNames(
    styles['table-button-header-cell'],
    {
      [styles['table-cell-align-right']]: align === 'right',
    },
  );

  return (
    <HCell<TSortColumn>
      className={ classes }
      { ...props }
    />
  );
};

export default TableButtonHCell;
