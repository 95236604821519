import React from 'react';

import Icomoon, { IIcomoonIconProps } from '@src/components/utils/icomoon/icomoon';

const ErrorReportIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="report"
    />
  );
};

export default ErrorReportIcon;
