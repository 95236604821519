import React, { useCallback, useState } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import Button from '@src/components/ui_v2/buttons/button';

import Form from './edit_linked_businesses_form';
import { IBusinessesInput } from './schema';

import styles from './styles.module.scss';

interface IEditLinkedBusinessesModalProps extends Omit<IUseModalProps, 'onDone'> {
  businessId: TID,
  linkedBusinessIds: TID[],
  onDone: (businesses: IBusiness[]) => void,
  isAdding?: boolean,
}

const EditLinkedBusinessesModal = ({
  businessId,
  linkedBusinessIds,
  isOpen,
  onCancel,
  onDone,
  isAdding,
}: IEditLinkedBusinessesModalProps): JSX.Element => {
  const [isUnlinkedBusinesses, setIsUnlinkedBusinesses] = useState(false);
  const handleSubmit = useCallback((values: IBusinessesInput) => {
    onDone(values.businesses);
    onCancel();
  }, [onCancel, onDone]);

  return (
    <Modal
      className={ styles['edit-linked-businesses-modal'] }
      show={ isOpen }
      title={ (
        <p className={ styles['edit-linked-businesses-modal-title'] }>
          { isAdding ? 'Select Businesses to Link' : 'Edit Linked Businesses' }
        </p>
     ) }
    >
      <Modal.Body>
        <Form
          businessId={ businessId }
          formId="edit_linked_businesses_form"
          linkedBusinessIds={ linkedBusinessIds }
          setIsUnlinkedBusinesses={ setIsUnlinkedBusinesses }
          onSubmit={ handleSubmit }
        />
      </Modal.Body>
      <Modal.Footer className="modal-footer-v2">
        <Button
          className="btn-outline"
          variant="link"
          onClick={ onCancel }
        >
          Cancel
        </Button>
        <Button
          disabled={ !isUnlinkedBusinesses }
          form="edit_linked_businesses_form"
          type="submit"
          variant="primary"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useEditLinkedBusinessesModal = makeUseModal(EditLinkedBusinessesModal);

export {
  IEditLinkedBusinessesModalProps,
  useEditLinkedBusinessesModal,
  EditLinkedBusinessesModal as default,
};
