import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateRevenueReportTypeParams,
  ICreateRevenueReportTypeResponse,
  IGetRevenueReportTypesParams,
  IGetRevenueReportTypesResponse,
  IGetRevenueCategoriesParams,
  IGetRevenueCategoriesResponse,
  createRevenueReportType,
  getRevenueReportTypes,
  getRevenueCategories,
  getRevenueReportTypeDetail,
  IGetRevenueReportTypeDetailResponse,
  ICreatePaymentProcessorParams,
  createPaymentProcessors,
  ICreatePaymentProcessorResponse,
  manageRevenueReportTypeMapping,
  IManageRevenueReportTypeMappingResponse,
  IManageRevenueReportTypeMappingParams,
} from '@src/requests/revenue_report_types';

const useGetRevenueReportTypes = (params: IGetRevenueReportTypesParams) => {
  return useQuery<IGetRevenueReportTypesResponse, Error>(
    [QueryKey.revenueReportTypes, params],
    () => getRevenueReportTypes(params),
  );
};

const useGetRevenueCategories = (params: IGetRevenueCategoriesParams) => {
  return useQuery<IGetRevenueCategoriesResponse, Error>(
    [QueryKey.revenueReportTypes, params],
    () => getRevenueCategories(params),
  );
};

const useCreateRevenueReportType = () => {
  return useMutation<
    ICreateRevenueReportTypeResponse,
    Error,
    ICreateRevenueReportTypeParams
  >(
    createRevenueReportType,
  );
};

const useGetRevenueReportTypeDetail = (id: number) => {
  return useQuery<IGetRevenueReportTypeDetailResponse, Error>(
    [QueryKey.revenueReportTypes, id],
    () => getRevenueReportTypeDetail(id),
  );
};

const useCreatePaymentProcessors = () => {
  return useMutation<ICreatePaymentProcessorResponse, Error, ICreatePaymentProcessorParams>(
    createPaymentProcessors,
  );
};

const useManageRevenueReportTypeMapping = () => {
  return useMutation<
    IManageRevenueReportTypeMappingResponse,
    Error,
    IManageRevenueReportTypeMappingParams
  >(
    manageRevenueReportTypeMapping,
  );
};

export {
  useGetRevenueReportTypes,
  useCreateRevenueReportType,
  useGetRevenueCategories,
  useGetRevenueReportTypeDetail,
  useCreatePaymentProcessors,
  useManageRevenueReportTypeMapping,
};
