import { TMongoID } from '@src/types/common';
import { IWidget } from '@src/types/dashboards';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IGetWidgetsParams {
  dashboardId: TMongoID,
  from?: string,
  to?: string,
}

interface IGetWidgetsResponse {
  collection: IWidget[],
}

const getWidgets = (
  params: IGetWidgetsParams,
): Promise<IGetWidgetsResponse> => {
  return apiGet(
    '/dashboards/api/v1/widgets.json',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.widgets as IWidget[],
    };
  });
};

export {
  IGetWidgetsParams,
  IGetWidgetsResponse,
  getWidgets,
};
