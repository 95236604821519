import { object, string, number } from 'yup';

import { TID } from '@src/types/common';

const confirmValidation = object({
  firstName:         string().required('Client first name is required.'),
  lastName:          string().required('Client last name is required.'),
  email:             string().email().required('Email must be a valid email.'),
  welcomeMessage:    string().required('Welcome email message is required.'),
  managementGroupId: number().required('Management Group is required.'),
});

interface IAddInviteClientValues {
  firstName: string;
  lastName: string;
  email: string;
  welcomeMessage: string;
  managementGroupId: TID;
}

export {
  IAddInviteClientValues,
  confirmValidation,
};
