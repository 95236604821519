import { TID } from '@src/types/common';
import { AuditScan, AuditScanDetails, IAuditTransactionData } from '@src/types/operational_dashboard/audit_scan';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from '../helpers';

interface IGetLatestAuditScansParams {
  businessId?: TID;
}

interface IGetLatestAuditScansResponse {
  auditScans: AuditScan[];
}

interface IRunAuditScanParams {
  businessId: TID;
}

interface ICloseAuditScanParams {
  id: TID;
  closeDate: string;
  notes: string;
  businessId: TID;
}

interface IGetAuditScanNotesParams {
  businessId: TID;
}

interface IGetAuditScanNotesResponseInfinite {
  meta: {
    page: number;
    totalCount: number;
  };
  collection: AuditScanDetails[];
}

interface IGetAuditTransactionsParams {
  businessId?: TID;
  anomaly?: boolean;
  page?: number;
  perPage?: number;
}

interface IGetAuditTransactionsResponse {
  meta: {
    page: number;
    totalCount: number;
  };
  collection: IAuditTransactionData[];
}

const getLatestAuditScans = (params: IGetLatestAuditScansParams): Promise<AuditScan> => {
  return apiGet('/api/v1/audit_scans/latest', { business_id: params.businessId })
    .then((data) => {
      const response = <IGetLatestAuditScansResponse>camelizeKeys(data);
      return response.auditScans[0] as AuditScan;
    });
};

const getAuditScanNotes = (params: IGetAuditScanNotesParams): Promise<IGetAuditScanNotesResponseInfinite> => {
  return apiGet('/api/v1/audit_scans', { business_id: params.businessId })
    .then((data) => {
      const response = <any>camelizeKeys(data);
      return {
        meta:       response.meta,
        collection: response.auditScans,
      } as IGetAuditScanNotesResponseInfinite;
    });
};

const runAuditScan = (params: IRunAuditScanParams):Promise<{}> => {
  return apiPost(
    '/api/v1/audit_scans',
    underscoreKeys({ business_id: params.businessId }),
  ).then((data) => {
    const response = camelizeKeys(data) as AuditScan;
    return response;
  });
};

const closeAuditScan = (params: ICloseAuditScanParams): Promise<void> => {
  return apiPut(`/api/v1/audit_scans/${params.id}/close`, {
    close_date:  params.closeDate,
    note:        params.notes,
    business_id: params.businessId,
  }).then(() => {
  })
    .catch((error) => {
      throw error;
    });
};

const getAuditTransactions = (
  params: IGetAuditTransactionsParams,
): Promise<IGetAuditTransactionsResponse> => {
  return apiGet(
    '/api/v1/audit_transactions',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      ...cdata,
      collection: cdata.auditTransactions,
    } as IGetAuditTransactionsResponse;
  });
};

export {
  getLatestAuditScans,
  getAuditScanNotes,
  runAuditScan,
  IGetAuditScanNotesParams,
  IGetAuditScanNotesResponseInfinite,
  IGetLatestAuditScansParams,
  IGetLatestAuditScansResponse,
  IRunAuditScanParams,
  closeAuditScan,
  getAuditTransactions,
  IGetAuditTransactionsParams,
  IGetAuditTransactionsResponse,
};
