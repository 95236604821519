import React, { useCallback } from 'react';

import { useSetAtom } from 'jotai';

import { useCreateDataExport } from '@src/hooks/queries/data_exports';
import { TID } from '@src/types/common';
import { IDataExportData } from '@src/types/data_exports';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { DownloadDocumentIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { isExportCSVCreatedFamily } from './atoms';

interface IExportCSVActionProps {
  /**
   * Unique key for export CSV state. It should match the key passed to
   * the pairing `ExportCSVBanner`.
   */
  atomKey: string,
  businessId: TID,
  columnMappings: IDataExportData['columnMappings'],
  exportName: IDataExportData['name'],
  exportParams: IDataExportData['exportParams'],
  icon?: JSX.Element,
  /** Action name */
  name?: string,
}

const ExportCSV = ({
  atomKey,
  businessId,
  columnMappings,
  exportName,
  exportParams,
  icon,
  name = 'Export CSV',
}: IExportCSVActionProps) => {
  const setIsCreated = useSetAtom(isExportCSVCreatedFamily(atomKey));
  const createDataExport = useCreateDataExport();

  const { mutate } = createDataExport;
  const handleClick = useCallback(() => {
    mutate({
      businessIds: [businessId],
      dataExport:  {
        name:       exportName,
        exportParams,
        exportType: 'export_transactions_csv',
        columnMappings,
      },
    }, {
      onSuccess: () => setIsCreated(true),
    });
  }, [exportParams, columnMappings, exportName, businessId, mutate, setIsCreated]);

  return (
    <>
      <MutationStatus mutation={ createDataExport } />
      <ActionsDropdown.Action
        icon={ icon || <DownloadDocumentIcon /> }
        name={ name }
        onClick={ handleClick }
      />
    </>
  );
};

export default React.memo(ExportCSV);
