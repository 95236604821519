module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, ref, tab;
    
      __out.push('<div id="transaction-list-region" class="transaction-list-region" style="');
    
      __out.push(__sanitize(this.type === 'categorized_transactions' ? 'padding-top: 0px !important;' : ''));
    
      __out.push('">\n  <div>\n    ');
    
      if (this.type !== 'categorized_transactions') {
        __out.push('\n      <div class="clearfix">\n        <h2 class="font-bold font-26 pull-left">');
        __out.push(__sanitize(this.title));
        __out.push('</h2>\n      </div>\n      ');
        if (this.tabArray.length > 0) {
          __out.push('\n      <div class="documents-cat-header">\n        <ul class="nav sub-header-menu chargeback-sub-menu">\n          ');
          ref = this.tabArray;
          for (i = 0, len = ref.length; i < len; i++) {
            tab = ref[i];
            __out.push('\n          <li class="m-r-25 ');
            __out.push(__sanitize(this.currentTab === tab.id ? 'active' : ''));
            __out.push('">\n            <a class="outstanding-link" href="');
            __out.push(__sanitize(tab.url));
            __out.push('">\n              ');
            __out.push(__sanitize(tab.name));
            __out.push('\n            </a>\n          </li>\n          ');
          }
          __out.push('\n        </ul>\n      </div>\n      ');
        }
        __out.push('\n      <hr/>\n    ');
      }
    
      __out.push('\n    <div class="display-flex align-items-center justify-content-between">\n      <div class="display-flex align-items-center">\n        <div class="bulk-actions-dropdown-container"></div>\n        <span class="m-l-16 font-16 inline-block">\n          ');
    
      __out.push(__sanitize(this.description));
    
      __out.push('\n        </span>\n      </div>\n      <div class="display-flex align-items-center">\n        <div class="only-flagged checkbox checkbox-primary inline-block m-l-15 m-r-15 m-b-0 m-t-0">\n          <input type="checkbox" class="pointer check-only-flagged" ');
    
      __out.push(__sanitize(this.onlyFlagged ? 'checked' : ''));
    
      __out.push('>\n          <label>Show only flagged transactions</label>\n        </div>\n        <div class="filter-link inline-block m-r-20 p-b-10">\n          <a class="pointer reset-transactions-filter disabled">Reset Filters<span class="filtered-count-panel hidden"></span></a>\n        </div>\n        <div class="counter-container"></div>\n      </div>\n    </div>\n    <div class="table-responsive transactions-table-wrapper p-r-20">\n      <div id="verified-transaction-table" class="panel panel-default">\n        <div class="transaction-filter-container">\n        </div>\n        <div class="transaction-list-container"></div>\n      </div>\n    </div>\n  </div>\n</div>\n<div id="transaction-right-side-region" class="transaction-right-side-region verified_transaction-right-side-region hidden"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}