import React, { FC } from 'react';

import { useQueryClient } from 'react-query';

import toastr from '@lib/toastr';
import { QueryKey } from '@src/constants/query_keys';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';

import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import SingleJournalEntryDownload
  from '@src/components/reconciliation_center/journal_entry/single_journal_entry_download';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import ActivityAction from './action/activity_action';
import Table from '../journal_entries/header/table';

import detailStyles from './styles.module.scss';

const renderQboLink = (entry: IAdjustmentEntry) => {
  if (entry.qboError) {
    return (
      <span className="display-inline-flex in-grey-700 align-items-center" title={ entry.qboError }>
        <span className="icon-qbo-error font-18" />
        <span className="font-16 m-l-10">View in QuickBooks</span>
      </span>
    );
  }

  return (
    <a className="display-inline-flex in-green-600 align-items-center" href={ entry.qboLink?.qboLink } rel="noreferrer" target="_blank">
      <span className="icon-qbo-on font-18" />
      <span className="font-16 m-l-10">View in QuickBooks</span>
    </a>
  );
};

const EditPanel: FC<{
  data: IAdjustmentEntry;
  setIsShowAuditHistory?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ data, setIsShowAuditHistory }) => {
  const { isOpen, open: openModal, close } = useDrawer();
  const queryClient = useQueryClient();
  const business = useBusinessContext();

  const { source, fromQbo, entryDate } = data;

  const openDocument = () => {
    if (source.id) {
      window.open(`/document/${source.id}`);
    } else {
      toastr.error('Document not found', 'Error');
    }
  };

  const onSuccess = async () => {
    const section = [QueryKey.adjustmentEntry, data.docytId];
    await queryClient.invalidateQueries(section);
  };

  const renderButtonPanel = () => {
    if (source?.type === 'Normal') {
      if (
        business?.reconciliationStartDate
        && entryDate
        && (new Date(entryDate) < new Date(business.reconciliationStartDate))
      ) {
        return (
          <Tooltip.Hover
            content="Cannot edit the journal entry since the journal entry belongs to a period before book closing date."
          >
            <Button className={ detailStyles['edit-btn'] } variant="link">
              Edit
            </Button>
          </Tooltip.Hover>
        );
      }
      return (
        <>
          <Button
            variant="link"
            onClick={ openModal }
          >
            Edit
          </Button>
          <JournalDrawer
            drawerControl={ { isOpen, open: openModal, close } }
            title="Edit Journal Entry"
          >
            <Table close={ close } editData={ data } onSuccess={ onSuccess } />
          </JournalDrawer>
        </>
      );
    }
    return (
      <Button
        className={ detailStyles['edit-btn'] }
        variant="link"
        onClick={ openDocument }
      >
        View
        {' '}
        {source?.type}
      </Button>
    );
  };

  return (
    <div className={ detailStyles['edit-container'] }>
      { renderQboLink(data) }
      <div className={ detailStyles['split-line'] } />
      {
        !fromQbo && (
          <>
            { renderButtonPanel() }
            <SingleJournalEntryDownload id={ data.id } />
          </>
        )
      }
      <div className={ detailStyles['split-line'] } />
      <ActivityAction setIsShowAuditHistory={ setIsShowAuditHistory } />
    </div>
  );
};

export default EditPanel;
