import React, { useEffect } from 'react';

import { IIntegration } from '@src/types/integrations/integrations';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { useDropdown } from '@src/components/ui_v2/dropdown/hooks';
import EditMappingIcon from '@src/components/utils/icomoon/edit_mapping';

import { useChangeMappingRevenueReportModal } from '../modal/change_mapping_revenue_report_action_modal';

interface IChangeMappingIntegrationActionProps{
  integration?: IIntegration,
  revenueSystem?: IRevenueSystem,
  revenueService: IRevenueService,
}

const ChangeMappingIntegrationAction = ({
  integration,
  revenueSystem,
  revenueService,
}: IChangeMappingIntegrationActionProps) => {
  const changeMappingRevenueReportModal = useChangeMappingRevenueReportModal();
  const popover = useDropdown();

  const changeMapping = () => {
    changeMappingRevenueReportModal.open();
  };

  useEffect(() => {
    if (changeMappingRevenueReportModal.props.isOpen && popover) {
      popover.hide();
    }
  }, [changeMappingRevenueReportModal.props.isOpen, popover]);

  return (
    <>
      <ActionsDropdown.Action
        icon={ <EditMappingIcon fontSize={ 20 } /> }
        title="Change Mapping"
        onSelect={ changeMapping }
      />
      <changeMappingRevenueReportModal.Component
        integration={ integration }
        revenueService={ revenueService }
        revenueSystem={ revenueSystem }
        { ...changeMappingRevenueReportModal.props }
      />
    </>

  );
};
export default React.memo(ChangeMappingIntegrationAction);
