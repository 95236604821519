import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

export const useVerifyTransaction = (document: ITransactionServiceDocument, payrollBCOAId: number | null) => {
  const business = useBusinessContext();
  const transactionTypes = window.configData.transaction_types;

  const checkDocumentRelated = () => {
    return !document.noDocumentRequired
      || (document.noDocumentRequired && document.documentNote?.note);
  };

  const getCategoryLength = () => {
    return document.category ? document.category.split(';').length : 0;
  };

  switch (document.transactionType) {
    case transactionTypes.EXPENSE: {
      const isVendorEmpty = !document.vendorId || String(document.vendorId) === 'undefined';
      const isCategorySplitsEmpty = !getCategoryLength();
      const isDocumentNoteRequired = document.noDocumentRequired && !document.documentNote?.note;

      return isVendorEmpty || isCategorySplitsEmpty || isDocumentNoteRequired;
    }

    case 'other_income': {
      const categoryLength = getCategoryLength();
      const documentRelated = checkDocumentRelated();
      return !categoryLength || !documentRelated;
    }

    case transactionTypes.TRANSFER: {
      const requiredValue = document.fromAccountId && document.toAccountId;
      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.LOAN_PAYMENTS: {
      const totalAmount = (parseFloat(document.principalAmount || '0')
        + parseFloat(document.interestAmount || '0')).toFixed(2);
      const txnAmount = (-document.amount).toFixed(2);
      const amountEqual = totalAmount === txnAmount;

      const requiredValue = document.subType
        && document.principalChartOfAccountId
        && document.interestChartOfAccountId
        && document.principalAmount
        && document.interestAmount
        && amountEqual;

      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.REVENUE: {
      const { revenueCenterEnabled } = business;
      const categoryLength = getCategoryLength();
      const processorValue = document.userPaymentProcessorId;

      const isValid = ((!revenueCenterEnabled && categoryLength > 0) || revenueCenterEnabled)
        && processorValue
        && checkDocumentRelated();

      return !isValid;
    }

    case transactionTypes.EQUITY_INVESTMENT: {
      const categoryLength = getCategoryLength();
      return !(document.subType && categoryLength && checkDocumentRelated());
    }

    case transactionTypes.PAYROLL: {
      return !(payrollBCOAId && checkDocumentRelated());
    }

    case transactionTypes.REVENUE_REVERSAL: {
      const { revenueCenterEnabled } = business;
      const requiredValue = revenueCenterEnabled
        ? document.userPaymentProcessorId
        : document.chartOfAccountId;
      return !requiredValue;
    }

    case transactionTypes.EQUITY_WITHDRAWAL: {
      const requiredValue = document.subType && document.chartOfAccountId;
      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.ASSET_PURCHASE: {
      const requiredValue = document.subType && document.chartOfAccountId;
      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.OTHER_RECEIVABLES: {
      const requiredValue = document.subType && document.chartOfAccountId;
      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.LIABILITY_PAYMENTS: {
      const requiredValue = document.subType && document.chartOfAccountId;
      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.LOAN_PROCEEDS: {
      const requiredValue = document.subType && document.chartOfAccountId;
      return !(requiredValue && checkDocumentRelated());
    }

    case transactionTypes.REFUND: {
      const isVendorEmpty = !document.vendorId || String(document.vendorId) === 'undefined';
      const isCategorySplitsEmpty = !getCategoryLength();
      const isDocumentNoteRequired = document.noDocumentRequired && !document.documentNote?.note;

      return isVendorEmpty || isCategorySplitsEmpty || isDocumentNoteRequired;
    }

    default:
      return true;
  }
};
