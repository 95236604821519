import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import { ChartOfAccountFilterField, ResourceFilterField } from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

const GROUP_OPTIONS = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'Advanced Settlement Journal Entry',
    label: 'Advanced Settlement Journal Entry',
  },
  {
    value: 'Inter Company Expense Journal Entry',
    label: 'Inter Company Expense Journal Entry',
  },
  {
    value: 'Manual Journal Entry',
    label: 'Manual Journal Entry',
  },
  {
    value: 'Matching Adjustment Journal Entry',
    label: 'Matching Adjustment Journal Entry',
  },
  {
    value: 'Period Accrual Journal Entry',
    label: 'Period Accrual Journal Entry',
  },
  {
    value: 'Uncleared Documents Adjustment Journal Entry',
    label: 'Uncleared Documents Adjustment Journal Entry',
  },
];

interface Props {
}

const Component: FC<Props> = () => {
  const business = useBusinessContext();
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { (visibleColumnNames.includes('entry_date') && (
      <Filter.DateField
        name="entry_date"
      />
      )) }

      { (visibleColumnNames.includes('docyt_id') && (
      <Filter.TextField
        label="Journal Entry ID"
        name="docyt_id"
        placeholder="Journal Entry ID"
      />
      )) }

      { (visibleColumnNames.includes('group') && (
      <Filter.DropdownField
        label="Type"
        name="group"
        options={ GROUP_OPTIONS }
        placeholder="Type"
      />
      )) }

      { (visibleColumnNames.includes('debit_categories') && (
      <ChartOfAccountFilterField
        businessId={ business.id }
        name="debit_chart_of_account_id"
        placeholder="Debit Account"
      />
      )) }

      { (visibleColumnNames.includes('credit_categories') && (
      <ChartOfAccountFilterField
        businessId={ business.id }
        name="credit_chart_of_account_id"
        placeholder="Credit Account"
      />
      )) }

      { (visibleColumnNames.includes('created_by') && (
      <ResourceFilterField
        label="Created By"
        name="created_by"
        placeholder="Created By"
        resource="users"
      />
      )) }

      { (visibleColumnNames.includes('debits') && (
      <Filter.AmountField
        name="debits"
        valueProps={ { fixedDecimalScale: false, placeholder: 'Amount' } }
      />
      )) }
    </Filter.TableContainer>
  );
};

export default React.memo(Component);
