import React, { useState } from 'react';

import { useReportServiceBudgetContext } from '@src/hooks/contexts/budget_context';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useExportBudgetAsExcel } from '@src/hooks/queries/report_service/report_service_budget_items';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';
import { apiYearToDate, endOfLastYearApiDate } from '@src/utils/date_helpers';

import Dropdown from '@src/components/ui/dropdown';
import Tooltip from '@src/components/ui_v2/tooltip';
import { EllipsisIcon } from '@src/components/utils/fa_icons';
import { ImportBudgetIcon, ExportBudgetIcon, UnableToSyncIcon } from '@src/components/utils/icomoon';
import { useItemsSelector } from '@src/components/utils/items_selector';

import { useImportBudgetModal } from '../modal';

interface IAutoFillMenuProps {
  filterParams: any,
  onSetDraft: (value: boolean) => void,
  onCreateExportData: (result: boolean) => void,
  onSuccessUpload: (token: string) => void,
}

const AutoFillMenu = ({
  filterParams,
  onSetDraft,
  onCreateExportData,
  onSuccessUpload,
}: IAutoFillMenuProps): JSX.Element => {
  // Get the business id from BusinessContext
  const business = useBusinessContext();
  const [formulaEnabled, setFormulaEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.RC_FILES_WITH_FORMULA_FLAG,
      String(business.id),
    ),
  );
  if (formulaEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.RC_FILES_WITH_FORMULA_FLAG,
      String(business.id),
    ).then((res) => {
      setFormulaEnabled(res);
    });
  }

  const selector = useItemsSelector();
  const downloadReports = useExportBudgetAsExcel();
  const { mutate } = downloadReports;
  const budget = useReportServiceBudgetContext();
  const importModal = useImportBudgetModal();

  const handleExport = () => {
    const startDateStr = apiYearToDate(`${budget.year}`) || `${budget.year}`;
    const endDateStr = endOfLastYearApiDate(new Date(`${budget.year + 1}`));

    if (filterParams.accountingClassId === '') {
      delete filterParams.accountingClassId;
    }

    mutate(
      { startDate: startDateStr, endDate: endDateStr, budgetID: budget.id.toString(), filter: filterParams },
      { onSuccess: () => onCreateExportData(true) },
    );
  };

  return (
    <div className="auto-fill-menu">
      <importModal.Component
        { ...importModal.props }
        budget={ budget }
        successUpload={ onSuccessUpload }
      />
      <Dropdown className="report-budget-export-action">
        <Dropdown.LinkToggle>
          <EllipsisIcon fontSize={ 18 } mt={ 5 } variant="horizontal" />
        </Dropdown.LinkToggle>
        <Dropdown.Menu alignment="right">
          <Dropdown.Item className="budget-action-button" onClick={ importModal.open }>
            <ImportBudgetIcon fontSize={ 22 } mr={ 14 } />
            Import
          </Dropdown.Item>
          <Dropdown.Item className="budget-action-button" onClick={ handleExport }>
            <ExportBudgetIcon fontSize={ 22 } mr={ 14 } />
            Export as excel
            {' '}
            {formulaEnabled && (
              <Tooltip.Hover
                content={ (
                  <div className="tooltipContent">
                    To get the extended exported file to work correctly, please enable the Macros
                    functionality. Open the exported file and in the context window during opening or at the
                    top of the MS Excel window, click on Enable Macros.
                  </div>
                ) }
              >
                <UnableToSyncIcon className="icon icon-unable-to-sync m-l-20 exportIcon" />
              </Tooltip.Hover>
            )}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AutoFillMenu;
